import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'roster-for-req',
  templateUrl: './roster-for-req.component.html',
  styleUrls: ['./roster-for-req.component.css']
})
export class RosterForReqComponent implements OnInit {

  rosterReqObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
  }

  _formClear() {
    this.rosterReqObjectForm = this.formBuilder.group({
      addedManually: [''],
      joinedOn: ['']
    });
    this.formReady.emit(this.rosterReqObjectForm);
  }

}
