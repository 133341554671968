import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'query-opt',
  templateUrl: './query-opt.component.html',
  styleUrls: ['./query-opt.component.css']
})
export class QueryOptComponent implements OnInit {

  queryOptObjectForm:FormGroup;  
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
  }

  _formClear() {
    this.queryOptObjectForm = this.formBuilder.group({
      childQuery: ['']
    });
    this.formReady.emit(this.queryOptObjectForm);
  }

}
