import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Item, VehicleProgram, ItemInitiationForm2 } from 'app/core/model/itemAdminModels';


@Component({
  selector: 'vehicle-program-object',
  templateUrl: './vehicle-program-object.component.html',
  styleUrls: ['./vehicle-program-object.component.css']
})
export class VehicleProgramObjectComponent implements OnInit {

  vehicleProgramObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()
  @Input('screenType') screenType: string;
  @Input('item') item: Item;
  @Input('vehicleProgram') vehicleProgram: VehicleProgram;
  @Input('showfield') showfield: boolean;
  @Input('approvalOptions') approvalOptions: ItemInitiationForm2[];
  @Input('transBrandOptions') transBrandOptions: ItemInitiationForm2[];

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
    this._updateVehicleProgramObjectForm();
  }

  _formClear() {
    this.vehicleProgramObjectForm = this.formBuilder.group({
      listDomains: [''],
      id: [''],
      comments: [''],
      endOfLife: [''],
      changeLevel: [''],
      modelYearEnd: [''],
      altLabel: [''],
      comparatorProgram: [''],
      jobOne: [''],
      curApproval: [''],
      pmtDomain: [''],
      namePlate: [''],
      modelYear: [''],
      proramCode: [''],
      brand: [''],
      kickOff: ['']
    });
    this.formReady.emit(this.vehicleProgramObjectForm);
  }

  _updateVehicleProgramObjectForm() {
    console.log('brand for the engine prograsm>>>>::::::::',this.vehicleProgram.vehicle_program_brand);
    this.vehicleProgramObjectForm.patchValue({
      listDomains: this.vehicleProgram.vehicle_program_list_domains,
      id: this.item.id,
      comments: this.vehicleProgram.vehicle_program_comments,
      endOfLife: this.vehicleProgram.vehicle_program_end_of_life,
      changeLevel: this.vehicleProgram.vehicle_program_change_level,
      modelYearEnd: this.vehicleProgram.vehicle_program_model_year_end,
      altLabel: this.vehicleProgram.vehicle_program_alt_label,
      comparatorProgram: this.vehicleProgram.vehicle_program_comparator_prog,
      jobOne: this.vehicleProgram.vehicle_program_job_one,
      curApproval: this.vehicleProgram.curApproval,
      pmtDomain: this.vehicleProgram.vehicle_program_pmt_domain,
      namePlate: this.vehicleProgram.vehicle_program_name_plate,
      modelYear: this.vehicleProgram.vehicle_program_model_year,
      proramCode: this.vehicleProgram.vehicle_program_code,
      brand: this.setBrand(this.vehicleProgram.vehicle_program_brand),
      kickOff: this.vehicleProgram.vehicle_program_kick_off
    })
  }

  setBrand(vehicle_program_brand: string): any {
    console.log('vehicle is',vehicle_program_brand)
    if (vehicle_program_brand) {
      console.log('vehicle brand is condition',this.transBrandOptions)
      for (let brand of this.transBrandOptions) {
        console.log('let brand is',this.transBrandOptions)
        if (brand.label == vehicle_program_brand) {
          console.log('brand value is',brand.value)
          return brand;
        }
      }
    } else {
      return { label: 'None', value: 'none' }
    }
  }

}
