import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { UserNotification } from "../model/usernotify";
import { AnnualReview } from '../model/annualreview';

@Injectable({
	providedIn: 'root'
})
export class AfrInitalProcessService {

	private anualreviewURI = environment.apdmServiceUrl;

	private headers: HttpHeaders;

	cdsId: any;

	token: any;

	constructor(private http: HttpClient) {

		this.token = JSON.parse(sessionStorage.getItem('strAccessToken'));


		if (this.token) {
			this.cdsId = this.token.CommonName;
		}
		//console.log(' teamcreation-cdsid--->', this.cdsId);

		if (this.cdsId !== undefined) {
			this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
		} else {
			this.cdsId = 'Invalid';
			this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');

		}
	}



	postAnnualReview(mailContent: AnnualReview) {

		console.log("postUserNotificationEmail", mailContent);
		return this.http.post(this.anualreviewURI + 'annualperformreview', mailContent, {
			headers: this.headers,
		});
	}

	public deleteAllFiles(cdsid:any,year:any) {
		//  console.log(this.teamURI+'dataview',result );
		this.headers = new HttpHeaders().append('Content-Type', 'text/plain');
		return this.http.post(this.anualreviewURI + 'destroyall/'+year,cdsid,{
		  headers: this.headers,
		});
	  }

	public getAFRFileCount(cdsid,year){
		//console.log("getAFRFileCount...." ,cdsid );
		this.headers = new HttpHeaders().append('Content-Type', 'text/plain');
		return this.http.post(this.anualreviewURI + 'getcount'+'/'+ year, cdsid, {
			headers: this.headers,
		  });
	}


}
