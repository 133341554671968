import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'partbase-for-opt',
  templateUrl: './partbase-for-opt.component.html',
  styleUrls: ['./partbase-for-opt.component.css']
})
export class PartbaseForOptComponent implements OnInit {

  partBaseOptForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {

    this._formClear();
  }

  _formClear() {
    this.partBaseOptForm = this.formBuilder.group({
      base: [''],
      prefix: [''],
      name: [''],
      designTeam: ['']
    });
    this.formReady.emit(this.partBaseOptForm);
  }

}
