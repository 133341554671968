import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AfrReportService {

  private teamURI = environment.apdmServiceUrl;
  private headers: HttpHeaders;


  cdsId : any;

	token: any;

  constructor(private http: HttpClient) {

    this.token = JSON.parse(sessionStorage.getItem('strAccessToken'));


		if(this.token)
		{
		  this.cdsId = this.token.CommonName;
		}



		//console.log(' teamcreation-cdsid--->', this.cdsId);

		if (this.cdsId !== undefined ) {
		  this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
		} else {
		  this.cdsId = 'Invalid';
		  this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');

		}
  }


  public postAFRTable() {
    //  console.log(this.teamURI+'dataview',result );


    return this.http.post(this.teamURI + 'afrreport', {
      headers: this.headers,
    });
  }





}
