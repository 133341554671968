import { Component, OnInit } from '@angular/core';
import { DeleteFileService} from '../../core/service/delete-file.service';
import { Subscription } from 'rxjs';
import {AFRReport} from '../../core/model/afr-reports';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../../core/service/shared.service';
import { UrlConsumerService } from '../../../app/oauth/url-consumer.service';

@Component({
  selector: 'delete-files',
  templateUrl: './delete-files.component.html',
  styleUrls: ['./delete-files.component.css']
})
export class DeleteFilesComponent implements OnInit {

  cols: any[];
  Afrreport: AFRReport[];

  cdsid: string ="";
  year: number;
  
  constructor( private service: DeleteFileService, private _Activatedroute: ActivatedRoute, private shared: SharedService, private userService:UrlConsumerService) { }

  subsciptiondatatable: Subscription;

  ngOnInit() { 

    this._Activatedroute.paramMap.subscribe(params => {
      this.userService.afrcdsid = params.get('cdsid');
      this.userService.afryear = params.get('year');
      this.cdsid = params.get('cdsid');
      this.year = Number(params.get('year'))

      this.shared.updateAFRLink({cdsid: this.cdsid, year:this.year})
    });

    this._Activatedroute
      .data
      .subscribe(data => 
        {
          if(data.afrlink)
          {
            sessionStorage.setItem('afrlink','true');
            this.shared.updateadminmenu('true');
          }
        console.log("router data",data)
      }
        );

    this.cols = [
      { field: 'labelStr', header: 'Folder Name'},
      { field: 'label', header: 'Filename' },
      { field: 'version', header: 'Ver'},
      { field: 'id', header: 'File ID'},
      { field: 'comments', header: 'Comments'},
    ];

    this.subsciptiondatatable = this.service.getdeleteFile(this.cdsid,this.year).subscribe((data: any) => {

    this.Afrreport = data;
     
 
      console.log('deletefiles', data);
 
 
    }, (error) => {
      
    })
 
 
  }

}
