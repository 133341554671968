import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Item, Ref } from 'app/core/model/itemAdminModels';

@Component({
  selector: 'ref-object',
  templateUrl: './ref-object.component.html',
  styleUrls: ['./ref-object.component.css']
})
export class RefObjectComponent implements OnInit {

  refObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()
  @Input('screenType') screenType: string;
  @Input('item') item: Item;
  @Input('ref') ref: Ref;
  @Input('showfield') showfield: boolean;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
    this._updateRefObjectForm();
  }

  _formClear() {
    this.refObjectForm = this.formBuilder.group({
      id: [''],
      toType: [''],
      toId: ['']
    });
    this.formReady.emit(this.refObjectForm);
  }

  _updateRefObjectForm() {
    this.refObjectForm.patchValue({
      id: this.item.id,
      toType: this.ref.to_type,
      toId: this.ref.to_id
    })
  }

}
