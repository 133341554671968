import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MenuItem } from 'primeng/primeng';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { UrlConsumerService } from '../../../app/oauth/url-consumer.service';
import { SharedService } from '../../core/service/shared.service';
@Component({
	selector: 'afr-menu',
	templateUrl: './afr-menu.component.html',
	styleUrls: ['./afr-menu.component.css']
})
export class AfrMenuComponent implements OnInit {


	afrMenuItems: MenuItem[];
	cdsid: string;
	year: string;

	constructor(private router: Router, private userService:UrlConsumerService, private shared: SharedService) {
		console.log("user service : ",this.userService);
		this.shared.receiveAFRLink().subscribe(obj => {
			if (obj)
			{
				console.log("After AFR home load ",obj)
				this.userService.afrcdsid = obj.cdsid;
				this.userService.afryear = obj.year;
				this.defineMenu();
			}
		  });
		  this.defineMenu();
		
	}

	ngOnInit() {
	}

	defineMenu(){
		this.afrMenuItems = [
			{
				label: 'AFR Initiation Process',
				routerLink: 'runAnnualFileReview',

			},
			{
				label: 'AFR Home',
				// routerLink: 'afrhome',
				// queryParams:{cdsid:this.userService.userInfo.cdsId,year:(new Date()).getFullYear()}
				routerLink: ['menu/afrhome',this.userService.afrcdsid?this.userService.afrcdsid.toLowerCase(): this.userService.userInfo.cdsId.toLowerCase(), this.userService.afryear? this.userService.afryear : (new Date()).getFullYear()]
			},
			{
				label: 'AFR Reports',
				routerLink: 'reportMain',
				
			},
			{
				label: 'AFR-Help',
				items: [
					{
						label: 'AFR - Folder Level',
						// routerLink: 'retentionfolderlevel',
						routerLink: ['retentionfolderlevel',this.userService.afrcdsid?this.userService.afrcdsid.toLowerCase(): this.userService.userInfo.cdsId.toLowerCase(), this.userService.afryear? this.userService.afryear : (new Date()).getFullYear()]
						
					},
					{
						label: 'AFR - Retention Code Level',
						// routerLink: 'retentioncodelevel',
						routerLink: ['retentioncodelevel',this.userService.afrcdsid?this.userService.afrcdsid.toLowerCase(): this.userService.userInfo.cdsId.toLowerCase(), this.userService.afryear? this.userService.afryear : (new Date()).getFullYear()]
						
					},
					{
						label: 'Retention Codes',
						routerLink: 'retention',
						
					},
					{
						label: 'Ford  Suspension Order Site',
						url: 'http://www.so.ford.com/sorp/default.asp',
						target: '_blank',
					},
					{
						label: 'AFR-Help',
						// routerLink: 'afrHelp',	
						url: environment.redirectUrl+'/assets/image/afr_help.html',
						target: '_blank'	
					}
				]
			},
			// {
			// 	label: 'Log Out',
			// 	routerLink: '/admin-portal/login',
			// //	command: (event) => this.setDocTitle('Admin')
			// },

		];

	}

}
