export const environment = {
	production: false,
    envName: 'DEV',
 
	
	apdmServiceUrl: 'https://api.d01e.gcp.ford.com/apdmcore/v4.0/',
    apdmWebDavUrl: 'https://api.d01e.gcp.ford.com/downstream/v4.0/',
	apdmServiceDownloadUrl:'https://api.d01e.gcp.ford.com/folderdownload/v4.0/',
 
    //ADFS
    adfsUrl: 'https://corpqa.sts.ford.com/adfs/oauth2/authorize',
    clientID: 'urn:apdatamanager:clientid:web_website001:dev',
	resource: 'urn:apdatamanager:resource:web_website001:dev', 
    // redirectUrl: 'https://localhost:4200',
    redirectUrl: 'https://apdm-frontend-dev-405978628231.us-central1.run.app',
    // accessTokenSplit: '.com/#',
    // bucketName: 'apdms3bucketdev'
	folderDownloadMaxLimit: 104857600.0 // 100 GB
};
