import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'ret-schedule-opt',
  templateUrl: './ret-schedule-opt.component.html',
  styleUrls: ['./ret-schedule-opt.component.css']
})
export class RetScheduleOptComponent implements OnInit {

  retScheduleOptObjectForm:FormGroup;  
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
  }

  _formClear() {
    this.retScheduleOptObjectForm = this.formBuilder.group({
      historicalStatus: [''],
      category: [''],
      period: [''],
      title: [''],
      taxStatus: ['']
    });
    this.formReady.emit(this.retScheduleOptObjectForm);
  }

}
