import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FaqComponent } from '../help/faq/faq.component';
import { ApdmHelpComponent } from '../help/apdm-help/apdm-help.component';
import {EmailDownloadComponent} from "../EmailDownload/email-download.component";
import {BrowserModule} from "@angular/platform-browser";
import {ProgressBarModule} from "primeng/primeng";
import { DialogModule } from 'primeng/dialog';
@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        ProgressBarModule,
        DialogModule
    ],
  declarations: [
    FaqComponent,
	  ApdmHelpComponent,
	  EmailDownloadComponent
  ],
  exports: [
    FaqComponent,
	  ApdmHelpComponent,
	  EmailDownloadComponent
  ]
})
export class HelpModule { }
