import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders} from '@angular/common/http';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DeleteFileService {

  private teamURI = environment.apdmServiceUrl;

  private headers: HttpHeaders;

  constructor(private http: HttpClient) { }


  public getdeleteFile(cdsid:any,year:any){
    //  console.log(this.teamURI+'dataview',result );
	  this.headers = new HttpHeaders().append('Content-Type', 'text/plain');
	  return this.http.post(this.teamURI+'listdeletedfiles/'+year, cdsid ,{
        headers: this.headers,
        });
    }
}
