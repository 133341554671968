import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'building-for-opt',
  templateUrl: './building-for-opt.component.html',
  styleUrls: ['./building-for-opt.component.css']
})
export class BuildingForOptComponent implements OnInit {

  buildingOptObjectForm:FormGroup;  
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
  }

  _formClear() {
    this.buildingOptObjectForm = this.formBuilder.group({
      country: [''],
      street: [''],
      comments: [''],
      fullName: [''],
      city: [''],
      name: [''],
      postCode: [''],
      state: [''],
    
      
    });
    this.formReady.emit(this.buildingOptObjectForm);
  }

}
