import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Item, EngineProgram, ItemInitiationForm2 } from 'app/core/model/itemAdminModels';

@Component({
  selector: 'engine-program-object',
  templateUrl: './engine-program-object.component.html',
  styleUrls: ['./engine-program-object.component.css']
})
export class EngineProgramObjectComponent implements OnInit {

  engineProgramObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()
  @Input('screenType') screenType: string;
  @Input('item') item: Item;
  @Input('engineProgram') engineProgram: EngineProgram;
  @Input('showfield') showfield: boolean;
  @Input('engineArchOptions') engineArchOptions: ItemInitiationForm2[];
  @Input('engineFuelOptions') engineFuelOptions: ItemInitiationForm2[];
  numvalvesOptions: ItemInitiationForm2[];
  curApprovalOptions: ItemInitiationForm2[];
  @Input('leadVehicleOptions') leadVehicleOptions: ItemInitiationForm2[];
  @Input('transBrandOptions') transBrandOptions: ItemInitiationForm2[];

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
    this._updateEngineProgramObjectForm();
  }

  _formClear() {
    this.numvalvesOptions = [
      { label: '2', value: '2' },
      { label: '3', value: '3' },
      { label: '4', value: '4' },
      { label: '5', value: '5' },
      { label: '12', value: '12' }
    ];
    this.curApprovalOptions = [
      { label: 'Benchmark', value: '50' },
      { label: 'Book Shelf', value: '40' },
      { label: 'Concept', value: '30' },
      { label: 'Design Intent', value: '20' },
      { label: 'Production', value: '10' },
      { label: 'Sandbox', value: '60' }
    ];
    this.engineProgramObjectForm = this.formBuilder.group({
      id: [''],
      config: [''],
      arch: [''],
      altLabel: [''],
      numValves: [''],
      curApproval: [''],
      fuel: [''],
      leadVehicle: [''],
      modelYear: [''],
      egmtSystemId: [''],
      displacement: [''],
      egmtEngineId: [''],
      brand: ['']
    });
    this.formReady.emit(this.engineProgramObjectForm);
  }

  _updateEngineProgramObjectForm() {
    console.log('brand for the engine prograsm>>>>::::::::',this.engineProgram.engine_program_brand);
    this.engineProgramObjectForm.patchValue({
      id: this.item.id,
      config: this.engineProgram.engine_program_config,
      arch: this.setArch(this.engineProgram.engine_program_arch),
      altLabel: this.engineProgram.engine_program_alt_label,
      numValves: this.setNumValves(this.engineProgram.engine_program_num_val),
      curApproval: this.setCurApproval(this.engineProgram.engine_program_cur_approval),
      fuel: this.setfuel(this.engineProgram.engine_program_fuel),
      leadVehicle: this.setleadVehicle(this.engineProgram.engine_program_lead_vehicle),
      modelYear: this.engineProgram.engine_program_modal_year,
      egmtSystemId: this.engineProgram.engine_program_pptm001_system_d,
      displacement: this.engineProgram.engine_program_displacement,
      egmtEngineId: this.engineProgram.engine_program_pptm001_engine_d,
      brand:this.setBrand(this.engineProgram.engine_program_brand)
    })
  }



  setArch(engine_program_arch: string): any {
    if (engine_program_arch) {
      for (let arch of this.engineArchOptions) {
        if (arch.value.includes(engine_program_arch)) {
          return arch;
        }
      }
    } else {
      return { label: 'None', value: 'none' }
    }

  }

  
  setBrand(engine_program_brand: string): any {
    
    console.log('brand is',engine_program_brand)
    if (engine_program_brand) {
      console.log('engine brand is condition',engine_program_brand)
      for (let brand of this.transBrandOptions) {
        console.log('let brand is',brand.label)
        if (brand.label.includes(engine_program_brand)) {
          console.log('brand value is',brand.value)
          return brand;
        }
      }
    } else {
      return { label: 'None', value: 'none' }
    }
  }


  setleadVehicle(engine_program_lead_vehicle: any): any {
    if (engine_program_lead_vehicle) {
      for (let leadVehicle of this.leadVehicleOptions) {
        if (leadVehicle.value.includes(engine_program_lead_vehicle)) {
          return leadVehicle;
        }
      }
    } else {
      return { label: 'None', value: 'none' }
    }
  }

  setfuel(engine_program_fuel: string): any {
    if (engine_program_fuel) {
      for (let fuel of this.engineFuelOptions) {
        if (fuel.value === engine_program_fuel.trim()) {
          return fuel;
        }
      }
    } else {
      return { label: 'None', value: 'none' }
    }
  }

  setCurApproval(engine_program_cur_approval: any): any {
    for (let curApproval of this.curApprovalOptions) {
      if (curApproval.value.includes(engine_program_cur_approval)) {
        return curApproval;
      }
    }
  }

  setNumValves(engine_program_num_val: string): any {
    for (let numValves of this.numvalvesOptions) {
      if (numValves.value.includes(engine_program_num_val)) {
        return numValves;
      }
    }
  }

  



}
