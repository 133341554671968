export class ViewFile {
    category: string;
    period: string;
    retCode: string;
    title: string;
}


export class RetentionCodeLevelHeaders {
    count: number;
    fileFolderName: string;
    id: string;
    retention: string;
    suspOrder: string;
    viewFiles: string;

}

export class listAfrFilesForFolderRequest {
    year: string;
    cdsid: string;
    itemid: string;
    retstatus: string;
    retcode:string;
     sorder:string;
}




export class FileView {
    delete: string;
    fileFolderName: string;
    id: string;
    lastModifiedDate: string;
    retention: string;
    suspOrder: string;
    update: string;
}




export class RetentionCodeLevelHeaderResposne extends RetentionCodeLevelHeaders {
    data: RetentionCodeLevelHeaders[];
}