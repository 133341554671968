import { OwnerSearchResult } from "./searchModel";


export class ItemModifyForm {
    item: Item;
    associatedTable: AssociatedTable;
    aclList: ACL[];
    inDirectAclList: ACL[];
    securityList: ItemInitiationForm2[];
    usageList: ItemInitiationForm2[];
    retStatusList: ItemInitiationForm2[];
    subjectList: ItemInitiationForm2[];
    approvalList: ItemInitiationForm2[];
    domainList: ItemInitiationForm2[];
    leadVehicleList: ItemInitiationForm2[];
    valveTrainList: ItemInitiationForm2[];
    engineFuelList: ItemInitiationForm2[];
    engineArchList: ItemInitiationForm2[];
    transBrandList: ItemInitiationForm2[];
    transArchList: ItemInitiationForm2[];
    driveTrainList: ItemInitiationForm2[];
}

export class ItemTypeList {
    id: number;
    label: string;
}

export class ModifyProgram {
    id: number;
    label: string;
    owner: string;
    itemTypes: string[];
    engine: string;
    transmission: string;
    vehicle: string;
    parent: string;
    selectedACLs: string[];
}

export class Item {
    id: any;
    type: any;
    security_level: any;
    securityLevel: ItemInitiationForm2;
    owner: any;
    steward: any;
    created_on: any;
    modified_on: any;
    published_on: any;
    domain: string;
    cpscCPmtList: ItemInitiationForm2;
    usage: string;
    subject: string;
    subjectList: ItemInitiationForm2;
    milestone: any;
    program: any;
    leader: any;
    parent: any;
    label: string;
    display_order: any;
    approval_required: string;
    stdApprovalProcess: ItemInitiationForm2;
    ret_schedule: string;
    expiration_date: any;
    ret_status: string;
    retStatus: ItemInitiationForm2;
    hold_count: any;
    keep_all_versions: string;
    lock_on_checkout: string;
    lockOnCheckout: ItemInitiationForm2;
    notify_on_read_denied: string;
    notifyOnReadDenied: ItemInitiationForm2;
    default_server: string;

    security_level_desc: string;
    owner_name: string;
    steward_name: string;
    std_type: string;
    frozen_on: any;
    superseded_on: any;
    deleted_on: any;
    folder: string;
    version: string;
    comments: string;
    psel_action: string;
    sel_action: string;
    folderType: string;
    created_date: string;
    modified_date: string;
    itemType: string;
    list_domains: string;
    pmt_domain: string;

    ownerSearch: OwnerSearchResult;
    stewardSearch: OwnerSearchResult;
}

export class AssociatedTable {
    engine: Engine;
    domainClass: Domain;
    folderItem: Folder;
    transProgram: Trans_Program;
    vehicleProgram: VehicleProgram;
    engineProgram: EngineProgram;
    attachedFile: AttachedFile;
    attachedAttr: AttachedAttr;
    lock: Lock;
    acl: ACL;
    part: Part;
    vehicleConfig: VehicleConfig;
    transConfig: TransConfig;
    engineConfig: EngineConfig;
    powerTrain: PowerTrain;
    ref: Ref;
    sdrc: Sdrc;
    drawing: Drawing;
    optegra: Optegra;
    jlrGroupRegister: JlrGroupRegister;
    jlrCaeFolder: JlrCaeFolder;
    ptnvhqosTest: PtnvhqosTest;
    ptnvhqosReport: PtnvhqosReport;
    stdTransArch: StdTransArch;
    testlab: Testlab;
    url: Url;
    query: Query;
}



export class Engine {
    id: any;
    engine_serial_num: string;
    engine_worktask_num: string;
    engine_part: string;
}

export class Domain {
    std_domain_comments: string;
    std_domain_code: string;
    std_domain: string;
    std_label: string;
    std_domain_id: string;
    flag: boolean;
}

export class Folder {
    id: any;
    folder_project_id: string;
    folder_purchase_order: string;
    folder_vendor: any;
    folder_comments: string;
}

export class Trans_Program {
    id: any;
    transprog_cur_approval: any;
    transprog_design_team_id: any;
    transprog_design_team: string;
    transprog_lead_vehicle: any;
    transprog_model_year: string;
    transprog_torque_capacity: string;
    transprog_kick_off: string;
    transprog_job_one: string;
    transprog_end_of_life: string;
    transprog_drive_train: string;
    transprog_comp_prog: string;
    transprog_change_level: string;
    transprog_comments: string;
    transprog_model_year_end: string;
    transprog_config: string;
    transprog_rms_prog_name: string;
    transprog_num_speeds: string;
    transprog_life_code: string;
    transprog_work_task_num: string;
    transprog_continuous: string;
    transprog_old_name: string;
    transprog_alt_label: string;
    transprog_list_domain: string;
    transprog_pmt_domain: string;
    end_of_life: any;
    job_one: any;
    kick_off: any;
    transprog_brand: string;
}

export class VehicleProgram {
    id: any;
    vehicle_program_cur_approval: any;
    curApproval: ItemInitiationForm2;
    vehicle_program_comparator_prog: any;
    vehicle_program_design_team_id: any;
    vehicle_program_design_team: string;
    vehicle_program_code: string;
    vehicle_program_change_level: string;
    vehicle_program_comments: string;
    vehicle_program_name_plate: string;
    vehicle_program_alt_label: string;
    vehicle_program_list_domains: string;
    vehicle_program_pmt_domain: string;
    vehicle_program_model_year_end: any;
    vehicle_program_model_year: any;
    vehicle_program_end_of_life: string;
    vehicle_program_job_one: string;
    vehicle_program_kick_off: string;
    end_of_life: any;
    job_one: any;
    kick_off: any;
    vehicle_program_brand: string;
}

export class EngineProgram {
    id: any;
    altLabel: string;
    arch: string;
    bankAngle: any;
    changeLevel: string;
    comments: string;
    config: string;
    curApproval: any;
    designTeam: any;
    displacement: any;
    endOfLife: any;
    fuel: string;
    jobOne: any;
    kickOff: any;
    lifeCode: string;
    listDomains: string;
    modelYear: any;
    modelYearEnd: any;
    numValves: any;
    other: string;
    pptm001SystemD: any;
    pptm002EngineD: any;
    rmsProgramName: string;
    valveTrain: string;
    workTaskNum: string;
    ComparatorProgram: any;
    stdDomain: string;

    engine_program_cur_approval: any;
    engine_program_cur_approval_des: string;
    engine_program_design_team_id: any;
    engine_program_design_team: string;
    engine_program_lead_vehicle: any;
    engine_program_modal_year: string;
    engine_program_displacement: string;
    engine_program_fuel: string;
    engine_program_value_train: string;
    engine_program_num_val: string;
    engine_program_bank_angle: string;
    engine_program_comp_prog: string;
    engine_program_change_level: string;
    engine_program_other: string;
    engine_program_comments: string;
    engine_program_arch: string;
    engine_program_config: string;
    engine_program_work_task: string;
    engine_program_model_year_end: string;
    engine_program_rms_prog: string;
    engine_program_life_code: string;
    engine_program_list_domain: string;
    engine_program_alt_label: string;
    engine_program_pmt_domain: string;
    engine_program_end_of_life: string;
    engine_program_kick_off: string;
    engine_program_job_one: string;
    engine_program_brand: string;
    engine_program_pptm001_system_d: string;
    engine_program_pptm001_engine_d: string;
}

export class AttachedFile {
    id: any;
    attached_owner: any;
    attached_owner_desc: string;

    file_is_default: string;
    file_version: string;
    file_prev_version: string;
    file_actual_server: string;
    file_fmccrypt_key: string;
    file_accessed_on: string;
    file_compression: string;
    file_checksum: string;
    file_backup_path: string;
    file_external_mime_type: string;
    file_comments: string;
    file_mime_type: string;
    file_deleted_from_disk_on: string;
    file_path: string;
    file_mime_param: string;
    file_ret_checkpoint: string;
    file_is_encrypted: string;
    file_mime_subtype: string;
    file_ret_status: string;
    file_st_mode: any;
    file_view_count: any;
    file_stored_size: any;
    file_actual_size: any;
    file_orig_stored_size: any;

    created_on: any;
    modified_on: any;
    published_on: any;
    accessed_on: any;
    Deleted_on: any;
    S3_Key: string;

    itemRows: any[];
}

export class AttachedAttr {
    id: any;
    attached_type: any;
    attached_owner: any;
    attached_attr_name: string;
    attached_attr_num: any;
    attached_attr_str: string;
    attached_attr_date: string;
    created_on: any;
    modified_on: any;
    date_val: any;
}

export class Lock {
    lock_dept: any;
    lock_expires_on: any;
    lock_started_on: any;
    id: any;
    owner: any;
    lock_owner_name: string;
}

export class ACL {
    actionID: string;
    id: any;
    teamId: any;
    queryAccess: string;
    readAccess: string;
    createAccess: string;
    writeAccess: string;
    deleteAccess: string;
    team: string;
    subTeam: string;
    teamType: string;
    label: string;
    aclList: ACL[];
    selaclList: ACL[];
}

export class Part {
    id: any;
    part_num: string;
    part_base: string;
    part_change_level: string;
    part_name: string;
    part_comments: string;
    part_prefix: string;
    part_comparator_num: string;
    part_design_team: string;
    part_designteam_id: any;
}

export class VehicleConfig {
    id: any;
    vehicle_config_program: any;
    vehicle_config_cur_approval: any;
    vehicle_config_engine: any;
    vehicle_config_trans: any;
    vehicle_config_zero_to_sixty: any;
    vehicle_config_fuel_econ: any;
    vehicle_config_weight: any;
    vehicle_config_body_style: string;
    vehicle_config_approvalList_val: string;
    vehicle_config_approvalList_label: string;
}

export class TransConfig {
    trans_program: string;
    cur_approval: string;
}

export class EngineConfig {
    id: any;
    engine_config_program: any;
    engine_config_curappr: any;
    true_displacement: string;
    true_fuel: string;
    true_value_train: string;
    true_num_values: string;
    true_bank_angle: string;
    max_horse_power: string;
    max_torque: string;
    max_rpm: string;
    torque_curve: string;
    engine_config_comments: string;
    true_config: string;
}

export class PowerTrain {
    trans_program: string;
    engine_program: string;
}

export class Ref {
    to_id: string;
    to_type: string;
}

export class Sdrc {
    id: any;
    sdrc_cur_approval: any;
    sdrc_num: string;
    sdrc_revision: string;
    sdrc_version: string;
    sdrc_name: string;
    sdrc_metaphase_class: string;
    sdrc_ideas_type: string;
    sdrc_tdm_status: string;
    sdrc_is_metaphase_superceded: string;
    sdrc_is_metaphase_checkedout: string;
    sdrc_is_metaphase_frozen: string;
    sdrc_tdm_id: string;
    sdrc_tdm_library: string;
    sdrc_tdm_project: string;
    sdrc_tdm_creator: string;
    sdrc_metaphase_creator: string;
    sdrc_last_checkout_user: string;
    sdrc_last_checkin_user: string;
    sdrc_metaphase_id: string;
    sdrc_tdm_created_on: string;
    sdrc_tdm_modified_on: string;
    sdrc_metaphase_created_on: string;
    sdrc_metaphase_modified_on: string;
    sdrc_metaphase_checkin_on: string;

    tdm_created_on: any;
    tdm_modified_on: any;
    metaphase_created_on;
    metaphase_modified_on: any;
    metaphase_checkin_on: any;
}

export class Drawing {
    id: any;
    drawing_brass_tag: string;
    drawing_num: string;
    drawing_purchase_order: string;
    drawing_vendor: any;
    drawing_name: string;
    drawing_comments: string;
}

export class Optegra {
    id: any;
    optegra_part_type: string;
    optegra_comments: string;
    optegra_migrated_on: any;
    optegra_revision: string;
    optegra_status: string;
}

export class JlrGroupRegister {
    auto_freeze: string;
    check_in_filter: string;
}

export class JlrCaeFolder {
    migrated_on: string;
    project_id: string;
    commnets: string;
    keywords: string;
}

export class PtnvhqosTest {
    id: any;
    ptnvhqos_test_speeddev: string;
    ptnvhqos_test_order3: string;
    ptnvhqos_test_fanspeedhighlimit: string;
    ptnvhqos_test_vehicle_config_disc: string;
    ptnvhqos_test_driver_seat_type: string;
    ptnvhqos_test_amb_humidity_units: string;
    ptnvhqos_test_thresh_steering: string;
    ptnvhqos_test_polynomial_range: string;
    ptnvhqos_test_maxfanspeed_100perc: string;
    ptnvhqos_test_engine_cyl_config: string;
    ptnvhqos_test_highsweepspeed: string;
    ptnvhqos_test_minfanspeed_operc: string;
    ptnvhqos_test_ordercut_width: string;
    ptnvhqos_test_trans_temp_units: string;
    ptnvhqos_test_amb_airpress_units: string;
    ptnvhqos_test_idlerpmquality: string;
    ptnvhqos_test_vib_orderopt: string;
    ptnvhqos_test_gear_sele_loc: string;
    ptnvhqos_test_amb_humidity: string;
    ptnvhqos_test_veh_opt_or_pack: string;
    ptnvhqos_test_body_style: string;
    ptnvhqos_test_vehicle_program: string;
    ptnvhqos_test_fanspeedavg: string;
    ptnvhqos_test_idle_ver: string;
    ptnvhqos_test_market: string;
    ptnvhqos_test_default_snd_fftsize: string;
    ptnvhqos_test_pot_proc: string;
    ptnvhqos_test_wot_ver: string;
    ptnvhqos_test_tire_manuf: string;
    ptnvhqos_test_num_cyl: string;
    ptnvhqos_test_fftopt_method: string;
    ptnvhqos_test_snd_orderopt: string;
    ptnvhqos_test_assblyplantcode: string;
    ptnvhqos_test_threshcheck: string;
    ptnvhqos_test_highanalspeed: string;
    ptnvhqos_test_vehicle_model: string;
    ptnvhqos_test_roof_opt: string;
    ptnvhqos_test_sweepavgfactor: string;
    ptnvhqos_test_vin: string;
    ptnvhqos_test_tire_type: string;
    ptnvhqos_test_freqresolution: string;
    ptnvhqos_test_driveline_type: string;
    ptnvhqos_test_amb_windspeed: string;
    ptnvhqos_test_no_of_doors: string;
    ptnvhqos_test_fanspeedlowlimit: string;
    ptnvhqos_test_engine_displacement: string;
    ptnvhqos_test_lowanalspeed: string;
    ptnvhqos_test_fanspeedmin: string;
    ptnvhqos_test_amb_airpressure: string;
    ptnvhqos_test_trans_temp: string;
    ptnvhqos_test_order2: string;
    ptnvhqos_test_neru_proc: string;
    ptnvhqos_test_other_vehicle_spec: string;
    ptnvhqos_test_neru_ver: string;
    ptnvhqos_test_amb_temp: string;
    ptnvhqos_test_vehbuildlevel: string;
    ptnvhqos_test_default_vib_fftsize: string;
    ptnvhqos_test_condition: string;
    ptnvhqos_test_fanspeedmax: string;
    ptnvhqos_test_amb_temp_units: string;
    ptnvhqos_test_wheel_type: string;
    ptnvhqos_test_lowsweepspeed: string;
    ptnvhqos_test_amb_windspeed_units: string;
    ptnvhqos_test_trim_level: string;
    ptnvhqos_test_description: string;
    ptnvhqos_test_speedstddev: string;
    ptnvhqos_test_sweepcheck: string;
    ptnvhqos_test_threshmethod: string;
    ptnvhqos_test_order4: string;
    ptnvhqos_test_basefilename: string;
    ptnvhqos_test_wot_proc: string;
    ptnvhqos_test_ordercut_method: string;
    ptnvhqos_test_fuel_type: string;
    ptnvhqos_test_orig_date: string;
    ptnvhqos_test_thresh_seat: string;
    ptnvhqos_test_pot_ver: string;
    ptnvhqos_test_operator: string;
    ptnvhqos_test_order1: string;
    ptnvhqos_test_num_repairs: string;
    ptnvhqos_test_veh_build_date: string;
    ptnvhqos_test_vehicle_type: string;
    ptnvhqos_test_tire_size: string;
    ptnvhqos_test_percent_repairs: string;
    ptnvhqos_test_opcondition: string;
    ptnvhqos_test_numberoffailed: string;
    ptnvhqos_test_fanrangetype: string;
    ptnvhqos_test_doeorderdata: string;
    ptnvhqos_test_valvetrainconfig: string;
    ptnvhqos_test_vehicle_config: string;
    ptnvhqos_test_orig_time: string;
    ptnvhqos_test_targetspeed: string;
    ptnvhqos_test_per_cyl: string;
    ptnvhqos_test_testcondition: string;
    ptnvhqos_test_idle_proc: string;
    ptnvhqos_test_assblyplantloc: string;
    ptnvhqos_test_seat_material: string;
    ptnvhqos_test_transmission_type: string;
}

export class PtnvhqosReport {
    id: any;
    ptnvhqos_report_eng_displacement: string;
    ptnvhqos_report_vehicle_configdesc: string;
    ptnvhqos_report_driver_seat_type: string;
    ptnvhqos_report_other_vehicle_specs: string;
    ptnvhqos_report_eng_cyl_config: string;
    ptnvhqos_report_vehbuildlevel: string;
    ptnvhqos_report_condition: string;
    ptnvhqos_report_gear_sel_location: string;
    ptnvhqos_report_body_style: string;
    ptnvhqos_report_veh_opt_or_pack: string;
    ptnvhqos_report_vehicle_prog: string;
    ptnvhqos_report_wheel_type: string;
    ptnvhqos_report_trim_level: string;
    ptnvhqos_report_orig_date: string;
    ptnvhqos_report_fuel_type: string;
    ptnvhqos_report_market: string;
    ptnvhqos_report_vehicle_type: string;
    ptnvhqos_report_tire_size: string;
    ptnvhqos_report_tire_manufacture: string;
    ptnvhqos_report_num_cylinder: string;
    ptnvhqos_report_end_date: string;
    ptnvhqos_report_assblyplantcode: string;
    ptnvhqos_report_test_metric_desc: string;
    ptnvhqos_report_valvetrainconfig: string;
    ptnvhqos_report_vehicle_config: string;
    ptnvhqos_report_orig_time: string;
    ptnvhqos_report_vehicle_model: string;
    ptnvhqos_report_start_date: string;
    ptnvhqos_report_valves_per_cylinder: string;
    ptnvhqos_report_roof_options: string;
    ptnvhqos_report_tire_type: string;
    ptnvhqos_report_vin: string;
    ptnvhqos_report_driveline_type: string;
    ptnvhqos_report_assblyplanloc: string;
    ptnvhqos_report_seat_material: string;
    ptnvhqos_report_number_of_doors: string;
    ptnvhqos_report_transmission_type: string;
    start_date: any;
    end_date: any;
}

export class StdTransArch {
    label: string;
    gui_order: string;
}

export class Testlab {
    id: any;
    test_rq_num: string;
    test_work_task_num: string;
    test_date: any;
    test_unit_under_test: string;
    test_title: string;
    test_cert: string;
    test_type: string;
}

export class Url {
    url: string;
}

export class Query {
    query: string;
}

export class ItemInitiationForm2 {
    label: string;
    value: string;
}

export class CreateItemPropertiesApi {
    ownerCdsid: string;
    itemType: string;
    labelOld: string;
    ret_scheduleVal: string;
    id: any;
    type: any;
    owner: string;
    steward: string;
    security_level: any;
    ret_schedule: string;
    domain: string;
    subject: string;
    expiration_date: string;
    milestone: any;
    program: any;
    parent: any;
    hold_count: any;
    display_order: any;
    keep_all_versions: string;
    notify_on_read_denied: string;
    lock_on_checkout: string;
    leader: any;
    default_server: string;
    // url
    externalUrl: string;

    // Ref
    refToId: string;
    refToType: string;

    // Domain
    stdDomainCode: string;
    stdDomainComments: string;
    domainClass: string;
    stdlabel: string;

    // subject
    stdSubjectComments: string;

    // PartName
    stdPartnameBase: string;
    stdPartnameName: string;
    stdPartnameDesignteam: string;

    // Part
    partdesignTeamId: any;
    partdesignTeam: string;

    // Roaster
    rosterTeam: string;
    rosterMember: string;
    rosterAddedManual: string;

    // lock
    lockDepth: string;
    lockExpiresOn: string;
    lockStartedOn: string;

    stdGuiOrder: string;

    // Test lab
    testRqNum: string;
    testWorkTaskNum: string;
    testUnitUnderTest: string;
    testTitle: string;
    testCert: string;
    testType: string;
    testDate: string;

    // Drawing
    drawingVendor: string;

    // Query
    childQuery: string;

    // Part
    partBase: string;
    partChangeLevel: string;
    partName: string;
    partNum: string;
    partComments: string;
    partPrefix: string;
    partComparatorNum: string;
    partDesignTeamId: string;

    // OPTEGRA ITEM
    optegraPartType: string;
    optegraComments: string;
    optegraRevision: string;
    optegraStatus: string;
    optegraMigratedOn: string;

    // JLR_GROUP_REGISTER
    jlrGrpAutofreeze: string;
    jlrGrpCheckFilter: string;

    // JLR_CAE_FOLDER
    jlrCaeKeyword: string;
    jlrCaeProjectId: string;
    jlrCaeComments: string;
    jlrCaeMigrated: string;

    // Power train
    transProgram: string;
    engineProgram: string;

    // Trans_Config
    transconfigProgram: string;
    transconfigCurApproval: string;

    // Ret_Schedule
    ret_hist_status: string;
    ret_category: string;
    ret_period: string;
    ret_title: string;
    ret_tax_status: string;

    // ROSTER
    added_manually: string;
    joined_on: string;

    team: string;
    member: string;

    // Lock
    lock_depth: any;
    lock_started_on: string;
    lock_expires_on: string;

    // TestLab
    test_work_task_num: string;
    test_unit_under_test: string;
    test_title: string;
    test_cert: string;
    test_type: string;
    test_date: string;
    test_rq_num: string;

    // Drawing

    drawing_num: string;
    drawing_name: string;
    drawing_brass_tag: string;
    drawing_purchase_order: string;
    drawing_comments: string;
    drawing_vendor: any;

    // Optegra
    optegra_part_type: string;
    optegra_comments: string;
    optegra_revision: string;
    optegra_status: string;
    optegra_migrated_on: string;

    // insertActivity

    mixin_type: string;
    comments: string;
    swizzle_type: string;
    label: string;
    for_domain: string;
    for_type: string;

    // insertFolder
    folder_project_id: string;
    folder_purchase_order: string;
    folder_comments: string;
    folder_vendor: any;
    folderItem: string;

    // insertCompany
    created_on: string;
    modified_on: string;
    company_name: string;
    company_fullname: string;
    company_comments: string;
    company_id: string;
    company_steward: string;

    // insertBuilding
    building_coutry: string;
    building_city: string;
    building_street: string;
    building_comments: string;
    building_name: string;
    building_postalcode: string;
    building_fullname: string;
    building_state: string;
    building_id: string;
    building_steward: string;

    // insert Engine
    engine_worktask_num: string;
    engine_part: string;
    engine_serial_num: string;

    // insertattachatr
    atCreated_on: any;
    atModified_on: any;
    attached_attr_str: string;
    attached_attr_num: string;
    attached_attr_name: string;
    date_val: any;

    // VehicleProgram
    vehicleProgramApi: VehicleProgramApi;

    //Trans Program
    transProgramApi: TransProgramApi;

    //Engine Config
    engineConfigApi: EngineConfigApi;

    // VehicleConfig
    vehicleConfigApi: VehicleConfigApi;

    // insert attached file
    attachFileApi: AttachFileApi;

    // PtnvhqosTest
    ptnvhqosTestApi: PtnvhqosTestApi;

    // PtnvhqosReport
    ptnvhqosReportApi: PtnvhqosReportApi;

    // SDRC ITEM
    sdrcReportApi: SdrcReportApi;

    // EngineProgram
    engineProgramApi: EngineProgramApi;

    securityList: ItemInitiationForm2[];
    retScheduleList: ItemInitiationForm2[];
    domainList: ItemInitiationForm2[];
    getSubjectList: ItemInitiationForm2[];
    getUsageList: ItemInitiationForm2[];
    getApprovalList: ItemInitiationForm2[];
    getServerList: ItemInitiationForm2[];
    driveTrainList: ItemInitiationForm2[];
    leadVehicleList: ItemInitiationForm2[];
    valveTrainList: ItemInitiationForm2[];
    engineFuelList: ItemInitiationForm2[];
    engineArchList: ItemInitiationForm2[];

    transArchList;
    transBrandList;
    itemTypeList: ItemTypeList[];

    approvalRequired: string;

}

export class VehicleProgramApi {
    vehicle_program_cur_approval: ItemInitiationForm2;
    vehicle_program_brand: ItemInitiationForm2;
    curApproval: ItemInitiationForm2;
    vehicle_program_model_year: string;
    vehicle_program_kick_off: string;
    vehicle_program_job_one: string;
    vehicle_program_end_of_life: string;
    vehicle_program_comparator_prog: string;
    vehicle_program_change_level: string;
    vehicle_program_comments: string;
    vehicle_program_name_plate: string;
    vehicle_program_list_domain: string;
    vehicle_program_model_year_end: string;
    vehicle_program_list_domains: string;
    vehicle_program_pmt_domain: string;
    Vehicle_program_design_team_id: string;
    Vehicle_program_alt_label: string;
    vehicle_program_code: string;
    job_one: any;
    end_of_life: any;
    kick_off: any;
    vehicle_program_design_team: string;
}

export class TransProgramApi {
    transprog_model_year: string;
    transprog_torque_capacity: string;
    transprog_job_one: string;
    transprog_end_of_life: string;
    transprog_kick_off: string;
    transprog_comp_prog: string;
    transprog_change_level: string;
    transprog_comments: string;
    transprog_model_year_end: string;
    transprog_num_speeds: string;
    transprog_rms_prog_name: string;
    transprog_life_code: string;
    transprog_work_task_num: string;
    transprog_continuous: string;
    transprog_old_name: string;
    transprog_pmt_domain: string;
    transprog_design_team_id: string;
    transprog_list_domain: string;
    transprog_alt_label: string;
    transprog_lead_vehicle: string;
    transprog_drive_train: string;
    transprog_config: string;
    transprog_cur_approval: string;
    transprog_design_team: string;
    job_one: any;
    end_of_life: any;
    kick_off: any;
}

export class EngineConfigApi {
    true_displacement: string;
    true_fuel: string;
    true_value_train: string;
    true_num_values: string;
    true_bank_angle: string;
    max_horse_power;
    max_torque: string;
    max_rpm: string;
    torque_curve: string;
    engine_config_comments: string;
    true_config: string;
    engine_config_program: string;
    engine_config_curappr: string;
}

export class VehicleConfigApi {
    vehicle_config_engine: string;
    vehicle_config_trans: string;
    vehicle_config_body_style: string;
    vehicle_config_weight: string;
    vehicle_config_fuel_econ: string;
    vehicle_config_zero_to_sixty: string;
    vehicle_config_program: string;
    vehicle_config_cur_approval: string;
}


export class AttachFileApi {

    file_actual_server: string;
    file_st_mode: string;
    file_fmccrypt_key: string;
    file_orig_stored_size: string;
    file_compression: string;
    File_compressio: string;
    File_view_count: string;
    file_checksum: string;
    file_stored_size: string;
    file_backup_path: string;
    file_external_mime_type: string;
    file_comments: string;
    file_mime_type: string;
    deleted_on: any;
    file_actual_size: string;
    file_path: string;
    file_ret_checkpoint: string;
    file_is_encrypted: string;
    file_mime_subtype: string;
    file_mime_param: string;
    file_version: string;
    attached_owner: string;
    file_is_default: string;
    file_ret_status: string;
    vehicle_config_engine: string;
    vehicle_config_trans: string;
    vehicle_config_body_style: string;
    vehicle_config_weight: string;
    vehicle_config_fuel_econ: string;
    vehicle_config_zero_to_sixty: string;
    vehicle_config_program: string;
    vehicle_program_code: string;
    vehicle_program_model_year: string;
    vehicle_program_kick_off: string;
    vehicle_program_job_one: string;
    vehicle_program_end_of_life: string;
    vehicle_program_comparator_prog: string;
    vehicle_program_change_level: string;
    vehicle_program_comments: string;
    vehicle_program_name_plate: string;
    vehicle_program_list_domain: string;
    vehicle_program_model_year_end: string;
    vehicle_program_list_domains: string;
    vehicle_program_pmt_domain: string;
    Vehicle_program_design_team_id: string;
    Vehicle_program_alt_label: string;
    job_one: any;
    end_of_life: any;
    kick_off: any;
    true_displacement: string;
    true_fuel: string;
    true_value_train: string;
    true_num_values: string;
    true_bank_angle: string;
    max_horse_power: string;
    max_torque: string;
    max_rpm: string;
    torque_curve: string;
    engine_config_comments: string;
    true_config: string;
    engine_config_program: string;
    transprog_model_year: string;
    transprog_torque_capacity: string;
    transprog_job_one: string;
    transprog_end_of_life: string;
    transprog_kick_off: string;
    transprog_comp_prog: string;
    transprog_change_level: string;
    transprog_comments: string;
    transprog_model_year_end: string;
    transprog_num_speeds: string;
    transprog_rms_prog_name: string;
    transprog_life_code: string;
    transprog_work_task_num: string;
    transprog_continuous: string;
    transprog_old_name: string;
    transprog_pmt_domain: string;
    transprog_design_team_id: string;
    transprog_list_domain: string;
    transprog_alt_label: string;
    created_on: any;
    modified_on: any;
    accessed_on: any;

}

export class PtnvhqosTestApi {

    ptnvhqos_test_amb_airpressure: string;
    ptnvhqos_test_amb_airpress_units: string;
    ptnvhqos_test_amb_humidity: string;
    ptnvhqos_test_amb_temp: string;
    ptnvhqos_test_amb_temp_units: string;
    ptnvhqos_test_amb_windspeed: string;
    ptnvhqos_test_amb_windspeed_units: string;
    ptnvhqos_test_assblyplantcode: string;
    ptnvhqos_test_assblyplantloc: string;
    ptnvhqos_test_basefilename: string;
    ptnvhqos_test_body_style: string;
    ptnvhqos_test_default_snd_fftsize: string;
    ptnvhqos_test_default_vib_fftsize: string;
    ptnvhqos_test_driveline_type: string;
    ptnvhqos_test_driver_seat_type: string;
    ptnvhqos_test_engine_cyl_config: string;
    ptnvhqos_test_engine_displacement: string;
    ptnvhqos_test_freqresolution: string;
    ptnvhqos_test_fuel_type: string;
    ptnvhqos_test_gear_sele_loc: string;
    ptnvhqos_test_idlerpmquality: string;
    ptnvhqos_test_market: string;
    ptnvhqos_test_num_repairs: string;
    ptnvhqos_test_num_cyl: string;
    ptnvhqos_test_no_of_doors: string;
    ptnvhqos_test_numberoffailed: string;
    ptnvhqos_test_orig_date: string;
    ptnvhqos_test_orig_time: string;
    ptnvhqos_test_other_vehicle_spec: string;
    ptnvhqos_test_percent_repairs: string;
    ptnvhqos_test_polynomial_range: string;
    ptnvhqos_test_condition: string;
    ptnvhqos_test_doeorderdata: string;
    ptnvhqos_test_fanrangetype: string;
    ptnvhqos_test_fanspeedavg: string;
    ptnvhqos_test_fanspeedhighlimit: string;
    ptnvhqos_test_fanspeedlowlimit: string;
    ptnvhqos_test_fanspeedmax: string;
    ptnvhqos_test_fanspeedmin: string;
    ptnvhqos_test_fftopt_method: string;
    ptnvhqos_test_highanalspeed: string;
    ptnvhqos_test_highsweepspeed: string;
    ptnvhqos_test_idle_proc: string;
    ptnvhqos_test_idle_ver: string;
    ptnvhqos_test_lowanalspeed: string;
    ptnvhqos_test_lowsweepspeed: string;
    ptnvhqos_test_maxfanspeed_100perc: string;
    ptnvhqos_test_minfanspeed_operc: string;
    ptnvhqos_test_neru_proc: string;
    ptnvhqos_test_neru_ver: string;
    ptnvhqos_test_opcondition: string;
    ptnvhqos_test_operator: string;
    ptnvhqos_test_order1: string;
    ptnvhqos_test_order2: string;
    ptnvhqos_test_order3: string;
    ptnvhqos_test_order4: string;
    ptnvhqos_test_ordercut_method: string;
    ptnvhqos_test_ordercut_width: string;
    ptnvhqos_test_pot_proc: string;
    ptnvhqos_test_pot_ver: string;
    ptnvhqos_test_snd_orderopt: string;
    ptnvhqos_test_speeddev: string;
    ptnvhqos_test_sweepavgfactor: string;
    ptnvhqos_test_sweepcheck: string;
    ptnvhqos_test_targetspeed: string;
    ptnvhqos_test_testcondition: string;
    ptnvhqos_test_description: string;
    ptnvhqos_test_thresh_seat: string;
    ptnvhqos_test_thresh_steering: string;
    ptnvhqos_test_threshcheck: string;
    ptnvhqos_test_threshmethod: string;
    ptnvhqos_test_trans_temp: string;
    ptnvhqos_test_trans_temp_units: string;
    ptnvhqos_test_vehicle_config: string;
    ptnvhqos_test_vehicle_config_disc: string;
    ptnvhqos_test_vib_orderopt: string;
    ptnvhqos_test_vin: string;
    ptnvhqos_test_wot_proc: string;
    ptnvhqos_test_wot_ver: string;
    ptnvhqos_test_roof_opt: string;
    ptnvhqos_test_seat_material: string;
    ptnvhqos_test_tire_manuf: string;
    ptnvhqos_test_tire_size: string;
    ptnvhqos_test_tire_type: string;
    ptnvhqos_test_transmission_type: string;
    ptnvhqos_test_trim_level: string;
    ptnvhqos_test_per_cyl: string;
    ptnvhqos_test_valvetrainconfig: string;
    ptnvhqos_test_veh_build_date: string;
    ptnvhqos_test_veh_opt_or_pack: string;
    ptnvhqos_test_vehbuildlevel: string;
    ptnvhqos_test_vehicle_model: string;
    ptnvhqos_test_vehicle_type: string;
    ptnvhqos_test_vehicle_program: string;
    ptnvhqos_test_wheel_type: string;
    ptnvhqos_test_speedstddev: string;
}


export class PtnvhqosReportApi {

    ptnvhqos_report_assblyplantcode: string;
    ptnvhqos_report_assblyplanloc: string;
    ptnvhqos_report_body_style: string;
    ptnvhqos_report_driveline_type: string;
    ptnvhqos_report_driver_seat_type: string;
    ptnvhqos_report_eng_cyl_config: string;
    ptnvhqos_report_eng_displacement: string;
    ptnvhqos_report_fuel_type: string;
    ptnvhqos_report_gear_sel_location: string;
    ptnvhqos_report_market: string;
    ptnvhqos_report_num_cylinder: string;
    ptnvhqos_report_number_of_doors: string;
    ptnvhqos_report_orig_date: string;
    ptnvhqos_report_orig_time: string;
    ptnvhqos_report_other_vehicle_specs: string;
    ptnvhqos_report_condition: string;
    ptnvhqos_report_vehicle_config: string;
    ptnvhqos_report_vehicle_configdesc: string;
    ptnvhqos_report_vin: string;
    ptnvhqos_report_roof_options: string;
    ptnvhqos_report_seat_material: string;
    ptnvhqos_report_tire_manufacture: string;
    ptnvhqos_report_tire_size: string;
    ptnvhqos_report_tire_type: string;
    ptnvhqos_report_transmission_type: string;
    ptnvhqos_report_trim_level: string;
    ptnvhqos_report_valves_per_cylinder: string;
    ptnvhqos_report_valvetrainconfig: string;
    ptnvhqos_report_veh_opt_or_pack: string;
    ptnvhqos_report_vehbuildlevel: string;
    ptnvhqos_report_vehicle_model: string;
    ptnvhqos_report_vehicle_type: string;
    ptnvhqos_report_vehicle_prog: string;
    ptnvhqos_report_wheel_type: string;
    ptnvhqos_report_test_metric_desc: string;
    Start_date: any;
    End_date: any;
}
export class EngineProgramApi {

    engine_program_lead_vehicle: any;
    engine_program_modal_year: string;
    engine_program_job_one: any;
    engine_program_end_of_life: any;
    engine_program_kick_off: any;
    engine_program_displacement: string;
    engine_program_fuel: string;
    engine_program_value_train: string;
    engine_program_num_val: string;
    engine_program_bank_angle: string;
    engine_program_comp_prog: string;
    engine_program_change_level: string;
    engine_program_other: string;
    engine_program_comments: string;
    engine_program_arch: string;
    engine_program_config: string;
    engine_program_work_task: string;
    engine_program_model_year_end: string;
    engine_program_rms_prog: string;
    engine_program_life_code: string;
    engine_program_list_domain: string;
    engine_program_pmt_domain: string;
    engine_program_design_team: string;
    engine_program_design_team_id: string;
    engine_program_cur_approval: string;
    engine_program_alt_label: string;
}

export class SdrcReportApi {
    sdrc_num: string;
    sdrc_revision: string;
    sdrc_version: string;
    sdrc_name: string;
    sdrc_metaphase_class: string;
    sdrc_ideas_type: string;
    sdrc_tdm_status: string;
    sdrc_is_metaphase_superceded: string;
    sdrc_is_metaphase_frozen: string;
    sdrc_is_metaphase_checkedout: string;
    sdrc_tdm_id: string;
    sdrc_tdm_library: string;
    sdrc_tdm_project: string;
    sdrc_tdm_creator: string;
    sdrc_metaphase_creator: string;
    sdrc_last_checkout_user: string;
    sdrc_last_checkin_user: string;
    Tdm_created_on: any;
    Tdm_modified_on: any;
    Metaphase_created_on: any;
    Metaphase_modified_on: any;
    Metaphase_checkin_on: any;
    sdrc_metaphase_id: string;

}
