import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'initial-afr',
  templateUrl: './initial-afr.component.html',
  styleUrls: ['./initial-afr.component.css']
})
export class InitialAfrComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
