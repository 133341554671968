import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Item, Sdrc } from 'app/core/model/itemAdminModels';

@Component({
  selector: 'sdrc-item-object',
  templateUrl: './sdrc-item-object.component.html',
  styleUrls: ['./sdrc-item-object.component.css']
})
export class SdrcItemObjectComponent implements OnInit {

  sdrcItemObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()
  @Input('screenType') screenType: string;
  @Input('item') item: Item;
  @Input('sdrcItem') sdrcItem: Sdrc;
  @Input('showfield') showfield: boolean;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
    this._updatesdrcItemObjectForm();
  }

  _formClear() {
    this.sdrcItemObjectForm = this.formBuilder.group({
      id: [''],
      metaPhaseCreator: [''],
      isMetaPhaseFrozen: [''],
      tdmId: [''],
      metaPhaseId: [''],
      tdmCreatedOn: [''],
      num: [''],
      ideasType: [''],
      curApproval: [''],
      lastCheckInUser: [''],
      tdmModifiedOn: [''],
      metaPhaseClass: [''],
      name: [''],
      tdmProject: [''],
      metaPhaseModifiedOn: [''],
      revision: [''],
      isMetaPhaseSuperceded: [''],
      tdmLibrary: [''],
      lastCheckoutUser: [''],
      metaPhaseCheckinOn: [''],
      tdmCreator: [''],
      tdmStatus: [''],
      isMetaPhaseCheckedOut: [''],
      version: [''],
      metaPhaseCreatedOn: ['']
    });
    this.formReady.emit(this.sdrcItemObjectForm);
  }

  _updatesdrcItemObjectForm() {
    this.sdrcItemObjectForm.patchValue({
      id: this.item.id,
      metaPhaseCreator: this.sdrcItem.sdrc_metaphase_creator,
      isMetaPhaseFrozen: this.sdrcItem.sdrc_is_metaphase_frozen,
      tdmId: this.sdrcItem.sdrc_tdm_id,
      metaPhaseId: this.sdrcItem.sdrc_metaphase_id,
      tdmCreatedOn: this.sdrcItem.sdrc_tdm_created_on,
      num: this.sdrcItem.sdrc_num,
      ideasType: this.sdrcItem.sdrc_ideas_type,
      curApproval: this.sdrcItem.sdrc_cur_approval,
      lastCheckInUser: this.sdrcItem.sdrc_last_checkin_user,
      tdmModifiedOn: this.sdrcItem.sdrc_tdm_modified_on,
      metaPhaseClass: this.sdrcItem.sdrc_metaphase_class,
      name: this.sdrcItem.sdrc_name,
      tdmProject: this.sdrcItem.sdrc_tdm_project,
      metaPhaseModifiedOn: this.sdrcItem.sdrc_metaphase_modified_on,
      revision: this.sdrcItem.sdrc_revision,
      isMetaPhaseSuperceded: this.sdrcItem.sdrc_is_metaphase_superceded,
      tdmLibrary: this.sdrcItem.sdrc_tdm_library,
      lastCheckoutUser: this.sdrcItem.sdrc_last_checkout_user,
      metaPhaseCheckinOn: this.sdrcItem.sdrc_metaphase_checkin_on,
      tdmCreator: this.sdrcItem.sdrc_tdm_creator,
      tdmStatus: this.sdrcItem.sdrc_tdm_status,
      isMetaPhaseCheckedOut: this.sdrcItem.sdrc_is_metaphase_checkedout,
      version: this.sdrcItem.sdrc_version,
      metaPhaseCreatedOn: this.sdrcItem.sdrc_metaphase_created_on
    })
  }

}
