import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpRequest } from "@angular/common/http";
import { Observable } from 'rxjs/internal/Observable';
import { HttpHeaders } from '@angular/common/http';
import { EditPropertiesResponse, CommonDescription } from '../model/editPropertiesModels';


@Injectable({
  providedIn: 'root'
})
export class EditPropertiesService {


  private apdmServiceBackEndUrl = environment.apdmServiceUrl;
  private headers: HttpHeaders;
  cdsId: any;
  token: any;

  constructor(private http: HttpClient) {
    this.token = JSON.parse(sessionStorage.getItem('strAccessToken'));
    if (this.token) {
      this.cdsId = this.token.CommonName;
    }
    //console.log(' cdsid--->', this.cdsId);
    if (this.cdsId !== undefined) {
      this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
    } else {
      this.cdsId = 'Invalid';
      this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
    }
  }

  public editItem(selectedIds: String, selectedID: String): Observable<any> {
    return this.http.post(this.apdmServiceBackEndUrl + 'editProperties/' + selectedIds + '/' + selectedID, {
      headers: this.headers
    });
  }



  getCpscCpmt(queryId: string, query: any): Observable<any> {
    return this.http.get(this.apdmServiceBackEndUrl + 'selectSearchData/' + queryId + '/' + query, {
      headers: this.headers
    });
  }

  getLeadVehicleList(query: any): Observable<any> {
    return this.http.get(this.apdmServiceBackEndUrl + 'getLeadVehicleList/' + query, {
      headers: this.headers
    });
  }


  postEditProperties(editedValue: EditPropertiesResponse) {
    console.log('<===================editedValue======================>', editedValue);
    return this.http.post(this.apdmServiceBackEndUrl + 'editupdate', editedValue);
  }

  postUpdateMultipleItem(editedValue: EditPropertiesResponse) {
    console.log('<===================editedValue======================>', editedValue);
    return this.http.post(this.apdmServiceBackEndUrl + 'editUpdateMultipleItem', editedValue);
  }

  getSecurityList(): Observable<any> {
    return this.http.get(this.apdmServiceBackEndUrl + 'getSecurityList', {
      headers: this.headers
    });
  }

  getRetScheduleList(): Observable<any> {
    return this.http.get(this.apdmServiceBackEndUrl + 'getRetScheduleList', {
      headers: this.headers
    });
  }


  getRetStatusList(): Observable<any> {
    return this.http.get(this.apdmServiceBackEndUrl + 'getRetStatusList', {
      headers: this.headers
    });
  }

  getServerList(): Observable<any> {
    return this.http.get(this.apdmServiceBackEndUrl + 'getServerList', {
      headers: this.headers
    });
  }


  getRetention(retId: string, itemId: string): Observable<any> {
    let formdata = new FormData();
    formdata.append('retId', retId);
    formdata.append('itemId', itemId);
    const req = new HttpRequest('POST', this.apdmServiceBackEndUrl + 'getRetention', formdata, {
      reportProgress: true,
      responseType: 'text'
    });
    return this.http.request(req);
  }

  getProgramList(min: number, max: number) {
    return this.http.get(this.apdmServiceBackEndUrl + 'getEditProgramList/' + min + "/" + max);
  }

  getMilestoneList(min: number, max: number) {
    return this.http.get(this.apdmServiceBackEndUrl + 'getEditMilestoneList/' + min + "/" + max);
  }

  getSubjectList(): Observable<any> {
    return this.http.get(this.apdmServiceBackEndUrl + 'getSubjectList', {
      headers: this.headers
    });
  }

  getApprovalList(): Observable<any> {
    return this.http.get(this.apdmServiceBackEndUrl + 'getApprovalList', {
      headers: this.headers
    });
  }

  getengineArchList(): Observable<any> {
    return this.http.get(this.apdmServiceBackEndUrl + 'getengineArchList', {
      headers: this.headers
    });
  }

  getvalveTrainList(): Observable<any> {
    return this.http.get(this.apdmServiceBackEndUrl + 'getvalveTrainList', {
      headers: this.headers
    });
  }

  getengineFuelList(): Observable<any> {
    return this.http.get(this.apdmServiceBackEndUrl + 'getengineFuelList', {
      headers: this.headers
    });
  }

  getLeadVehicleList_AdminPortal(): Observable<any> {
    return this.http.get(this.apdmServiceBackEndUrl + 'getLeadVehicleList', {
      headers: this.headers
    });
  }

  getTransBrandList(): Observable<any> {
    return this.http.get(this.apdmServiceBackEndUrl + 'getTransBrandList', {
      headers: this.headers
    });
  }

  getDomainListD(): Observable<any> {
    return this.http.get(this.apdmServiceBackEndUrl + 'getDomainListD', {
      headers: this.headers
    });
  }



}
