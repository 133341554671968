import { Component, OnInit } from '@angular/core';
import { TeamAdministrationService } from 'app/core/service/team-administration.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TeamCreate, TeamDetail, TeamAdminResponse, TeamAdminAcl, UserData, Subject, TeamMembers, TeamIndirectAcl } from 'app/core/model/teamAdministrationModel';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'list-team',
  templateUrl: './list-team.component.html',
  styleUrls: ['./list-team.component.css']
})
export class ListTeamComponent implements OnInit {
  teamListForm: FormGroup;
  teamGetDataForm: FormGroup;
  teamModify: FormGroup;
  submitted: boolean = false;
  teamCreateModel: TeamCreate;
  showTeamObject: boolean = false;
  showPersonInformation: boolean = false;
  showAcl: boolean = false;
  teamCreationStatus: string;
  teamDisplay: boolean = false;
  titleTeamName: string;
  teamName : string;
  teamDetails: TeamDetail[];
  teamAcls: TeamAdminAcl[];
  userlist: Subject[];
  selectedList: string;
  getTeamName: string;
  listTeamMember:boolean = false;
  teamMember:TeamMembers[];
  teamSelected:string[];
  getSelected:string[];
  showIndirectAcl:boolean = false;
  indirectAcl:TeamIndirectAcl[];
  showDropDownTeamName:boolean = false;
  showTextBoxTeamName:boolean = true;
  constructor(private formBuilder: FormBuilder, private teamAdmin: TeamAdministrationService) { }

  ngOnInit() {
    this.teamListForm = this.formBuilder.group({
      teamId: [''],
      teamName: ['', Validators.required],
      steward: [''],
      comments: [''],
      teamType: [''],
      memberList: [''],
      selectedTeams: [''],
      modifiedOn: [''],
      createdOn: [''],
      type: [''],
      isAclFlag: [''],
      isMembersFlag: [''],
      userData: ['']
    });
    this.teamGetDataForm = this.formBuilder.group({
      teamId: [''],
      teamName: [''],
      steward: [''],
      comments: [''],
      teamType: [''],
      memberList: [''],
      selectedTeams: [''],
      modifiedOn: [''],
      createdOn: [''],
      type: [''],
      isAclFlag: [''],
      isMembersFlag: [''],
      userData: ['']
    });
    this.teamModify = this.formBuilder.group({
      teamId: [''],
      teamName: [''],
      steward: [''],
      comments: [''],
      teamType: [''],
      memberList: [''],
      selectedTeams: [''],
      modifiedOn: [''],
      createdOn: [''],
      type: [''],
      isAclFlag: [''],
      isMembersFlag: [''],
      userData: ['']
    });
    this.teamSelected = ['p', 't'];
    this.userlist = [];
    this.getSelected = ['members'];
    this.showTeamObject = false; 
    this.showPersonInformation = false; 
    this.showAcl = false; 
    this.listTeamMember = false;
    //this.teamListForm.get('teamType').setValue(['p', 't']);
    //this.teamGetDataForm.get('teamType').setValue(['members']);
    //After click ok from team create
    if (this.teamAdmin.teamNameView && this.teamAdmin.teamType) {
      this.titleTeamName = this.teamAdmin.teamNameView;
      if (this.titleTeamName) {
        this.teamCreateModel = new TeamCreate();
        this.teamCreateModel.teamName = this.titleTeamName;
        this.teamCreateModel.teamType = this.teamAdmin.teamType;
       if(this.teamCreateModel) {
        this.teamAdmin.teamAdminGetData(this.teamCreateModel).subscribe((data: TeamAdminResponse) => {
          console.log('redirect data', data);

          this.teamAdmin.teamNameView ='';
          this.teamAdmin.teamType = [];
          if (data.teamCreate) {
            this.teamModify.setValue(data.teamCreate);
            this.showTeamObject = true;
          }
          if (data.teamDetail && data.teamDetail.length>0) {
            this.teamDetails = data.teamDetail;
            this.showPersonInformation = true;
          }
          if (data.teamMembers &&  data.teamMembers.length>0 ) {
            this.teamMember = data.teamMembers;
            console.log(this.teamMember);
            this.listTeamMember = true;
          }
          if (data.teamAdminAcl && data.teamAdminAcl.length>0) {
            this.teamAcls = data.teamAdminAcl;
            this.showAcl = true;
          }
          if(data.teamIndirectAcl && data.teamIndirectAcl.length>0 ){
            this.indirectAcl = data.teamIndirectAcl;
            this.showIndirectAcl = true;
          }
        });
       }
        
      }
    }
    //End
  }
  //Search
  teamListSearch() {
      
    this.showTeamObject = false;
    this.showPersonInformation = false;
    this.showAcl = false;
    this.listTeamMember=false;
   
    console.log('team list search');
    console.log('teamSelected',this.teamSelected);
    this.submitted = true;
    if (this.teamListForm.valid) {
      this.teamCreateModel = this.teamListForm.value;
     // this.teamCreateModel.memberList = [];
      this.teamCreateModel.selectedTeams = [];
      this.teamCreateModel.userData = [];
      this.teamCreateModel.teamType = [];
      this.teamCreateModel.teamType = this.teamSelected;
      if (this.teamCreateModel) {
        console.log('teamListSearch', this.teamCreateModel);
        this.teamName = this.teamCreateModel.teamName;
        this.showDropDownTeamName= true;
        this.showTextBoxTeamName = false;
        this.teamAdmin.teamAdminSearch(this.teamCreateModel).subscribe((data: TeamCreate) => {
          console.log('list search', data);
          console.log(data.userData);

          if (data.userData.length>0) {
            this.userlist = data.userData;
            console.log('if');
          }else{
            console.log('else');

            this.ngOnInit();
          }

        });
      }
    }
  }
  // Team Get Data
  teamGetData() {
    console.log('team get data');
    console.log(this.teamGetDataForm.value);
    console.log('get team', this.selectedList);
    if (this.selectedList) {
      console.log('this.selectedList == IF')
      this.getTeamName = this.selectedList;
      this.titleTeamName = this.selectedList;
    } else {
      console.log('this.selectedList == ELSE');
      if (this.userlist) {
        for (var i = 0; i < this.userlist.length; i++) {
          console.log(this.userlist[i]);
          console.log(this.userlist[0].label);
          this.getTeamName = this.userlist[0].label;
          this.titleTeamName = this.userlist[0].label;
          break;
        }
      }
    }

    if (this.teamGetDataForm.valid) {
      this.teamCreateModel = this.teamGetDataForm.value;
      this.teamCreateModel.teamName = this.getTeamName;
      //this.teamCreateModel.memberList = [];
      this.teamCreateModel.selectedTeams = [];
      this.teamCreateModel.userData = [];
      console.log(this.teamCreateModel);
      this.teamCreateModel.teamType = [];
      this.teamCreateModel.teamType =  this.getSelected;
      console.log('this.teamCreateModel',this.teamCreateModel);
      if (this.teamCreateModel.teamName || this.teamCreateModel.teamId) {
        this.teamAdmin.teamAdminGetData(this.teamCreateModel).subscribe((data: TeamAdminResponse) => {
          console.log('data', data);
          if (data.teamCreate) {
            this.teamModify.setValue(data.teamCreate);
            this.showTeamObject = true;
          }
          if (data.teamDetail) {
            this.teamDetails = data.teamDetail;
            this.showPersonInformation = true;
          }
         
            if (data.teamAdminAcl && data.teamAdminAcl.length>0) {
              this.teamAcls = data.teamAdminAcl;
              this.showAcl = true;
            }
         
         
          if (data.teamMembers && data.teamMembers.length>0) {
            this.teamMember = data.teamMembers;
            console.log(this.teamMember);
            this.listTeamMember = true;
          }
          if(data.teamIndirectAcl && data.teamIndirectAcl.length>0){
            this.indirectAcl = data.teamIndirectAcl;
            this.showIndirectAcl = true;
          }

        });
      }
    } else {
      console.log('else');
      this.teamDisplay = true;
      this.teamCreationStatus = 'No Team exists with Specified Id or Name';
    }
  }
  get f() {
    return this.teamListForm.controls;
  }
  close() {
    this.teamDisplay = false;
  }
}
