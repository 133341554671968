export class ApplicationsInfo {
    app_id: number;
    app_name: string;
    team_id: number;
    form_name: string;
}
export class ReauthInitiateBO {
    year: string;
    reminderNo: string;
    completionDate: string;
    appInfo: string[];
    appInfoList: ApplicationsInfo[];
    gotMailApproverCdsids: string[];
	noMailApproverCdsids: string[];
	cdsIds: string[];
}
export class appList {
    appInfo: string;
}
export class AccountAuthNotificationBO {
    action: string;
    authYearStr: string;
    AuthCompletionDateStr: string;
    AuthReminderStr: string;
    teamIds: string[];
    approverCdsids: string[];
    gotMailApproverCdsids: string[];
    noMailApproverCdsids: string[];
}
export class AccountAuth {
    approverCdsid: string;
    yearStr: string;
    cdsidAccountContents: any;
    cdsidTeamIds: any;
    cdsidTeamIdsStr: string;
    accountContent: AccountContent[];
}
export class AccountAuthResponseApi {
    isError: string;
    accountAuth: AccountAuth;
}
export class AccountContent {
    appId: any;
    teamId: any;
    appName: string;
    bizCase: string;
    authStatus: string;
    cdsid: string;
}


// export class AccountAuthMap {
//     accountAuthMap: Map<string, string[]>;
// }

