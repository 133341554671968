import { Component, OnInit } from '@angular/core';
import {RetentionHeaders, RetentionHeaderResposne} from '../../core/model/afr-retention';
import {RetentionService} from '../../core/service/retention.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'retention',
  templateUrl: './retention.component.html',
  styleUrls: ['./retention.component.css']
})
export class RetentionComponent implements OnInit {


  RetentionData: RetentionHeaders[];
  cols: any[];
  subsciptiondatatable: Subscription;

  constructor(private service: RetentionService) { }

  ngOnInit() {
    this.cols = [
      { field: 'retCode', header: 'Retention Code'},
      { field: 'category', header: 'Category' },
      { field: 'title', header: 'Title'},
      { field: 'period', header: 'Period' },
    ];





    this.subsciptiondatatable = this.service.getretentionTable().subscribe((data: RetentionHeaderResposne[]) => {

      console.log('retentionResposne',data);
     this.RetentionData = data;


   }, (error) => {
     
   })






  }

}



