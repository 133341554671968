import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AfrInitalProcessService } from '../../core/service/afr-inital-process.service';


interface template {
  label: string;
  value: string;
}

@Component({
  selector: 'afr-inital-process',
  templateUrl: './afr-inital-process.component.html',
  styleUrls: ['./afr-inital-process.component.css']
})
export class AfrInitalProcessComponent implements OnInit {
  type: any;
  //   selectone: Selectone;
  template: template[];
  annualreview: FormGroup;
  body: string;
  //  emailtemplate: EmailContent[];
  Subject: string = '';
  year: any = '';
  Content: string;
  Annualresult: boolean;
  leftCdsId: any[];
  successCdsId: any[];
  validErrorMsg: boolean = false;
  successMsg: boolean = false;
  leftMsg: boolean = false;

  constructor(private formBuilder: FormBuilder, private anualreview: AfrInitalProcessService) { }
  ngOnInit() {

    this.template = [
      { label: '1', value: "1" },
      { label: '2', value: "2" },
      { label: '3', value: "3" },
    ];

    this.annualreview = this.formBuilder.group({
      year: [null, [Validators.required, Validators.maxLength(4)]],
      reminderNo: [''],
      subject: [''],
      body: [''],
    });
  }

  OnTemplateload(event) {
    this.year = this.annualreview.get('year').value;
    this.Subject = "APDM  Files - " + this.year + " Annual File Review "
    if (event.value.value == "1") {
      this.body = '<p style="text-align:center"><strong style="color: rgb(230, 0, 0);">' + this.year + ' Annual File Review - APDM Files - 1 notice</strong></p><p><span style="color: rgb(0, 102, 204);">You are receiving this note because you have expired files in APDM. The Ford GIS1 policy requires that documents be destroyed after their usefulness has passed. This policy mandates a classification scheme that defines the useful life of various kinds of documents, and this scheme is incorporated in APDM. You are required under this policy to review all your documents during the&nbsp;</span><strong style="color: rgb(0, 102, 204);">ANNUAL FILE REVIEW&nbsp;</strong><span style="color: rgb(0, 102, 204);">period and to either delete all those that have exceeded their retention period or to reclassify them to a new retention level.</span>&nbsp;<strong style="color: rgb(161, 0, 0);">Please complete the AFR process within two weeks.&nbsp;</strong></p><p><span style="color: rgb(0, 102, 204);">Please follow the web link below to review your files that are expiring. You will be given the opportunity to:</span></p><ul><li><span style="color: rgb(0, 102, 204);">Delete all your expired files with a single click</span></li><li><span style="color: rgb(0, 102, 204);">Delete entire folders of files with a single click</span></li><li><span style="color: rgb(0, 102, 204);">Change the retention period for files you want to keep</span></li><li><span style="color: rgb(0, 102, 204);">Execute your AFR over several sessions if you have many files</span></li></ul>';
      this.annualreview.patchValue({
        body: this.body,
        subject: this.Subject
      });
    } else if (event.value.value == "2") {
      this.body = '<p style="text-align:center"><strong style="color: rgb(230, 0, 0);">' + this.year + ' Annual File Review - APDM Files - 2 notice</strong></p><p><span style="color: rgb(0, 102, 204);">You are receiving this note because you have expired files in APDM. The Ford GIS1 policy requires that documents be destroyed after their usefulness has passed. This policy mandates a classification scheme that defines the useful life of various kinds of documents, and this scheme is incorporated in APDM. You are required under this policy to review all your documents during the&nbsp;</span><strong style="color: rgb(0, 102, 204);">ANNUAL FILE REVIEW&nbsp;</strong><span style="color: rgb(0, 102, 204);">period and to either delete all those that have exceeded their retention period or to reclassify them to a new retention level.</span>&nbsp;<strong style="color: rgb(161, 0, 0);">Please complete the AFR process within two weeks. Your next notice will be escalated to your Supervisor.</strong><strong>&nbsp;</strong></p><p><span style="color: rgb(0, 102, 204);">Please follow the web link below to review your files that are expiring. You will be given the opportunity to:</span></p><ul><li><span style="color: rgb(0, 102, 204);">Delete all your expired files with a single click</span></li><li><span style="color: rgb(0, 102, 204);">Delete entire folders of files with a single click</span></li><li><span style="color: rgb(0, 102, 204);">Change the retention period for files you want to keep</span></li><li><span style="color: rgb(0, 102, 204);">Execute your AFR over several sessions if you have many files</span></li></ul>';
      this.annualreview.patchValue({
        body: this.body,
        subject: this.Subject
      });
    } else if (event.value.value == "3") {
      this.body = '<p style="text-align:center"><strong style="color: rgb(230, 0, 0);">' + this.year + ' Annual File Review - APDM Files - 3 notice</strong></p><p><span style="color: rgb(0, 102, 204);">You are receiving this note because you have expired files in APDM. The Ford GIS1 policy requires that documents be destroyed after their usefulness has passed. This policy mandates a classification scheme that defines the useful life of various kinds of documents, and this scheme is incorporated in APDM. You are required under this policy to review all your documents during the&nbsp;</span><strong style="color: rgb(0, 102, 204);">ANNUAL FILE REVIEW&nbsp;</strong><span style="color: rgb(0, 102, 204);">period and to either delete all those that have exceeded their retention period or to reclassify them to a new retention level.&nbsp;</span><strong style="color: rgb(161, 0, 0);">Please complete the AFR process within two weeks.&nbsp;</strong></p><p><span style="color: rgb(0, 102, 204);">Please follow the web link below to review your files that are expiring. You will be given the opportunity to:</span></p><ul><li><span style="color: rgb(0, 102, 204);">Delete all your expired files with a single click</span></li><li><span style="color: rgb(0, 102, 204);">Delete entire folders of files with a single click</span></li><li><span style="color: rgb(0, 102, 204);">Change the retention period for files you want to keep</span></li><li><span style="color: rgb(0, 102, 204);">Execute your AFR over several sessions if you have many files</span></li></ul>';
      this.annualreview.patchValue({
        body: this.body,
        subject: this.Subject
      });
    }
  }


  annualReview() {
    console.log("form annual", this.annualreview.value);
    this.successMsg = false;
    this.leftMsg = false;
    if (this.annualreview.valid) {
      this.anualreview.postAnnualReview(this.annualreview.value).subscribe((data: any) => {
        console.log('Final review output:', data);
        if (typeof data === 'object' && data !== null) {
          console.log("security level", data.data);
          if (data.data != null && data.data.leftCdsId !== undefined) {
            this.leftCdsId = data.data.leftCdsId;
          }
          if (data.data != null && data.data.successCdsId !== undefined) {
            this.successCdsId = data.data.successCdsId;
          }
          if (this.successCdsId && this.successCdsId !== undefined) {
            this.successMsg = true;
            console.log("Sucess cdsid", this.successCdsId);
          }
          if (this.leftCdsId && this.leftCdsId !== undefined) {
            this.leftMsg = true;
            console.log("Left cdsid", this.leftCdsId);
          }
          this.Annualresult = true;
        }
      });
    } else {
      this.validErrorMsg = true;
    }
  }

  get f() {
    return this.annualreview.controls;
  }
  
  close() {
    this.validErrorMsg = false;
  }

}
