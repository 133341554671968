import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ModifyUserService } from '../../core/service/modify-user.service';
import { Username, UserList } from '../../core/model/admin-modify-user';
import { UserData, Subject } from 'app/core/model/teamAdministrationModel';
import { ModifyInput, ModifyListResponse, ApplicationMembership, TeamData, TeamAcl, TeamAdminAcl } from 'app/core/model/user-admin';

@Component({
  selector: 'list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.css']
})
export class ListUserComponent implements OnInit {

  cars: any[];
  teamName: any;
  userSearchForm: FormGroup;
  getUserForm: FormGroup;
  userModify: FormGroup;

  usernameForm: Boolean = false;
  warningdisplay: Boolean = false;
  //userform: Username;
  warningMsg: any;


  userlist: Subject[];
  modifyInput: ModifyInput;
  applicationMember: ApplicationMembership[];
  teamAffiliation: TeamData[];
  teamAcl: TeamAcl[];
  personAcl:TeamAdminAcl[];
  cdsId: string;
  userError: string;
  userDisplay: boolean = false;
  selectedList: string;
  getTeamName: string;
  titleTeamName: string;
  showPerson: boolean = false;
  showAppMember: boolean = false;
  showTeam: boolean = false;
  showTeamAcls: boolean = false;
  showPersonAcls: boolean = false;
  teamSelected: string[];
  listusermatch: string;
  userSearchResult: boolean = false;
  userSearchError: String;

  personAclMsg: boolean = false;
  teamAclMsg: boolean = false;
  teamDataMsg: boolean = false;
  personDataMsg: boolean = false;
  // personMsg: boolean = false;
  // teamMsg: boolean = false;
  // personAclMsgdata: boolean = false;
  // teamAclMsgdata: boolean = false;
  teamAclMessage: string;
  personAclMessage: string;
  teamMessage: string;
  personMessage: string;

  constructor(private formBuilder: FormBuilder, private modify: ModifyUserService) { }

  ngOnInit() {


    this.userSearchForm = this.formBuilder.group({
      username: ['', Validators.required]
    });

    this.getUserForm = this.formBuilder.group({
      cdsIdPattern: [''],
      personId: [''],
      selectedData: ['']
    });
    this.userModify = this.formBuilder.group({
      id: [''],
      application: [''],
      authorizer: [''],
      building: [''],
      businessCase: [''],
      company: [''],
      country: [''],
      createdOn: [''],
      department: [''],
      division: [''],
      email: [''],
      empType: [''],
      firstName: [''],
      lastName: [''],
      hasAccess: [''],
      hasCdsRecord: [''],
      location: [''],
      mailDrop: [''],
      modifiedOn: [''],
      office: [''],
      organization: [''],
      password: [''],
      phone: [''],
      reauthorizedOn: [''],
      reason: [''],
      reportsTo: [''],
      role: [''],
      steward: [''],
      storageClass: [''],
      unit: [''],
      cdsId: [''],
      lastAuthorizedOn: [''],
      buildingName: [''],
      departmentCode: [''],
      fordMailDrop: [''],
      fordBusinessUnitCode: [''],
      managerRole: [''],
      errorMessage: [''],
      stewardId: [''],
      reportsToId: [''],
      authorizerId: [''],
      companyId: [''],
      buildingId: ['']
    });
    //this.getUserForm.get('selectedData').setValue(['P', 'T', 'PA', 'TA']);
    this.teamSelected = ['P', 'T', 'PA', 'TA'];

  }


  searchUser() {
    this.showPerson = false;
    this.showAppMember = false;
    this.showTeam = false;
    this.showTeamAcls = false;

    console.log("username", this.userSearchForm.get('username').value);
    this.listusermatch = this.userSearchForm.get('username').value;
    const regex = new RegExp(/(\*+)/);


    if (this.userSearchForm.valid || regex.test(this.listusermatch)) {

      this.cdsId = this.userSearchForm.get('username').value;
      this.modify.getUser(this.cdsId).subscribe((response: Subject[]) => {
        console.log('list user', response);
        console.log('list user length', response.length);
        if (response.length != 0) {
          this.userlist = response;
          console.log(this.userlist);
        }
        else{
          this.userSearchResult = true;
          this.userSearchError = "This Search returns no data";
           }
      });

    } else {
      this.userDisplay = true;
      this.userError = '';
      this.userError = 'Please input part of CDSID to start the search!';
    }
  }
  getUser() {
    console.log('team get data');
    console.log(this.getUserForm.value);
    //console.log('get team', this.selectedList);
    if (this.selectedList) {
      console.log('this.selectedList == IF')
      this.getTeamName = this.selectedList;
      // this.titleTeamName = this.selectedList;
    } else {
      console.log('this.selectedList == ELSE');
      if (this.userlist) {
        for (var i = 0; i < this.userlist.length; i++) {
          //console.log(this.userlist[i]);
          //console.log(this.userlist[0].label);
          this.getTeamName = this.userlist[0].label;
          //  this.titleTeamName = this.userlist[0].label;
          break;
        }
      }
    }

    console.log(this.getUserForm.value);
    if (this.getUserForm.valid) {
      this.modifyInput = this.getUserForm.value;
      this.modifyInput.cdsIdPattern = this.getTeamName;
      this.modifyInput.selectedData = [];
      this.modifyInput.selectedData = this.teamSelected;
      console.log(this.modifyInput);
      if (this.modifyInput.cdsIdPattern || this.modifyInput.personId) {
        this.modify.getUserData(this.modifyInput).subscribe((response: ModifyListResponse) => {
          console.log('response:', response);
          if (response) {
            if(this.modifyInput.selectedData.includes('P')){
            if (response.personData.personData != null ) {
              const division = response.personData.personData.division;
              response.personData.personData.division = division !== 'unknown' ? division:'null';
              this.titleTeamName = response.personData.personData.cdsId;
              this.userModify.setValue(response.personData.personData);
              this.applicationMember = response.personData.applicationMembership;
               this.showAppMember = true;
              this.showPerson = true;
            }else{
              this.personDataMsg = true;
              this.personMessage = '';
              this.personMessage = 'This search by PERSON data returns no data!';
            }
            }else{
            this.showPerson = false;
            this.showAppMember = false;
            }

           if(this.modifyInput.selectedData.includes('T')){
            if (response.teamData != null) {
              this.teamAffiliation = response.teamData;
             this.showTeam = true;
            }
             else{
              this.teamDataMsg = true;
              this.teamMessage = '';
              this.teamMessage = 'This search by TEAM data returns no data!';

             }
            }else{
              this.showTeam = false;
            }
            if(this.modifyInput.selectedData.includes('TA')){
              if (response.teamAclData) {
                this.teamAcl = response.teamAclData;
                 this.showTeamAcls = true;
               }
             else{
              this.teamAclMsg = true;
              this.teamAclMessage = '';
              this.teamAclMessage = 'This search by TEAM ACL data returns no data!';

             }
            }else{
              this.showTeamAcls = false;
            }

            if(this.modifyInput.selectedData.includes('PA')){
            if (response.personAclData) {
              this.personAcl = response.personAclData;
               this.showPersonAcls = true;
             }else{
               this.personAclMsg = true;
               this.personAclMessage = '';
              this.personAclMessage = 'This search by PERSON ACL data returns no data!';
             }
            }else{
              this.showPersonAcls = false;
            }

          }

        });
      }
    } else {
      this.userDisplay = true;
      this.userError = '';
      this.userError = 'A cdsid or person id needs to be entered to perform the search';

    }
  }

  close() {
    this.userDisplay = false;
  }
  userSearchclose() {
    this.userSearchResult = false;
  }
  closePD() {
    this.personDataMsg = false;
  }
  closeTD() {
    this.teamDataMsg = false;
  }
  closeTAc() {
    this.teamAclMsg = false;
  }
  closePAc() {
    this.personAclMsg = false;
  }

}


