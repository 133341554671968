import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { cpscCpmt } from 'app/core/model/editPropertiesModels';
import { EditPropertiesService } from 'app/core/service/edit-properties.service';
import { ItemAdministrationService } from 'app/core/service/item-administration.service';
import { ItemTypeList, ModifyProgram, ItemModifyForm, ItemInitiationForm2, ACL, CreateItemPropertiesApi, VehicleProgramApi, TransProgramApi, AttachFileApi, EngineProgramApi } from 'app/core/model/itemAdminModels';
import { Table } from 'primeng/components/table/table';
import { AdminDateFormatPipe } from 'app/shared/admin-date-format.pipe';

@Component({
  selector: 'list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.css']
})
export class ListItemComponent implements OnInit {

  //Clear the selection of P-Table
  @ViewChild(Table) tableComponent: Table;

  itemObjectForm: FormGroup;
  itemAssociatedObjectForm: FormGroup;
  sub;
  type: string = '';
  itemTypeOptions: ItemTypeList[];
  itemLabelOptions: ItemTypeList[];
  itemLabel: string = '';
  ownerCds: string = '';
  parentId: string = '';
  selectedItemType: ItemTypeList[];
  itemLabelDropDown: ItemTypeList;
  itemLabelText: string = '';
  itemId: string = '';
  selectedDirectAclsList: ACL[] = [];
  directAclsCols: any[];
  inDirectAclsCols: any[];
  selectedInDirectAclsList: ACL[];

  retentionStatusOptions: cpscCpmt[];
  retentionScheduleOptions: cpscCpmt[];
  defaultServerOptions: cpscCpmt[];
  securityLevelOptions: ItemInitiationForm2[];
  approvalOptions: ItemInitiationForm2[];
  curApprovalOptions: ItemInitiationForm2[];
  subjectOptions: ItemInitiationForm2[];
  engineArchOptions: ItemInitiationForm2[];
  engineFuelOptions: ItemInitiationForm2[];
  leadVehicleOptions: ItemInitiationForm2[];
  transBrandOptions: ItemInitiationForm2[];
  domainOptions: ItemInitiationForm2[];

  modifyProgram = new ModifyProgram();
  itemModifyForm = new ItemModifyForm();
  showItemObjectForm: boolean = false;

  showFieldForChildComponent: boolean = false;

  response: any;
  createItemPropertiesApi = new CreateItemPropertiesApi();
  vehicleProgramApi = new VehicleProgramApi();
  transProgramApi = new TransProgramApi();
  attachFileApi = new AttachFileApi();
  engineProgramApi = new EngineProgramApi();

  //Program Screen:
  screenId: string = '';

  // show and hide the label text and drop down 
  showItemLabelTextBox: boolean = true;
  showItemLabeldropdown: boolean = false;


  constructor(private formBuilder: FormBuilder, private _Activatedroute: ActivatedRoute,
    public _EditpropertiesService: EditPropertiesService,
    public _ItemAdministrationService: ItemAdministrationService, public _AdminDateFormatPipe: AdminDateFormatPipe) {

  }

  ngOnInit() {
    this.formClear();
    this.directAclsCols = [
      { field: 'teamId', header: 'Team ID' },
      { field: 'team', header: 'Parent Team' },
      { field: 'query', header: 'Query' },
      { field: 'write', header: 'Write' },
      { field: 'read', header: 'Read' },
      { field: 'create', header: 'Create' },
      { field: 'delete', header: 'Delete' }
    ];

    this.inDirectAclsCols = [
      { field: 'teamId', header: 'Team ID' },
      { field: 'subTeam', header: 'Team' },
      { field: 'teamType', header: 'Type' },
      { field: 'team', header: 'Parent Team' },
      { field: 'queryAccess', header: 'Query' },
      { field: 'writeAccess', header: 'Write' },
      { field: 'readAccess', header: 'Read' },
      { field: 'createAccess', header: 'Create' },
      { field: 'deleteAccess', header: 'Delete' }
    ];

    this.sub = this._Activatedroute.paramMap.subscribe(params => {
      console.log('this._Activatedroute.paramMap=================>', params);
      this.type = params.get('type');
    });

    this._getData();

    this.itemObjectForm = this.formBuilder.group({
    });

    this.itemAssociatedObjectForm = this.formBuilder.group({
    });

    if (this.type === 'modify') {
      this.showFieldForChildComponent = false;
    } else if (this.type === 'list') {
      this.showFieldForChildComponent = true;
    }

    this.screenId = this._Activatedroute.snapshot.queryParamMap.get('screenId');

    if (this.screenId) {
      this._ModifyOrListProgram();
    }

  }

  _ModifyOrListProgram() {
    this.ownerCds = this._Activatedroute.snapshot.queryParamMap.get('owner');
    this.itemId = this._Activatedroute.snapshot.queryParamMap.get('programId');
    var programType = this._Activatedroute.snapshot.queryParamMap.get('programType');
    var itemLabel = this._Activatedroute.snapshot.queryParamMap.get('programName');

    var token = JSON.parse(sessionStorage.getItem('strAccessToken'));
    console.log('token=======>', token);
    // if (token) {
    //   this.ownerCds = token.CommonName;
    // }

    console.log('this.ownerCds=======>', this.ownerCds);
    console.log('itemLabel =====>', itemLabel);
    console.log('programType =====>', programType);
    console.log('itemTypeOptions======>', this.itemTypeOptions);

    this.modifyProgram = new ModifyProgram();
    this.modifyProgram.label = itemLabel;
    this.modifyProgram.itemTypes = [];

    if (programType == '43') {
      this.modifyProgram.itemTypes.push('43');
    } else if (programType == '44') {
      this.modifyProgram.itemTypes.push('44');
    } else if (programType == '42') {
      this.modifyProgram.itemTypes.push('42');
    }

    if (this.ownerCds) {
      this.modifyProgram.owner = this.ownerCds.toLowerCase();
    }

    this.modifyProgram.parent = this.parentId;
    // console.log('this.modifyProgram.label.length',this.modifyProgram.label.length);
    // console.log('this.itemId.length',this.itemId.length);
    if (this.itemId && this.modifyProgram.label.length <= 0) {
      // console.log('item id is not null and label is null');
      this.getItemData();
    } else if (this.itemId.length <= 0 && this.modifyProgram.label) {
      // console.log('item id is null and label is not null');
      if (this.showItemLabeldropdown) {
        // this.itemLabelOptions = data[0].itemLabelList[0];
      }
      if (this.showItemLabelTextBox) {
        this.itemLabelText = this.modifyProgram.label;
      }
      this.getItemData();
    } else {
      this._ItemAdministrationService.getItemLabel(this.modifyProgram).subscribe(data => {
        console.log('getItemLabel----->', data);
        if (data[0].itemLabelList) {
          if (this.showItemLabeldropdown) {
            this.itemLabelOptions = data[0].itemLabelList[0];
          }
          if (this.showItemLabelTextBox) {
            this.itemLabelText = data[0].itemLabelList[0].label;
          }
          this.getItemData();
        } else if (data[0].message) {
          alert(data[0].message)
        }
      });
    }

  }

  formClear() {
    if (this.tableComponent) {
      this.tableComponent.reset();
    }
    this.itemLabelText = '';
    this.type = '';
    this.itemTypeOptions = [];
    this.itemLabelOptions = [];
    this.itemLabel = '';
    this.ownerCds = '';
    this.parentId = '';
    this.selectedItemType = [];
    this.itemId = '';
    this.selectedDirectAclsList = [];
    this.selectedInDirectAclsList = [];

    this.retentionStatusOptions = [];
    this.retentionScheduleOptions = [];
    this.defaultServerOptions = [];
    this.securityLevelOptions = [];

    this.modifyProgram = new ModifyProgram();
    this.createItemPropertiesApi = new CreateItemPropertiesApi();
    this.vehicleProgramApi = new VehicleProgramApi();
    this.transProgramApi = new TransProgramApi();
    this.attachFileApi = new AttachFileApi();
    this.engineProgramApi = new EngineProgramApi();
  }

  _getItemLabel() {

    // show and hide the item label drop and text 

    this.showItemLabelTextBox = false;
    this.showItemLabeldropdown = true;
    this.modifyProgram = new ModifyProgram();
    this.modifyProgram.label = this.itemLabel;
    this.modifyProgram.itemTypes = [];
    this.selectedItemType.forEach((element) => {
      this.modifyProgram.itemTypes.push(element.id.toString());
    });
    this.modifyProgram.owner = this.ownerCds;
    this.modifyProgram.parent = this.parentId;

    this._ItemAdministrationService.getItemLabel(this.modifyProgram).subscribe(data => {
      console.log('getItemLabel----->', data);
      if (data[0].itemLabelList) {
        // this.itemLabelDropDown = data[0].itemLabelList[0].label;
        this.itemLabelDropDown = data[0].itemLabelList[0]
        this.itemLabelOptions = data[0].itemLabelList;
      } else if (data[0].message) {
        alert(data[0].message)
      }
    });
  }

  getItemData() {
    if (this.tableComponent) {
      this.tableComponent.reset();
    }
    this.showItemObjectForm = false;
    console.log('this.itemLabelDropDown', this.itemLabelDropDown);
    if (this.itemLabelDropDown != undefined) {
      if (this.itemLabelDropDown) {
        this.modifyProgram.label = this.itemLabelDropDown.label;
        this.modifyProgram.id = this.itemLabelDropDown.id;
      }
    } else if (this.itemLabelDropDown == undefined && this.itemId != undefined) {
      console.log('this.itemLabelOptions', this.itemLabelOptions);
      // console.log('this.modifyProgram.id', this.modifyProgram.id);
      if (this.itemLabelOptions && this.itemLabelOptions.length) {
        this.modifyProgram.label = this.itemLabelOptions[0].label;
        this.modifyProgram.id = this.itemLabelOptions[0].id;
      } else if (this.modifyProgram.id == undefined || this.modifyProgram.id) {
        var x = this.itemId;
        // console.log('x---->', x);
        this.modifyProgram.id = +x;
      }
    } else {
      if (this.itemId) {
        var x = this.itemId;
        this.modifyProgram.id = +x;
        this.modifyProgram.label = this.itemLabelDropDown.label;
      } else {
        this.modifyProgram.label = this.itemLabelDropDown.label;
        this.modifyProgram.id = this.itemLabelDropDown.id;
      }
    }
    console.log("final data", this.modifyProgram);
    this._ItemAdministrationService.getItemData(this.modifyProgram).subscribe(data => {
      console.log('getItemData----->', data);
      if (data) {
        this.itemModifyForm = data;
        this.showItemObjectForm = true;
        this.itemId = this.itemModifyForm.item.id;
        this.parentId = this.itemModifyForm.item.parent;
        this.itemLabelOptions = [];
        this.itemLabelDropDown = new ItemTypeList();
        this.itemLabelOptions = [{
          label: this.itemModifyForm.item.label,
          id: this.itemModifyForm.item.id
        }]
        this.itemLabelDropDown = {
          label: this.itemModifyForm.item.label,
          id: this.itemModifyForm.item.id
        }

        if (this.showItemLabelTextBox) {
          this.itemLabelText = this.itemModifyForm.item.label;
        }

        // if (this.itemModifyForm.item.itemType == 'engine_program') {

        // }

        // if (this.itemModifyForm.item.type == 43 || this.itemModifyForm.item.type == 44) {

        // }

        // if (this.itemModifyForm.item.type == 44 || this.itemModifyForm.item.type == 43 || this.itemModifyForm.item.type == 42){

        // }

      }
    });
  }

  formInitialized(name: string, form: FormGroup) {
    this.itemObjectForm.setControl(name, form);
  }

  modifyItem() {
    if (this.itemObjectForm.value) {
      if (this.itemObjectForm.value.itemObjectForm) {
        console.log('itemObjectForm', this.itemObjectForm.value.itemObjectForm);
        this.itemModifyForm.item.id = this.itemObjectForm.value.itemObjectForm.id
        // this.itemModifyForm.item.created_on = this.itemObjectForm.value.itemObjectForm.createdOn
        this.itemModifyForm.item.subjectList = this.itemObjectForm.value.itemObjectForm.subject
        this.itemModifyForm.item.ownerSearch = this.itemObjectForm.value.itemObjectForm.owner
        this.itemModifyForm.item.stewardSearch = this.itemObjectForm.value.itemObjectForm.steward
        this.itemModifyForm.item.label = this.itemObjectForm.value.itemObjectForm.label
        this.itemModifyForm.item.default_server = this.itemObjectForm.value.itemObjectForm.defaultServer
        // this.itemModifyForm.item.deleted_on = this.itemObjectForm.value.itemObjectForm.deletedOn
        this.itemModifyForm.item.expiration_date = this.itemObjectForm.value.itemObjectForm.expirationDate
        this.itemModifyForm.item.ret_schedule = this.itemObjectForm.value.itemObjectForm.retSchedule
        this.itemModifyForm.item.parent = this.itemObjectForm.value.itemObjectForm.parent
        this.itemModifyForm.item.milestone = this.itemObjectForm.value.itemObjectForm.milestone
        this.itemModifyForm.item.notifyOnReadDenied = this.itemObjectForm.value.itemObjectForm.notifyOnReadDenied
        this.itemModifyForm.item.program = this.itemObjectForm.value.itemObjectForm.program
        this.itemModifyForm.item.stdApprovalProcess = this.itemObjectForm.value.itemObjectForm.approvalRequired
        this.itemModifyForm.item.hold_count = this.itemObjectForm.value.itemObjectForm.holdCount
        this.itemModifyForm.item.lockOnCheckout = this.itemObjectForm.value.itemObjectForm.lockOnCheckOut
        this.itemModifyForm.item.retStatus = this.itemObjectForm.value.itemObjectForm.retStatus
        this.itemModifyForm.item.itemType = this.itemObjectForm.value.itemObjectForm.type
        this.itemModifyForm.item.frozen_on = this.itemObjectForm.value.itemObjectForm.frozenOn
        this.itemModifyForm.item.securityLevel = this.itemObjectForm.value.itemObjectForm.securityLevel
        this.itemModifyForm.item.keep_all_versions = this.itemObjectForm.value.itemObjectForm.keppAllVersion
        this.itemModifyForm.item.leader = this.itemObjectForm.value.itemObjectForm.leader
        this.itemModifyForm.item.display_order = this.itemObjectForm.value.itemObjectForm.displayOrder
        this.itemModifyForm.item.superseded_on = this.itemObjectForm.value.itemObjectForm.supersededOn
        // this.itemModifyForm.item.modified_on = this.itemObjectForm.value.itemObjectForm.modifiedOn
        // this.itemModifyForm.item.published_on = this.itemObjectForm.value.itemObjectForm.publishedOn
        this.itemModifyForm.item.cpscCPmtList = this.itemObjectForm.value.itemObjectForm.domain
        console.log('itemModifyForm //////', this.itemModifyForm);
        this._ItemAdministrationService.postItemModify(this.itemModifyForm).subscribe(data => {
          this.response = data;
          if (this.response === 'modSuccess') {
            alert(this.itemModifyForm.item.id + ' Item has been modified Successfully');
          } else if (this.response === 'failure') {
            alert('Failed');
          } else if (this.response === 'restrictedcharlabel') {
			  alert('Label contains \\ / : * ? " < > | or other restricted characters. Please try with different Label.');
		  } else if (this.response === 'restrictedcharcomments') {
			  alert('Comments contains \\ / : * ? " < > | or other restricted characters. Please try with different Comments.');
		  }
        })
      }
    }
  }

  subFormInitialized(name: string, form: FormGroup) {
    this.itemAssociatedObjectForm.setControl(name, form);
  }

  modifySubItem() {
    this.createItemPropertiesApi = new CreateItemPropertiesApi();
    if (this.itemAssociatedObjectForm.value) {
      console.log('itemAssociatedObjectForm', this.itemAssociatedObjectForm.value);
      if (this.itemAssociatedObjectForm.value.engineObjectForm) {
        this._UpdateEngineObject();
      }
      if (this.itemAssociatedObjectForm.value.engineProgramObjectForm) {
        // Commented by mprabh24 due to no saving/updating fn for engine program
        // this._UpdateEngineProgram();
      }
      if (this.itemAssociatedObjectForm.value.fileObjectForm) {
        this._UpdateAttachedFile()
      }
      if (this.itemAssociatedObjectForm.value.folderObjectForm) {
        this._UpdateFolderObject();
      }
      if (this.itemAssociatedObjectForm.value.transProgramObjectForm) {
        this._UpdateTransProgramObject();
      }
      if (this.itemAssociatedObjectForm.value.vehicleProgramObjectForm) {
        this._UpdateVehicleProgramObject();
      }
    }
    console.log('this.createItemPropertiesApi /////////////////====>', this.createItemPropertiesApi);
    this._ItemAdministrationService.postSubItemModify(this.createItemPropertiesApi).subscribe(data => {
      this.response = data;
      if (this.response === 'modSuccess') {
        alert(this.itemModifyForm.item.id + ' Sub Item has been modified Successfully');
      } else if (this.response === 'failure') {
		  alert(this.itemModifyForm.item.id + ' Sub Item modification Failed');
	  } else if (this.response === 'restrictedcharlabel') {
		  alert('Label contains \\ / : * ? " < > | or other restricted characters. Please try with different Label.');
	  } else if (this.response === 'restrictedcharcomments') {
		  alert('Comments contains \\ / : * ? " < > | or other restricted characters. Please try with different Comments.');
	  }
    })
  }

  _UpdateEngineProgram() {
    this.createItemPropertiesApi = new CreateItemPropertiesApi();
    this.engineProgramApi = new EngineProgramApi();
    this.createItemPropertiesApi.id = this.itemAssociatedObjectForm.value.engineProgramObjectForm.id;
    this.engineProgramApi.engine_program_config = this.itemAssociatedObjectForm.value.engineProgramObjectForm.config;
    this.engineProgramApi.engine_program_arch = this.itemAssociatedObjectForm.value.engineProgramObjectForm.engine_program_arch;
    this.engineProgramApi.engine_program_alt_label = this.itemAssociatedObjectForm.value.engineProgramObjectForm.engine_program_alt_label;
    this.engineProgramApi.engine_program_num_val = this.itemAssociatedObjectForm.value.engineProgramObjectForm.engine_program_num_val;
    this.engineProgramApi.engine_program_cur_approval = this.itemAssociatedObjectForm.value.engineProgramObjectForm.engine_program_cur_approval;
    this.engineProgramApi.engine_program_fuel = this.itemAssociatedObjectForm.value.engineProgramObjectForm.engine_program_fuel;
    this.engineProgramApi.engine_program_lead_vehicle = this.itemAssociatedObjectForm.value.engine_program_lead_vehicle;
    this.engineProgramApi.engine_program_modal_year = this.itemAssociatedObjectForm.value.engineProgramObjectForm.engine_program_modal_year;
    //this.engineProgramApi.ppt= egmtSystemId: [this.engineProgram.engine_program_pptm001_system_d],
    this.engineProgramApi.engine_program_displacement = this.itemAssociatedObjectForm.value.engine_program_displacement;
    //this.engineProgramApi.engine= egmtEngineId: [this.engineProgram.engine_program_pptm001_engine_d],
    //this.engineProgramApi.=brand: [this.engineProgram.engine_program_brand]
    console.log(' this.engineProgramApi', this.engineProgramApi);
    this.createItemPropertiesApi.type = this.itemModifyForm.item.type;
    this.createItemPropertiesApi.engineProgramApi = this.engineProgramApi;
  }

  _UpdateTransProgramObject() {
    this.createItemPropertiesApi = new CreateItemPropertiesApi();
    this.transProgramApi = new TransProgramApi();
    this.createItemPropertiesApi.id = this.itemAssociatedObjectForm.value.transProgramObjectForm.id;
    this.transProgramApi.transprog_old_name = this.itemAssociatedObjectForm.value.transProgramObjectForm.oldName;
    this.transProgramApi.transprog_continuous = this.itemAssociatedObjectForm.value.transProgramObjectForm.continuous;
    this.transProgramApi.transprog_change_level = this.itemAssociatedObjectForm.value.transProgramObjectForm.changeLevel;
    this.transProgramApi.transprog_config = this.itemAssociatedObjectForm.value.transProgramObjectForm.config;
    this.transProgramApi.transprog_rms_prog_name = this.itemAssociatedObjectForm.value.transProgramObjectForm.rmsProgramName;
    this.transProgramApi.transprog_alt_label = this.itemAssociatedObjectForm.value.transProgramObjectForm.altLabel;
    this.transProgramApi.transprog_comp_prog = this.itemAssociatedObjectForm.value.transProgramObjectForm.comparatorProgram;
    this.transProgramApi.transprog_cur_approval = this.itemAssociatedObjectForm.value.transProgramObjectForm.curApproval;
    this.transProgramApi.transprog_work_task_num = this.itemAssociatedObjectForm.value.transProgramObjectForm.workTaskNum;
    this.transProgramApi.transprog_list_domain = this.itemAssociatedObjectForm.value.transProgramObjectForm.listDomains;
    this.transProgramApi.transprog_torque_capacity = this.itemAssociatedObjectForm.value.transProgramObjectForm.torqueCapacity;
    this.transProgramApi.end_of_life = this.itemAssociatedObjectForm.value.transProgramObjectForm.endOfLife;
    this.transProgramApi.transprog_comments = this.itemAssociatedObjectForm.value.transProgramObjectForm.comments;
    this.transProgramApi.transprog_life_code = this.itemAssociatedObjectForm.value.transProgramObjectForm.lifeCode;
    this.transProgramApi.transprog_drive_train = this.itemAssociatedObjectForm.value.transProgramObjectForm.driveTrain;
    this.transProgramApi.transprog_model_year_end = this.itemAssociatedObjectForm.value.transProgramObjectForm.modelYearEnd;
    this.transProgramApi.job_one = this.itemAssociatedObjectForm.value.transProgramObjectForm.jobOne;
    this.transProgramApi.transprog_pmt_domain = this.itemAssociatedObjectForm.value.transProgramObjectForm.pmtDomain;
    this.transProgramApi.transprog_model_year_end = this.itemAssociatedObjectForm.value.transProgramObjectForm.modelYear;
    //this.transProgramApi.brand=this.itemAssociatedObjectForm.value.transProgramObjectForm.transprog_brand;
    this.transProgramApi.transprog_num_speeds = this.itemAssociatedObjectForm.value.transProgramObjectForm.numSpeeds;
    this.transProgramApi.transprog_lead_vehicle = this.itemAssociatedObjectForm.value.transProgramObjectForm.leadVehicle;
    this.transProgramApi.transprog_kick_off = this.itemAssociatedObjectForm.value.transProgramObjectForm.kickOff;
    console.log(' this.transProgramApi', this.transProgramApi);
    this.createItemPropertiesApi.type = this.itemModifyForm.item.type;
    this.createItemPropertiesApi.transProgramApi = this.transProgramApi;
  }

  _UpdateEngineObject() {
    this.createItemPropertiesApi.id = this.itemAssociatedObjectForm.value.engineObjectForm.id;
    this.createItemPropertiesApi.type = this.itemModifyForm.item.type;
    this.createItemPropertiesApi.engine_worktask_num = this.itemAssociatedObjectForm.value.engineObjectForm.workTaskNum;
  }

  _UpdateFolderObject() {
    this.createItemPropertiesApi.id = this.itemAssociatedObjectForm.value.folderObjectForm.id;
    this.createItemPropertiesApi.type = this.itemModifyForm.item.type;
    this.createItemPropertiesApi.folder_purchase_order = this.itemAssociatedObjectForm.value.folderObjectForm.purchaseOrder;
    this.createItemPropertiesApi.folder_comments = this.itemAssociatedObjectForm.value.folderObjectForm.comments;
    this.createItemPropertiesApi.folder_vendor = this.itemAssociatedObjectForm.value.folderObjectForm.vendor;
    this.createItemPropertiesApi.folder_project_id = this.itemAssociatedObjectForm.value.folderObjectForm.projectId;
  }

  _UpdateVehicleProgramObject() {
    this.createItemPropertiesApi = new CreateItemPropertiesApi();
    this.vehicleProgramApi = new VehicleProgramApi();
    console.log('this.itemAssociatedObjectForm.value.vehicleProgramObjectForm.brand++++++++', this.itemAssociatedObjectForm.value.vehicleProgramObjectForm.brand)
    console.log('this.itemAssociatedObjectForm.value.vehicleProgramObjectForm.curApproval', this.itemAssociatedObjectForm.value.vehicleProgramObjectForm.curApproval)
    console.log('///this.itemAssociatedObjectForm.value.vehicleProgramObjectForm////', this.itemAssociatedObjectForm.value.vehicleProgramObjectForm);

    this.createItemPropertiesApi.id = this.itemAssociatedObjectForm.value.vehicleProgramObjectForm.id;
    this.vehicleProgramApi.vehicle_program_brand = this.itemAssociatedObjectForm.value.vehicleProgramObjectForm.brand;
    this.vehicleProgramApi.vehicle_program_list_domains = this.itemAssociatedObjectForm.value.vehicleProgramObjectForm.listDomains;
    this.vehicleProgramApi.vehicle_program_comments = this.itemAssociatedObjectForm.value.vehicleProgramObjectForm.comments;
    this.vehicleProgramApi.vehicle_program_end_of_life = this.itemAssociatedObjectForm.value.vehicleProgramObjectForm.endOfLife;
    this.vehicleProgramApi.vehicle_program_change_level = this.itemAssociatedObjectForm.value.vehicleProgramObjectForm.changeLevel;
    this.vehicleProgramApi.vehicle_program_model_year_end = this.itemAssociatedObjectForm.value.vehicleProgramObjectForm.modelYearEnd;
    this.vehicleProgramApi.Vehicle_program_alt_label = this.itemAssociatedObjectForm.value.vehicleProgramObjectForm.altLabel;
    this.vehicleProgramApi.vehicle_program_comparator_prog = this.itemAssociatedObjectForm.value.vehicleProgramObjectForm.comparatorProgram;
    this.vehicleProgramApi.vehicle_program_job_one = this.itemAssociatedObjectForm.value.vehicleProgramObjectForm.jobOne;
    
    this.vehicleProgramApi.curApproval = this.itemAssociatedObjectForm.value.vehicleProgramObjectForm.curApproval;

    this.vehicleProgramApi.vehicle_program_pmt_domain = this.itemAssociatedObjectForm.value.vehicleProgramObjectForm.pmtDomain;
    this.vehicleProgramApi.vehicle_program_name_plate = this.itemAssociatedObjectForm.value.vehicleProgramObjectForm.namePlate;
    this.vehicleProgramApi.vehicle_program_model_year = this.itemAssociatedObjectForm.value.vehicleProgramObjectForm.modelYear;
    this.vehicleProgramApi.vehicle_program_code = this.itemAssociatedObjectForm.value.vehicleProgramObjectForm.proramCode;
    //this.createItemPropertiesApi.vehicleProgramApi.brand: [this.vehicleProgram.vehicle_program_brand],
    this.vehicleProgramApi.vehicle_program_kick_off = this.itemAssociatedObjectForm.value.vehicleProgramObjectForm.kickOff;
    console.log(' this.vehicleProgramApi', this.vehicleProgramApi);
    this.createItemPropertiesApi.type = this.itemModifyForm.item.type;
    this.createItemPropertiesApi.vehicleProgramApi = this.vehicleProgramApi;
    if(this.itemAssociatedObjectForm.value.vehicleProgramObjectForm.brand.value != null  || this.itemAssociatedObjectForm.value.vehicleProgramObjectForm.brand.value != ''){
    this.createItemPropertiesApi.company_name = this.itemAssociatedObjectForm.value.vehicleProgramObjectForm.brand.value;
  }
}

  _UpdateAttachedFile() {

    this.createItemPropertiesApi = new CreateItemPropertiesApi();
    this.attachFileApi = new AttachFileApi();

    this.createItemPropertiesApi.id = this.itemAssociatedObjectForm.value.fileObjectForm.id;
    // this.attachFileApi.created_on = this.itemAssociatedObjectForm.value.fileObjectForm.createdOn;
    this.attachFileApi.file_actual_server = this.itemAssociatedObjectForm.value.fileObjectForm.actualServer;
    this.attachFileApi.file_fmccrypt_key = this.itemAssociatedObjectForm.value.fileObjectForm.fmcCryptKey;
    this.attachFileApi.file_st_mode = this.itemAssociatedObjectForm.value.fileObjectForm.stMode;
    this.attachFileApi.accessed_on = this.itemModifyForm.associatedTable.attachedFile.accessed_on;
   
    this.attachFileApi.file_orig_stored_size = this.itemAssociatedObjectForm.value.fileObjectForm.origStoredSize;
    this.attachFileApi.file_compression = this.itemAssociatedObjectForm.value.fileObjectForm.compression;
    this.attachFileApi.file_is_default = this.itemAssociatedObjectForm.value.fileObjectForm.isDefault.value;
    this.attachFileApi.File_view_count = this.itemAssociatedObjectForm.value.fileObjectForm.viewCount;
    this.attachFileApi.file_checksum = this.itemAssociatedObjectForm.value.fileObjectForm.checkSum;
    this.attachFileApi.file_stored_size = this.itemAssociatedObjectForm.value.fileObjectForm.storedSize;
    this.attachFileApi.file_backup_path = this.itemAssociatedObjectForm.value.fileObjectForm.backUpPath;
    this.attachFileApi.file_external_mime_type = this.itemAssociatedObjectForm.value.fileObjectForm.externalMimeType;
    this.attachFileApi.file_comments = this.itemAssociatedObjectForm.value.fileObjectForm.comments;
    this.attachFileApi.file_mime_type = this.itemAssociatedObjectForm.value.fileObjectForm.mimeType;
    this.attachFileApi.deleted_on = this._AdminDateFormatPipe.transform(this.itemAssociatedObjectForm.value.fileObjectForm.deleteFromDiskOn);
    this.attachFileApi.file_ret_status = this.itemAssociatedObjectForm.value.fileObjectForm.retStatus.value;
    this.attachFileApi.file_actual_size = this.itemAssociatedObjectForm.value.fileObjectForm.actualSize;
    this.attachFileApi.file_mime_param = this.itemAssociatedObjectForm.value.fileObjectForm.mimeParam;
    this.attachFileApi.file_path = this.itemAssociatedObjectForm.value.fileObjectForm.path;
    this.attachFileApi.file_is_encrypted = this.itemAssociatedObjectForm.value.fileObjectForm.isEncrypted.value;
    this.attachFileApi.file_ret_checkpoint = this.itemAssociatedObjectForm.value.fileObjectForm.retCheckPoint;
    this.attachFileApi.file_version = this.itemAssociatedObjectForm.value.fileObjectForm.version;
    this.attachFileApi.file_mime_subtype = this.itemAssociatedObjectForm.value.fileObjectForm.mimeSubType;
    // this.attachFileApi.modified_on = this.itemAssociatedObjectForm.value.fileObjectForm.modifiedOn;
    this.attachFileApi.attached_owner = this.itemAssociatedObjectForm.value.fileObjectForm.owner;
    // this.attachFileApi.= this.itemAssociatedObjectForm.value.fileObjectForm.publishedOn;
    console.log(' this.attachFileApi', this.attachFileApi);
    this.createItemPropertiesApi.type = this.itemModifyForm.item.type;
    this.createItemPropertiesApi.attachFileApi = this.attachFileApi;
  }


  fetchItems() {
    this.showItemObjectForm = false;
    var labelStr = (<HTMLInputElement>document.getElementById("labelStr")).value;
    var owner = (<HTMLInputElement>document.getElementById("owner")).value;
    var parent = (<HTMLInputElement>document.getElementById("parent")).value;

    if (labelStr == "" && owner == "" && parent == "") {
      alert("Please enter Label String or OwnerCDSID or ParentID");
      return false;
    }
    if ((parent) && (parent != "") && !this.validateInteger(parent)) {
      alert("Invalid ParentId Specified");
      return false;
    }
    this._getItemLabel();
  }

  validateInteger(strValue: string) {
    var objRegExp = /(^-?\d\d*$)/;
    return objRegExp.test(strValue);
  }



  _getData() {
    this._ItemAdministrationService.getItemType().subscribe(data => {
      if (data.result) {
        this.itemTypeOptions = data.itemTypeList;
        console.log('Default selectedItemType value _getData');
        this.selectedItemType = this.itemTypeOptions;
      }
    });

    this._EditpropertiesService.getRetScheduleList().subscribe(data => {
      this.retentionScheduleOptions = data;
    });

    this._EditpropertiesService.getRetStatusList().subscribe(data => {
      this.retentionStatusOptions = data;
    });

    this._EditpropertiesService.getServerList().subscribe(data => {
      this.defaultServerOptions = data;
    });

    this._EditpropertiesService.getSecurityList().subscribe(data => {
      this.securityLevelOptions = data;
    });

    this._EditpropertiesService.getApprovalList().subscribe(data => {
      this.approvalOptions = data;
    });

    this._EditpropertiesService.getSubjectList().subscribe(data => {
      this.subjectOptions = data;
    });

    this._EditpropertiesService.getengineArchList().subscribe(data => {
      this.engineArchOptions = data;
    });

    this._EditpropertiesService.getengineFuelList().subscribe(data => {
      this.engineFuelOptions = data;
    });

    this._EditpropertiesService.getLeadVehicleList_AdminPortal().subscribe(data => {
      this.leadVehicleOptions = data;
    });

    this._EditpropertiesService.getTransBrandList().subscribe(data => {
      this.transBrandOptions = data;
    });

    this._EditpropertiesService.getDomainListD().subscribe(data => {
      this.domainOptions = data;
    });



  }

  modifyACL() {
    console.log('selectedDirectAclsList ////modifyACL////', this.selectedDirectAclsList);
    this._ItemAdministrationService.postModifyACL(this.selectedDirectAclsList, this.itemModifyForm).subscribe(data => {
      // console.log('///////',data);
      if (data === 'failure') {
        alert(data);
      } else {
        alert(data);
        this.selectedDirectAclsList = [];
        if (this.tableComponent) {
          this.tableComponent.reset();
        }
      }
    });
  }

  deleteACL() {
    console.log('selectedDirectAclsList ////deleteACL////', this.selectedDirectAclsList);
    console.log('AclList ////Before deleteACL////', this.itemModifyForm.aclList);
    this._ItemAdministrationService.postDeleteACL(this.selectedDirectAclsList, this.itemModifyForm).subscribe(data => {
      // console.log('///////',data);
      if (data === 'failure') {
        alert(data);
      } else {
        alert(data);
        this.selectedDirectAclsList.forEach((element) => {
          const index = this.itemModifyForm.aclList.indexOf(element);
          this.itemModifyForm.aclList.splice(index, 1);
        });
        this.selectedDirectAclsList = [];
        if (this.tableComponent) {
          this.tableComponent.reset();
        }
        console.log('AclList ////After deleteACL////', this.itemModifyForm.aclList);
        this.getItemData();
      }
    });
  }

  showAclModifyandDeleteButton() {
    if (this.itemModifyForm.aclList.length == 0) {
      return true;
    } else {
      return this.showFieldForChildComponent;
    }
  }


  // if (this.itemAssociatedObjectForm.value.jlrCaeFolderObjectForm) {
  //   this.createItemPropertiesApi.id = this.itemAssociatedObjectForm.value.jlrCaeFolderObjectForm.id;
  //   this.createItemPropertiesApi.jlrCaeComments = this.itemAssociatedObjectForm.value.jlrCaeFolderObjectForm.comments;
  //   this.createItemPropertiesApi.jlrCaeKeyword = this.itemAssociatedObjectForm.value.jlrCaeFolderObjectForm.keywords;
  //   this.createItemPropertiesApi.jlrCaeMigrated = this.itemAssociatedObjectForm.value.jlrCaeFolderObjectForm.migratedOn;
  //   this.createItemPropertiesApi.jlrCaeProjectId = this.itemAssociatedObjectForm.value.jlrCaeFolderObjectForm.projectId;
  // }
  // if (this.itemAssociatedObjectForm.value.jlrGroupRegisterObjectForm) {
  //   this.createItemPropertiesApi.id = this.itemAssociatedObjectForm.value.jlrGroupRegisterObjectForm.id;
  //   this.createItemPropertiesApi.jlrGrpAutofreeze = this.itemAssociatedObjectForm.value.jlrGroupRegisterObjectForm.autoFreeze;
  //   this.createItemPropertiesApi.jlrGrpCheckFilter = this.itemAssociatedObjectForm.value.jlrGroupRegisterObjectForm.checkInFilter;
  // }
  // if (this.itemAssociatedObjectForm.value.optegraItemObjectForm) {
  //   this.createItemPropertiesApi.id = this.itemAssociatedObjectForm.value.optegraItemObjectForm.id;
  //   this.createItemPropertiesApi.optegra_comments = this.itemAssociatedObjectForm.value.optegraItemObjectForm.comments;
  //   this.createItemPropertiesApi.optegra_part_type = this.itemAssociatedObjectForm.value.optegraItemObjectForm.partType;
  //   this.createItemPropertiesApi.optegra_revision = this.itemAssociatedObjectForm.value.optegraItemObjectForm.revision;
  //   this.createItemPropertiesApi.optegra_status = this.itemAssociatedObjectForm.value.optegraItemObjectForm.optegraStatus;
  //   this.createItemPropertiesApi.optegra_migrated_on = this.itemAssociatedObjectForm.value.optegraItemObjectForm.migratedOn;
  // }
  // if (this.itemAssociatedObjectForm.value.partObjectForm) {
  //   this.createItemPropertiesApi.id = this.itemAssociatedObjectForm.value.partObjectForm.id;
  //   this.createItemPropertiesApi.partBase = this.itemAssociatedObjectForm.value.partObjectForm.base;
  //   this.createItemPropertiesApi.partName = this.itemAssociatedObjectForm.value.partObjectForm.name;
  //   this.createItemPropertiesApi.partComments = this.itemAssociatedObjectForm.value.partObjectForm.comments;
  //   this.createItemPropertiesApi.partChangeLevel = this.itemAssociatedObjectForm.value.partObjectForm.changeLevel;
  //   this.createItemPropertiesApi.partPrefix = this.itemAssociatedObjectForm.value.partObjectForm.prefix;
  //   // this.createItemPropertiesApi.partBase=this.itemAssociatedObjectForm.value.partObjectForm.num;
  //   this.createItemPropertiesApi.partComparatorNum = this.itemAssociatedObjectForm.value.partObjectForm.comparatorNum;
  //   this.createItemPropertiesApi.partDesignTeamId = this.itemAssociatedObjectForm.value.partObjectForm.designTeam;
  // }
  // if (this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm) {
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_speeddev = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosSpeedDev;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_order3 = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosOrder3;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_fanspeedhighlimit = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosFanSpeedHighLimit;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_vehicle_config_disc = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosVehicleConfigDesc;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_driver_seat_type = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.driverSeatType;
  //   // this.createItemPropertiesApi.ptnvhqosTestApi.amb= this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ambHumidityUnits;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_thresh_steering = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosThreshStreeing;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_polynomial_range = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.polynomialRange;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_maxfanspeed_100perc = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosMaxFanSpeed;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_engine_cyl_config = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.engineCylinderConfiguration;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_highsweepspeed = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosHighSweepSpeed;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_trans_temp_units = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosTransTempUnits;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_ordercut_width = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosOrderCutWidth;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_minfanspeed_operc = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosMinFanSpeed;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_amb_airpress_units = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ambAirPressureUnits;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_idlerpmquality = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.idleRpmQuality;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_vib_orderopt = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosVibOrderOpt;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_gear_sele_loc = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.gearSelectorLocation;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_amb_humidity = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ambHumidity;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_body_style = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.bodyStyle;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_veh_opt_or_pack = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.vehOptionsOrPackages;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_vehicle_program = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.vehicleProgram;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_fanspeedavg = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosFanSpeedAvg;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_idle_ver = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosIdleVer;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_default_snd_fftsize = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.defaultSndFftSize;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_market = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.market;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_tire_manuf = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.tireManufacture;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_wot_ver = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosWotVer;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_pot_proc = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosPotProc;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_num_cyl = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.numberOfCylinders;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_fftopt_method = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosFftOptMethod;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_assblyplantcode = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.assblyplantCode;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_snd_orderopt = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosSndOrderOpt;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_threshcheck = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosThreshCheck;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_highanalspeed = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqoshighAnalSpeed;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_vehicle_model = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.vehicleModel;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_roof_opt = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.roofOptions;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_sweepavgfactor = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosSweepPavgFactor;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_freqresolution = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.freqreSolution;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_vin = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosVin;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_tire_type = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.tireType;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_amb_windspeed = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ambWindSpeed;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_driveline_type = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.driveLineType;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_no_of_doors = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.numberOfDoors;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_fanspeedlowlimit = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosFanSpeedLowLimit;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_engine_displacement = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.engineDisplacement;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_lowanalspeed = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosLowAnalSpeed;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_trans_temp = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosTransTemp;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_amb_airpressure = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ambAirPressure;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_fanspeedmin = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosFanSppedMin;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_order2 = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosOrder2;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_other_vehicle_spec = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.otherVehicleSpecs;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_neru_proc = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosneruProc;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_vehbuildlevel = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.vehBuildLevel;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_amb_temp = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ambTemp;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_neru_ver = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosNeruVer;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_default_vib_fftsize = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.defaultVibFftSize;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_condition = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosCondition;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_amb_temp_units = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ambTempUnits;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_fanspeedmax = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosFanSpeedMax;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_wheel_type = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.wheelType;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_trim_level = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.trimLevel;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_amb_windspeed_units = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ambWindSpeedUnits;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_lowsweepspeed = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosLowSweepSpeed;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_description = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosTestDescription;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_speedstddev = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosSpeedsTdDev;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_sweepcheck = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosSweepCheck;
  //   this.createItemPropertiesApi.id = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.id;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_threshmethod = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosTheshMethod;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_order4 = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosOrder4;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_basefilename = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.baseFileName;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_wot_proc = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosWotProc;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_ordercut_method = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosOrderCutMethod;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_fuel_type = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.fuelType;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_orig_date = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.origDate;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_thresh_seat = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosThreshSeat;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_pot_ver = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosPotVer;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_operator = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosOperator;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_order1 = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosOrder1;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_num_repairs = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.numRepairs;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_veh_build_date = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.vehBuildDate;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_tire_size = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.tireSize;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_vehicle_type = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.vehicleType;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_percent_repairs = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.percentRepairs;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_opcondition = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosOpCondition;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_numberoffailed = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.numberOfFailed;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_fanrangetype = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosFanRangeType;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_doeorderdata = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosDoOrderData;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_vehicle_config = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosVehicleConfig;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_valvetrainconfig = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.valveTrainConfig;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_orig_time = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.origTime;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_targetspeed = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosTargetSpeed;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_per_cyl = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.valvesperCylinder;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_testcondition = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosTestCondition;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_idle_proc = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.ptnvhqosIdleProc;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_assblyplantloc = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.assblyPlantLoc;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_seat_material = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.seatMaterial;
  //   this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_transmission_type = this.itemAssociatedObjectForm.value.ptnvhqosTestObjectForm.transmissionType;
  // }
  // if (this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm) {
  //   this.createItemPropertiesApi.id = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.id;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_eng_displacement = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_eng_displacement;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_vehicle_configdesc = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_vehicle_configdesc;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_driver_seat_type = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_driver_seat_type;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_other_vehicle_specs = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_other_vehicle_specs;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_vehbuildlevel = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_vehbuildlevel;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_eng_cyl_config = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_eng_cyl_config;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_condition = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_condition;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_gear_sel_location = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_gear_sel_location;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_veh_opt_or_pack = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_veh_opt_or_pack;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_body_style = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_body_style;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_wheel_type = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_wheel_type;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_vehicle_prog = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_vehicle_prog;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_trim_level = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_trim_level;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_fuel_type = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_fuel_type
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_orig_date = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_orig_date;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_market = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_market;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_vehicle_type = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_vehicle_type;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_tire_manufacture = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_tire_manufacture;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_tire_size = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_tire_size;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_num_cylinder = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_num_cylinder;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.End_date = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_end_date;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_assblyplantcode = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_assblyplanloc;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_test_metric_desc = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_test_metric_desc;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_vehicle_config = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_vehicle_config;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_valvetrainconfig = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_valvetrainconfig;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_orig_time = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_orig_time;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_vehicle_model = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_vehicle_model;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.Start_date = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_start_date;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_roof_options = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_roof_options;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_valves_per_cylinder = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_valves_per_cylinder;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_tire_type = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_tire_type;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_vin = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_vin;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_driveline_type = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_driveline_type;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_seat_material = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_seat_material;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_assblyplanloc = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_assblyplanloc;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_number_of_doors = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_number_of_doors;
  //   this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_transmission_type = this.itemAssociatedObjectForm.value.ptnvhqosReportObjectForm.ptnvhqos_report_transmission_type;
  // }
  // if (this.itemAssociatedObjectForm.value.queryObjectForm) {
  //   this.createItemPropertiesApi.id = this.itemAssociatedObjectForm.value.queryObjectForm.id;
  //   this.createItemPropertiesApi.childQuery = this.itemAssociatedObjectForm.value.queryObjectForm.query;
  // }
  // if (this.itemAssociatedObjectForm.value.refObjectForm) {
  //   this.createItemPropertiesApi.id = this.itemAssociatedObjectForm.value.refObjectForm.id;
  //   this.createItemPropertiesApi.refToType = this.itemAssociatedObjectForm.value.refObjectForm.to_type;
  //   this.createItemPropertiesApi.refToId = this.itemAssociatedObjectForm.value.refObjectForm.to_id;
  // }
  // if (this.itemAssociatedObjectForm.value.sdrcItemObjectForm) {
  //   this.createItemPropertiesApi.id = this.itemAssociatedObjectForm.value.sdrcItemObjectForm.id;
  //   this.createItemPropertiesApi.sdrcReportApi.sdrc_metaphase_creator = this.itemAssociatedObjectForm.value.sdrcItemObjectForm.sdrc_metaphase_creator;
  //   this.createItemPropertiesApi.sdrcReportApi.sdrc_is_metaphase_frozen = this.itemAssociatedObjectForm.value.sdrcItemObjectForm.sdrc_is_metaphase_frozen;
  //   this.createItemPropertiesApi.sdrcReportApi.sdrc_tdm_id = this.itemAssociatedObjectForm.value.sdrcItemObjectForm.sdrc_tdm_id;
  //   this.createItemPropertiesApi.sdrcReportApi.sdrc_metaphase_id = this.itemAssociatedObjectForm.value.sdrcItemObjectForm.sdrc_metaphase_id;
  //   this.createItemPropertiesApi.sdrcReportApi.Tdm_created_on = this.itemAssociatedObjectForm.value.sdrcItemObjectForm.sdrc_tdm_created_on;
  //   this.createItemPropertiesApi.sdrcReportApi.sdrc_num = this.itemAssociatedObjectForm.value.sdrcItemObjectForm.sdrc_num;
  //   this.createItemPropertiesApi.sdrcReportApi.sdrc_ideas_type = this.itemAssociatedObjectForm.value.sdrcItemObjectForm.sdrc_ideas_type;
  //   //this.createItemPropertiesApi.sdrcReportApi.curApproval: [this.sdrcItem.sdrc_cur_approval],
  //   this.createItemPropertiesApi.sdrcReportApi.sdrc_last_checkin_user = this.itemAssociatedObjectForm.value.sdrcItemObjectForm.sdrc_last_checkin_user;
  //   this.createItemPropertiesApi.sdrcReportApi.Tdm_modified_on = this.itemAssociatedObjectForm.value.sdrcItemObjectForm.sdrc_tdm_modified_on;
  //   this.createItemPropertiesApi.sdrcReportApi.sdrc_metaphase_class = this.itemAssociatedObjectForm.value.sdrcItemObjectForm.sdrc_metaphase_class;
  //   this.createItemPropertiesApi.sdrcReportApi.sdrc_name = this.itemAssociatedObjectForm.value.sdrcItemObjectForm.sdrc_name;
  //   this.createItemPropertiesApi.sdrcReportApi.sdrc_tdm_project = this.itemAssociatedObjectForm.value.sdrcItemObjectForm.sdrc_tdm_project;
  //   this.createItemPropertiesApi.sdrcReportApi.Metaphase_modified_on = this.itemAssociatedObjectForm.value.sdrcItemObjectForm.sdrc_metaphase_modified_on;
  //   this.createItemPropertiesApi.sdrcReportApi.sdrc_revision = this.itemAssociatedObjectForm.value.sdrcItemObjectForm.sdrc_revision;
  //   this.createItemPropertiesApi.sdrcReportApi.sdrc_is_metaphase_superceded = this.itemAssociatedObjectForm.value.sdrcItemObjectForm.sdrc_is_metaphase_superceded;
  //   this.createItemPropertiesApi.sdrcReportApi.sdrc_tdm_library = this.itemAssociatedObjectForm.value.sdrcItemObjectFormsdrc_tdm_library;
  //   this.createItemPropertiesApi.sdrcReportApi.sdrc_last_checkout_user = this.itemAssociatedObjectForm.value.sdrcItemObjectForm.sdrc_last_checkout_user;
  //   this.createItemPropertiesApi.sdrcReportApi.Metaphase_checkin_on = this.itemAssociatedObjectForm.value.sdrcItemObjectForm.sdrc_metaphase_checkin_on;
  //   this.createItemPropertiesApi.sdrcReportApi.sdrc_tdm_creator = this.itemAssociatedObjectForm.value.sdrcItemObjectForm.sdrc_tdm_creator;
  //   this.createItemPropertiesApi.sdrcReportApi.sdrc_tdm_status = this.itemAssociatedObjectForm.value.sdrcItemObjectForm.sdrc_tdm_status;
  //   this.createItemPropertiesApi.sdrcReportApi.sdrc_is_metaphase_checkedout = this.itemAssociatedObjectForm.value.sdrcItemObjectForm.sdrc_is_metaphase_checkedout;
  //   this.createItemPropertiesApi.sdrcReportApi.sdrc_version = this.itemAssociatedObjectForm.value.sdrcItemObjectForm.sdrc_version;
  //   this.createItemPropertiesApi.sdrcReportApi.Metaphase_created_on = this.itemAssociatedObjectForm.value.sdrcItemObjectForm.sdrc_metaphase_created_on;
  // }
  // if (this.itemAssociatedObjectForm.value.urlObjectForm) {
  //   this.createItemPropertiesApi.id = this.itemAssociatedObjectForm.value.urlObjectForm.id;
  //   this.createItemPropertiesApi.externalUrl = this.itemAssociatedObjectForm.value.urlObjectForm.toUrl;
  // }
  // if (this.itemAssociatedObjectForm.value.testLabObjectForm) {
  //   this.createItemPropertiesApi.id = this.itemAssociatedObjectForm.value.testLabObjectForm.id;
  //   this.createItemPropertiesApi.testCert = this.itemAssociatedObjectForm.value.testLabObjectForm.certTest;
  //   this.createItemPropertiesApi.testType = this.itemAssociatedObjectForm.value.testLabObjectForm.testType;
  //   this.createItemPropertiesApi.testRqNum = this.itemAssociatedObjectForm.value.testLabObjectForm.testRqNo;
  //   this.createItemPropertiesApi.testWorkTaskNum = this.itemAssociatedObjectForm.value.testLabObjectForm.workTaskNum;
  //   this.createItemPropertiesApi.testDate = this.itemAssociatedObjectForm.value.testLabObjectForm.testDate;
  //   this.createItemPropertiesApi.testTitle = this.itemAssociatedObjectForm.value.testLabObjectForm.testTitle;
  //   this.createItemPropertiesApi.testUnitUnderTest = this.itemAssociatedObjectForm.value.testLabObjectForm.unitUnderTest;
  // }
  // if (this.itemAssociatedObjectForm.value.drawingObjectForm) {
  //   this.createItemPropertiesApi.id = this.itemAssociatedObjectForm.value.drawingObjectForm.id;
  //   this.createItemPropertiesApi.drawing_num = this.itemAssociatedObjectForm.value.drawingObjectForm.num;
  //   this.createItemPropertiesApi.drawing_name = this.itemAssociatedObjectForm.value.drawingObjectForm.name;
  //   this.createItemPropertiesApi.drawing_brass_tag = this.itemAssociatedObjectForm.value.drawingObjectForm.brassTag;
  //   this.createItemPropertiesApi.drawing_purchase_order = this.itemAssociatedObjectForm.value.drawingObjectForm.purchaseOrder;
  //   this.createItemPropertiesApi.drawing_comments = this.itemAssociatedObjectForm.value.drawingObjectForm.comments;
  //   this.createItemPropertiesApi.drawing_vendor = this.itemAssociatedObjectForm.value.drawingObjectForm.vendor;
  // }


  showAssociatedTableBtn() {
    if (this.itemModifyForm.item.itemType !== 'engine_program') {
      return true;
    }
    return false;
  }

  itemIdMethod($event) {
    console.log('itemIdMethod $event', $event);
    this.itemId = $event;
  }

  itemLabelMethodtext($event) {
    console.log("item label", $event);
    this.itemLabelText = $event;
    this.itemLabelDropDown = new ItemTypeList();
    this.itemLabelDropDown.label = this.itemLabelText;

  }

  itemLabelMethod($event) {
    console.log('itemLabelMethod $event', $event);
    this.itemLabelDropDown = new ItemTypeList();
    this.itemLabelDropDown.label = $event.label;
    this.itemLabelDropDown.id = $event.id;
  }
}
