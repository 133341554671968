import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Item, Part } from 'app/core/model/itemAdminModels';

@Component({
  selector: 'part-object',
  templateUrl: './part-object.component.html',
  styleUrls: ['./part-object.component.css']
})
export class PartObjectComponent implements OnInit {

  partObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()
  @Input('screenType') screenType: string;
  @Input('item') item: Item;
  @Input('partItem') partItem: Part;
  @Input('showfield') showfield: boolean;
  disabled = true;
  
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
    this._updatePartObjectForm();
  }

  _formClear() {
    this.partObjectForm = this.formBuilder.group({
      id: [''],
      base: [''],
      name: [''],
      comments: [''],
      changeLevel: [''],
      prefix: [''],
      num: [''],
      comparatorNum: [''],
      designTeam: ['']
    });
    this.formReady.emit(this.partObjectForm);
  }

  _updatePartObjectForm() {

    this.partObjectForm.patchValue({
      id: this.item.id,
      base: this.partItem.part_base,
      name: this.partItem.part_name,
      comments: this.partItem.part_comments,
      changeLevel: this.partItem.part_change_level,
      prefix: this.partItem.part_prefix,
      num: this.partItem.part_num,
      comparatorNum: this.partItem.part_comparator_num,
      designTeam: this.partItem.part_design_team
    })

  }

}
