import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UrlConsumerService } from './url-consumer.service';
import { Subscription } from 'rxjs';
import { SharedService } from '../core/service/shared.service';


@Injectable()
export class AuthGuardService implements CanActivate {


	Adminaccess: Subscription;
	haveaccess: String;
	webclientaccess: String;
	webcaccess: String;
	constructor(private router: Router, private urlConsumerService: UrlConsumerService, private shared: SharedService) {
		console.log('<---AuthGuardService Constructor--->');

		// this.Adminaccess = this.urlConsumerService.getadminaccess().subscribe((data: String) => {
		// 	console.log("fired sucess", data)
		// 	this.haveaccess = data;
		// }, (error) => {
		// 	console.log("fired error", error.message)
		// 	//alert(error);
		// })

		// this.Adminaccess = this.urlConsumerService.getwebclientaccess().subscribe((data: String) => {
		// 	console.log("fired webclientaccess", data)
		// 	this.webclientaccess = data;
		// 	console.log("authweb", this.webclientaccess);
		// }, (error) => {
		// 	console.log("fired error", error.message)
		// 	//alert(error);
		// })

	}

	// ngOnInit(){
	// 	console.log('<===== Auth Guard Service OnInit =====>');
	// }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		console.log('Store state url in sessionStorage for later redirect by callback component');
		// Store state url in sessionStorage for later redirect by callback component
		sessionStorage['redirectURL'] = state.url;
		if (this.checkLogin() && (this.isAuthorized(route.data['roles'], state.url))) {
			console.log('if the user can log in & have the expectedRole or are an authorized supervisor, return true', route.data['roles']);
			//if the user can log in & have the expectedRole or are an authorized supervisor, return true
			return true;
		} else {
			return false;
		}
	}

	isTokenExpired(): boolean {
		const epoch = Math.trunc(new Date().getTime() / 1000);
		let expEpoch = null;
		console.log('epoch=================>', epoch);
		if (sessionStorage.getItem('tokenExp') && sessionStorage.getItem('tokenExp') !== 'null') {
			expEpoch = parseInt(sessionStorage.getItem('tokenExp'), 10);
			console.log('expEpoch=================>', expEpoch);
			return (epoch >= expEpoch);
		} else {
			return true;
		}
	}

	// User is logged in if current ID token is not expired
	checkLogin(): boolean {
		// Check if token is expired (or null)
		if (this.isTokenExpired()) {
			// Clear oauth session items
			sessionStorage.setItem('tokenExp', null);
			sessionStorage.setItem('tokenIssue', null);
			//sessionStorage.setItem('strAccessToken', null);
			sessionStorage.removeItem('strAccessToken');
			sessionStorage.setItem('jsonAccessToken', null);
			sessionStorage.setItem('encodedAccessToken', null);

			// Navigate to the login page with extras
			this.router.navigate(['/login']);

			return false; // guard complete and then router redirects to /login
		}
		this.urlConsumerService.toggle();
		return true;
	}

	isAuthorized(roles: string, url: string): boolean {
		if (this.urlConsumerService.userInfo.roles[this.urlConsumerService.userInfo.selectedProject] === roles) {
			//check the role of user matches the role passed from route
			console.log("*****************isAuthorized urls**********************", url);
			console.log("*****************roles**********************", roles);
			if (url.match('/admin-portal/login')) {
				console.log('url.match(/admin-portal/login)', this.Adminaccess);
				// var tmpToken =JSON.parse(sessionStorage.getItem('strAccessToken'));
				// console.log(' check-Token--->', tmpToken);
				// if (tmpToken) {
				// 	this.Adminaccess = this.urlConsumerService.getadminaccess().subscribe((data: String) => {
				// 		console.log("fired sucess", data)
				// 		this.haveaccess = data;
				// 		if (this.haveaccess == 'true') {
				// 			return true;
				// 		} else {
				// 			//alert("else condition = "+this.haveaccess);
				// 			console.log("else condition = ", this.haveaccess)
				// 			this.router.navigate(['/home/main-page']);
				// 			alert("You do not have access to this page, Please request one from http://www.itconnect.ford.com .");
				// 			return false;
				// 		}
				// 	}, (error) => {
				// 		console.log("fired error", error.message)
				// 		//alert(error);
				// 	})
				// }

				// if (this.haveaccess !== undefined) {
				// 	if (this.haveaccess == 'true') {
				// 		return true;
				// 	} else {
				// 		//alert("else condition = "+this.haveaccess);
				// 		console.log("else condition = ", this.haveaccess)
				// 		alert("You do not have access to this page, Please request one from http://www.itconnect.ford.com .");
				// 		return false;
				// 	}
				// }
			} else if (url.match('/webclient')) {
				if (this.webclientaccess !== undefined) {
					if (this.webclientaccess == 'success') {
						return true;
					} else if (this.webclientaccess == 'LoginPage') {
						console.log("cdsid Null redirect login")
						this.checkLogin();
					}
					else {
						//alert("else condition = "+this.haveaccess);
						console.log("else condition wc = ", this.webclientaccess)
						//this.shared.webclientaccessbox.next(true);
						//alert("You do not have access to this page, Please request one from http://www.itconnect.ford.com .");
						this.webcaccess = 'noaccess';
						console.log("wc =", this.webcaccess)
						return false;
					}
				}
			}
			console.log("default true  = ", this.haveaccess)
			return true;
		}else if (url.match('/upload')) {
			return true;
		} else if (url.match('/manageUserAccess')) {
			console.log('isAuthorized role manageuser --->', roles);
			return this.urlConsumerService.userInfo.isManager;
		}
		else if (url.match('/email-download')) {
			console.log('iemail-download --->', roles);
			return true;
		}
		else {
			console.log('isAuthorized role false --->', roles);
			return false;
		}
	}

	ngOnDestroy() {
		if (this.Adminaccess) {
			this.Adminaccess.unsubscribe();
		}

	}

}
