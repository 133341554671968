import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'nvhmodaltestbook-for-opt',
  templateUrl: './nvhmodaltestbook-for-opt.component.html',
  styleUrls: ['./nvhmodaltestbook-for-opt.component.css']
})
export class NvhmodaltestbookForOptComponent implements OnInit {

  nvhModelTestBookOpt:FormGroup;  
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {

    this._formClear();
  }

  _formClear() {
    this.nvhModelTestBookOpt = this.formBuilder.group({
      displayOrder: ['']

    });
    this.formReady.emit(this.nvhModelTestBookOpt);
  }

}
