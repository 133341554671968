import { Component, OnInit, Input } from '@angular/core';
import { TeamAdministrationService } from 'app/core/service/team-administration.service';

@Component({
  selector: 'error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.css']
})
export class ErrorMessageComponent implements OnInit {
  errorMsg:string;
  constructor(private teamAdmin: TeamAdministrationService) { }

  ngOnInit() {
    if (this.teamAdmin.personErrorMsg) {
      this.errorMsg = this.teamAdmin.personErrorMsg;
    }

  }

}
