import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TeamCreate } from 'app/core/model/teamAdministrationModel';
import { TeamAdministrationService } from 'app/core/service/team-administration.service';
import { Router } from '@angular/router';

@Component({
  selector: 'create-team',
  templateUrl: './create-team.component.html',
  styleUrls: ['./create-team.component.css']
})
export class CreateTeamComponent implements OnInit {
  teamCreateForm: FormGroup;
  teamCreateModel: TeamCreate;
  submitted: boolean = false;
  teamCreateDisplay: boolean = false;
  teamCreationStatus: string;
  successMsg: boolean = false;
  errorMsg: boolean = false;
  teamNameValid: boolean = false;
  stewardValid: boolean = false;
  commentsValid: boolean = false;
  redirectMsg: boolean = false;
  getSelected: string[];
  constructor(private formBuilder: FormBuilder, private teamAdmin: TeamAdministrationService, private router: Router) { }

  ngOnInit() {
    this.teamCreateForm = this.formBuilder.group({
      teamName: ['', Validators.required],
      steward: ['', Validators.required],
      comments: ['', Validators.required],
      type: [''],
      memberList: [''],
    });
    this.teamCreateForm.get('type').setValue('t');
    this.getSelected = ['members'];
  }
  teamCreate() {
    this.submitted = true;
    console.log('team creation');
    if (this.teamCreateForm.valid) {
      console.log(this.teamCreateForm.value);
      if (this.teamCreateForm.value) {
        this.teamCreateModel = this.teamCreateForm.value;
        this.teamCreateModel.teamId = '';
        this.teamCreateModel.type = '';
        this.teamCreateModel.modifiedOn = '';
        this.teamCreateModel.createdOn = '';
        // this.teamCreateModel.teamType=[];
        //this.teamCreateModel.memberList = [];
        this.teamCreateModel.type = this.teamCreateForm.get('type').value;
        console.log('this.teamCreateModel.type:', this.teamCreateModel.type);
        //this.teamCreateModel.memberList.push(this.teamCreateForm.get('memberList').value);
        if (this.teamCreateModel) {
          this.teamAdmin.teamAdminCreate(this.teamCreateModel).subscribe(data => {
            console.log(data);
            if (data) {
              let message = data.split('+');
              console.log('message:', message)
              this.teamCreateDisplay = true;
              this.teamCreationStatus = message[1];
              this.successMsg = true;
              if (message[0] != 'noRedirect' && message[1]) {
                console.log('if loop  create');
                this.teamAdmin.teamNameView = message[0];
                this.teamAdmin.teamType = this.getSelected;
               
                this.redirectMsg = true;
                this.errorMsg = false;
              } else {
                console.log(' else  create');
                this.redirectMsg = false;
                this.errorMsg = true;
              }

              // if (message[1]) {
              //   console.log('if');
              //   this.successMsg = true;
              //   this.errorMsg = true;
              //   this.redirectMsg = false;

              // }
              // if (message[0]) {
              //   console.log('redirect if');
              //   console.log('message[0]', message[0]);
              //   if (message[0] === 'noRedirect') {
              //     this.redirectMsg = false;
              //     this.errorMsg = true;
              //   } else {
              //     this.redirectMsg = true;
              //     this.errorMsg = false;
              //   }

              // }
            }
          });
        }
      }
    } else {
      this.teamCreateDisplay = true;
      console.log(this.teamCreateForm.get('teamName').value);
      if (!this.teamCreateForm.get('teamName').value) {
        console.log('inside the teamName if');
        this.teamNameValid = true;
        this.stewardValid = false;
        this.commentsValid = false;
        this.successMsg = false;
      } else if (!this.teamCreateForm.get('steward').value) {
        console.log('inside the steward if');
        this.teamNameValid = false;
        this.stewardValid = true;
        this.commentsValid = false;
        this.successMsg = false;
      } else if (!this.teamCreateForm.get('comments').value) {
        console.log('inside the comments if');
        this.teamNameValid = false;
        this.stewardValid = false;
        this.commentsValid = true;
        this.successMsg = false;
      }

    }
  }
  get f() {
    return this.teamCreateForm.controls;
  }
  close() {
    this.teamCreateDisplay = false;
    this.successMsg = false;
    this.teamNameValid = false;
    this.stewardValid = false;
    this.commentsValid = false;
  }
  closeSuccess() {
    this.teamCreateDisplay = false;
    this.successMsg = false;
    this.teamNameValid = false;
    this.stewardValid = false;
    this.commentsValid = false;
    this.router.navigateByUrl('admin-portal/list-team');

  }

}
