export class PersonData {
    id: number;

    name: string;

    has_access: string;

    steward: number;

    created_on: Date;

    modified_on: Date;

    reports_to: number;

    company: number;

    building: number;

    stewardName: string;

    buildingName: string;

    authName: string;

    reportsTOName: string;

    companyName: string;

    storage_className: string;

    has_cds_record: string;

    password: string;

    first_name: string;

    last_name: string;

    email: string;

    phone: string;

    office: string;

    mail_drop: string;

    department: string;

    country: string;

    unit: string;

    strReauthorized_On: string;

    division: string;

    organization: string;

    location: string;

    application: string;

    role: string;

    business_case: string;

    authorizer: number;

    reauthorized_on: Date;

    reason: string;

    storage_class: number;

    totalStorageUsed: number;

    emptype: string;
    strCreated_on: string;
    strModified_on: string;
    strReauthorizedTime: string;

}
export class Person {
    id: number;
    name: string;
    has_access: string;
    steward: number;
    created_on: Date;
    modified_on: Date;
    reports_to: number;
    company: number;
    building: number;
    has_cds_record: string;
    password: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    office: string;
    mail_drop: string;
    department: string;
    country: string;
    unit: string;
    division: string;
    organization: string;
    location: string;
    application: string;
    role: string;
    business_case: string;
    authorizer: number;
    reauthorized_on: Date;
    reason: string;
    storage_class: number;
    emptype: string;
    stewardName: string;
    buildingName: string;
    authName: string;
    reportsTOName: string;
    storage_className: string;
    companyName: string;
    totalStorageUsed: number;
    strReauthorized_On: string;
    strCreated_on: string;
    strModified_on: string;
    strReauthorizedTime: string;


}
export class Team {
    id: number;
    name: string;
    steward: number;
    created_on: Date;
    modified_on: Date;
    type: string;
    comments: string;
    stewardId: number;
    members: string[];
    joined_on: Date;
    typeDesc: string;
    stewardName: string;
    strId: string;
    strCreated_on: string;
    strModified_on: string;
    totalStorage:string


}
export class MemberOnTeams {
    id: number;
    name: string;
    type: string;
    date: string;

}
export class AccessShare {
    ids: number;
    name: string;
    firstName: string;
    lastName: string;
    has_access: string;
    date: string;

}
export class ViewPersonResponse {
    personData: PersonData;
    person: Person;
    team: Team;
    listOfMemberTeam: MemberOnTeams[];
    listOfAccessShare: AccessShare[];
    viewType:string;
}