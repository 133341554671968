import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { ModifyProgram, ItemModifyForm, CreateItemPropertiesApi, ACL } from '../model/itemAdminModels';

@Injectable({
  providedIn: 'root'
})
export class ItemAdministrationService {

  private apdmServiceBackEndUrl = environment.apdmServiceUrl;
  private headers: HttpHeaders;
  cdsId: any;
  token: any;

  constructor(private http: HttpClient) {
    this.token = JSON.parse(sessionStorage.getItem('strAccessToken'));
    if (this.token) {
      this.cdsId = this.token.CommonName;
    }
    //console.log(' cdsid--->', this.cdsId);
    if (this.cdsId !== undefined) {
      this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
    } else {
      this.cdsId = 'Invalid';
      this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
    }
  }

  getItemType(): Observable<any> {
    return this.http.get(this.apdmServiceBackEndUrl + 'searchItem', {
      headers: this.headers
    });
  }

  getItemLabel(modifyProgram: ModifyProgram) {
    // console.log('<===================getItemLabel======================>', modifyProgram);
    return this.http.post(this.apdmServiceBackEndUrl + 'fetchItems', modifyProgram);
  }

  getItemData(modifyProgram: ModifyProgram): Observable<any> {
    // console.log('<===================getItemData======================>', modifyProgram);
    return this.http.post(this.apdmServiceBackEndUrl + 'listadminprogram', modifyProgram);
  }

  postItemModify(itemModifyForm: ItemModifyForm) {
    // console.log('<===================postItemModify======================>', itemModifyForm);
    return this.http.post(this.apdmServiceBackEndUrl + 'modifyItem', itemModifyForm, {
      responseType: 'text',
    });
  }

  postSubItemModify(createItemPropertiesApi: CreateItemPropertiesApi) {
    // console.log('<===================postSubItemModify======================>', createItemPropertiesApi);
    return this.http.post(this.apdmServiceBackEndUrl + 'modifySubItem', createItemPropertiesApi,{
      responseType: 'text',
    });

  }

  postModifyACL(selectedDirectAclsList: ACL[], itemModifyForm: ItemModifyForm) {
    // console.log('<===================postModifyACL======================>', itemModifyForm);
    return this.http.post(this.apdmServiceBackEndUrl + 'modifyACL/' + itemModifyForm.item.id + '/' + itemModifyForm.item.itemType, selectedDirectAclsList, {
      responseType: 'text',
    });
  }

  postDeleteACL(selectedDirectAclsList: ACL[], itemModifyForm: ItemModifyForm) {
    // console.log('<===================postModifyACL======================>', itemModifyForm);
    return this.http.post(this.apdmServiceBackEndUrl + 'deletemarkedacl/' + itemModifyForm.item.id, selectedDirectAclsList, {
      responseType: 'text',
    });
  }

  postCreateAdItem(createItemPropertiesApi: CreateItemPropertiesApi) {
    // console.log('<===================postCreateAdItem======================>', createItemPropertiesApi);
    return this.http.post(this.apdmServiceBackEndUrl + 'createAdmItem', createItemPropertiesApi, {
      responseType: 'text',
    });
  }

  checkOwner(ownerCdsid: string): Observable<any> {
	  this.headers = new HttpHeaders().append('Content-Type', 'text/plain');
	  return this.http.post(this.apdmServiceBackEndUrl + 'checkOwner', ownerCdsid, {
		  responseType: 'text',
		  headers: this.headers,
    });
  }

  getPersonInfo():Observable<any>{
    return this.http.get(this.apdmServiceBackEndUrl + 'applicationPersonInfo' , {
     // responseType: 'text',
     headers: this.headers

    });

  }

}
