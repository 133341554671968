import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from 'rxjs/internal/Observable';
// import * as AWS from 'aws-sdk/global';
// import * as S3 from 'aws-sdk/clients/s3';

@Injectable({
  providedIn: 'root'
})
export class CheckInService {

  selectedNodeItemId: any;
  private apdmServiceBackEndUrl = environment.apdmServiceUrl
  private headers: HttpHeaders;
  cdsId: any;
  token: any;


  constructor(private http: HttpClient) {
    this.token = JSON.parse(sessionStorage.getItem('strAccessToken'));
    if (this.token) {
      this.cdsId = this.token.CommonName;
    }
    //console.log(' cdsid--->', this.cdsId);
    if (this.cdsId !== undefined) {
      this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
    } else {
      this.cdsId = 'Invalid';
      this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
    }
  }

  pushFileToStorage(formdata: any): Observable<HttpEvent<{}>> {
    const req = new HttpRequest('POST', this.apdmServiceBackEndUrl + 'checkIn/by-id/item', formdata, {
      reportProgress: true,
      responseType: 'text'
    });
    return this.http.request(req);
  }

  // pushFileToStorage(fileBlob: any, selectedNode: any, fileName: any, fileLength: any): Observable<HttpEvent<{}>> {
  //   // const formdata: FormData = new FormData();
  //   // formdata.append('cdsId', this.cdsId);
  //   const req = new HttpRequest('POST', this.apdmServiceBackEndUrl + 'checkIn/by-id/item/' + selectedNode + '/' + fileName + '/' + fileLength,
  //     fileBlob, {
  //     reportProgress: true,
  //     responseType: 'text',
  //     // headers: this.headers.set('Content-Type', 'application/octet-stream')
  //   });
  //   return this.http.request(req);
  // }

  // pushFileToStorage(file: any, selectedNode: any, fileName: any, fileLength: any): Observable<HttpEvent<{}>> {
  //   let data = new FormData();
  //   data.append('file', file);
  //   const req = new HttpRequest('POST', this.apdmServiceBackEndUrl + 'checkIn/by-id/item/' + selectedNode + '/' + fileName + '/' + fileLength,
  //     data, {
  //     reportProgress: true,
  //     responseType: 'text',
  //     // headers: this.headers.set('Content-Type', 'application/octet-stream')
  //   });
  //   return this.http.request(req);
  // }

  // pushFileToStorage(file: any, selectedNode: any, fileName: any, fileLength: any) {
  //   let data = new FormData();
  //   data.append('file', file);
  //   const req = new HttpRequest('POST', this.apdmServiceBackEndUrl + 'checkIn/by-id/item/' + selectedNode + '/' + fileName + '/' + fileLength,
  //     data, {
  //     reportProgress: true,
  //     responseType: 'text',
  //     // headers: this.headers.set('Content-Type', 'application/octet-stream')
  //   });
  //   return this.http.request(req);
  //   // this.uploadFile(file);
  // }

  doValidateList(uploadedFiles: any[], itemId: String, type: String): Observable<HttpEvent<{}>> {
    const req = new HttpRequest('POST', this.apdmServiceBackEndUrl + 'checkIn/doValidate/' + itemId + '/' + type, uploadedFiles, {
      reportProgress: true,
      responseType: 'text'
    });
    return this.http.request(req);
  }


  public getParentFolderForFiles(selectedItemId: String): Observable<any> {
    return this.http.get(this.apdmServiceBackEndUrl + 'checkIn/getParentFolderForFiles/' + selectedItemId, {
      headers: this.headers
    });
  }

  public getParentPathForItemId(selectedItemId: String): Observable<string> {
    return this.http.get(this.apdmServiceBackEndUrl + 'getParentPath/' + selectedItemId, {
      headers: this.headers,
      responseType: 'text'
    });
  }
  doInitialUpload(selectedItemId: String): Observable<HttpEvent<{}>> {
    //console.log('cdsId   ====>', this.cdsId);
    const req = new HttpRequest('POST', this.apdmServiceBackEndUrl + 'checkIn/doInitialUploadSecurityCheck/' + selectedItemId , this.cdsId, {
      reportProgress: true,
      responseType: 'text'
    });
    return this.http.request(req);
  }

  public hasApplicationAccess(): Observable<HttpEvent<{}>> {
    // console.log(this.apdmServiceBackEndUrl + 'baseAction/process/' + cdsId);
    const req = new HttpRequest('GET', this.apdmServiceBackEndUrl + 'baseAction/process', {
      reportProgress: true,
      responseType: 'text'
    });
    return this.http.request(req);
  }

  public fileUploadActionDoUpload(itemId: String, fileName: String): Observable<HttpEvent<{}>> {
    // console.log(this.apdmServiceBackEndUrl + 'baseAction/process/' + cdsId);
    const req = new HttpRequest('POST', this.apdmServiceBackEndUrl + 'checkIn/doUploadSecurityCheck/' + itemId + '/' + fileName, this.cdsId,{
      reportProgress: true,
      responseType: 'text'
    });
    return this.http.request(req);
  }

  generatePreSignedURLForUpload(selectedNode: any, fileName: string): Observable<any> {
    return this.http.get(this.apdmServiceBackEndUrl + 'checkIn/generatePreSignedURLForUpload/' + selectedNode + '/' + fileName, {
      headers: this.headers
    });
  }

  getRetStatusList(): Observable<any> {
    return this.http.get(this.apdmServiceBackEndUrl + 'getRetStatusList', {
      headers: this.headers
    });
  }

  uploadfileAWSS3(fileuploadurl: any, file: any): Observable<any> {
    //this will be used to upload all csv files to AWS S3
    const headers = new HttpHeaders({
      'Content-Type': 'binary/octet-stream'
    });
    const req = new HttpRequest('PUT', fileuploadurl, file,
      {
        headers: headers,
        reportProgress: true, //This is required for track upload process
        withCredentials: false
      });
    return this.http.request(req);
  }
}
