import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonDescription, cpscCpmt } from 'app/core/model/editPropertiesModels';
import { Item, ItemInitiationForm2 } from 'app/core/model/itemAdminModels';
import { EditPropertiesService } from 'app/core/service/edit-properties.service';
import { Result } from 'app/core/model/searchModel';
import { SearchService } from 'app/core/service/search.service';
import { DateFormatPipe } from 'app/shared/date-format.pipe';
import { AdminDateFormatPipe } from 'app/shared/admin-date-format.pipe';

@Component({
  selector: 'item-object',
  templateUrl: './item-object.component.html',
  styleUrls: ['./item-object.component.css']
})
export class ItemObjectComponent implements OnInit {

  itemObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()
  @Input('screenType') screenType: string;
  @Input('item') item: Item;
  @Input('securityLevelList') securityLevelOptions: ItemInitiationForm2[];
  @Input('retentionStatusList') retentionStatusOptions: ItemInitiationForm2[];
  @Input('approvalList') approvalOptions: ItemInitiationForm2[];
  @Input('subjectList') subjectOptions: ItemInitiationForm2[];
  @Input('domainOptions') domainOptions: ItemInitiationForm2[];

  notifyOnReadDeniedOptions: ItemInitiationForm2[];
  lockOnCheckoutOptions: ItemInitiationForm2[];

  @Input('showfield') showfield: boolean;
  cpscCPmtSuggestions: cpscCpmt[];
  ownerSuggestions: Result;
  stewrdSuggestions: Result;


  constructor(private formBuilder: FormBuilder, private _EditPropertiesService: EditPropertiesService,
    private search: SearchService, public _DateFormatPipe: DateFormatPipe, public _AdminDateFormatPipe: AdminDateFormatPipe) { }

  ngOnInit() {
    this._formClear();
    this._updateItemObjectForm();
    this.notifyOnReadDeniedOptions = [
      { label: 'No', value: 'n' },
      { label: 'Yes', value: 'y' }
    ];
    this.lockOnCheckoutOptions = [
      { label: 'No', value: 'n' },
      { label: 'Yes', value: 'y' }
    ];

  }

  _formClear() {
    this.itemObjectForm = this.formBuilder.group({
      id: [''],
      createdOn: [''],
      subject: [''],
      owner: [''],
      steward: [''],
      label: [''],
      defaultServer: [''],
      deletedOn: [''],
      expirationDate: null,
      retSchedule: [''],
      parent: [''],
      milestone: [''],
      notifyOnReadDenied: [''],
      program: [''],
      approvalRequired: [''],
      holdCount: [''],
      lockOnCheckOut: [''],
      retStatus: [''],
      type: [''],
      frozenOn: [''],
      securityLevel: [''],
      keppAllVersion: [''],
      leader: [''],
      displayOrder: [''],
      supersededOn: [''],
      modifiedOn: [''],
      publishedOn: [''],
      domain: [''],
    });
    this.formReady.emit(this.itemObjectForm);
  }

  _updateItemObjectForm() {    
    this.itemObjectForm.patchValue({
      id: this.item.id,
      createdOn: this._AdminDateFormatPipe.transform(this.item.created_on),
      subject: this.setSubject(this.item.subjectList),
      owner: this.item.ownerSearch,
      steward: this.item.stewardSearch,
      label: this.item.label,
      defaultServer: this.item.default_server,
      deletedOn: this._AdminDateFormatPipe.transform(this.item.deleted_on),
      expirationDate: this.item.expiration_date ? new Date(this._AdminDateFormatPipe.transform(this.item.expiration_date)) : null,
      retSchedule: this.item.ret_schedule,
      parent: this.item.parent,
      milestone: this.item.milestone,
      notifyOnReadDenied: this.item.notifyOnReadDenied,
      program: this.item.program,
      approvalRequired: this.item.stdApprovalProcess,
      holdCount: this.item.hold_count,
      lockOnCheckOut: this.item.lockOnCheckout,
      retStatus: this.item.retStatus,
      type: this.item.itemType,
      frozenOn: this.item.frozen_on,
      securityLevel: this.item.securityLevel,
      keppAllVersion: this.item.keep_all_versions,
      leader: this.item.leader,
      displayOrder: this.item.display_order,
      supersededOn: this.item.superseded_on,
      modifiedOn: this._AdminDateFormatPipe.transform(this.item.modified_on),
      publishedOn: this._AdminDateFormatPipe.transform(this.item.published_on),
      domain: this.item.cpscCPmtList,
    })
  }

  setSubject(subject: any): any {    
    for (var val of this.subjectOptions) {
      if (val.value == subject.value) {        
        return val;
      }
    }
  }

  cpscCPmtSearch(event) {
    this._EditPropertiesService.getCpscCpmt('LOVDOMAIN', event.query).subscribe(data => {
      this.cpscCPmtSuggestions = data;
    });
  }

  Ownersearch(event) {
    this.search.getOwnerResult(event.query).subscribe((data: Result) => {
      this.ownerSuggestions = data;
    });
  }

  StewrdOwnersearch(event) {
    this.search.getOwnerResult(event.query).subscribe((data: Result) => {
      this.stewrdSuggestions = data;
    });
  }

}
