import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'account-request',
  templateUrl: './account-request.component.html',
  styleUrls: ['./account-request.component.css']
})
export class AccountRequestComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  
  }

}
