import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient , HttpHeaders} from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { Result} from '../../core/model/treeViewModel'


@Injectable({
  providedIn: 'root'
})
export class DataTableServiceService {

  private teamURI = environment.apdmServiceUrl;

  private headers: HttpHeaders;

  cdsId : any;

  token: any;

  constructor(private http: HttpClient) {

    this.token = JSON.parse(sessionStorage.getItem('strAccessToken'));


    if(this.token)
    {
      this.cdsId = this.token.CommonName;
    }



    //console.log(' cdsid--->', this.cdsId);

    if (this.cdsId !== undefined ) {
      this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
    } else {
      this.cdsId = 'Invalid';
      this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');

    }


   }

  public getDataTable(result : Result){
    console.log(this.teamURI+'dataview',result );


    return this.http.post(this.teamURI+'dataview',result,{
      headers: this.headers,
      });
  }

  // public getEngineData() {
  //   return this.http.get(this.baseUrl + 'enginedata');
  // }

}
