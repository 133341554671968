import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'std-body-style-opt',
  templateUrl: './std-body-style-opt.component.html',
  styleUrls: ['./std-body-style-opt.component.css']
})
export class StdBodyStyleOptComponent implements OnInit {

  stdBodyStyleOptObjectForm:FormGroup;  
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this. _formClear() 
  }

  _formClear() {
    this.stdBodyStyleOptObjectForm = this.formBuilder.group({
      guiOrder: ['']
    });
    this.formReady.emit(this.stdBodyStyleOptObjectForm);
  }

}
