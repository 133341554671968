import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'davlocktoken-for-req',
  templateUrl: './davlocktoken-for-req.component.html',
  styleUrls: ['./davlocktoken-for-req.component.css']
})
export class DavlocktokenForReqComponent implements OnInit {

 
  davLockTokenReq:FormGroup;  
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {

    this._formClear();
  }

  _formClear() {
    this.davLockTokenReq = this.formBuilder.group({
      expiresOn: ''
    });
    this.formReady.emit(this.davLockTokenReq);
  }

}
