import {NgModule} from '@angular/core';
import {CommonModule, Location, HashLocationStrategy, LocationStrategy} from '@angular/common';
import {OAuthCallbackComponent} from './oauth-callback.component';
import {AuthGuardService} from './auth-guard.service';
import {UrlConsumerService} from './url-consumer.service';
import {LoginComponent} from './login.component';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		OAuthCallbackComponent,
		LoginComponent
	],
	providers: [
		AuthGuardService,
		UrlConsumerService,
		Location,
		{provide: LocationStrategy, useClass: HashLocationStrategy}
	]
})
export class OauthModule {
}
