export class PersonList {
    id: number;
    personName: string;
}
export class TeamList {
    id: number;
    teamName: string;
}
export class FormData {
    id: number;
    createdOn: Date;
    name: string;
    steward: string;
    comments: string;
    teamType: string;
    modifiedDate: Date;

    teamAddMembers: string;
}

export class OteamGetApi {
    formData: FormData;
    personList: PersonList[];
    teamList: TeamList[];
}

export class Result extends OteamGetApi {
    oteamGetApi: OteamGetApi;
}

export class TeamModifyResponse {
    result: Result;
    error: string;
}