import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Item,Url } from 'app/core/model/itemAdminModels';

@Component({
  selector: 'url-object',
  templateUrl: './url-object.component.html',
  styleUrls: ['./url-object.component.css']
})
export class UrlObjectComponent implements OnInit {

  urlObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()
  @Input('screenType') screenType: string;
  @Input('item') item: Item;
  @Input('url') url: Url;
  @Input('showfield') showfield: boolean;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
    this._updateUrlObjectForm();
  }

  _formClear() {
    this.urlObjectForm = this.formBuilder.group({
      id: [''],
      toUrl: ['']
    });
    this.formReady.emit(this.urlObjectForm);
  }

  _updateUrlObjectForm() {
    this.urlObjectForm.patchValue({
      id: this.item.id,
      toUrl: this.url.url
    })
  }

}
