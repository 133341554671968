import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'part-for-opt',
  templateUrl: './part-for-opt.component.html',
  styleUrls: ['./part-for-opt.component.css']
})
export class PartForOptComponent implements OnInit {

  partOptForm:FormGroup;  
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {

    this._formClear();
  }

  _formClear() {
    this.partOptForm = this.formBuilder.group({
      base: [''],
      name: [''],
      prefix: [''],
      designTeam: [''],
      changeLevel: [''],
      comments: [''],
      compNum: ['']

    });
    this.formReady.emit(this.partOptForm);
  }

}
