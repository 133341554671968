import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ModifyUserService } from '../../core/service/modify-user.service';
import { Subject } from 'app/core/model/teamAdministrationModel';
import { ModifyInput, ApplicationMembership, TeamData, TeamAcl, ModifyListResponse, TeamAdminAcl, PersonList, DeleteUserTeamResponse, DeletePersonAclResponse, UpdateTeamMembershipResponse } from 'app/core/model/user-admin';
import { SelectItem } from 'primeng/api';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'modify-user',
  templateUrl: './modify-user.component.html',
  styleUrls: ['./modify-user.component.css']
})
export class ModifyUserComponent implements OnInit {

  userSearchForm: FormGroup;
  getUserForm: FormGroup;
  userModify: FormGroup;
  userlist: Subject[];
  modifyInput: ModifyInput;
  applicationMember: ApplicationMembership[];
  teamAffiliation: TeamData[]
  teamAcl: TeamAcl[]
  personAcl: TeamAdminAcl[]
  rowSelectedData: ApplicationMembership[];
  hasAccess: SelectItem[];
  hasCdsid: SelectItem[];
  userPerson: PersonList;
  personAcls: TeamAdminAcl;
  cdsId: string;
  userError: string;
  selectedList: string;
  getTeamName: string;
  titleTeamName: string;
  userDisplay: boolean = false;
  showPerson: boolean = false;
  showAppMember: boolean = false;
  showTeam: boolean = false;
  showTeamAcls: boolean = false;
  showPersonAcls: boolean = false;
  aclDialog: boolean = false;
  val: string;
  inputData: string;
  aclSelectedData: TeamAdminAcl[];
  successMessage: string;
  modifyItem: string;
  modifyQuery: string;
  modifyWrite: string;
  modifyRead: string;
  modifyCreate: string;
  modifyDelete: string;
  displaySuccessMsg: boolean = false;
  confirmSuccessMsg: boolean = false;
  stewardTemp: string;
  teamSelected: string[];
  displayDeleteMsgTeamAff: boolean = false;
  deleteMessage: string = '';
  teamData = new TeamData();
  personalacldata = new TeamAdminAcl();
  displayDeleteMsgPersonalAcl: boolean = false;
  aclAppMsg: boolean = false;
  //aclMessage:string = '';
  teamAclData: TeamAdminAcl;
  aclMessage: string = '';
  checkUpdateApp: boolean = false;
  membershipName: string;
  personAclMsg: boolean = false;
  teamAclMsg: boolean = false;
  teamDataMsg: boolean = false;
  personDataMsg: boolean = false;
  authorize: string;
  personMsg: boolean = false;
  teamMsg: boolean = false;
  personAclMsgdata: boolean = false;
  teamAclMsgdata: boolean = false;
  teamAclMessage: string;
  personAclMessage: string;
  teamMessage: string;
  personMessage: string;
  personId: number;
  saveAclArr = [];
  isSaveAcl: boolean = false;
  constructor(private formBuilder: FormBuilder,private datePipe: DatePipe, private modify: ModifyUserService) { }

  ngOnInit() {
    // this.teamDataMsg = true;
    // this.personDataMsg = true;
    this.hasAccess = [
      { label: 'y', value: 'y' },
      { label: 'n', value: 'n' },
      { label: 'w', value: 'w' },
    ]

    this.hasCdsid = [
      { label: '', value: 'u' },
      { label: 'y', value: 'y' },
      { label: 'n', value: 'n' }
    ];

    this.userSearchForm = this.formBuilder.group({
      username: ['', Validators.required]
    });
    this.getUserForm = this.formBuilder.group({
      cdsIdPattern: [''],
      personId: [''],
      selectedData: ['']
    });
    this.userModify = this.formBuilder.group({
      id: [''],
      application: [''],
      authorizer: [''],
      building: [''],
      businessCase: [''],
      company: [''],
      country: [''],
      createdOn: [''],
      department: [''],
      division: [''],
      email: [''],
      empType: [''],
      firstName: [''],
      lastName: [''],
      hasAccess: [''],
      hasCdsRecord: [''],
      location: [''],
      mailDrop: [''],
      modifiedOn: [''],
      office: [''],
      organization: [''],
      password: [''],
      phone: [''],
      reauthorizedOn: [''],
      reason: [''],
      reportsTo: [''],
      role: [''],
      steward: [''],
      storageClass: [''],
      unit: [''],
      cdsId: [''],
      lastAuthorizedOn: [''],
      buildingName: [''],
      departmentCode: [''],
      fordMailDrop: [''],
      fordBusinessUnitCode: [''],
      managerRole: [''],
      errorMessage: [''],
      stewardId: [''],
      reportsToId: [''],
      authorizerId: [''],
      companyId: [''],
      buildingId: ['']

    });
    //this.getUserForm.get('selectedData').setValue(['P', 'T']);
    this.teamSelected = ['P', 'T'];

  }

  //Search user
  searchUser() {
    this.showPerson = false;
    this.showAppMember = false;
    this.showTeam = false;
    this.showTeamAcls = false;
    this.showPersonAcls = false;
   // console.log("username", this.userSearchForm.get('username').value);
    if (this.userSearchForm.valid) {
      this.cdsId = this.userSearchForm.get('username').value;
      this.modify.getUser(this.cdsId).subscribe((response: Subject[]) => {
        console.log('list user', response);
        if (response.length > 0) {
          this.userlist = response;
          console.log(this.userlist);
        } else {
          this.displaySuccessMsg = true;
          this.successMessage = '';
          this.successMessage = 'This search returns no data (or) Entered CDSID is invalid!';
          this.userlist = [];
        }
      });
    } else {
      // console.log("double----->");
      this.displaySuccessMsg = true;
      this.successMessage = '';
      this.successMessage = 'Please input part of CDSID to start the search!';
    }
  }
  //Get User Data
  getUser() {
    console.log('team get data');
    console.log(this.getUserForm.value);
    console.log('get team', this.selectedList);
    if (this.selectedList) {
      console.log('this.selectedList == IF')
      this.getTeamName = this.selectedList;
      this.modify.fdsId = this.selectedList;
    } else {
      console.log('this.selectedList == ELSE');
      if (this.userlist) {
        for (var i = 0; i < this.userlist.length; i++) {
          //console.log(this.userlist[i]);
          //console.log(this.userlist[0].label);
          this.getTeamName = this.userlist[0].label;
          this.modify.fdsId = this.userlist[0].label;
          break;
        }
      }
    }
    if (this.teamSelected) {
      this.personMsg = false;
      this.teamMsg = false;
      this.personAclMsgdata = false;
      this.teamAclMsgdata = false;
      this.teamSelected.forEach(data => {
        console.log('for data:', data);
        if (data) {
          if (data === 'P') {
            console.log('pppp');
            this.personMsg = true;
          } else if (data === 'T') {
            console.log('ttt');
            this.teamMsg = true;
          } else if (data === 'PA') {
            console.log('papapap');
            this.personAclMsgdata = true;
          } else if (data === 'TA') {
            console.log('tatata');
            this.teamAclMsgdata = true;
          }
        }

      });
    }

    //console.log(this.getUserForm.value);

    if (this.getUserForm.valid) {
      this.modifyInput = this.getUserForm.value;
      this.modifyInput.cdsIdPattern = this.getTeamName;
      this.modifyInput.selectedData = [];
      this.modifyInput.selectedData = this.teamSelected;
      console.log(this.modifyInput);
      this.showPerson = false;
      this.showAppMember = false;
      this.showTeam = false;
      this.showTeamAcls = false;
      this.showPersonAcls = false;
      if (this.modifyInput.cdsIdPattern || this.modifyInput.personId) {
        console.log('check--check------->:', this.modifyInput);
        this.modify.getUserData(this.modifyInput).subscribe((response: ModifyListResponse) => {
          console.log('response:', response);
          console.log('teamSelected:', this.teamSelected);

          if (response) {
            if (response.personData !== null && response.personData.personData !== null) {
              this.titleTeamName = response.personData.personData.cdsId;
              this.authorize = response.personData.personData.authorizer;
              this.userModify.setValue(response.personData.personData);

              this.userModify.patchValue({
                application: response.personData.personData.application ? response.personData.personData.application : 'null',
                reauthorizedOn: response.personData.personData.reauthorizedOn ? response.personData.personData.reauthorizedOn : 'null',
                password: response.personData.personData.password ? response.personData.personData.password : 'null',
                role: response.personData.personData.role ? response.personData.personData.role : 'null',
                businessCase: response.personData.personData.businessCase ? response.personData.personData.businessCase : 'null',
                country: response.personData.personData.country ? response.personData.personData.country : 'null',
                unit: response.personData.personData.unit ? response.personData.personData.unit : 'null',
                building: response.personData.personData.building ? response.personData.personData.building : 'null',
                steward: response.personData.personData.steward ? response.personData.personData.steward : 'null',
                lastName: response.personData.personData.lastName ? response.personData.personData.lastName : 'null',
                company: response.personData.personData.company ? response.personData.personData.company : 'null',
                division: response.personData.personData.division ? response.personData.personData.division : 'null',
                reportsTo: response.personData.personData.reportsTo ? response.personData.personData.reportsTo : 'null',
                reason: response.personData.personData.reason ? response.personData.personData.reason : 'null',
                location: response.personData.personData.location ? response.personData.personData.location : 'null',
                authorizer: response.personData.personData.authorizer ? response.personData.personData.authorizer : 'null',
                organization: response.personData.personData.organization ? response.personData.personData.organization : 'null',
                storageClass: response.personData.personData.storageClass ? response.personData.personData.storageClass : 'null',
                phone: response.personData.personData.phone ? response.personData.personData.phone : 'null',
                mailDrop: response.personData.personData.mailDrop ? response.personData.personData.mailDrop : 'null',
                email: response.personData.personData.email ? response.personData.personData.email : 'null',
                firstName: response.personData.personData.firstName ? response.personData.personData.firstName : 'null',
                department: response.personData.personData.department ? response.personData.personData.department : 'null',
                office: response.personData.personData.office ? response.personData.personData.office : 'null',
                id: response.personData.personData.id ? response.personData.personData.id : 'null',
                createdOn: response.personData.personData.createdOn ? response.personData.personData.createdOn : 'null',
                empType: response.personData.personData.empType ? response.personData.personData.empType : 'null',
                buildingName: response.personData.personData.buildingName ? response.personData.personData.buildingName : 'null',
                modifiedOn: response.personData.personData.modifiedOn ? response.personData.personData.modifiedOn : 'null',
              });
              this.showPerson = true;
            }
            if (response.personData !== null && response.personData.applicationMembership !== null) {
              this.membershipName = 'Update Team Memberships';
              this.applicationMember = response.personData.applicationMembership;
              console.log('applicaiton membership:::',this.applicationMember[0].businessCase);
              if(this.applicationMember[0].businessCase != null){
                 // response.personData.personData.hasAccess === 'y'){
                this.rowSelectedData = response.personData.applicationMembership;
              }


              this.personId = response.personData.personData.id;
              //console.log('persondata::id::',this.personId)
              this.showAppMember = true;
            }
            if (response.teamData) {
              this.teamAffiliation = response.teamData;
              this.showTeam = true;
            }
            if (response.teamAclData) {
              this.teamAcl = response.teamAclData;
              this.showTeamAcls = true;
            }
            if (response.personAclData) {
              this.personAcl = response.personAclData;
              this.showPersonAcls = true;
            }
            //message alert

            if ( response.personData === null && this.personMsg) {
              console.log('else personData');
              // this.displaySuccessMsg = false;
              // this.personAclMsg = false;
              // this.teamAclMsg = false;
              // this.teamDataMsg = false;
              this.personDataMsg = true;
              this.personMessage = '';
              this.personMessage = 'This search by PERSON data returns no data!';
            }


            //console.log('response.teamData.length:',response.teamData.length);
            console.log('response.teamData:',response.teamData);
            console.log('this.teamMsg:',this.teamMsg);
             if (response.teamData === null && this.teamMsg) {
              console.log('else teamData');
              // this.displaySuccessMsg = false;
              // this.personAclMsg = false;
              // this.teamAclMsg = false;
              this.teamDataMsg = true;
              // this.personDataMsg = false;
              this.teamMessage = '';
              this.teamMessage = 'This search by TEAM data returns no data!';
            }
             if (response.teamAclData === null && this.teamAclMsgdata) {
              console.log('else teamAclData');
              // this.displaySuccessMsg = false;
              // this.personAclMsg = false;
              this.teamAclMsg = true;
              // this.teamDataMsg = false;
              // this.personDataMsg = false;
              this.teamAclMessage = '';
              this.teamAclMessage = 'This search by TEAM ACL data returns no data!';
            }

             if (response.personAclData === null && this.personAclMsgdata) {
              console.log('else personAclData');
              // this.displaySuccessMsg = false;
              this.personAclMsg = true;
              // this.teamAclMsg = false;
              // this.teamDataMsg = false;
              // this.personDataMsg = false;
              this.personAclMessage = '';
              this.personAclMessage = 'This search by PERSON ACL data returns no data!';
            }



          }
        });
      }
    } else {
      this.userDisplay = true;
      this.userError = '';
      this.userError = 'A cdsid or person id needs to be entered to perform the search';

    }
  }

  modifyUserProfile() {
    console.log('modify user profile');
    if (this.userModify.value) {
      this.userPerson = this.userModify.value;
      //console.log('this.userPerson', this.userPerson);
      if (this.userPerson) {
        this.modify.updateUser(this.userPerson).subscribe((response: PersonList) => {
          console.log('Response:', response)
          //.log('email from persondata',response.email)
          if (response) {
            this.successMessage = '';
            this.successMessage = response.errorMessage;
            this.displaySuccessMsg = true;
            if (response) {
              this.userModify.setValue(response);

              this.userModify.patchValue({
                application: response.application ? response.application : 'null',
                reauthorizedOn: response.reauthorizedOn ? response.reauthorizedOn : 'null',
                password: response.password ? response.password : 'null',
                role: response.role ? response.role : 'null',
                businessCase: response.businessCase ? response.businessCase : 'null',
                country: response.country ? response.country : 'null',
                unit: response.unit ? response.unit : 'null',
                building: response.building ? response.building : 'null',
                steward: response.steward ? response.steward : 'null',
                lastName: response.lastName ? response.lastName : 'null',
                company: response.company ? response.company : 'null',
                division: response.division ? response.division : 'null',
                reportsTo: response.reportsTo ? response.reportsTo : 'null',
                reason: response.reason ? response.reason : 'null',
                location: response.location ? response.location : 'null',
                authorizer: response.authorizer ? response.authorizer : 'null',
                organization: response.organization ? response.organization : 'null',
                storageClass: response.storageClass ? response.storageClass : 'null',
                phone: response.phone ? response.phone : 'null',
                mailDrop: response.mailDrop ? response.mailDrop : 'null',
                email: response.email ? response.email : 'null',
                firstName: response.firstName ? response.firstName : 'null',
                department: response.department ? response.department : 'null',
                office: response.office ? response.office : 'null',
                id: response.id ? response.id : 'null',
                createdOn: response.createdOn ? response.createdOn : 'null',
                empType: response.empType ? response.empType : 'null',
                buildingName: response.buildingName ? response.buildingName : 'null',
                modifiedOn: response.modifiedOn ? response.modifiedOn : 'null',
              });

            }
          }
        });
      }

    }
  }
  getFDSData() {
    console.log('get fds data');
    this.modify.getFDS().subscribe((data: PersonList) => {
      console.log('data::::::::::',data.division);
      if (data) {

        //this.userModify.setValue(data);
        this.userModify.patchValue({
          application: data.application ? data.application : '',
          reauthorizedOn: data.reauthorizedOn ? data.reauthorizedOn : 'null',
          password: data.password ? data.password : 'null',
          role: data.role ? data.role : 'null',
          businessCase: data.businessCase ? data.businessCase : '',
          country: data.country ? data.country : '',
          unit: data.unit !='null' ? data.unit : '',
          building: data.building ? data.building : '',
          steward: data.steward ? data.steward : '',
          lastName: data.lastName ? data.lastName : '',
          company: data.company ? data.company : '',
          division: data.division ? data.division : '',
          reportsTo: data.reportsTo ? data.reportsTo : '',
          reason: data.reason ? data.reason : '',
          location: data.location ? data.location : '',
          authorizer: data.authorizer ? data.authorizer : '',
          organization: data.organization ? data.organization : 'null',
          storageClass: data.storageClass ? data.storageClass : 'null',
          phone: data.phone ? data.phone : '',
          mailDrop: data.mailDrop  ? data.mailDrop : 'null',
          email: data.email ? data.email : '',
          firstName: data.firstName ? data.firstName : '',
          department: data.department ? data.department : '',
          office: data.office !='null' ? data.office : '',
          id: data.id ? data.id : 'null',
          createdOn: data.createdOn ? data.createdOn : '',
          empType: data.empType ? data.empType : '',
          buildingName: data.buildingName ? data.buildingName : '',
          modifiedOn: data.modifiedOn ? data.modifiedOn : '',
          cdsId:data.cdsId,
        })





        }
    });
  }

  // delete tam affililitaes
  deleteAffiliations(data: TeamData) {
    this.teamData = data;
    this.deleteMessage = 'Are you sure?';
    //this.deleteMessage = '';
    this.displayDeleteMsgTeamAff = true;



    console.log('deleteAffiliations:', this.teamData);

  }

  // delete personal ACL

  personAclDelete(data: TeamAdminAcl) {
    console.log('personAcl delete:', data);
    this.deleteMessage = 'Are you sure?';
    this.displayDeleteMsgPersonalAcl = true;
    this.personalacldata = data;

  }


  saveAcl(data: TeamAdminAcl) {
    debugger;
    var record;
    var sec;

    console.log('saveacl', data);
    this.teamAclData = data;
    this.isSaveAcl = true;
    if (data) {
      this.saveAclArr.forEach(value => {
      this.aclMessage += '`' + value.item + '`' + '`' + value.query + '`' + '`' + value.write + '`' + '`' + value.read + '`' + '`' + value.create + '`' + '`' + value.delete + '`' + '~\n';
      });

      if (this.saveAclArr.length == 0)
        this.aclMessage = 'Please double-click to select the value';
      else
        this.aclMessage = this.aclMessage.substring(0, this.aclMessage.length - 2);
      // sec = sec + record;
      console.log('junk:', record);

      //console.log('sec junk:',sec)
      // alert(acl);
      // this.aclMessage = acl;
      //console.log('jjhhhhh:',this.aclMessage)
      this.aclAppMsg = true;
    }else{
      this.aclAppMsg = true;
      this.aclMessage = 'Please double-click to select the value';
    }

    console.log('saveAclArr', this.saveAclArr)
  }

  updateTeamMember(data: ApplicationMembership[]) {
    console.log('updateTeamMember data', data);
    console.log('userscreen updatemembership :::',this.userModify.get('hasAccess').value)
    if (this.userModify.get('hasAccess').value ==='y' || this.userModify.get('hasAccess').value ==='w') {
      console.log('updateTeamMember:', data);

        data[0].authorizer = this.authorize;
        if(data[0].personId ==null || data[0].personId ==0){
          data[0].personId = this.personId;
          console.log('data is null ',data[0].personId)
        }else{
          data[0].personId = data[0].personId;
          console.log('data is not null ',data[0].personId)
        }
        if (data[0].businessCase != null && data[0].businessCase != '') {
          data[0].selectStatus = "selected";
          console.log('data input:', data);
          this.modify.updateTeamMember(data).subscribe((response: UpdateTeamMembershipResponse) => {
            console.log(response)
            if (response) {
              this.successMessage = '';
              this.successMessage = response.message;
              this.displaySuccessMsg = true;
            }

          });
        } else {
          console.log('businesscase else');
          this.displaySuccessMsg = true;
          this.membershipName = 'Processing.....';
          this.successMessage = '';
          this.successMessage = 'A business case is needed for ' + data[0].appName + ' Usage';
        }

    } else {
      console.log('else part');
      this.displaySuccessMsg = true;
      this.successMessage = '';
      this.successMessage = 'Click on the checkbox to update membership';
    }

  }

  onRowSelect(event: TeamAdminAcl, inputstring: string, inputId: string) {
    console.log('row selected', event);
    console.log('row selected', inputstring);
    this.personAcls = event;
    this.inputData = inputstring;
    this.val = inputId;
    this.aclDialog = true;
  }

  getPopup(event) {
    console.log('get popup event', event);
    this.clonePersonAcl(this.personAcls, this.inputData, event);
    this.aclDialog = false;

  }
  clonePersonAcl(c: TeamAdminAcl, input: string, event: string): TeamAdminAcl {
    console.log('clonePersonal:', c);
    console.log('clonePersonal input:', input);
    console.log('clonePersonal event:', event);
    this.saveAclArr.push(c);
    // if (this.saveAclArr.length > 0) {
    //   this.saveAclArr.forEach((value, index) => {
    //     if (value.item === c.item) {
    //       this.saveAclArr[index] = c;
    //     } else {
    //       this.saveAclArr.push(c);
    //     }
    //   });
    // } else {
    //   this.saveAclArr.push(c);
    // }
    console.log('this.saveAclAr', this.saveAclArr);
    for (let prop in c) {
      console.log('prop', prop);
      console.log('c[prop]', c[prop]);
      if (input === prop) {
        console.log('inside if');
        c[prop] = event;
      }

    }

    return c;
  }

  close() {
    this.membershipName = '';
    this.membershipName = 'Update Team Memberships';
    this.displaySuccessMsg = false;
  }
  deleteOkTeamAff() {
    this.displayDeleteMsgTeamAff = false;
    console.log('this.teamData:', this.teamData);
    if (this.teamData) {
      this.modify.deleteAffiliations(this.teamData).subscribe((response: DeleteUserTeamResponse) => {
        console.log(response)
        if (response) {
          this.successMessage = '';
          this.successMessage = response.message;
          this.displaySuccessMsg = true;
          if (response.team) {
            this.teamAffiliation = [];
            this.teamAffiliation = response.team;
          }
        }
      });
    }

  }
  cancelTeamAff() {
    this.displayDeleteMsgTeamAff = false;
  }

  deleteOkPersonalAcl() {
    this.displayDeleteMsgPersonalAcl = false;
    this.modify.personAclDelete(this.personalacldata).subscribe((response: DeletePersonAclResponse) => {
      console.log(response)
      if (response) {
        this.successMessage = '';
        this.successMessage = response.message;
        this.displaySuccessMsg = true;
        if (response.person) {
          this.personAcl = [];
          this.personAcl = response.person;
        }


      }
    });

  }
  cancelPersonalAcl() {
    this.displayDeleteMsgPersonalAcl = false;
  }
  aclClose() {
    this.aclAppMsg = false;

    if (this.teamAclData && this.isSaveAcl) { console.log('close:', this.saveAclArr);
     // this.modify.savePersonAcl(this.teamAclData).subscribe((response: string) => {
        this.modify.savePersonAcl(this.saveAclArr).subscribe((response: string) => {
        console.log('response',response)
        if (!response) {
          this.aclAppMsg = true;
          this.aclMessage = '';
        }
        if (response) {
          this.successMessage = '';
          this.successMessage = response;
          this.displaySuccessMsg = true;
          console.log('this.successMessage', this.successMessage);
        }
        //if (response) {
        // this.successMessage = '';
        // this.successMessage = response;
        // this.displaySuccessMsg = true;
        // }else{
        //  this.displaySuccessMsg = true;
        // }

      });
      this.saveAclArr = [];
      this.aclMessage = '';
      this.isSaveAcl = false;
    }
  }
  onRowSelectApp(data: any) {
    console.log('data.....:', data);
    if (data) {
      this.checkUpdateApp = true;
      this.userModify.get('hasAccess').setValue('y');
    }
  }
  onRowUnSelected(data:any){
    console.log('un row un selecte data.....:', data);
    if(data){
      this.userModify.get('hasAccess').setValue('n');

    }

  }

  closePD() {
    this.personDataMsg = false;
  }
  closeTD() {
    this.teamDataMsg = false;
  }
  closeTAc() {
    this.teamAclMsg = false;
  }
  closePAc() {
    this.personAclMsg = false;
  }


}









