import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'sdrc-item-for-opt',
  templateUrl: './sdrc-item-for-opt.component.html',
  styleUrls: ['./sdrc-item-for-opt.component.css']
})
export class SdrcItemForOptComponent implements OnInit {

  sdrcItemOptObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
   this._formClear();
  }

  _formClear() {
    
    this.sdrcItemOptObjectForm = this.formBuilder.group({
      metaphaseCreator: '',
      isMetaphaseFrozen: '',
      tdmID: '',
      metaphaseId: '',
      num: '',
      ideasType: '',
      tdmCreatedOn: '',
      curApproval: '',
      lastCheckinUser: '',
      tdmModifiedOn: '',
      metaphaseClass: '',
      name: '',
      tdmProject: '',
      metaphaseModifiedOn: '',
      revision: '',
      isMetaphaseSuperceded: '',
      tdmLibrary: '',
      lastCheckOutUser: '',
      metaphaseCheckinOn: '',
      tdmStatus: '',
      tdmCreator: '',
      isMetaphaseCheckedOut: '',
      version: 'v1',
      metaphaseCreatedOn: ''
    });
    this.formReady.emit(this.sdrcItemOptObjectForm);
  }
}
