import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'epdbook-for-req',
  templateUrl: './epdbook-for-req.component.html',
  styleUrls: ['./epdbook-for-req.component.css']
})
export class EpdbookForReqComponent implements OnInit {

  epdBookReqObjectForm:FormGroup;  
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
  }

  _formClear() {
    this.epdBookReqObjectForm = this.formBuilder.group({
      progLabel: [''],
      
    });
    this.formReady.emit(this.epdBookReqObjectForm);
  }

}
