import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Item, AssociatedTable, Folder } from 'app/core/model/itemAdminModels';

@Component({
  selector: 'folder-object',
  templateUrl: './folder-object.component.html',
  styleUrls: ['./folder-object.component.css']
})
export class FolderObjectComponent implements OnInit {

  folderObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()
  @Input('screenType') screenType: string;
  @Input('item') item: Item;
  @Input('folderItem') folderItem: Folder;
  @Input('showfield') showfield: boolean;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
    this._updateFolderObjectForm();

  }

  _formClear() {
    this.folderObjectForm = this.formBuilder.group({
      id: [''],
      purchaseOrder: [''],
      comments: [''],
      vendor: [''],
      projectId: [''],
    });
    this.formReady.emit(this.folderObjectForm);
  }

  _updateFolderObjectForm() {
    this.folderObjectForm.patchValue({
      id: this.item.id,
      purchaseOrder: this.folderItem.folder_purchase_order,
      comments: this.folderItem.folder_comments,
      vendor: this.folderItem.folder_vendor,
      projectId: this.folderItem.folder_project_id
    })
  }

}
