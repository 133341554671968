import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'filedata-for-opt',
  templateUrl: './filedata-for-opt.component.html',
  styleUrls: ['./filedata-for-opt.component.css']
})
export class FiledataForOptComponent implements OnInit {

  fileDataOptForm:FormGroup;  
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {

    this._formClear();
  }

  _formClear() {
    this.fileDataOptForm = this.formBuilder.group({
      actualServer: [''],
      dataCheckSum: [''],
      dataExternalMimeType: [''],
      dataMimeParam: [''],
      dataCompression: [''],
      dataRetStatus: [''],
      viewCount: [''],
      dataCreatedOn: [''],
      dataOwner: [''],
      displayOrder: [''],
      version: [''],
      dataAccessedOn: [''],
      dataMimeType: [''],
      dataMimeSubType: [''],
      dataPublishedOn: [''],
      dataActualSize: [''],
      dataIsDefault: [''],
      dataModifiedOn: [''],
      dataIsEncrypted: [''],
      dataStMode: [''],
      dataStoredSize: [''],
      dataComments: ['']

    });
    this.formReady.emit(this.fileDataOptForm);
  }

}
