import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'engineconfig-for-opt',
  templateUrl: './engineconfig-for-opt.component.html',
  styleUrls: ['./engineconfig-for-opt.component.css']
})
export class EngineconfigForOptComponent implements OnInit {

  engineConfOptObjectForm:FormGroup;  
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
  }

  _formClear() {
    this.engineConfOptObjectForm = this.formBuilder.group({
      trueNumVal: [''],
      maxTorq: [''],
      comments: [''],
      maxRpm: [''],
      torqCurve: [''],
      trueValTrain: [''],
      trueDisp: [''],
      truebankAngle: [''],
      trueConfig: [''],
      trueFuel: [''],
      maxHorsPow: [''],
    
      
    });
    this.formReady.emit(this.engineConfOptObjectForm);
  }

}
