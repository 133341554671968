import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'stddomain-for-opt',
  templateUrl: './stddomain-for-opt.component.html',
  styleUrls: ['./stddomain-for-opt.component.css']
})
export class StddomainForOptComponent implements OnInit {

  stdDomainOptObjectForm:FormGroup;  
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
  }

  _formClear() {
    this.stdDomainOptObjectForm = this.formBuilder.group({
      comments: [''],
      code: [''],
    
      
    });
    this.formReady.emit(this.stdDomainOptObjectForm);
  }

}
