import { EventEmitter, Injectable, Output } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserInfo } from '../login/user-info.model';
import { Location } from '@angular/common';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../environments/environment";

@Injectable()
export class UrlConsumerService implements CanActivate {

	private teamURI = environment.apdmServiceUrl;

	private headers: HttpHeaders;

	cdsId: any;

	token: any;
	@Output() change: EventEmitter<boolean> = new EventEmitter();
	userInfo: UserInfo = new UserInfo();

	afrcdsid: string = '';
	afryear: string = '';

	constructor(private router: Router, private location: Location, private http: HttpClient) {
		console.log('<---UrlConsumerService constructor--->');

		// admin accesss//
		this.token = JSON.parse(sessionStorage.getItem('strAccessToken'));
		console.log(' UrlConsumerService-Token--->', this.token);

		if (this.token) {
			this.cdsId = this.token.CommonName;
			// this.cdsId = "gboopat1";
		}

		// this.cdsId = "gboopat1";
		//console.log(' teamcreation-cdsid--->', this.cdsId);

		if (this.cdsId !== undefined) {
			this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
		} else {
			this.cdsId = 'Invalid';
			this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
		}
		this.change.subscribe(() => {
			const token = JSON.parse(sessionStorage.getItem('strAccessToken'));
			console.log('token--->', token);
			this.userInfo.fullName = token.givenname + ' ' + token.surname;
			this.userInfo.cdsId = token.CommonName;
		});
	}

	public getadminaccess(): Observable<any> {
		return this.http.get(this.teamURI + 'hasApplicationAdminAccess', {
			headers: this.headers,
			responseType: 'text'
		});
	}

	public getwebclientaccess(): Observable<string> {
		return this.http.get(this.teamURI + 'baseAction/process', {
			headers: this.headers,
			responseType: 'text'
		});
	}

	toggle() {
		this.change.emit(true);
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		console.log('<-- Start canActivate--->');
		const queryParamsObj = this.getQueryParams();
		console.log('<-- queryParamsObj--->', queryParamsObj);
		if (queryParamsObj && queryParamsObj['access_token'] != null) {
			//token is part 2 of a JWT (index 1)
			console.log('<-- Start queryParamsObj--->', queryParamsObj['access_token']);
			const accessToken = atob(queryParamsObj['access_token'].split('.')[1]);
			const jsonToken = JSON.parse(accessToken);

			sessionStorage.setItem('strAccessToken', accessToken);
			sessionStorage.setItem('encodedAccessToken', queryParamsObj['access_token']);

			sessionStorage.setItem('tokenIssue', jsonToken.iat);
			sessionStorage.setItem('tokenExp', jsonToken.exp);
			if (sessionStorage.getItem('redirectURL') === null || sessionStorage.getItem('redirectURL') == null
			|| sessionStorage.getItem('redirectURL') == 'null') {
				sessionStorage.removeItem('tokenExp');
				sessionStorage.setItem('redirectURL', '/');
			}

			// Redirect to originally requested URL
			if (sessionStorage.getItem('redirectURL')) {
				//sessionStorage.setItem('login','true');
				this.router.navigate([sessionStorage.getItem('redirectURL')]);
				return false;
			}
			return true;
		} else {
			console.error('Invalid Token');
			sessionStorage.removeItem('tokenExp');
			this.router.navigate(['/login']);
		}

		return false;
	}

	getQueryParams() {
		console.log('<-- getQueryParams() Method Start--->');
		if (this.location.path(true).indexOf('access_token') === 0) {
			const queryString = this.location.path(true);

			//URLSearchParams should be the solution here. it's not working. so we did it manually
			const paramArray = queryString.split('&');
			const queryParamsObj = new Object();

			for (const param of paramArray) {
				//we can't use a simple split() call here as base64 allows for = padding
				const i = param.indexOf('=');
				const splitArray = [param.slice(0, i), param.slice(i + 1)];
				queryParamsObj[splitArray[0]] = splitArray[1];
			}
			console.log('<-- getQueryParams() Method End--->', queryParamsObj);
			return queryParamsObj;
		} else {
			return null;
		}
	}
}
