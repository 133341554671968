import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TeamAdministrationService } from 'app/core/service/team-administration.service';
import { TeamType, TeamCreate, TeamDetail, Subject, TeamAdminResponse, TeamAdminAcl, TeamMembers, TeamModifyResponse, TeamDetailResponse, TeamAclResponse, TeamMemberResponse, TeamIndirectAcl } from 'app/core/model/teamAdministrationModel';
import { SelectItem } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'modify-team',
  templateUrl: './modify-team.component.html',
  styleUrls: ['./modify-team.component.css']
})
export class ModifyTeamComponent implements OnInit {
  teamSearchForm: FormGroup;
  teamGetDataForm: FormGroup;
  teamModify: FormGroup;
  teamTypeList: TeamType[];
  submitted: boolean = false;
  teamCreateModel: TeamCreate;
  teamCreationStatus: string;
  teamDisplay: boolean = false;
  teamDisplayDelete: boolean = false;
  teamName: string;
  type: SelectItem[];
  teamDetails: TeamDetail[];
  rowSelectedData: TeamDetail[];
  rowSelectedMember: TeamMembers[]
  showTeamObject: boolean = false;
  showPersonInformation: boolean = false;
  userlist: Subject[];
  selectedList: string;
  getTeamName: string;
  titleTeamName: string;
  teamAcls: TeamAdminAcl[];
  showAcl: boolean = false;
  aclSelectedData: TeamAdminAcl[];
  showTeamMember: boolean = false;
  teamMember: TeamMembers[];
  redirectMsg: boolean = false;
  successMsg: boolean = false;
  teamSelected: string[];
  getSelected: string[];
  showIndirectAcl: boolean = false;
  showDropDownTeamName: boolean = false;
  showTextBoxTeamName: boolean = true;
  indirectAcl: TeamIndirectAcl[]
  aclAppMsg: boolean = false;
  aclMessage: string;
  deleteTeamData: TeamCreate;

  constructor(private formBuilder: FormBuilder, private teamAdmin: TeamAdministrationService, private router: Router) { }

  ngOnInit() {
    this.teamAdmin.personErrorMsg = ''
    this.type = [
      { label: 'person', value: 'p' },
      { label: 'company', value: 'c' },
      { label: 'building', value: 'b' },
      { label: 'organization', value: 'o' },
      { label: 'role', value: 'r' },
      { label: 'automaton', value: 'a' },
      { label: 'team', value: 't' },
    ]

    this.teamSearchForm = this.formBuilder.group({
      teamId: [''],
      teamName: ['', Validators.required],
      steward: [''],
      comments: [''],
      teamType: [''],
      memberList: [''],
      selectedTeams: [''],
      modifiedOn: [''],
      createdOn: [''],
      type: [''],
      isAclFlag: [''],
      isMembersFlag: [''],
      userData: ['']
    });
    this.teamGetDataForm = this.formBuilder.group({
      teamId: [''],
      teamName: [''],
      steward: [''],
      comments: [''],
      teamType: [''],
      memberList: [''],
      selectedTeams: [''],
      modifiedOn: [''],
      createdOn: [''],
      type: [''],
      isAclFlag: [''],
      isMembersFlag: [''],
      userData: [''],

    });
    this.teamModify = this.formBuilder.group({
      teamId: [''],
      teamName: [''],
      steward: [''],
      comments: [''],
      teamType: [''],
      memberList: [''],
      selectedTeams: [''],
      modifiedOn: [''],
      createdOn: [''],
      type: [''],
      isAclFlag: [''],
      isMembersFlag: [''],
      userData: ['']

    });
    // this.teamSearchForm.get('teamType').setValue(['p', 't']);
    // this.teamGetDataForm.get('teamType').setValue(['members']);
    this.teamSelected = ['p', 't'];
    this.userlist = [];
    this.getSelected = ['members'];

    this.showTeamObject = false;
    this.showPersonInformation = false;
    this.showAcl = false;
    this.showTeamMember = false;
    this.showIndirectAcl = false;
  }
  teamModifySearch() {

    this.showTeamObject = false;
    this.showPersonInformation = false;
    this.showAcl = false;
    this.showTeamMember = false;
    this.submitted = true;
    this.showTextBoxTeamName = false;
    this.showDropDownTeamName = true;

    console.log('team search');
    if (this.teamSearchForm.valid) {
      this.teamCreateModel = this.teamSearchForm.value;
      //this.teamCreateModel.memberList = [];
      this.teamCreateModel.selectedTeams = [];
      this.teamCreateModel.userData = [];

      this.teamCreateModel.teamType = [];
      this.teamCreateModel.teamType = this.teamSelected;
      if (this.teamCreateModel) {
        console.log('teamListSearch', this.teamCreateModel);
        this.teamName = this.teamCreateModel.teamName;

        this.teamAdmin.teamAdminSearch(this.teamCreateModel).subscribe((data: TeamCreate) => {
          console.log('list search', data);
          console.log(data.userData);
          if (data.userData && data.userData.length > 0) {
            this.userlist = data.userData;
            //this.teamName = 'hii';

            console.log(this.userlist);
          } else {
            console.log('else');

            this.ngOnInit();
          }
        });
      }
    }
  }

  // Team Get Data
  teamGetData() {
    console.log('team get data');
    console.log(this.teamGetDataForm.value);
    console.log('get team', this.selectedList);
    if (this.selectedList) {
      console.log('this.selectedList == IF')
      this.getTeamName = this.selectedList;
      this.titleTeamName = this.selectedList;
      // this.teamAdmin.teamNameView = this.selectedList;
      // this.teamAdmin.teamType = this.getSelected;

    } else {
      console.log('this.selectedList == ELSE');
      if (this.userlist) {
        for (var i = 0; i < this.userlist.length; i++) {
          console.log(this.userlist[i]);
          console.log(this.userlist[0].label);
          this.getTeamName = this.userlist[0].label;
          this.titleTeamName = this.userlist[0].label;
          // this.teamAdmin.teamNameView = this.userlist[0].label;
          // this.teamAdmin.teamType = this.getSelected;
          break;
        }
      }
    }

    this.showTeamObject = false;
    this.showPersonInformation = false;
    this.showAcl = false;
    this.showTeamMember = false;
    if (this.teamGetDataForm.valid) {
      this.teamCreateModel = this.teamGetDataForm.value;
      this.teamCreateModel.teamName = this.getTeamName;
      //this.teamCreateModel.memberList = [];
      this.teamCreateModel.selectedTeams = [];
      this.teamCreateModel.userData = [];
      this.teamCreateModel.teamType = [];
      this.teamCreateModel.teamType = this.getSelected;
      console.log(this.teamCreateModel);
      if (this.teamCreateModel.teamName || this.teamCreateModel.teamId) {
        this.teamAdmin.teamAdminGetData(this.teamCreateModel).subscribe((data: TeamAdminResponse) => {
          console.log('data', data);

          console.log('data id', data.teamCreate.teamId);

          if (data.teamCreate.teamId == null) {

            this.showTeamObject = false;
            this.showPersonInformation = false;
            this.showAcl = false;
            this.showTeamMember = false;
            this.showIndirectAcl = false;
            this.teamDisplay = true;
            this.teamCreationStatus = 'No Team exists with Specified Id or Name';
            this.redirectMsg = false;
            this.successMsg = true;
            this.teamGetDataForm.get('teamId').setValue('');
          }

          if (data.teamCreate && data.teamCreate.teamId != null) {
            this.teamModify.setValue(data.teamCreate);
            this.showTeamObject = true;


          }
          if (data.teamDetail && data.teamDetail.length > 0) {
            this.teamDetails = data.teamDetail;
            this.showPersonInformation = true;
           
          }
          if (data.teamAdminAcl && data.teamAdminAcl.length > 0) {
            this.teamAcls = data.teamAdminAcl;
            this.showAcl = true;
          }
          if (data.teamMembers && data.teamMembers.length > 0) {
            this.teamMember = data.teamMembers;
            console.log(this.teamMember);
            this.showTeamMember = true;
           
          }
          if (data.teamIndirectAcl && data.teamIndirectAcl.length > 0) {
            this.indirectAcl = data.teamIndirectAcl;
            this.showIndirectAcl = true;
          }

        });
      }
    } else {
      console.log('else');
      this.teamDisplay = true;
      this.teamCreationStatus = 'No Team exists with Specified Id or Name';
      this.redirectMsg = false;
      this.successMsg = true;
    }
  }
  modifyTeam() {
    console.log('modify team');
    console.log(this.teamModify.value);
    this.teamCreateModel = new TeamCreate();
    if (this.teamModify.value) {
      this.teamCreateModel = this.teamModify.value;
      this.teamCreateModel.selectedTeams = [];
      this.teamCreateModel.userData = [];
      // this.teamCreateModel.teamType.push(this.teamModify.get('teamType').value) ;

      console.log('this.teamCreateModel', this.teamCreateModel);
      if (this.teamCreateModel) {
        this.teamAdmin.modifyTeam(this.teamCreateModel).subscribe((data: string) => {
          console.log('modify data:', data);

          if (data) {

            let message = data.split('+');
            console.log('message:', message);

            if (message[0] != 'redirectAdmin') {
              if (message[0] != 'noRedirect' && message[1]) {
                console.log('if loop  modify');
                this.teamAdmin.teamNameView = message[0];
                this.teamAdmin.teamType = this.getSelected;
                this.teamDisplay = true;
                this.teamCreationStatus = '';
                this.teamCreationStatus = message[1];
                this.redirectMsg = true;
                this.successMsg = false;
              } else {
                console.log('if else  modify');
                this.teamDisplay = true;
                this.teamCreationStatus = '';
                this.teamCreationStatus = message[1];
                this.redirectMsg = false;
                this.successMsg = true;
              }
            } else {
              console.log('person error');
              this.teamAdmin.personErrorMsg = ''
              this.teamAdmin.personErrorMsg = message[1];
              this.router.navigateByUrl('admin-portal/error');

            }
          }

        });
      }
    }
  }
  deleteTeam() {
    console.log('delete team');
    console.log(this.teamModify.value);
    if (this.teamModify.value) {
     this.deleteTeamData = new TeamCreate();
     this.deleteTeamData = this.teamModify.value;
    
      if(this.teamDetails || this.showTeamMember){
        console.log('inside boolean:')
        this.deleteTeamData.isMembersFlag = "true";
      }
      console.log('this.deleteTeamData:',this.deleteTeamData);
      this.teamAdmin.deleteTeam(this.deleteTeamData).subscribe((data: string) => {
        console.log('delete team:', data);
        if (data) {
          let message = data.split('+');
          console.log('message:', message);
          if (message[0] != 'redirectAdmin') {
            if (message[0] != 'noRedirect' && message[1]) {
              console.log('if loop  modify');
              this.teamAdmin.teamNameView = message[0];
              this.teamAdmin.teamType = this.getSelected;
              this.teamDisplay = true;
              this.teamCreationStatus = '';
              this.teamCreationStatus = message[1];
              this.redirectMsg = true;
              this.successMsg = false;
            } else {
              console.log('if else  modify');
              this.teamDisplay = true;
              this.teamCreationStatus = '';
              this.teamCreationStatus = message[1];
              this.redirectMsg = false;
              this.successMsg = true;
            }

          }else {
              console.log('person error');
              this.teamAdmin.personErrorMsg = ''
              this.teamAdmin.personErrorMsg = message[1];
              this.router.navigateByUrl('admin-portal/error');

            }


          // this.teamDisplay = true;
          // this.teamCreationStatus = '';
          // this.teamCreationStatus = data;
          // this.redirectMsg = false;
          // this.successMsg = true;

        }

      });
    }
  }
  deleteMarkMember(data: TeamDetail[]) {
    console.log('deleteMarkMember');
    console.log('data', data);



    if (data) {
      this.teamAdmin.deleteMember(data).subscribe((data: TeamDetailResponse) => {
        if (data) {
          this.teamDisplay = true;
          // this.redirectMsg = false;
          // this.successMsg = true;
          // this.teamCreationStatus = '';
          // this.teamCreationStatus = data.message;
          // if(data.teamDetails){
          //   this.teamDetails = data.teamDetails;
          // }
          this.teamCreationStatus = '';
          let message = data.message.split('+');
          console.log('message:', message);
          this.teamCreationStatus = message[1];
          // this.teamAdmin.teamNameView = message[0];
          if (message[0]) {
            this.redirectMsg = true;
            this.successMsg = false;
          }
        }
      });
    }
    else {
      this.teamDisplayDelete = true;
      this.teamCreationStatus = "Please select the checkbox before delete the members";
    }
  }
  modifyACLs(data: TeamAdminAcl[]) {
    console.log('data modify Acl', data);
    if (data) {
      this.teamAdmin.modifyACLs(data).subscribe((data: TeamAclResponse) => {
        if (data) {
          this.teamDisplay = true;
          // this.redirectMsg = false;
          // this.successMsg = true;
          // this.teamCreationStatus = '';
          // this.teamCreationStatus = data.message;
          // if (data.teamAcl) {
          //   this.teamAcls = [];
          //   this.teamAcls = data.teamAcl;
          // }
          this.teamCreationStatus = '';
          let message = data.message.split('+');
          console.log('message:', message);
          this.teamCreationStatus = message[1];
          // this.teamAdmin.teamNameView = message[0];
          if (message[0]) {
            this.redirectMsg = true;
            this.successMsg = false;
          }

        }

      });

    }
  }
  deleteACLs(data: TeamAdminAcl[]) {
    console.log('data deleyte Acl', data);
    if (data) {
      this.teamAdmin.deleteACLs(data).subscribe((data: TeamAclResponse) => {
        if (data) {
          this.teamDisplay = true;
          // this.redirectMsg = false;
          // this.successMsg = true;
          // this.teamCreationStatus = '';
          // this.teamCreationStatus = data.message;
          // if (data.teamAcl) {
          //   this.teamAcls = [];
          //   this.teamAcls = data.teamAcl;
          // }
          this.teamCreationStatus = '';
          let message = data.message.split('+');
          console.log('message:', message);
          this.teamCreationStatus = message[1];
          // this.teamAdmin.teamNameView = message[0];
          if (message[0]) {
            this.redirectMsg = true;
            this.successMsg = false;
          }
        }

      });

    }
  }
  deleteMarkTeamMember(data: TeamMembers[]) {
    console.log('deleteMarkTeamMember', data);
    if (data) {
      this.teamAdmin.deleteMarkTeamMember(data).subscribe((data: TeamMemberResponse) => {
        if (data) {
          this.teamDisplay = true;
          this.redirectMsg = false;
          this.successMsg = true;
          this.teamCreationStatus = '';
          this.teamCreationStatus = data.message;
          if (data.teamMembers) {
            this.teamMember = [];
            this.teamMember = data.teamMembers;
          }
        }
      });
    }
    else {
      this.teamDisplayDelete = true;
      this.teamCreationStatus = "Please select the checkbox before delete the members";
    }
  }

  get f() {
    console.log('f');
    return this.teamSearchForm.controls;
  }
  close() {
    this.teamDisplay = false;
    this.teamDisplayDelete = false;
  }
  closeRedirect() {
    this.teamDisplay = false;
    this.teamDisplayDelete = false;
    this.router.navigateByUrl('admin-portal/list-team');
  }

  aclClose() {

  }



}
