import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { MenuItem } from 'primeng/primeng';


@Component({
	selector: 'home-nav',
	templateUrl: './home-nav.component.html',
	styleUrls: ['./home-nav.component.css']
})
export class HomeNavComponent {


	navMenuItems: MenuItem[];

	opened = false;
	togglePanel: boolean = true;


	constructor() {
		this.navMenuItems = [
			{
				label: 'Request',
				items: [
					{
						label: 'Account Request',
						routerLink: 'acc-request', 
						icon: 'pi pi-angle-double-right'						
					},
					{
						label: 'Account Status',
						routerLink: 'acc-status',
						icon: 'pi pi-angle-double-right'
					}				
				]
			},
			{
				label: 'Team',
				items: [
					{
						label: 'Creation',
						routerLink: 'team-create',
						icon: 'pi pi-angle-double-right'
	
					},
					{
						label: 'Modification',
						routerLink: 'team-modify',
						icon: 'pi pi-angle-double-right'
					}
	
				]
				},
			
			{
				label: 'Instructions',
				items: [
					{
						label: 'Work Instructions',
						routerLink: 'work-instruct',
						icon: 'pi pi-angle-double-right'

					}
				]
				
			},
		];
	}



	menuPanelClose() {
		this.opened = false;
		this.togglePanel = true;
	}



}
