import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'folder-for-opt',
  templateUrl: './folder-for-opt.component.html',
  styleUrls: ['./folder-for-opt.component.css']
})
export class FolderForOptComponent implements OnInit {

  folderOptForm:FormGroup;  
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {

    this._formClear();
  }

  _formClear() {
    this.folderOptForm = this.formBuilder.group({
      purchaseOrder: [''],
      vendor: [''],
      projectId: [''],
      comments: ['']

    });
    this.formReady.emit(this.folderOptForm);
  }

}
