import { Component, OnInit, Output, EventEmitter, OnDestroy, Input, ViewChild } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { TreeViewService } from '../../core/service/tree-view.service';
import { SharedService } from '../../core/service/shared.service';
import { HttpClient, HttpResponse, HttpRequest, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { ContextMenu, MenuItem, Tree } from 'primeng/primeng';
import { Result, ChildNodeResult } from '../../core/model/treeViewModel'
import { CheckInService } from '../../core/service/check-in.service';
import { saveAs } from 'file-saver';
import { CheckInGetParentDetails } from '../../core/model/checkInModel';
import { Subscription } from 'rxjs';
import { CheckOut, FileDownloadUrl, TempPath } from 'app/core/model/checkout';
import { CheckOutService } from 'app/core/service/check-out.service';
import { DeleteService } from '../../core/service/delete.service';
import { CopyPasteService } from '../../core/service/copy-paste.service';
import { ConfirmationService } from 'primeng/api';
import { DeleteItemResponse } from 'app/core/model/DeleteItemRequest';
import { ViewProperties } from 'app/core/model/viewProperties';
import {environment} from 'environments/environment';
import {CheckoutResponse} from "./tree-view.interfce";

@Component({
	selector: 'tree-view',
	templateUrl: './tree-view.component.html',
	styleUrls: ['./tree-view.component.css'],
	providers: [TreeViewService]
})
export class TreeViewComponent implements OnInit, OnDestroy {
	response: Result;
	files: TreeNode[] = [];
	lazyFiles: TreeNode[];
	selectedFile2: TreeNode;
	selectedNodeRetention: TreeNode;
	@Output() selectedNodeRefresh = new EventEmitter<string>();
	@Output() selectedTreeNode = new EventEmitter<any>();
	childResponse: ChildNodeResult;
	treebaseID: string;
	public items2: MenuItem[];
	@Output() private Properties = new EventEmitter<any>();
	@Output() private checkIn = new EventEmitter<boolean>();
	@Output() private checkInError = new EventEmitter<boolean>();
	@Output() private apdmError = new EventEmitter<boolean>();
	@Output() private checkOut = new EventEmitter<boolean>();
	@Output() private editProperties = new EventEmitter<any>();

	@Input() item: string;//<!--FordChanges-->
	display: boolean = false;
	stopDownloadFile: boolean = false;
	Viewpropertiedisplay: boolean = false;
	loaderdisplay: boolean = false;
	checkoutDisplay: boolean = false;
	TreeNodeData: any;
	treeParentNodeRightClick: TreeNode;
	treeNodeRightClickEvent: any;
	event: any;
	classpathFileName = 'bootstrap.yml';
	test: any;
	//selectrowsync: any;
	//selectrowsynclength :number;
	subsciption: Subscription;

	subsciptiontree: Subscription;

	subsciptionsync: Subscription;

	//unhighlight color change on click
	treesinglesync: Subscription;

	commonDownload: Subscription;

	//highlight color change on click
	treeunchecksinglesync: Subscription;

	folderdownloadStop: any;


	selectedRules: TreeNode[];
	treeId: any;
	loading: boolean;
	message = [];
	errorMsg: any;
	successMsg: any;
	displayDownload: boolean = false;
	checkInGetParentDetails = new CheckInGetParentDetails();
	labelindex: number;
	deleteItemResponse: DeleteItemResponse[];
	//copyIds: any;
	//pasteType: any;
	cutNode: any;

	checkOutData = new CheckOut();

	treeNodeSelectedEmitter = new EventEmitter<any>();
	viewPropertiesValue = new ViewProperties();
	subSequent: string;
	checkOutSubscibe: Subscription;
	rightClickType: string = "";
	checkOutFile = new CheckOut();
	percentDone: number = 0;
	fileSize: string = '0';
	totalSize: string = '0';
	downloadSize: string;
	attemptCount: number = 0;
	fileDownloadErrorMsg: boolean = false;
	headers: HttpHeaders;
	environmentName: string;
	req: any;
	folderloaderdisplay: boolean = false;
	checkoutSizeMessage: boolean = false;
	checkoutMessage: string = '';

	extensionCheck: boolean = false;
	tempPath = new TempPath();
	resetTreeTableOnDataSelect: Subscription;
	resetTreeTableOnDataSelectfilemenu: Subscription;
	@ViewChild(ContextMenu) public cm: ContextMenu;
	@ViewChild(Tree) public treeTable: Tree;

	constructor(private service: TreeViewService, private shared: SharedService, private http: HttpClient,
		private checkInService: CheckInService, private checkOutService: CheckOutService,
		private deleteService: DeleteService, private confirmationService: ConfirmationService,
		private copyPasteService: CopyPasteService) {
		console.log("////////////TREE VIEW COMPONENT CONSTRUCTOR////////////////////");

		this.subsciption = this.shared.intialTreeBaseId.subscribe((data: string) => {
			// console.log("tabtree1")
			this.loading = false;
			this.getTree(data);
		})

		this.subsciptiontree = this.shared.treeBaseID.subscribe((response: string) => {
			// console.log("tabtree2")
			this.loading = false;
			this.getTree(response);
		});

		this.subsciptionsync = this.shared.treeDatatablesync.subscribe((data: any) => {
			if (this.item != data.ownerTreeId) {
				// Event belongs to some other tree
				return;
			}
			this.treeId = data.parent;

			// console.log("synx", this.treeId)
			// this.loadNodeForDataTableDoubleClick(data.parent);
			this.loadNode(data.parent);
		});

		this.checkOutSubscibe = this.shared.checkOutProperties.subscribe((response: any) => {
			// console.log('tree checkout', response)
			this.OncheckOutDialog(response);
		});


		// unhighlight the tree on single click
		this.treesinglesync = this.shared.treeselectionsync.subscribe((onRowSelectedDataTable: any) => {
			console.log("/////UN-SELECT THE CURRENT TREE VIEW NODE//////ACTIVE TAB INDEX//////////////////////", this.shared.selectedTab);
			this.shared.trees[this.shared.selectedTab].selectedFile2 = undefined;
		});


		if (this.shared.commonFileDownload.observers.length == 0) {
			this.commonDownload = this.shared.commonFileDownload.subscribe((data: any) => {
				console.log('file download next count:', data);
				if (data) {
					console.log('File Download');
					this.attemptCount = 0;
					this.downloadSize = '0';
					this.fileDownloadPreSignedUrl(data);
				}
			});
		}


		// highlight tree on single click
		this.treeunchecksinglesync = this.shared.treeunselectionsync.subscribe((onRowUnSelectedDataTable: any) => {
			if (onRowUnSelectedDataTable.length == 0) {
				// console.log("//////SELECT THE CURRENT TREE VIEW NODE/////ACTIVE TAB INDEX//////////////////////", this.shared.selectedTab);
				this.shared.trees[this.shared.selectedTab].selectedFile2 = this.shared.treeSelectionRetention[this.shared.selectedTab].selectedNodeRetention;
			}
		});

	}

	ngOnInit() {
		//this.shared.searchTreeNodeSelected =  new Subject<any>()
		this.rightClickType = "";
		this.shared.defaultNode = '';
		this.shared.defaultOnChange = '';
		this.items2 = [
			{
				label: 'Check Out',
				//   command: this.OncheckOutDialog,
				command: (event) => this.OncheckOutDialog(this.TreeNodeData)
			},
			{
				label: 'Check In',
				command: (event) => this.OncheckInDialog(this.TreeNodeData)
			},
			{
				label: 'View Properties',
				command: (event) => this.OnViewPropertieDialog(this.TreeNodeData),
			},
			{
				label: 'Edit Properties',
				command: (event) => this.OnEditPropertieDialog(this.TreeNodeData),
			},
			{
				label: 'Cut',
				command: (event) => this.OnCutDialog(this.TreeNodeData),
			},
			{
				label: 'Copy',
				command: (event) => this.OnCopyDialog(this.TreeNodeData),
			},
			{
				label: 'Paste',
				command: (event) => this.OnPasteDialog(this.TreeNodeData),
			},
			{
				label: 'Paste Short Cut',
				command: (event) => this.OnPasteShortCutDialog(this.TreeNodeData),
			},
			{
				label: 'Change Display to CPSC',
				disabled: true
			},
			{
				label: 'Delete',
				command: (event) => this.OndeleteDialog(this.TreeNodeData)
			},
			{
				label: 'Restore from Recycle Bin',
				command: (event) => this.OnRestoreItemDialog(this.TreeNodeData)
				// disabled: true
			},
			{
				label: 'Refresh from Database',
				command: (event) => this.OnRefreshFromDatabase(this.TreeNodeData)
			}
		];

		// console.log("treebaseId = " + this.treebaseID);
		this.shared.changeSelect = false;
		this.getTree(this.item);
		this.resetTreeTableOnDataSelect = this.shared.resetTreeTableOnDataSelectevent.subscribe((data: any) => {
			console.log('/////////resetTreeTableOnDataSelect/////////');
			this.cm.hide();
			this.treeTable.selection = null;
		});
		this.resetTreeTableOnDataSelectfilemenu = this.shared.resetTreeTableOnDataSelecteventfilemenu.subscribe((data: any) => {
			console.log('/////////resetTreeTableOnDataSelect/////////');
			this.cm.hide();
		});
	}

	getTree(treebaseId: string): void {
		console.log('/////////START GET TREE METHOD/////////TAB INDEX/////////', treebaseId);
		this.files = [];
		// console.log('treebaseId', treebaseId)
		this.treebaseID = treebaseId;
		// console.log("selected node", this.selectedFile2);
		this.service.getInputTreebaseId(+treebaseId).subscribe((data: Result) => {
			this.response = data;
			console.log(this.response);
			// console.log("initial tree data load", this.response);
			if (this.files.length == 0) {
				this.response.data.forEach(element => {
					this.files.push({
						label: element.label,
						expandedIcon: element.expandedIcon,
						collapsedIcon: element.collapsedIcon,
						type: element.id.toString(),
						styleClass: element.styleClass,
						leaf: false,
						data: element.data,
						children: [],
						// tabId: element.tabId
					});
				})

				this.shared.defaultNode = '';
				// For the "V Engine" Tab, load the second node "V Engine Programs" and its contents by default
				this.loadDefaultNode(1, 14);

				// For the "I Engine" Tab, load the third node "I Engine Programs" and its contents by default
				this.loadDefaultNode(3, 25);

				// For the "My APDM" Tab, load the first node "Personal Storage" and its contents by default
				this.loadDefaultNode(12, "Personal Storage");
			}

			this.response.data[0].id = 0;
			this.response.data[0].label = "l";


			//<!--FordChanges-->
			this.shared.treeDatatableId.emit({
				item: this.item,
				data: this.response.data[0].id + '&' + this.response.data[0].label
			});
			// this.shared.treeDatatableId.emit(this.response);
		});
		// Store this tree into the shared "trees" array
		this.shared.trees[this.item] = this;
		this.shared.treeSelectionRetention[this.item] = this;

		// ADDED BY MPRABH24 FOR FILEMENU
		console.log('SETTING INITIAL ACTIVE TAB VALUE TO SHARED SELECTED TAB VALUE', this.shared.selectedTab, this.item);
		if (this.shared.selectedTab == undefined) {
			this.shared.selectedTab = Number(this.item);
		}


	}


	onRightClick(event) {
		this.TreeNodeData = event.node.data[0];
		this.treeNodeRightClickEvent = event;
		// console.log('onRightClick', event);
		// console.log('onRightClick --->', this.TreeNodeData);
		// console.log('treeParentNodeRightClick = ', event.node.parent);
		event.node.expanded = true;
		this.selectedNodeRefresh.emit(event);
		this.shared.resetDataTableOnTreeSelectevent.next(null);
		// this.shared.dataTableSelectedEmitter.emit(null);
	}


	/********Node select******/

	nodeSelect(event) {
		console.log('********TREE NODE SELECT********', event);
		console.log("********CHANGE SELECT********", this.shared.changeSelect);
		console.log('********SELECTEDFILE2********', this.selectedFile2);
		//this.shared.treeDatatableId.emit(event.node.data[0].id + '&' + event.node.data[0].label);
		//this.selectedFile2 = event;
		this.shared.treeNodeSelectedEmitter.emit(event.node);
		this.shared.searchTreeNodeSelected.next(event);
		this.shared.TreeNode(event);


		if (this.shared.changeSelect == false) {
			console.log("INSIDE IF CONDITION - SHARED CHANGESELECT");
			this.shared.changeSelect = true;
			// COMMENTED ON 24/5/2020 BY MPRABH24 FOR "DATATABLE IS NOT LOADING WHEN WE CLICKON FOLDER(NODE)"
			// this.selectedFile2.expanded = false;

			// ADDED BY MPRABH24 ON 24/5/2020
			this.selectedFile2.expanded = true;
			this.loadNode(this.selectedFile2);
		} else {
			console.log("INSIDE ELSE CONDITION - SHARED CHANGESELECT");
			this.selectedFile2.expanded = true;
			this.loadNode(this.selectedFile2);
			this.shared.changeSelect = false;
		}

		this.shared.treeSelectedEmitter.emit(event);
		this.shared.dataTableSelectedEmitter.emit(null);

		// Selected row shared to File menu
		this.shared.rowSelectDatatableValue.next(null);

		// this.shared.treeNodeSelectedEmitter.emit(this.selectedFile2);
		// this.shared.treeNodeSelectedEmitter.subscribe(data => {
		//   console.log('valueemittted');
		//   console.log(data);
		// })
		//File Menu Checkout
		if (event.node) {
			if (event.node.data) {
				// console.log('event.node.data', event.node.data[0]);
				this.checkOutFile = new CheckOut();
				this.checkOutFile.id = event.node.data[0].id;
				this.checkOutFile.label = event.node.data[0].label;
				this.checkOutFile.type = event.node.data[0].type;
				if (this.checkOutFile) {
					this.checkOutService.fileMenuCheckOut = new CheckOut();
					this.checkOutService.fileMenuCheckOut = this.checkOutFile;
				}
			}
		}
	}
	/********Node select******/

	// Treeview double click file download Start
	onRowDblclick(data: any) {
		console.log('////////TREE VIEW DOUBLE CLICK/////////////', this.shared.trees[this.shared.selectedTab].selectedFile2);
		console.log('//////////CHECK OUT FILE//////////////', this.checkOutFile);
		if (this.checkOutFile) {
			var extension = this.checkOutFile.label.split('.').pop();
			console.log('Extension:', extension);
			this.extensionCheck = this.getExtensionType(extension);
			console.log('Double click Extension:', this.extensionCheck);
			console.log('type:', this.checkOutFile.type);

		}
		var currentTreeNode = this.shared.trees[this.shared.selectedTab].selectedFile2;
		console.log('CurrentTree node:', currentTreeNode)

		if ((this.checkOutFile.type == '32') || (this.extensionCheck && this.checkOutFile.type == '65')) {
			console.log('/////////////Tree View Double ClickFile Download////////////////');
			this.attemptCount = 0;
			this.downloadSize = '';
			//this.loaderdisplay = true;
			this.fileDownloadPreSignedUrl(this.checkOutFile);

		} else if (currentTreeNode.data[0].type == 64 || (currentTreeNode.data[0].type == 65 && currentTreeNode.data[0].actualItemType == 64)) {
			console.log('/////INSIDE - 1/////////');
			var strVal = currentTreeNode.data[0].externalUrl;
			var url = "";
			if (strVal.length > 4) {
				var value = strVal.substring(0, 4);
				if (value == "http") {
					url = strVal;
				}
				else {
					url = "http://" + strVal;
				}
				window.open(url);
			} else {
				alert("not valid url");
			}
		}


		//	console.log('/////INSIDE/////////');

		//}
	}


	/*****lazy loading *** */
	loadNode(event) {
		console.log("//////////////START TREE- LOAD NODE EVENT/////////////////////", event);
		this.selectedNodeRefresh.emit(event);
		var node: TreeNode = event.node;

		if (event.node === undefined) {
			node = event;
			if (this.selectedFile2) {
				this.selectedFile2.expanded = true;
			}
			this.loading = false;
		} else {
			node.children = [];
		}

		this.selectedFile2 = node;
		this.shared.searchTreeNodeSelected.next(node);
		this.shared.currentTreeSelectionNode = this.selectedFile2;

		if (node.children === undefined || node.children.length === 0) {
			// console.log('/////////INSIDE IF - LOADNODE EVENT////////////');
			this.loading = true;
			console.log('TREE CHILD PARAM I/P', node.data[0])
			this.service.getChild(node.data[0]).subscribe((data: TreeNode) => {
				// console.log('////////////SINGLE CALL TREE AND DATATABLE SUBSCRIBE/////////////////////////');
				this.shared.singleCallFlag = true;
				this.shared.singleCallTreeAndDataTable.next(data);
				// Not Need due to legacy doesnot have this functionality , creating tree view issue
				// if (data.data) {
				// 	node.data[0].label = data.data[0].label;
				// 	node.data[0].lockOwner = data.data[0].lockOwner;
				// 	node.data[0].version = data.data[0].version;
				// 	node.data[0].dateModified = data.data[0].dateModified;
				// 	node.data[0].owner = data.data[0].owner;
				// 	node.data[0].description = data.data[0].description;
				// 	node.label = data.data[0].label;
				// }
				if (data.children !== null) {
					if (node.data[0].typeLabel == "show") {
						this.labelindex = node.parent.children.findIndex(x => x.label === "Show More");
						// console.log("//////////////BEFORE SHOW MORE INDEX///////////////////", this.labelindex);
						if (this.labelindex) {
							node.parent.children.splice(this.labelindex, 1);
						}
						data.children.forEach(element =>
							node.parent.children.push({
								label: element.label,
								expandedIcon: element.expandedIcon,
								collapsedIcon: element.collapsedIcon,
								icon: element.icon,
								styleClass: element.styleClass,
								leaf: element.leaf,
								data: element.data,
								children: [],
							})
						);
						// console.log("//////////////AFTER SHOW MORE INDEX///////////////////", this.labelindex);
						// console.log("//////////////LENGTH///////////////////", node.parent.children.length);
						// var selectTreeNodeIndex = node.parent.children.findIndex(x => x.label === "Show More");
						// console.log("//////////////FINAL INDEX///////////////////", selectTreeNodeIndex);
						// console.log("//////////////NODE PARENT///////////////////", node.parent);
						// if (selectTreeNodeIndex != -1) {
						// 	this.selectedFile2 = node.parent.children[selectTreeNodeIndex];
						// } else {
						this.selectedFile2 = node.parent;
						// }
					} else {
						if (event.node !== undefined || (node.children === undefined || node.children.length === 0)) {
							node.children = [];
							data.children.forEach(element => {
								// console.log('////////NODE CHILDREN////////////',node.children);
								// console.log('////////ELEMENT DATA//////////////', element);
								node.children.push({
									label: element.label,
									expandedIcon: element.expandedIcon,
									collapsedIcon: element.collapsedIcon,
									icon: element.icon,
									styleClass: element.styleClass,
									leaf: element.leaf,
									data: element.data,
									children: [],
								});
							});
						}
					}
				}
				this.loading = false;
			}, (error) => {
				this.loading = false;
			});
			//Simultaneously load the table when a node is selected in the tree
			this.shared.treeDatatableId.emit({ item: this.item, data: node });//<!--FordChanges-->
		} else if (node.parent === undefined || Object.keys(node.parent).length != 0) {
			// console.log('/////////INSIDE ELSE - LOADNODE EVENT////////////');
			this.shared.singleCallFlag = false;
			this.shared.treeDatatableId.emit({ item: this.item, data: node });//<!--FordChanges-->
		}
	}
	/*****lazy loading *** */

	/**** node collapse *****/
	nodeCollapse(event) {
		console.log('//////////NODE COLLAPSE//////////////', event);
		this.selectedFile2 = event.node;
		this.response.data[0].id = 0;
		this.response.data[0].label = "l";
		//<!--FordChanges-->
		this.shared.treeDatatableId.emit({
			item: this.item, data: this.response.data[0].id + '&'
				+ this.response.data[0].label
		});
	}
	/**** node collapse *****/


	OnViewPropertieDialog = (data: any) => {
		console.log('tree input param for view:', data);
		this.viewPropertiesValue = new ViewProperties();

		if (data.length == 0) {
			this.viewPropertiesValue.size = 0;
		} else if (data.length > 1) {
			this.viewPropertiesValue.size = data.length;
		}
		console.log('get view property', data);
		console.log(data.subsquentId);
		this.subSequent = data.subsquentId;
		console.log(this.subSequent);
		this.viewPropertiesValue.itemId = data.id;
		this.viewPropertiesValue.type = data.type;
		this.viewPropertiesValue.label = data.label;
		this.viewPropertiesValue.itemType = data.itemType;
		this.viewPropertiesValue.nodeTypeLabel = data.typeLabel
		this.viewPropertiesValue.folderType = data.folderType;
		this.viewPropertiesValue.parentLabel = data.parentLabel;
		this.viewPropertiesValue.subsquentLabel = data.subsquentLabel;
		this.viewPropertiesValue.depth = data.depth;
		this.viewPropertiesValue.rootLabel = data.rootLabel;
		this.viewPropertiesValue.domain = data.domain;
		this.viewPropertiesValue.simpleFolderParent = data.simpleFolderParent;
		this.viewPropertiesValue.simpleFolderLabel = data.simpleFolderLabel;


		console.log('Tree View Input:', this.viewPropertiesValue);
		this.Properties.emit(this.viewPropertiesValue);
	};

	OnEditPropertieDialog = (data: any) => {
		console.log(data);
		this.editProperties.emit(data);
	};

	OncheckOutDialog(data: any) {
		console.log('//////////ON CHECK OUT DIALOG//////////', data);
		this.checkOutData = new CheckOut();
		if (data && data.id) {
			this.checkOutData.id = data.id;
			this.checkOutData.label = data.label;
			this.checkOutData.type = data.type;
			var extension = this.checkOutData.label.split('.').pop();
			console.log('Extension:', extension);
			console.log('Extension length:', extension.length);

			this.extensionCheck = this.getExtensionType(extension);
			console.log('this.extensionCheck:', this.extensionCheck);
			if ((this.checkOutData.type == '32') || (this.extensionCheck && this.checkOutData.type == '65')) {
				console.log('//////////File Download//////////');
				this.attemptCount = 0;
				this.downloadSize = '';
				//this.loaderdisplay = true;
				this.fileDownloadPreSignedUrl(this.checkOutData);
			} else {
				console.log('//////////Folder Download//////////');
				let id: any[] = [];
				id.push(this.checkOutData.id);
				var size: string = "";
				var sizeDetails: string = "";
				this.checkOutService.checkSizeBeforeCheckout(id).subscribe(resp => {
					console.log('resp:' + resp);
					sizeDetails = resp;

					size = sizeDetails.split("KB", 1)[0];
					console.log('size:' + size);
					size = size.replace(/,/g, '');
					var actualSize = parseFloat(size);

					var maxLimit = environment.folderDownloadMaxLimit;
					if (actualSize > maxLimit) {
						var marker = 1024; // Change to 1000 if required
						var decimal = 2; // Change as required
						var gigaBytes = marker * marker;
						var totalSize = (actualSize / gigaBytes).toFixed(decimal);
						var maxLimitSize = (maxLimit / gigaBytes).toFixed(0);
						this.checkoutSizeMessage = true;
						this.checkoutMessage = "The size (" + totalSize + " GB) of the folder(s)/file(s) you have selected for check out exceeds the maximum limit of "+maxLimitSize+" GB and cannot be downloaded.\n Please unselect some of the folder(s)/file(s) and try again.";
					}
					else if (actualSize <= maxLimit && actualSize > 2097153.0) {
						var marker = 1024; // Change to 1000 if required
						var decimal = 2; // Change as required
						var gigaBytes = marker * marker;
						var totalSize = (actualSize / gigaBytes).toFixed(decimal);
						console.log('email');
						this.checkoutSizeMessage = true;
						this.checkoutMessage = "Size:" + totalSize + " \n GB. Your total Check out size is more than 2 GB. This will take some time.\n So we will notify through email, once your download is ready.";
						this.checkOutService.checkoutSendEmail(this.checkOutData,totalSize).subscribe(data => {

						}, error => {
							console.log('Folder Download Error message', error);
							this.folderloaderdisplay = false;
							// if (error && error.status === 504) {
							// 	this.errorMsg = '';
							// 	this.errorMsg = 'Unable to download file/folder due to larger size';
							// 	this.fileDownloadErrorMsg = true;
							// }
						});
					}
					else {
						this.folderloaderdisplay = true;
						this.folderdownloadStop = this.checkOutService.checkOutNew(this.checkOutData).subscribe((resp: HttpResponse<CheckoutResponse>) => {
							if (resp) {
								console.log('resp.status:', resp.status)
								console.log('Response:', resp);
								const url = resp.body.url;
								// const file = new Blob([resp.body]);
								// const fileName = resp.headers.get('FileName') || 'download.zip';
								// saveAs(file, fileName);

								// Download the file from the URL
								this.downloadFileFromUrl(url);

								this.folderloaderdisplay = false;
								//console.log('Path:', resp.headers.get('Path'));
								this.folderloaderdisplay = false;

								this.tempPath = new TempPath();
								this.tempPath.path = url;
								console.log('tempPath:', this.tempPath);
								this.checkOutService.deleteTempPath(this.tempPath).subscribe(data => {
										console.log('Temp path deleted successfully.');
								},
									error => {
										console.log('error in deletepath:', error);
									});
							}
						}, error => {
							console.log('Folder Download Error message', error);
							this.folderloaderdisplay = false;
							if (error && error.status === 504) {
								this.errorMsg = '';
								this.errorMsg = 'Unable to download file/folder due to larger size';
								this.fileDownloadErrorMsg = true;
							}
						});
					}

				});
			}
		}
	}
	
	private downloadFileFromUrl(url: string) {
		// Create an anchor element and programmatically click it to trigger the download
		const a = document.createElement('a');
		a.href = url;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}

	stopFolderDownload() {
		this.stopDownloadFile = false;
		this.folderloaderdisplay = false;
		this.errorMsg = "File/Folder Download Cancelled.";
		this.fileDownloadErrorMsg = true;
		this.folderdownloadStop.unsubscribe();
	}

	OncheckInDialog = (data: any) => {
		console.log("data", data);
		this.isVirtualFolder(data);
	};

	isVirtualFolder(selectedNode) {
		// var virtualFolder = false;
		if (selectedNode) {
			if (selectedNode.type == 1003) {
				// virtualFolder = true;
				console.log('CheckInError');
				this.checkInError.emit(true);
			} else {
				this.getParentFolderForFiles(selectedNode.id);
			}

		}
	}

	getParentFolderForFiles(node: string) {
		if (node) {
			var selectedNode;
			this.checkInService.getParentFolderForFiles(node).subscribe((data: CheckInGetParentDetails) => {
				console.log('getParentFolderForFiles --> ', data);
				this.checkInGetParentDetails = new CheckInGetParentDetails();
				this.checkInGetParentDetails = data;
				if (this.checkInGetParentDetails.childItemDEType == 32) {// || (this.checkInGetParentDetails.childType == 65 && node.data.actualItemType == 32)) {
					selectedNode = this.checkInGetParentDetails.parentItemDEId;
					console.log('this.checkinService.selectedNodeItemId--->', this.checkInService.selectedNodeItemId);
					console.log('this.checkinService.selectedNodeItemId selectedNode--->', selectedNode);
					this.doInitialUpload(selectedNode);
				} else {
					this.doInitialUpload(node);
				}
			})
		}
	}

	doInitialUpload(node: string) {
		console.log("DO initial Upload node===>", node);
		this.checkInService.doInitialUpload(node).subscribe((event) => {
			if (event instanceof HttpResponse) {
				console.log('doInitialUpload', event);
				var result = event.body;
				if (result === 'success') {
					this.checkInService.selectedNodeItemId = node;
					console.log('this.checkInService.selectedNodeItemId', this.checkInService.selectedNodeItemId);
					this.checkIn.emit(true);
				} else if (result === 'CheckInError') {
					console.log('CheckInError');
					this.checkInError.emit(true);
				} else if (result === 'APDMError') {
					console.log('APDMError');
					this.apdmError.emit(true);
				}
			}
		});
	}

	closeCheck(event: any) {
		if (!event) {
			this.checkoutDisplay = event;
		}
	}


	OndeleteDialog = (data: any) => {
		console.log("OndeleteDialog***************", data);
		console.log("data***************", data.id);
		var selectedNode = null;

		selectedNode = data;
		var allIds = "";
		var excludeIds = "";

		if (selectedNode.subsquentId) {
			if (selectedNode.subsquentId === 'NO') {
				alert("Top-Level items may not be deleted");
				return false;
			}
		}

		if (selectedNode.type == 1003) {
			excludeIds += " <br/>" + selectedNode.label;
		} else {
			allIds = selectedNode.id + ",";
		}

		console.log('<=======allIds====excludeIds======>', allIds, excludeIds);
		var pseudoEvent = { node: this.treeNodeRightClickEvent.node.parent };
		if (selectedNode.parentLabel == "Recycle Bin" || selectedNode.rootLabel == "Recycle Bin") {
			if (excludeIds.length > 0) {
				alert("The following Vault Items cannot be deleted " + excludeIds);
			} else {
				this.deleteService.doPreDelete(allIds).subscribe((data) => {
					console.log('doPreDelete data----->', data);
					if (data.result) {
						this.callBack1(data.result.deleteItemApi.message, pseudoEvent);
					} else {
						alert(data.error.errorCode);
						if (this.shared.trees[this.shared.selectedTab].selectedFile2) {
							this.shared.trees[this.shared.selectedTab].selectedFile2.children = [];
							this.shared.trees[this.shared.selectedTab].loadNode(this.shared.trees[this.shared.selectedTab].selectedFile2);
						} else if (this.shared.trees[this.shared.selectedTab].selectedNodeRetention) {
							this.shared.trees[this.shared.selectedTab].selectedNodeRetention.children = [];
							this.shared.trees[this.shared.selectedTab].loadNode(this.shared.trees[this.shared.selectedTab].selectedNodeRetention);
						}
					}
				});
				// YAHOO.util.Connect.asyncRequest('GET', 'deleteItemAction?actionID=doPreDelete&itemIds=' + allIds, callback1, null);
			}
		} else {
			if (excludeIds.length > 0) {
				alert("The following Vault Items cannot be deleted " + excludeIds);
			} else {
				this.deleteService.doPreMarkDelete(allIds).subscribe((data) => {
					console.log('doPreMarkDelete data----->', data);
					if (data.result) {
						this.callBack1(data.result.deleteItemApi.message, pseudoEvent);
					} else {
						alert(data.error.errorCode);
						if (this.shared.trees[this.shared.selectedTab].selectedFile2) {
							this.shared.trees[this.shared.selectedTab].selectedFile2.children = [];
							this.shared.trees[this.shared.selectedTab].loadNode(this.shared.trees[this.shared.selectedTab].selectedFile2);
						} else if (this.shared.trees[this.shared.selectedTab].selectedNodeRetention) {
							this.shared.trees[this.shared.selectedTab].selectedNodeRetention.children = [];
							this.shared.trees[this.shared.selectedTab].loadNode(this.shared.trees[this.shared.selectedTab].selectedNodeRetention);
						}
					}
				});
				// YAHOO.util.Connect.asyncRequest('GET', 'deleteItemAction?actionID=doPreMarkDelete&itemIds=' + allIds, callback1, null);
			}
		}
	};

	OnRefreshFromDatabase = (data: any) => {
		// console.log('////////OnRefreshFromDatabase//////////', this.shared.trees[this.shared.selectedTab].selectedFile2);
		if (this.shared.trees[this.shared.selectedTab].selectedFile2) {
			if (this.shared.trees[this.shared.selectedTab].selectedFile2.data[0].type.toString() === '32' || this.shared.trees[this.shared.selectedTab].selectedFile2.data[0].type.toString() === '64') {
				// console.log('/////////////OnRefreshFromDatabase////////1////////////');
				this.shared.trees[this.shared.selectedTab].selectedFile2.parent.children = [];
				this.shared.trees[this.shared.selectedTab].loadNode(this.shared.trees[this.shared.selectedTab].selectedFile2.parent);
			} else {
				// console.log('/////////////OnRefreshFromDatabase////////2////////////');
				this.shared.trees[this.shared.selectedTab].selectedFile2.children = [];
				this.shared.trees[this.shared.selectedTab].loadNode(this.shared.trees[this.shared.selectedTab].selectedFile2);
			}
		}
		// this.loadNode(this.treeNodeRightClickEvent);
	};

	OnCopyDialog = (data: any) => {
		console.log("copySourceId", data.id);
		console.log("pasteType", this.shared.pasteType);
		//alert("In Copy");
		var selectedTree = data;
		// var rootNode = selectedTree.selectedNode;
		// var depth = rootNode.depth;
		var allItemIds = "";

		// if (depth != 0) {
		//   for (var i = 0; i < depth; i++) {
		//     rootNode = rootNode.parent
		//   }
		// }
		// selectedRowIDs = selectedTree.dataTable.getSelectedRows();
		var allFlag = true;
		var usrMessage = "";

		if (selectedTree) {

			var nodeType = selectedTree.type;
			var nodeTypeLabel = selectedTree.typeLabel;
			// label = selectedTree.selectedNode.data.label;
			// selectedID = selectedTree.selectedNode.data.id;


			//alert(nodeType + ":::" + nodeTypeLabel + ":::" + selectedTree.selectedNode.parent.isRoot());
			if ((nodeType == 1003 && nodeTypeLabel == "CPSC Domain") ||
				(nodeType == 1003 && nodeTypeLabel == "Prefix") ||
				(nodeType == 1003 && nodeTypeLabel == "Part Base") ||
				(nodeType == 1003 && nodeTypeLabel == "Program") ||
				(nodeType == 1003 && nodeTypeLabel == "Trans Program") ||
				(nodeType == 1003 && nodeTypeLabel == "Drive Train") ||
				(nodeType == 1003 && nodeTypeLabel == "Design Team") ||
				(nodeType == 1003 && nodeTypeLabel == "Vehicle Program") ||
				(nodeType == 1003 && nodeTypeLabel == "Model Year") ||
				(nodeType == 1003 && nodeTypeLabel == "All") ||
				(nodeType == 1003 && nodeTypeLabel == "V Engine Program") ||
				(nodeType == 1003 && nodeTypeLabel == "I Engine Program") ||
				(nodeType == 1003 && nodeTypeLabel == "Displacement")) {
				allFlag = false;

			}
			//alert(nodeType + ":::" + nodeTypeLabel + ":::" + allFlag);
			if (allFlag == true) {
				allItemIds = selectedTree.id + ",";
			}
			else {
				usrMessage += "\n" + selectedTree.label;
			}
		} else {
			// DataTable
			// var i;
			// for (i = 0; i < selectedRowIDs.length; i++) {
			//   var record = selectedTree.dataTable.getRecord(selectedRowIDs[i]);

			//   nodeType = record.getData().type;
			//   nodeTypeLabel = record.getData().typeLabel;
			//   label = record.getData().label;
			//   selectedID = record.getData().id;
			//   //alert(nodeType + ":::" + nodeTypeLabel);
			//   if ((nodeType == 1003 && selectedTree.selectedNode.data.typeLabel == "CPSC Domain") ||
			//     (nodeType == 1003 && selectedTree.selectedNode.data.typeLabel == "Prefix") ||
			//     (nodeType == 1003 && selectedTree.selectedNode.data.typeLabel == "Part Base") ||
			//     (nodeType == 1003 && nodeTypeLabel == "Program") ||
			//     (nodeType == 1003 && nodeTypeLabel == "Trans Program") ||
			//     (nodeType == 1003 && nodeTypeLabel == "Drive Train") ||
			//     (nodeType == 1003 && nodeTypeLabel == "Design Team") ||
			//     (nodeType == 1003 && nodeTypeLabel == "Vehicle Program") ||
			//     (nodeType == 1003 && nodeTypeLabel == "Model Year") ||
			//     (nodeType == 1003 && nodeTypeLabel == "All") ||
			//     (nodeType == 1003 && nodeTypeLabel == "Program") ||
			//     (nodeType == 1003 && nodeTypeLabel == "V Engine Program") ||
			//     (nodeType == 1003 && nodeTypeLabel == "I Engine Program") ||
			//     (nodeType == 1003 && nodeTypeLabel == "Displacement")) {
			//     allFlag = false;

			//   }
			//   if (allFlag == true) {
			//     allItemIds += record.getData().id + ",";
			//   }
			//   else {
			//     usrMessage += "\n" + record.getData().label;
			//   }
			// }
		}
		this.shared.pasteType = "COPY";
		this.shared.copyIds = allItemIds;
		//alert("copyIds");
		if (allFlag == false) {
			alert("The following item can not be copied: \n \n" + usrMessage);
		}
	};


	callBack1(resultData: any, parentNode: any) {
		console.log('/// Start Callback 1 ///', this.TreeNodeData);
		// var delItems_details: any[] = [];
		var allIds = "";
		var excludeIds = "";

		if (this.TreeNodeData.type == 1003) {
			excludeIds += " <br/>" + this.TreeNodeData.label;
		} else {
			allIds = this.TreeNodeData.id + ",";
		}
		// var delItems_details = null;
		var resultStr = "";
		if (resultData) {//if (resultData === 'success') {
			console.log('/// success ///');
			this.deleteItemResponse = <DeleteItemResponse[]>JSON.parse(resultData);
			console.log(this.deleteItemResponse);
			console.log('/// this.deleteItemResponse length///', this.deleteItemResponse.length);
			if (this.deleteItemResponse != null && (this.TreeNodeData.parentLabel != "Recycle Bin" && this.TreeNodeData.rootLabel != "Recycle Bin")) {

				resultStr += "The following VAULT";

				if (this.deleteItemResponse.length == 1) {
					resultStr += " Item ";
				} else if (this.deleteItemResponse.length > 1) {
					resultStr += " Items ";
				}
				resultStr += "will be deleted:" + '\n\n';

				for (let delItem of this.deleteItemResponse) {
					console.log("delItem....", delItem);
					resultStr += delItem.label + '\n';
					var itemCount = delItem.count;
					if (itemCount == 1) {

					} else if (itemCount == 2) {
						resultStr += " (Warning : contains " + (itemCount - 1) + " Item )" + '\n';
					} else if (itemCount > 2) {
						resultStr += " (Warning : contains " + (itemCount - 1) + " Items )" + '\n';
					}
				}
				resultStr += "" + '\n\n' + "Do you want to DELETE it?"
				console.log('/// resultStr ///', resultStr);
				if (resultStr.length > 0) {
					var r;
					if (resultStr.length > 0) {
						r = window.confirm(resultStr);
					}
					if (r == true) {
						this.deleteService.doMarkDelete(allIds).subscribe((data) => {
							console.log('doMarkDelete accept---->', data)
							if (data.result) {
								alert(data.result.deleteItemApi.message);
								// Refresh the tree node after delete
								this.loadNode(parentNode);
							}
						});
					} else {
						console.log('doMarkDelete reject---->')
					}
				}
			} else if (this.deleteItemResponse != null && (this.TreeNodeData.parentLabel == "Recycle Bin" || this.TreeNodeData.rootLabel == "Recycle Bin")) {
				resultStr += "The following VAULT";
				if (this.deleteItemResponse.length == 1) {
					resultStr += " Item ";
				} else if (this.deleteItemResponse.length > 1) {
					resultStr += " Items ";
				}
				resultStr += "will be deleted:" + '\n\n';
				for (let delItem of this.deleteItemResponse) {
					resultStr += delItem.label + '\n';
				}
				resultStr += "" + '\n\n' + "Do you want to DELETE it?"
				if (resultStr.length > 0) {
					var r;
					if (resultStr.length > 0) {
						r = confirm(resultStr);
					}
					if (r == true) {
						this.deleteService.doDelete(allIds).subscribe((data) => {
							console.log('doDelete accept---->', data)
							if (data.result) {
								alert(data.result.deleteItemApi.message);
								// Refresh the tree node after delete
								console.log("refresh", parentNode);
								this.loadNode(parentNode);
							} else {
								// alert();
							}
						});
						// var deleteUrl1 = 'deleteItemAction?actionID=doDelete&itemIds=' + allIds;
						// YAHOO.util.Connect.asyncRequest('GET', deleteUrl1, callback2, null);
					} else {
						console.log('doDelete reject---->')
					}
				}
			}
		} else {
			alert("Error while Deleting");
		}
		console.log('/// END Callback ///');
	}

	OnCutDialog = (data: any) => {
		console.log("cutSourceId", data.id);
		var selectedTree = data;//getActualSelectedTree();
		// var rootNode = selectedTree.selectedNode;
		// var depth = rootNode.depth;
		var allItemIds = "";

		// if (depth != 0) {
		//   this.cutNode = selectedTree.selectedNode.parent;
		//   for (var i = 0; i < depth; i++) {
		//     rootNode = rootNode.parent
		//   }
		// } else {
		//   this.cutNode = null;
		// }
		// DataTable
		// selectedRowIDs = selectedTree.dataTable.getSelectedRows();
		var allFlag = true;
		var usrMessage = "";
		if (selectedTree) {

			var nodeType = selectedTree.type;
			var nodeTypeLabel = selectedTree.typeLabel;
			// label = selectedTree.selectedNode.data.label;
			// selectedID = selectedTree.selectedNode.data.id;

			if ((nodeType == 1003 && nodeTypeLabel == "CPSC Domain") ||
				(nodeType == 1003 && nodeTypeLabel == "Prefix") ||
				(nodeType == 1003 && nodeTypeLabel == "Part Base") ||
				(nodeType == 1003 && nodeTypeLabel == "Program") ||
				(nodeType == 1003 && nodeTypeLabel == "Trans Program") ||
				(nodeType == 1003 && nodeTypeLabel == "Drive Train") ||
				(nodeType == 1003 && nodeTypeLabel == "Design Team") ||
				(nodeType == 1003 && nodeTypeLabel == "Vehicle Program") ||
				(nodeType == 1003 && nodeTypeLabel == "Model Year") ||
				(nodeType == 1003 && nodeTypeLabel == "All") ||
				(nodeType == 1003 && nodeTypeLabel == "Program") ||
				(nodeType == 1003 && nodeTypeLabel == "V Engine Program") ||
				(nodeType == 1003 && nodeTypeLabel == "I Engine Program") ||
				(nodeType == 1003 && nodeTypeLabel == "Displacement")) {
				allFlag = false;

			}
			if (allFlag == true) {
				allItemIds = selectedTree.id + ",";//selectedTree.selectedNode.data.id + ",";
			}
			else {
				usrMessage += "\n" + selectedTree.label;
			}
		} else {
			//DataTable
			// var i;
			// for (i = 0; i < selectedRowIDs.length; i++) {
			//   var record = selectedTree.dataTable.getRecord(selectedRowIDs[i]);

			//   nodeType = record.getData().type;
			//   nodeTypeLabel = record.getData().typeLabel;
			//   label = record.getData().label;
			//   selectedID = record.getData().id;

			//   if ((nodeType == 1003 && selectedTree.selectedNode.data.typeLabel == "CPSC Domain") ||
			//     (nodeType == 1003 && selectedTree.selectedNode.data.typeLabel == "Prefix") ||
			//     (nodeType == 1003 && selectedTree.selectedNode.data.typeLabel == "Part Base") ||
			//     (nodeType == 1003 && nodeTypeLabel == "Program") ||
			//     (nodeType == 1003 && nodeTypeLabel == "Trans Program") ||
			//     (nodeType == 1003 && nodeTypeLabel == "Drive Train") ||
			//     (nodeType == 1003 && nodeTypeLabel == "Design Team") ||
			//     (nodeType == 1003 && nodeTypeLabel == "Vehicle Program") ||
			//     (nodeType == 1003 && nodeTypeLabel == "Model Year") ||
			//     (nodeType == 1003 && nodeTypeLabel == "All") ||
			//     (nodeType == 1003 && nodeTypeLabel == "Program") ||
			//     (nodeType == 1003 && nodeTypeLabel == "V Engine Program") ||
			//     (nodeType == 1003 && nodeTypeLabel == "I Engine Program") ||
			//     (nodeType == 1003 && nodeTypeLabel == "Displacement")) {
			//     allFlag = false;

			//   }
			//   if (allFlag == true) {
			//     allItemIds += record.getData().id + ",";
			//   }
			//   else {
			//     usrMessage += "\n" + record.getData().label;
			//   }

			// }
		}
		this.shared.pasteType = "CUT";
		this.shared.copyIds = allItemIds;
		if (allFlag == false) {
			alert("The following item can not be cut: \n \n" + usrMessage)
		}
	};


	OnPasteDialog = (data: any) => {
		console.log("pasteDestinationId", data.id);
		console.log("pasteType", this.shared.pasteType);
		// display_load_content();
		var selectedTree = data;//getActualSelectedTree();
		var rootNode = selectedTree.selectedNode;
		// var depth = rootNode.depth;
		var allItemIds = "";

		// if (depth != 0) {
		//   for (var i = 0; i < depth; i++) {
		//     rootNode = rootNode.parent
		//   }
		// }
		// Datatable
		// selectedRowIDs = selectedTree.dataTable.getSelectedRows();

		if (selectedTree) {
			allItemIds = selectedTree.id + ",";
		} else {
			//Datatable
			// var i;
			// for (i = 0; i < selectedRowIDs.length; i++) {
			//   var record = selectedTree.dataTable.getRecord(selectedRowIDs[i]);
			//   allItemIds += record.getData().id + ",";
			// }
		}
		var delItems_details = null;
		var resultStr = "";

		//Datatable
		// if (selectedRowIDs.length > 1) {
		//   alert('Your Must select one folder in the APDM Vault to paste.');
		//   return;
		// }
		var sUrl = "";
		if (this.shared.pasteType == 'COPY') {
			// sUrl = "../do/copyItemAction?actionID=copyFile&srcItemIds=" + this.shared.copyIds + "&destItemIds=" + allItemIds;
			this.shared.showProgress(true);
			this.copyPasteService.doCopyFile(this.shared.copyIds, allItemIds).subscribe((data) => {
				console.log('accept---->', data)
				this.onPasteCallBack(data);
			});
		}
		if (this.shared.pasteType == 'CUT') {
			// sUrl = "../do/copyItemAction?actionID=cutPasteFile&srcItemIds=" + this.shared.copyIds + "&destItemIds=" + allItemIds;
			this.shared.showProgress(true);
			this.copyPasteService.doCutPasteFile(this.shared.copyIds, allItemIds).subscribe((data) => {
				console.log('accept---->', data)
				this.onPasteCallBack(data);
			});
		}

	};

	onPasteCallBack(data: any) {
		// success: function (oResponse) {
		// remove_load_content();
		var paste_creation = data;
		// if (paste_creation.result) {
		//   alert(paste_creation.result.cutPasteItemApi.message);
		// } else if (paste_creation.error) {
		//   alert(paste_creation.error.errorCode);
		// }
		console.log("onPasteCallBack", paste_creation);
		this.shared.showProgress(false);
		if (paste_creation.result) {
			var api = paste_creation.result.cutPasteItemApi === undefined ? paste_creation.result.copyPasteItemApi : paste_creation.result.cutPasteItemApi;
			this.shared.showAlert(api.message);
			// Refresh the node after successful paste operation
			console.log("tree-view - onPasteCallBack() ===> ", this.treeNodeRightClickEvent);
			this.loadNode(this.treeNodeRightClickEvent);
		}
		else if (paste_creation.error) {
			this.shared.showAlert(paste_creation.error.errorCode);
		}
		// resetNode(getTopWindow().selectedTree.selectedNode);
		if (this.cutNode != null) {
			// resetNode(cutNode);
			this.cutNode = null;
		}
		// },
		// failure: function (oResponse) {
		//   // remove_load_content();
		//   var paste_creation = oResponse.responseText;
		//   if (paste_creation.trim().length > 0) {
		//     alert(paste_creation);
		//   }
		//   // resetNode(getTopWindow().selectedTree.selectedNode);
		// }
	}

	OnPasteShortCutDialog = (data: any) => {
		console.log("OnPasteShortCutDialog", data.id);
		var selectedTree = data;//getActualSelectedTree();
		// var rootNode = selectedTree.selectedNode;
		// var depth = rootNode.depth;
		var allItemIds = "";

		// if (depth != 0) {
		//   for (var i = 0; i < depth; i++) {
		//     rootNode = rootNode.parent
		//   }
		// }
		// Datatable
		// selectedRowIDs = selectedTree.dataTable.getSelectedRows();

		if (selectedTree) {
			var a = "";
			if (selectedTree.id == "88")
				a = selectedTree.selectedNode.data.actualItemId;
			else
				a = selectedTree.id;
			allItemIds = a + ",";
		} else {
			// Datatable
			// var i;
			// for (i = 0; i < selectedRowIDs.length; i++) {
			//   var record = selectedTree.dataTable.getRecord(selectedRowIDs[i]);
			//   allItemIds += record.getData().id + ",";
			// }
		}

		// DataTable
		// if (selectedRowIDs.length > 1) {
		//   alert('Your Must select one folder in the APDM Vault to paste your short cut to.');
		//   return;
		// }
		// var sUrl = "../rest/pasteShortcut/" + copyIds + "/" + allItemIds + "/";
		this.shared.showProgress(true);
		this.copyPasteService.doPasteShortcut(this.shared.copyIds, allItemIds).subscribe((data) => {
			console.log('accept---->', data)
			this.onPasteShortCutCallBack(data);
		});
		// YAHOO.util.Connect.asyncRequest('GET', sUrl, callback2, null);
	};

	onPasteShortCutCallBack(data: any) {
		// var callback2 = {
		//   success: function (oResponse) {
		var shortcut_creation = data;

		this.shared.showProgress(false);
		if (shortcut_creation.result) {
			var api = shortcut_creation.result.copyPasteItemApi === undefined ? shortcut_creation.result.cutPasteItemApi : shortcut_creation.result.copyPasteItemApi;
			console.log("shortcut_creation ==>", shortcut_creation.result);
			this.shared.showAlert(api.message);
			this.loadNode(this.treeNodeRightClickEvent);
		}
		else if (shortcut_creation.error) {
			this.shared.showAlert(shortcut_creation.error.errorCode);
			// alert(shortcut_creation.error.errorCode);
		}
		// if (shortcut_creation.result) {
		//   alert(shortcut_creation.result.copyPasteItemApi.message);
		// } else {
		//   alert(shortcut_creation.error.errorCode);
		// }
		// resetNode(getTopWindow().selectedTree.selectedNode);
		// },
		// failure: function (oResponse) {
		//   if (oResponse.responseText.length > 0) {
		//     alert(oResponse.responseText);
		//   }

		// }
		// };
	}

	OnRestoreItemDialog = (data: any) => {
		this.rightClickType = "restore";
		// display_load_content();
		console.log('OnRestoreItemDialog data----->', data);
		var selectedTree = data;//getActualSelectedTree();
		// var rootNode = selectedTree.selectedNode;
		// var depth = rootNode.depth;
		var allItemIds = "";

		// if (depth != 0) {
		//   for (var i = 0; i < depth; i++) {
		//     rootNode = rootNode.parent
		//   }
		// }
		// DataTable
		// selectedRowIDs = selectedTree.dataTable.getSelectedRows();

		if (selectedTree) {
			allItemIds = selectedTree.id + ",";
		} else {
			// DataTable
			// //alert(" Ids... : "+selectedRowIDs);
			// setSelectedNode(getActualSelectedTree(), getSelectedNode());
			// var i;
			// for (i = 0; i < selectedRowIDs.length; i++) {
			//   var record = selectedTree.dataTable.getRecord(selectedRowIDs[i]);
			//   allItemIds += record.getData().id + ",";
			//   //alert("Id : "+allIds);
			// }
		}
		//alert(" All Ids "+allItemIds);
		// display_load_content();
		// alert(" rootNode  Val :" + rootNode.data.label);

		if (selectedTree.parentLabel == "Recycle Bin" || selectedTree.rootLabel == "Recycle Bin") {
			//	alert(rootNode.data.label + " Restore items from Recycle Bin calling .."+ allItemIds);
			// YAHOO.util.Connect.asyncRequest('GET', 'deleteItemAction?actionID=doPreRestore&itemIds=' + allItemIds, callback2, null);
			this.deleteService.doPreRestore(allItemIds).subscribe((data) => {
				console.log('doPreRestore data----->', data);
				if (data.result) {
					this.OnRestoreItemCallback2(data, allItemIds);
				} else {
					if (data.error) {
						alert(data.error.errorCode);
					}
				}
			});
			//	alert("After pre-restore Request "+ allItemIds);
		}
	};

	OnRestoreItemCallback2(data: any, allItemIds: any) {
		console.log('<---OnRestoreItemCallback2--->', data);
		var delItems_details = null;
		var resultStr = "";
		// success: function (oResponse) {
		// remove_load_content();
		delItems_details = data;//YAHOO.lang.JSON.parse(oResponse.responseText);
		var delItem = null;
		for (var i = 0; i < delItems_details.length; i++) {
			delItem = delItems_details[i];
		}
		//alert(" Root Label:  "+ rootNode.data.label+" parent Folder :" + delItem.label)
		if (this.TreeNodeData.parentLabel == "Recycle Bin" || this.TreeNodeData.rootLabel == "Recycle Bin") {
			//	alert(" If Block");
			//alert(" delItems_details "+ delItems_details.length);
			resultStr += "APDM will attempt to restore all files and folders to their original" + '\n';
			resultStr += " location. If for some reason that's not possible, APDM will " + '\n';
			resultStr += "put them in the Restore Bin with the names changed to <itemID>_<name>." + '\n';
			resultStr += "The last know path will be added to the Description field of each item." + '\n\n';
			resultStr += "" + '\n\n' + "Do you want to RESTORE?"
			var r;
			// alert(" ALl IDs Len :: " +allItemIds.length);
			if (allItemIds.length > 0) {
				r = confirm(resultStr);
			}
			if (r == true) {
				// display_load_content();
				// YAHOO.util.Connect.asyncRequest('GET', 'deleteItemAction?actionID=doRestore&itemIds=' + allItemIds, callback3, null);
				this.deleteService.doRestore(allItemIds).subscribe((data) => {
					console.log('doRestore data----->', data);
					if (data.result) {
						this.OnRestoreItemCallBack3(data);
					} else {
						if (data.error) {
							alert(data.error.errorCode);
						}
					}
				});
				//resetNode(getActualSelectedTree().selectedNode);
			} else {
				alert("Cancelling the Restore operation  ");
			}
		}
	}

	OnRestoreItemCallBack3(data: any) {
		var msg = data;
		if (msg.result) {
			alert(msg.result.restoreItemApi.message);
			// this.loadNode(this.treeNodeRightClickEvent);
			if (this.shared.trees[this.shared.selectedTab].selectedFile2) {
				// console.log('/////////////OnRefreshFromDatabase////////1////////////');
				this.shared.trees[this.shared.selectedTab].selectedFile2.parent.children = [];
				this.shared.trees[this.shared.selectedTab].loadNode(this.shared.trees[this.shared.selectedTab].selectedFile2.parent);
			}
		} else {
			if (msg.error) {
				alert(msg.error.errorCode);
				// this.loadNode(this.treeNodeRightClickEvent);
				if (this.shared.trees[this.shared.selectedTab].selectedFile2) {
					// console.log('/////////////OnRefreshFromDatabase////////1////////////');
					this.shared.trees[this.shared.selectedTab].selectedFile2.parent.children = [];
					this.shared.trees[this.shared.selectedTab].loadNode(this.shared.trees[this.shared.selectedTab].selectedFile2.parent);
				}
			}
		}
	}

	ngOnDestroy() {
		if (this.subsciption) {
			this.subsciption.unsubscribe();
		}
		if (this.subsciptiontree) {
			this.subsciptiontree.unsubscribe();
		}
		if (this.subsciptionsync) {
			this.subsciptionsync.unsubscribe();
		}
		if (this.checkOutSubscibe) {
			this.checkOutSubscibe.unsubscribe();
		}
		if (this.commonDownload) {
			this.commonDownload.unsubscribe();
		}

		if (this.resetTreeTableOnDataSelect) {
			this.resetTreeTableOnDataSelect.unsubscribe();
		}
		if (this.resetTreeTableOnDataSelectfilemenu) {
			this.resetTreeTableOnDataSelectfilemenu.unsubscribe();
		}
		if (this.treeunchecksinglesync) {
			this.treeunchecksinglesync.unsubscribe();
		}
		// unhighlight changes on single click
		if (this.treesinglesync) {
			this.treesinglesync.unsubscribe();
		}
	}

	checkViewChild() {
		console.log('checkViewChild successfully executed.');
	}

	informOthersOnTabChange(event: any) {
		console.log('//////////INFORM OTHERS ON TAB CHANGE////////////////');
		if (event.node) {
			this.shared.defaultNode = '';
			this.shared.defaultOnChange = '';
			this.shared.defaultOnChange = event.node
		}
		console.log('///////////////////////TAB CHANGE EVENT//////////////////', event);
		console.log('/////////////SHARED DEFAULT ON CHANGE////////////////////', this.shared.defaultOnChange);

		this.shared.treeNodeSelectedEmitter.emit(event.node);
		this.shared.searchTreeNodeSelected.next(event);
		this.shared.TreeNode(event);
		this.shared.treeSelectedEmitter.emit(event);
		this.shared.dataTableSelectedEmitter.emit(null);
		this.shared.rowSelectDatatableValue.next(null);

		// ADDED BY MPRABH24 ON 25/5/2020 FOR "Datatable is not freshing between the Tab"
		this.loadNode(this.shared.defaultOnChange);
		// ADDED BY MPRABH24 ON 25/5/2020 FOR "Datatable is not freshing between the Tab"

		//File Menu Checkout
		if (event.node) {
			if (event.node.data) {
				// console.log('event.node.data', event.node.data[0]);
				this.checkOutFile = new CheckOut();
				this.checkOutFile.id = event.node.data[0].id;
				this.checkOutFile.label = event.node.data[0].label;
				this.checkOutFile.type = event.node.data[0].type;
				this.checkOutService.fileMenuCheckOut = this.checkOutFile;
			}
		}
	}

	loadDefaultNode(treeBaseId: any, fileIdOrLabel: any) {
		this.shared.defaultNode = '';
		this.shared.defaultOnChange = '';
		console.log("typeof(fileIdOrLabel)", typeof (fileIdOrLabel));
		if (this.treebaseID != treeBaseId) {
			return;
		}

		if (typeof (fileIdOrLabel) == "string") {
			for (let file of this.files) {
				console.log("file.data[0].label", file.data[0].label);
				console.log('File :', file);
				if (file.data[0].label == fileIdOrLabel) {
					this.selectedFile2 = file;
					console.log("select node", this.selectedFile2);
					this.shared.defaultNode = '';
					if (this.selectedFile2) {
						this.shared.defaultNode = this.selectedFile2;
					}
					this.loadNode(this.selectedFile2);
					break;
				}
			}
		} else if (typeof (fileIdOrLabel) == "number") {
			for (let file of this.files) {
				console.log("file.data[0].id", file.data[0].id)
				if (file.data[0].id == fileIdOrLabel) {
					this.selectedFile2 = file;
					console.log("select node", this.selectedFile2);
					this.shared.defaultNode = '';
					if (this.selectedFile2) {
						this.shared.defaultNode = this.selectedFile2;
					}
					this.loadNode(this.selectedFile2);
					break;
				}
			}
		}
		console.log('this.shared.defaultNode tree view :', this.shared.defaultNode);
	}

	stopDownloadFunc() {
		this.stopDownloadFile = true;
	}

	fileDownloadPreSignedUrl(checkOutData: CheckOut) {
		console.log('inside the fileDownloadPreSignedUrl method:', checkOutData)
		this.checkOutService.fileCheckOutPreSignedUrl(checkOutData).subscribe((resp: FileDownloadUrl) => {
			console.log('resp', resp);
			console.log('resp.url:', resp.url);
			console.log('resp.errorMsg:', resp.errorMsg);
			this.percentDone = 0;
			this.fileSize = '0';
			this.totalSize = '0';
			if (resp.url && (resp.urlType == '64' || resp.url.includes('htm'))) {
				window.open(resp.url, "_blank");
			} else if (resp && resp.url && !(resp.url.includes('htm'))) {

				this.req = new HttpRequest('GET', resp.url, {
					reportProgress: true,
					observe: 'response',
					responseType: 'blob',
				});

				console.log('req:', this.req);
				//this.http.request(this.req).
				let checkOutunSubscibe = this.http.request(this.req).subscribe((event: HttpEvent<any>) => {
					console.log('first event:', event);

					this.loaderdisplay = true;
					//this.downloadSize = ''

					if (event.type === HttpEventType.DownloadProgress) {
						if (this.stopDownloadFile) {
							this.stopDownloadFile = false;
							this.loaderdisplay = false;
							this.errorMsg = "File/Folder Download Cancelled.";
							this.fileDownloadErrorMsg = true;
							checkOutunSubscibe.unsubscribe();
						}
						else {
							this.percentDone = Math.round(100 * event.loaded / event.total);
							//console.log('Percent:', this.percentDone);
							var marker = 1024; // Change to 1000 if required
							var decimal = 2; // Change as required
							var kiloBytes = marker; // One Kilobyte is 1024 bytes
							var megaBytes = marker * marker; // One MB is 1024 KB
							var gigaBytes = marker * marker * marker; // One GB is 1024 MB
							var teraBytes = marker * marker * marker * marker; // One TB is 1024 GB
							if (event.total) {
								if (event.total < kiloBytes)
									this.totalSize = event.total + " Bytes";
								else if (event.total < megaBytes)
									this.totalSize = (event.total / kiloBytes).toFixed(decimal) + " KB";
								else if (event.total < gigaBytes)
									this.totalSize = (event.total / megaBytes).toFixed(decimal) + " MB";
								else
									this.totalSize = (event.total / gigaBytes).toFixed(decimal) + " GB";
							}

							if (event.loaded) {
								if (event.loaded < kiloBytes)
									this.fileSize = event.loaded + " Bytes";
								else if (event.loaded < megaBytes)
									this.fileSize = (event.loaded / kiloBytes).toFixed(decimal) + " KB";
								else if (event.loaded < gigaBytes)
									this.fileSize = (event.loaded / megaBytes).toFixed(decimal) + " MB";
								else
									this.fileSize = (event.loaded / gigaBytes).toFixed(decimal) + " GB";

							}


							// console.log('totalSize:', this.totalSize);
							// console.log('this.fileSize:', this.fileSize);
							this.downloadSize = this.fileSize + ' / ' + this.totalSize;
						}
					} else if (event.type === HttpEventType.Response) {
						this.loaderdisplay = false;
						console.log('event:', event);
						console.log('event body:', event.body);
						console.log('File is completely downloaded!');
						let file = new Blob([event.body]);
						saveAs(file, checkOutData.label);
					}

				}
					, err => {
						console.log('Error:', err);
						this.attemptCount++;

						console.log('this.attemptCount:', this.attemptCount)
						if (this.attemptCount < 3) {
							this.downloadSize = '';
							this.downloadSize = 'Retry attempt ' + this.attemptCount;
							this.fileDownloadPreSignedUrl(checkOutData);
						} else {
							this.loaderdisplay = false;
						}
					}
				);

			} else {
				console.log('else file download:', resp.errorMsg)
				if (resp && resp.errorMsg) {
					console.log('else else');
					this.errorMsg = '';
					this.errorMsg = resp.errorMsg;
					this.fileDownloadErrorMsg = true;
				}


			}

			// if (resp) {
			// 	this.checkOutService.downloadFile(resp).subscribe((resp: HttpResponse<Blob>) => {
			// 		console.log('blob response:', resp);
			// 		console.log('blob response:', resp.status);
			// 		console.log('blob response:', resp.statusText);
			// 		console.log('blob response:', resp.type);
			// 		let file = new Blob([resp.body]);
			// 		console.log(file.size + " bytes file downloaded. File type: ", file.type);
			// 		saveAs(file, data.label);
			// 	},
			// 	err => {
			// 		alert("Problem while downloading the file.");
			// 		console.log('Error :',err);
			// 		console.error(err);
			// 	  }
			// 	);
			// }
		},
			error => {
				console.log('get parent error:', error)
			}
		);

	}

	closeErrorMsg() {
		this.fileDownloadErrorMsg = false;
	}

	closeCheckoutMessage() {
		this.checkoutSizeMessage = false;
	}

	getExtensionType(type: string) {
		if (type == 'java' || type == 'zip' || type == 'jpg' || type == 'jpeg' || type == 'gif' ||
			type == 'png' || type == 'xls' || type == 'xlsx' || type == 'doc' || type == 'docx' || type == 'pdf' ||
			type == 'ppt' || type == 'rar' || type == 'tar' || type == 'mp3' || type == 'txt' || type == 'xml' ||
			type == 'html' || type == 'htm' || type == 'jar' || type == 'class' || type == 'msi' || type == 'exe' || type.length <= 4) {
			return true;
		} else {
			return false;
		}
	}
}

