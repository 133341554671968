import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileSizePipe } from './file-size.pipe';
import { DateFormatPipe } from './date-format.pipe';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { HttpClientModule } from '@angular/common/http';
import { MenubarModule } from 'primeng/menubar';
import { TabMenuModule } from 'primeng/tabmenu';
import { FileUploadModule } from 'primeng/fileupload';
import { TabViewModule } from 'primeng/tabview';
import { TreeModule } from 'primeng/tree';
import { DialogModule } from 'primeng/dialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { CheckboxModule } from 'primeng/checkbox';
import { DatatableComponent } from '../web-client/datatable/datatable.component';
import { TreeViewComponent } from '../web-client/tree-view/tree-view.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessageModule } from 'primeng/message';
import { DataScrollerModule } from 'primeng/datascroller';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ViewTeamComponent } from 'app/web-client/view-properties/view-team/view-team.component';
import { ViewPersonComponent } from 'app/web-client/view-properties/view-person/view-person/view-person.component';
import { TeamDescriptionComponent } from 'app/web-client/view-properties/view-team/team-description/team-description.component';
import { TeamPeopleComponent } from 'app/web-client/view-properties/view-team/team-people/team-people.component';
import { OwnerDescriptionComponent } from 'app/web-client/view-properties/view-person/owner-description/owner-description.component';
import { MemberListComponent } from 'app/web-client/view-properties/view-person/member-list/member-list.component';
import { ShareAccessComponent } from 'app/web-client/view-properties/view-person/share-access/share-access.component';

import { RadioButtonModule } from 'primeng/radiobutton';
import { PanelMenuModule } from 'primeng/panelmenu';
import { MenuModule } from 'primeng/menu';
import {PanelModule, ScrollPanelModule} from 'primeng/primeng';
import { EditorModule } from 'primeng/editor';
import { AdminDateFormatPipe } from './admin-date-format.pipe';
import {ListboxModule} from 'primeng/listbox';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DialogModule,
        TableModule,
        HttpClientModule,
        MenubarModule,
        TabMenuModule,
        FileUploadModule,
        TabViewModule,
        TreeModule,
        ContextMenuModule,
        CheckboxModule,
        ConfirmDialogModule,
        DataScrollerModule,
        MessageModule,
        OverlayPanelModule,
        AutoCompleteModule,
        DropdownModule,
        CalendarModule,
        ProgressBarModule,
        ProgressSpinnerModule,
        EditorModule,
        RadioButtonModule,
        PanelMenuModule,
        MenuModule,
        PanelModule,
        ScrollPanelModule,
        ListboxModule
    ],
  declarations: [
    FileSizePipe,
    DateFormatPipe,
    AdminDateFormatPipe,
    DatatableComponent,
    TreeViewComponent,
    ViewPersonComponent,
    OwnerDescriptionComponent,
    MemberListComponent,
    ShareAccessComponent,
    ViewTeamComponent,
    TeamDescriptionComponent,
    TeamPeopleComponent,
  ],
  exports: [
    FileSizePipe,
    DateFormatPipe,
    AdminDateFormatPipe,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    TableModule,
    HttpClientModule,
    MenubarModule,
    TabMenuModule,
    FileUploadModule,
    CheckboxModule,
    TabViewModule,
    TreeModule,
    ContextMenuModule,
    DropdownModule,
    AutoCompleteModule,
    CalendarModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    DatatableComponent,
    TreeViewComponent,
    MessageModule,
    ViewPersonComponent,
    OwnerDescriptionComponent,
    MemberListComponent,
    ShareAccessComponent,
    ViewTeamComponent,
    TeamDescriptionComponent,
    TeamPeopleComponent,
    PanelMenuModule,
    RadioButtonModule,
    MenuModule,
    PanelModule,
    EditorModule
  ],
  providers: [
    DateFormatPipe,
    AdminDateFormatPipe
  ]
})
export class SharedModule { }
