import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ItemInitiationForm2 } from 'app/core/model/itemAdminModels';

@Component({
  selector: 'attachedfile-for-opt',
  templateUrl: './attachedfile-for-opt.component.html',
  styleUrls: ['./attachedfile-for-opt.component.css']
})
export class AttachedfileForOptComponent implements OnInit {

  attchFileOptObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()
  isEncryptOptions: ItemInitiationForm2[];

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.isEncryptOptions = [
      { label: 'y', value: 'y' },
      { label: 'n', value: 'n' }
    ];
    this._formClear();
  }

  _formClear() {
    this.attchFileOptObjectForm = this.formBuilder.group({
      actServer: '',
      fmCrptKey: '',
      orgStrdSize: '',
      viewCount: '0',
      strSize: '',
      extMimType: '',
      mimType: '',
      actSize: '',
      mimParam: '',
      isEncrpt: { label: 'n', value: 'n' },
      stMode: '',
      accsOn: null,
      comprsion: '',
      chkSum: '',
      bckUpPath: '',
      comments: 'Object created using APDM Admin Portal',
      delfrmDskOn: null,
      path: '',
      rtChkPt: '',
      mimSubType: ''
    });
    this.formReady.emit(this.attchFileOptObjectForm);
  }

}
