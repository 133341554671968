import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'apdm-help',
  templateUrl: './apdm-help.component.html',
  styleUrls: ['./apdm-help.component.css']
})
export class ApdmHelpComponent implements OnInit {
  constructor(private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle('Support');
  }

	scroll(el: HTMLElement) {
		el.scrollIntoView();
	}

}
