export class DeleteItemRequest {
    public itemId: string;
}

export class DeleteItemResponse {
    public count: number;
    public label: string;
}

export class CutPasteItemRequest {
    public sourceId: string;
    public destinationId: string;
}
