import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import {SearchForm, Result} from '../../core/model/searchModel';
import {ChildRequest} from '../model/viewPropertiesModels';
import { NgStyle } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { SubjectList } from 'app/core/model/searchModel';

@Injectable({
  providedIn: 'root'
})
export class SearchService {


  private searchURI = environment.apdmServiceUrl;

  cdsId : any;

  token: any;

  query: String;

  private headers: HttpHeaders;
  quickName:string;
  quickOwner:string;
  quickModifyFrom:string;
  quickModifyTo:string;
  quickSuspensionOrder:string;
  quickSearchIn:any;
  quickClearSearchIn:any;
  searchSubjectVal = 'any';
  searchSubject = new BehaviorSubject("a");
  searchEngineering = new BehaviorSubject("a");
  searchCheckbox = new BehaviorSubject("a");
  searchSubjectSelected = new BehaviorSubject("a");
  searchDataTable = new BehaviorSubject("a");
  searchDataTableResult = new BehaviorSubject("a");
  searchDataTableList = [];
  isDataTableRefresh: boolean = false;
  searchRowSelected = new BehaviorSubject("a");
  searchFileList = [];
  isDataTable = true;
  rowSelectedData = [1];
  loading = false;
  searchChildNode = new BehaviorSubject("a");
  searchEditRowSelect = new BehaviorSubject("a");

  constructor(private http: HttpClient) {

    this.token = JSON.parse(sessionStorage.getItem('strAccessToken'));


		if(this.token)
		{
		  this.cdsId = this.token.CommonName;
		}



		//console.log(' teamcreation-cdsid--->', this.cdsId);

		if (this.cdsId !== undefined ) {
		  this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
		} else {
		  this.cdsId = 'Invalid';
		  this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');

		}
   }

  getSecurityLevelList() {
    return this.http.get(this.searchURI + 'security');
  }

  getOwnerResult(query: any ){
	  this.headers = new HttpHeaders().append('Content-Type', 'text/plain');
	  return this.http.post(this.searchURI + 'autocomplete/person', query);

  }


  getVehicleResult(){

    return this.http.get(this.searchURI + 'leadvehiclelist' );

  }

  getEngineResult(){

    return this.http.get(this.searchURI + 'enginequerylist' );

  }

  getTransResult(){

    return this.http.get(this.searchURI + 'transprogramlist' );

  }

  getKeywordResult(){

    return this.http.get(this.searchURI + 'milestoneinfo' );

  }

  getNamePlateResult(query: String[] ){

    return this.http.get(this.searchURI + 'nameplate/' + query);

  }

  getProgramCodeResult(query: String[] ){

    return this.http.get(this.searchURI + 'programcode/' + query);

  }

  getBrandResult(query: String[] ){

    return this.http.get(this.searchURI + 'brand/' + query);
  }

  getStorageVaultResult(query: any ){
	  this.headers = new HttpHeaders().append('Content-Type', 'text/plain');
	  return this.http.post(this.searchURI + 'autocomplete/person', query);
  }
  getBuildingResult(query: any ){
	  this.headers = new HttpHeaders().append('Content-Type', 'text/plain');
	  return this.http.post(this.searchURI + 'autocomplete/person', query);
  }
  getReportResult(query: any ){
	  this.headers = new HttpHeaders().append('Content-Type', 'text/plain');
	  return this.http.post(this.searchURI + 'autocomplete/person' , query);
  }
  getCompanyResult(query: any ){
	  this.headers = new HttpHeaders().append('Content-Type', 'text/plain');
	  return this.http.post(this.searchURI + 'autocomplete/person' , query);
  }

  getTitleResultProject(query: any){
	  this.headers = new HttpHeaders().append('Content-Type', 'text/plain');
	  return this.http.post(this.searchURI + 'autocomplete/person', query );
  }

  getVendorResult(query: String[]){
	  this.headers = new HttpHeaders().append('Content-Type', 'text/plain');
	  return this.http.post(this.searchURI + 'autocomplete/person' , query);
  }

  getLeadVehicleResult(query: any){
    return this.http.post(this.searchURI + 'autocomplete/person' , query);
  }
  getArchitectureresult(query: String[]){
    return this.http.get(this.searchURI + 'engine/arch/' + query);
  }

  getRetentionStatusList() {
    return this.http.get(this.searchURI + 'retstatus');
  }

  getRetentionSheduleList() {
    return this.http.get(this.searchURI + 'retschedule');
  }

  getConfigurationList(){
    return this.http.get(this.searchURI + 'engineconfig');
  }
  getSubList() {
    return this.http.get(this.searchURI + 'subjectlist');
  }

  getLeadVehicleAutoresult(query: String[]){
    return this.http.get(this.searchURI + 'autocomplete/engProg/' + query);
  }

  postSearchFindAll(searchform :SearchForm ) {

    console.log("postSearchFindAll" , searchform );
    return this.http.post(this.searchURI + 'find/optegra', searchform);
  }

  postSearchFindAnyFolder(searchform :SearchForm ) {

    console.log("postSearchFindAnyFolder" , searchform );
    return this.http.post(this.searchURI + 'find/folder', searchform);
  }

  postSearchDrawing(searchform :SearchForm ) {

    console.log("postSearchDrawing" , searchform );
    return this.http.post(this.searchURI + 'find/drawing', searchform);
  }

  postSearchPerson(searchform :SearchForm ) {

    console.log("postSearchPerson" , searchform );
    return this.http.post(this.searchURI + 'find/person', searchform);
  }

  postSearchTeam(searchform :SearchForm ) {

    console.log("postSearchTeam" , searchform );
    return this.http.post(this.searchURI + 'find/team', searchform);
  }


  postSearchProject(searchform :SearchForm ) {

    console.log("postSearchProject" , searchform );
    return this.http.post(this.searchURI + 'find/project', searchform);
  }

  postSearchBook(searchform :SearchForm ) {

    console.log("postSearchBook" , searchform );
    return this.http.post(this.searchURI + 'find/book', searchform);
  }

  postSearchTransmissionProgram(searchform :SearchForm ) {

    console.log("postSearchTransmissionProgram" , searchform );
    return this.http.post(this.searchURI + 'find/transprogram', searchform);
  }

  postSearchEngineBuild(searchform :SearchForm ) {

    console.log("postSearchEngineBuild" , searchform );
    return this.http.post(this.searchURI + 'find/enginebuild', searchform);
  }

  postSearchPart(searchform :SearchForm ) {

    console.log("postSearchTransmissionProgram" , searchform );
    return this.http.post(this.searchURI + 'find/part', searchform);
  }

  postSearchVechicle(searchform :SearchForm ) {

    console.log("postSearchVechicle" , searchform );
    return this.http.post(this.searchURI + 'find/vehicleprogram', searchform);
  }

  postSearchTestDataFolder(searchform :SearchForm ) {

    console.log("postSearchTestDataFolder" , searchform );
    return this.http.post(this.searchURI + 'find/testdata', searchform);
  }

  postSearchFile(searchform :SearchForm ) {

    console.log("postSearchFile" , searchform );
    return this.http.post(this.searchURI + 'find/file', searchform);
  }
  postSearchEngineProgram(searchform :SearchForm ) {

    console.log("postSearchEngineProgram" , searchform );
    return this.http.post(this.searchURI + 'find/engineprogram', searchform);
  }







  getEngineConfigList() {
    return this.http.get(this.searchURI + 'engineconfig');
  }

  getEngineFuelList() {
    return this.http.get(this.searchURI + 'fuel');
  }

  getEngineValveTrain(){
    return this.http.get(this.searchURI + 'enginevalvetrain');
  }

  getDesignApproval(){
    return this.http.get(this.searchURI + 'designApproval');
  }




  getEngineArch(query: String[] ){

    return this.http.get(this.searchURI + 'engine/arch/' + query);

  }


  getVendorDrawing(query: String[]){
    return this.http.get(this.searchURI + 'autocomplete/vendor/' + query);
  }

  postSearchIn(searchnode :ChildRequest ) {
    return this.http.post(this.searchURI + 'searchin', searchnode);
  }

  getTitleResult(query: String[]){
    return this.http.get(this.searchURI + 'autocomplete/name/'+query );
  }


  showSubjectList(event: any) {
    this.searchSubjectVal = event.value;
    this.storeSearchEngineering('a');
    this.storeSearchCheckbox('a');
    this.storeSearchSubjSelected('a');
  }

  storeSearchSubject(value: any) {
    this.searchSubject.next(value);
  }

  storeSearchEngineering(value: any) {
    this.searchEngineering.next(value);
  }

  storeSearchCheckbox(value: any) {
    this.searchCheckbox.next(value);
  }

  storeSearchSubjSelected(value: any) {
    this.searchSubjectSelected.next(value);
  }

  subjectCheckboxChangeVal(event: SubjectList) {
    var subject = '';
    if (event.engineering.length > 0) {
      for (let entry of event.engineering) {
        subject = subject.concat(':').concat(entry);
      }
      subject = subject.concat(':').concat('en');
    }
    if (event.analysis.length > 0) {
      for (let entry of event.analysis) {
        subject = subject.concat(':').concat(entry);
      }
      subject = subject.concat(':').concat('an');
    }
    if (event.design.length > 0) {
      for (let entry of event.design) {
        subject = subject.concat(':').concat(entry);
      }
      subject = subject.concat(':').concat('de');
    }
    if (event.anySubject) {
      for (let entry of event.anySubject) {
        subject = subject.concat(':').concat(entry);
      }
    }

    if (event.subjectName !== 'none') {
      if (event.subjectName.toString() == 'manufacturing'){
        subject = subject.concat(':').concat('mf');
      }else  if (event.subjectName.toString() == 'productDevelopment'){
        subject = subject.concat(':').concat('pd');
      }
      return subject;
    }

  }

  storeSearchDataTable(value: any) {
    this.searchDataTable.next(value);
  }

  storeSearchDataTableResult(value: any) {
    this.searchDataTableResult.next(value);
  }

  storeSearchRowSelected(value: any) {
    this.searchRowSelected.next(value);
  }

  storeSearchChildNode(value: any) {
    this.searchChildNode.next(value);
  }

  storeSearchEditRowSelect(value: any) {
    this.searchEditRowSelect.next(value);
  }



}
