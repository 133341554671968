import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Item, Query } from 'app/core/model/itemAdminModels';

@Component({
  selector: 'query-object',
  templateUrl: './query-object.component.html',
  styleUrls: ['./query-object.component.css']
})
export class QueryObjectComponent implements OnInit {

  queryObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()
  @Input('screenType') screenType: string;
  @Input('item') item: Item;
  @Input('query') query: Query;
  @Input('showfield') showfield: boolean;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
    this._updateQueryObjectForm();
  }

  _formClear() {
    this.queryObjectForm = this.formBuilder.group({
      id: [''],
      childQuery: ['']
    });
    this.formReady.emit(this.queryObjectForm);
  }

  _updateQueryObjectForm() {
    this.queryObjectForm.patchValue({
      id: this.item.id,
      childQuery: this.query.query
    })
  }

}
