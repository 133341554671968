import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ItemInitiationForm2 } from 'app/core/model/itemAdminModels';

@Component({
  selector: 'vehicle-prog-req',
  templateUrl: './vehicle-prog-req.component.html',
  styleUrls: ['./vehicle-prog-req.component.css']
})
export class VehicleProgReqComponent implements OnInit {

  vehicleProgramReqObjectForm:FormGroup;  
  @Output() formReady = new EventEmitter<FormGroup>()
  curApprovalOptions: ItemInitiationForm2[];
  brandListOptions: ItemInitiationForm2[];
  // selectedApproval: string = '60';

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {

    this.curApprovalOptions = [{"label":"Sandbox","value":"60"},
    {"label":"Benchmark","value":"50"},
    {"label":"Book Shelf","value":"40"},
    {"label":"Concept","value":"30"},
    {"label":"Design Intent","value":"20"},
    {"label":"Production","value":"10"}];

    this.brandListOptions = [{"value":"","label":"None"},
    {"value":"47180","label":"VALEO"},
    {"value":"47182","label":"TREMEC"},
    {"value":"47183","label":"ZF"},
    {"value":"47189","label":"GFT"},
    {"value":"47190","label":"MAZDA"},
    {"value":"10","label":"FORD"},
    {"value":"54172","label":"VOLVO"},
    {"value":"54169","label":"JAGUAR"},
    {"value":"54170","label":"LAND ROVER"},
    {"value":"54171","label":"ASTON MARTIN"},
    {"value":"54173","label":"LINCOLN"},
    {"value":"54198","label":"ROVER"},
    {"value":"54174","label":"MERCURY"},
    {"value":"54196","label":"GM"},
    {"value":"54197","label":"AISIN"},
    {"value":"54199","label":"JATCO"},
    {"value":"11665","label":"GETRAG"},
    {"value":"251630739","label":"Hcl Comnet Ltd"},
    {"value":"91998","label":"DIGITAL1"},
    {"value":"227721280","label":"VALEO1"}
  ]


    this._formClear();
  } 

  _formClear() {
    this.vehicleProgramReqObjectForm = this.formBuilder.group({
      altLabel: [''],
      curApproval: [{"label":"Sandbox","value":"60"}],
      brand: [''],
      namePlate: [''],
      programCode: [''],
      modelYear: [''],  
    });
    this.formReady.emit(this.vehicleProgramReqObjectForm);
  }

}
