import {Injectable} from '@angular/core';
import {HttpClient ,HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Injectable()
export class TeamcreationService {
	private teamURI = environment.apdmServiceUrl;

	cdsId : any;

	token: any;

	private headers: HttpHeaders;

	constructor(private http: HttpClient) {

		this.token = JSON.parse(sessionStorage.getItem('strAccessToken'));


    if(this.token)
    {
      this.cdsId = this.token.CommonName;
    }



    //console.log(' teamcreation-cdsid--->', this.cdsId);

    if (this.cdsId !== undefined ) {
      this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
    } else {
      this.cdsId = 'Invalid';
      this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');

    }

	}

	sendRequest(data) {
		return this.http.post(this.teamURI + 'teamCreation',
			data,{
				headers: this.headers,
			  });
	}
}
