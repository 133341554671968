import { Component, OnInit } from '@angular/core';
import { TeamAdministrationService } from 'app/core/service/team-administration.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TeamCreate, TeamDetail, Subject, TeamAdminResponse } from 'app/core/model/teamAdministrationModel';
@Component({
  selector: 'listmembership-team',
  templateUrl: './listmembership-team.component.html',
  styleUrls: ['./listmembership-team.component.css']
})
export class ListmembershipTeamComponent implements OnInit {
  teamListForm: FormGroup;
  teamGetDataForm: FormGroup;
  submitted: boolean = false;
  teamCreateModel: TeamCreate;
  showPersonInformation: boolean = false;
  teamCreationStatus: string;
  teamDisplay: boolean = false;
  teamName: string;
  teamDetails: TeamDetail[];
  userlist: Subject[];
  selectedList: string;
  getTeamName: string;
  titleTeamName: string;
  teamSelected:string[];
  showDropDownTeamName:boolean = false;
  showTextBoxTeamName:boolean = true;
  constructor(private formBuilder: FormBuilder, private teamAdmin: TeamAdministrationService) { }

  ngOnInit() {
       this.teamListForm = this.formBuilder.group({
      teamId: [''],
      teamName: ['', Validators.required],
      steward: [''],
      comments: [''],
      teamType: [''],
      memberList: [''],
      selectedTeams: [''],
      modifiedOn: [''],
      createdOn: [''],
      type: [''],
      isAclFlag: [''],
      isMembersFlag: [''],
      userData: ['']
    });
    this.teamGetDataForm = this.formBuilder.group({
      teamId: [''],
      teamName: [''],
      steward: [''],
      comments: [''],
      teamType: [''],
      memberList: [''],
      selectedTeams: [''],
      modifiedOn: [''],
      createdOn: [''],
      type: [''],
      isAclFlag: [''],
      isMembersFlag: [''],
      userData: ['']
    });
    this.teamListForm.get('teamType').setValue(['p', 't']);
    this.teamSelected = ['p', 't'];
    this.userlist = [];
    this.showPersonInformation = false;
    
  }
  teamListSearch() {
  
    this.showPersonInformation = false;
  
    console.log('team list search');
    this.submitted = true;
    if (this.teamListForm.valid) {
      this.teamCreateModel = this.teamListForm.value;
     // this.teamCreateModel.memberList = [];
      this.teamCreateModel.selectedTeams = [];
      this.teamCreateModel.userData = [];
      this.teamCreateModel.teamType = [];
      this.teamCreateModel.teamType = this.teamSelected;
      if (this.teamCreateModel) {
        console.log('teamListSearch', this.teamCreateModel);
        this.teamName = this.teamCreateModel.teamName;
        this.showDropDownTeamName= true;
        this.showTextBoxTeamName = false;
    
        this.teamAdmin.teamAdminSearch(this.teamCreateModel).subscribe((data: TeamCreate) => {
          console.log('list search', data);
          console.log(data.userData);
          if (data.userData.length>0) {
            this.userlist = data.userData;

            console.log(this.userlist);
          }else{
            console.log('else');

            this.ngOnInit();
          }

        });
      }
    }
  }
  teamGetData() {
    console.log('team get data');
    console.log(this.teamGetDataForm.value);
    console.log('get team', this.selectedList);
    if (this.selectedList) {
      console.log('this.selectedList == IF')
      this.getTeamName = this.selectedList;
      this.titleTeamName = this.selectedList;
    } else {
      console.log('this.selectedList == ELSE');
      if (this.userlist) {
        for (var i = 0; i < this.userlist.length; i++) {
          console.log(this.userlist[i]);
          console.log(this.userlist[0].label);
          this.getTeamName = this.userlist[0].label;
          this.titleTeamName = this.userlist[0].label;
          break;
        }
      }
    }
    if (this.teamGetDataForm.valid) {
      this.teamCreateModel = this.teamGetDataForm.value;
      this.teamCreateModel.teamName = this.getTeamName;
      //this.teamCreateModel.memberList = [];
      this.teamCreateModel.selectedTeams = [];
      this.teamCreateModel.userData = [];
      this.teamCreateModel.teamType = [];
      if (this.teamCreateModel.teamName || this.teamCreateModel.teamId) {
        this.teamAdmin.teamAdminGetDataMember(this.teamCreateModel).subscribe((data: TeamAdminResponse) => {
          console.log('data:', data);
          if (data.teamDetail) {
            this.teamDetails = data.teamDetail;
            this.showPersonInformation = true;
          }
        });
      }
    } else {
      console.log('else');
      this.teamDisplay = true;
      this.teamCreationStatus = 'No Team exists with Specified Id or Name';
    }
  }
  get f() {
    return this.teamListForm.controls;
  }
  close() {
    this.teamDisplay = false;
    this.showPersonInformation = false;
  }

}
