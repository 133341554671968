import { Injectable } from '@angular/core';
import {HttpClient ,HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Username , UserList} from "../model/admin-modify-user";
import { Observable } from 'rxjs/internal/Observable';
import { ModifyInput,  TeamData, TeamAdminAcl, ApplicationMembership, PersonList } from '../model/user-admin';
@Injectable({
  providedIn: 'root'
})
export class ModifyUserService {

  private teamURI = environment.apdmServiceUrl;
  private headers: HttpHeaders;
  cdsId: any;
  token: any;
  fdsId:string;

  constructor(private http: HttpClient) {
    this.token = JSON.parse(sessionStorage.getItem('strAccessToken'));
    if (this.token) {
      this.cdsId = this.token.CommonName;
    }
   // console.log(' cdsid--->', this.cdsId);
    if (this.cdsId !== undefined) {
      this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
    } else {
      this.cdsId = 'Invalid';
      this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
    }
  }



  getUser(username: string) {
    console.log('username',username);
    return this.http.post(this.teamURI + 'togetuserslist' , username);
  }
  getUserData(data:ModifyInput){
    console.log('data',data);
    return this.http.post(this.teamURI + 'listuserdata' ,data);
  }
  updateUser(data:PersonList){
    console.log(data);
    return this.http.post(this.teamURI + 'modifyperson' ,data);
  }
  getFDS(){
    console.log('get FDS');
    //console.log('this.cdsId',this.fdsId)
    if(this.fdsId){
      return this.http.post(this.teamURI + 'getfds' ,this.fdsId);
    }

  }
  deleteAffiliations(data:TeamData){
    return this.http.post(this.teamURI + 'deleteuserteam' ,data);
  }
  // savePersonAcl(data:any): Observable<string>{
  //   return this.http.post(this.teamURI + 'personacl' ,data, {
  //     responseType: 'text',
  //   });
  // }

  savePersonAcl(data:any){
    return this.http.post(this.teamURI + 'personacl' ,data, {
      responseType: 'text',
    });
  }
  personAclDelete(data:TeamAdminAcl){
    return this.http.post(this.teamURI + 'deletepersonacl' ,data);
  }
  updateTeamMember(data:ApplicationMembership[]){
    return this.http.post(this.teamURI + 'updateteammembership' ,data);
  }



}
