export class UserInfo {
    cdsId: string;
    employeeType: string;
    fullName: string;
    program: string;
    isManager: boolean;
    isAuthorized: boolean;
    isAdmin: boolean;
    isSme: boolean;
    supervisor: boolean;
    hasWriteAccess: boolean;
    superAdmin: boolean;
    roles: any;
    selectedProject: string;
    approvedPrograms: any[];
    gvtdmProjects: string[];
    firstName: string;
    lastName: string;
    isSupplier: boolean;
    showMenu: boolean;

    constructor(userInfo?: UserInfo) {
        this.cdsId = '';
        this.employeeType = '';
        this.fullName = '';
        this.program = '';
        this.isAuthorized = false;
        this.isManager = false;
        this.isAdmin = false;
        this.isSme = false;
        this.supervisor = true;
        this.hasWriteAccess = false;
        this.roles = {roles: 'admin'};
        this.approvedPrograms = [];
        this.firstName = '';
        this.lastName = '';
        this.isSupplier = false;
        this.showMenu = false;
        // this.selectedProject = {};
        // this.emiProjects = [];

        this.gvtdmProjects = [];
        if (userInfo) {
            Object.assign(this, userInfo);
        }
    }
}
