import {Component, OnInit} from '@angular/core';
import {CheckInGetParentDetails} from "../core/model/checkInModel";
import {HttpResponse} from "@angular/common/http";
import {CheckInService} from "../core/service/check-in.service";
import {ActivatedRoute} from "@angular/router";
import {UrlConsumerService} from "../oauth/url-consumer.service";
import {SharedService} from "../core/service/shared.service";

@Component({
	selector: 'upload-from-url',
	templateUrl: './upload-from-url.component.html',
	styleUrls: ['./upload-from-url.component.css']
})
export class UploadFromUrlComponent implements OnInit {
	checkInGetParentDetails = new CheckInGetParentDetails();
	checkInError: boolean;
	checkIn: boolean;
	apdmError: boolean;
	parentItemId: string;
	isAuthorized: boolean = true;
	isDBError: boolean = false;
	parentPath: string;

	constructor(private checkInService: CheckInService,
				private urlConsumerService: UrlConsumerService,
				private sharedService: SharedService,
				private route: ActivatedRoute) {
	}

	ngOnInit() {
		this.isDBError = false;
		this.isAuthorized = true;
		this.parentItemId = this.route.snapshot.params['parentItemId'];
		this.checkIfUserHasApplicationAccess();
	}

	checkIfUserHasApplicationAccess() {
		this.urlConsumerService.getwebclientaccess().subscribe((data: String) => {
			console.log("fired webclientaccess", data)
			if (data == 'success') {
				this.isAuthorized = true;
				this.getParentFolderForFiles(this.parentItemId);
			} else if (data == 'DBError') {
				this.isDBError = true;
			} else {
				this.isAuthorized = false;
				//this.sharedService.webclientaccess.next(this.isAuthorized);
			}
		}, (error) => {
			console.log("fired error", error.message)
			this.isAuthorized = false;
		})
	}

	getParentFolderForFiles(node: string) {
		if (node) {
			var selectedNode;
			this.checkInService.getParentFolderForFiles(node).subscribe((data: CheckInGetParentDetails) => {
				console.log('getParentFolderForFiles --> ', data);
				this.checkInGetParentDetails = new CheckInGetParentDetails();
				this.checkInGetParentDetails = data;
				if (this.checkInGetParentDetails.childItemDEType == 32) {// || (this.checkInGetParentDetails.childType == 65 && node.data.actualItemType == 32)) {
					selectedNode = this.checkInGetParentDetails.parentItemDEId;
					console.log('this.checkinService.selectedNodeItemId--->', this.checkInService.selectedNodeItemId);
					console.log('this.checkinService.selectedNodeItemId selectedNode--->', selectedNode);
					this.doInitialUpload(selectedNode);
				} else {
					this.doInitialUpload(node);
				}
			})
		}
	}

	doInitialUpload(node: string) {
		console.log("DO initial Upload node===>", node);
		this.checkInService.getParentPathForItemId(node).subscribe((path) => {
			this.parentPath = path;
			this.checkInService.doInitialUpload(node).subscribe((event) => {
				if (event instanceof HttpResponse) {
					console.log('doInitialUpload', event);
					var result = event.body;
					if (result === 'success') {
						this.checkInService.selectedNodeItemId = node;
						console.log('this.checkInService.selectedNodeItemId', this.checkInService.selectedNodeItemId);
						this.checkIn = true;
					} else if (result === 'CheckInError') {
						console.log('CheckInError');
						this.checkInError = true;
					} else if (result === 'APDMError') {
						console.log('APDMError');
						this.apdmError = true;
					}
				}
			});
		});
	}

}
