import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'vehicle-config-opt',
  templateUrl: './vehicle-config-opt.component.html',
  styleUrls: ['./vehicle-config-opt.component.css']
})
export class VehicleConfigOptComponent implements OnInit {

  
  vehicleConfigOptObjectForm:FormGroup;  
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
  }

  _formClear() {
    this.vehicleConfigOptObjectForm = this.formBuilder.group({
      engine: [''],
      trans: [''],
      zeroToSixty: [''],
      fuelEconomy: [''],
      bodyStyle: [''],
      weight: ['']         
    });
    this.formReady.emit(this.vehicleConfigOptObjectForm);
  }

}
