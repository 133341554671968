import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import { HttpClient, HttpResponse, HttpRequest, HttpEventType, HttpEvent } from '@angular/common/http';
import { ContextMenu, MenuItem } from 'primeng/primeng';
import { DataTableServiceService } from '../../core/service/data-table-service.service';
import { DataTableHeaderResposne, DataTableHeaders, ParentDetails } from '../web-client-data/DataTableModel';
import { SharedService } from '../../core/service/shared.service';
import { TreeNode } from 'primeng/api';
import { Table } from 'primeng/components/table/table';
import { Result } from '../../core/model/treeViewModel';
import { CheckInService } from '../../core/service/check-in.service';
import { CheckInGetParentDetails } from '../../core/model/checkInModel';
import { CheckOut, FileDownloadUrl, TempPath } from 'app/core/model/checkout';
import { CheckOutService } from 'app/core/service/check-out.service';
import { saveAs } from 'file-saver';
import { ViewProperties, ViewDataTableHeaders } from 'app/core/model/viewProperties';
import { Subscription } from 'rxjs';
import { CopyPasteService } from 'app/core/service/copy-paste.service';
import { DeleteService } from 'app/core/service/delete.service';
import { DeleteItemResponse } from 'app/core/model/DeleteItemRequest';
import {environment} from "../../../environments/environment";
import {CheckoutResponse} from "../tree-view/tree-view.interfce";



@Component({
  selector: 'datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.css'],
  providers: [DataTableServiceService],
})
export class DatatableComponent implements OnInit, OnDestroy {
  @ViewChild(Table) tableComponent: Table;
  cols: any[];
  selectedData: DataTableHeaders[] = [];
  dataTableSelectedRecords: any[] = [];
  rowSelected: DataTableHeaderResposne;
  subsciption: Subscription;
  subsciptiondatatable: Subscription;
  subsciptiontabchange: Subscription;
  stopDownloadFile: boolean = false;
  count: number;

  rowSelectedData: DataTableHeaders[] = [];
  contextMenuRowSelectedData: DataTableHeaders[] = [];
  globalRightClickRowSelectedData: DataTableHeaders[] = [];
  // treeId: Result;
  treeId: any;

  treeLabel: string;
  loading: boolean;
  public items2: MenuItem[];
  files: TreeNode;
  checkoutrecord: any;
  emptymessage: boolean;
  result: Result;
  loaderdisplay: boolean = false;
  totalRecords: number;
  //treenode: any;

  treenode: any;
  val: any[];

  arr: any[];

  parentobj: TreeNode;
  checkInGetParentDetails = new CheckInGetParentDetails();
  @Output() private checkIn = new EventEmitter<boolean>();
  @Output() private checkInError = new EventEmitter<boolean>();
  @Output() private apdmError = new EventEmitter<boolean>();
  @Output() private apdmInfoError = new EventEmitter<boolean>();
  apdmInfoErrorMsg: any;
  @Output() apdmInfoMessageEvent = new EventEmitter<string>();
  @Output() private checkOutMsg = new EventEmitter<boolean>();
  @Output() private viewProperties = new EventEmitter<any>();
  @Output() private editProperties = new EventEmitter<any>();
  @Output() private fileMenuTreeDataTableData = new EventEmitter<any>();
  @Input() public ownerTreeId: string;

  first: number = 0;

  checkOutData: CheckOut[] = [];
  checkOutFile = new CheckOut();

  parentNodeLabel: any;

  checkOut: CheckOut;
  viewPropertiesValue = new ViewProperties();

  //copyIds: any;
  //pasteType: any;
  cutNode: any;
  deleteItemResponse: DeleteItemResponse[];
  checkOutdataTableSubscibe: Subscription;
  dataTableSelectedEmitter = new EventEmitter<any>();
  nochildren: DataTableHeaders;
  checkOutSingle = new CheckOut();

  contectMenuDisable: boolean;
  download = new CheckOut();
  fileMenuDownload = new CheckOut();

  singleCallTreeAndDataTableSubscibe: Subscription;
  treeDatatableIdSubscibe: Subscription;
  treeDatatableclearSubscibe: Subscription;
  singleCalltreeDatatableId: Subscription;
  folderdownloadsubscribe: any;
  fileSize: string = '0';
  totalSize: string = '0';
  downloadSize: string;
  attemptCount: number = 0;
  fileDownloadErrorMsg: boolean = false;
  percentDone: number = 0;
  req: any;
  errorMsg: any;
  folderloaderdisplay: boolean = false;
  extensionCheck: boolean = false;
  checkoutSizeMessage: boolean = false;
  checkoutMessage: string = '';
  resetDataTableOnTreeSelect: Subscription;
  resetDataTableOnTreeSelectfilemenu: Subscription;
  @ViewChild(ContextMenu) public cm: ContextMenu;
  @ViewChild(Table) public dataTable: Table;
  tempPath = new TempPath();

  constructor(private service: DataTableServiceService, private http: HttpClient,
    private treeService: SharedService, private checkInService: CheckInService,
    private checkOutService: CheckOutService, private copyPasteService: CopyPasteService,
    private deleteService: DeleteService) {

    console.log('////////////INSIDE DATATABLE CONSTRUCTOR////////////////');

    this.selectedData = [];

    //Clear Datatable Selected row from Edit property Screen
    this.treeDatatableclearSubscibe = this.treeService.isDataTableSelectRow().subscribe(flag => {
      if (flag)
        this.rowSelectedData = [];
    });


    this.treeDatatableIdSubscibe = this.treeService.treeDatatableId.subscribe((treeData: Object) => {
      console.log('/////////////TREE SERVICE - TREEDATATABLEID/////////////////////');
      // console.log("treeData[item] = ", treeData["item"], "this.ownerTreeId = ", this.ownerTreeId);
      if (this.ownerTreeId != treeData["item"]) {
        // console.log("this.treeLabel = " + this.treeLabel);
        return;
      }

      this.treeId = treeData["data"];
      // console.log("this.treeId = " + this.treeId);
      // console.log("this.treeId.label = " + this.treeId.label);
      // console.log("tree datatable", this.treeId);
      if (this.treeId.label == "Show More") {
        this.parentNodeLabel = this.treeId.parent.label;
      } else {
        this.parentNodeLabel = this.treeId.label;
      }
      // console.log("label parent", this.parentNodeLabel)
      this.getDatatable();
    });

    this.checkOutdataTableSubscibe = this.treeService.checkOutDatatable.subscribe((response: any) => {
      console.log('///////////////TREE SERVICE - CHECKOUTDATATABLE////////////////////', response)
      this.OncheckOutDialog(response);
    });

    this.contectMenuDisable = true;
  }

  ngOnInit() {
    console.log('///////INSIDE ON INIT DATATABLE METHOD//////////this.rowSelectedData', this.rowSelectedData);

    this.cols = [
      { field: 'treedata', header: 'hiddenfield', display: 'none' },
      { field: 'name', header: 'Name', display: 'table-cell' },
      { field: 'lockOwner', header: 'LockOwner', display: 'table-cell' },
      { field: 'version', header: 'Version', display: 'table-cell' },
      { field: 'size', header: 'Size', display: 'table-cell' },
      { field: 'dateModified', header: 'DateModified', display: 'table-cell' },
      { field: 'type', header: 'hiddenfield', display: 'none' },
      { field: 'typeLabel', header: 'Type', display: 'table-cell' },
      { field: 'owner', header: 'Owner', display: 'table-cell' },
      { field: 'description', header: 'Description', display: 'table-cell' }
    ];

    this.contextMenuRowSelectedData = [];
    this.rowSelectedData = [];
    this.globalRightClickRowSelectedData = [];
    this.tableComponent.reset();
    this.setContextMenu(this.rowSelectedData);
    // uncheck the checkbox on tab change
    this.subsciptiontabchange = this.treeService.tabchangeevent.subscribe((data: any) => {
      console.log('///////////////TREE SERVICE - TABCHANGEEVENT////////////////////');
      if (this.rowSelectedData.length > 0) {
        this.rowSelectedData = [];
      }
    });
    this.resetDataTableOnTreeSelect = this.treeService.resetDataTableOnTreeSelectevent.subscribe((data: any) => {
      this.cm.hide();
      this.dataTable.selection = null;
      this.dataTable.contextMenuSelection = null;
    });
    this.resetDataTableOnTreeSelectfilemenu = this.treeService.resetDataTableOnTreeSelecteventfilemenu.subscribe((data: any) => {
      this.cm.hide();
    });

    // Store this DATATABLE into the shared "datatables" array
    this.treeService.dataTables[this.ownerTreeId] = this;

  }

  setContextMenu(rightClickSelectedData: DataTableHeaders[] = []) {

    console.log("this.rowSelectdata :::::::::" + this.rowSelectedData);
    console.log(this.rowSelectedData);
    console.log("this.contextMenuRowSelectedData :::::::::" + this.contextMenuRowSelectedData);
    console.log(this.contextMenuRowSelectedData);

    var tempRightClickSelectedData = [];
    if (this.rowSelectedData && this.rowSelectedData.length > 0) {
      tempRightClickSelectedData = this.rowSelectedData;
      this.globalRightClickRowSelectedData = tempRightClickSelectedData;
    } else {
      tempRightClickSelectedData.push(rightClickSelectedData);
      this.globalRightClickRowSelectedData = [];
    }

    console.log("tempRightClickSelectedData:", tempRightClickSelectedData);

    console.log('///////////////INSIDE SET CONTEXT MENU METHOD////////////////////');
    this.items2 = [
      {
        label: 'Check Out',
        command: (event) => this.OncheckOutDialog(tempRightClickSelectedData),
        // disabled: this.contectMenuDisable// disabled: this.contectMenuDisable// disabled: this.contectMenuDisable
      },
      {
        label: 'Check In',
        command: (event) => this.OncheckInDialog(tempRightClickSelectedData),
        // disabled: this.contectMenuDisable
      },
      {
        label: 'View Properties',
        command: (event) => this.OnViewPropertieDialog(tempRightClickSelectedData),
        // disabled: this.contectMenuDisable
      },
      {
        label: 'Edit Properties',
        command: (event) => this.OnEditPropertieDialog(tempRightClickSelectedData),
        // disabled: this.contectMenuDisable
      },
      {
        label: 'Cut',
        command: (event) => this.OnCutDialog(tempRightClickSelectedData),
        // disabled: this.contectMenuDisable
      },
      {
        label: 'Copy',
        command: (event) => this.OnCopyDialog(tempRightClickSelectedData),
        // disabled: this.contectMenuDisable
      },
      {
        label: 'Paste',
        command: (event) => this.OnPasteDialog(tempRightClickSelectedData),
        // disabled: this.contectMenuDisable
      },
      {
        label: 'Paste Short Cut',
        command: (event) => this.OnPasteShortCutDialog(tempRightClickSelectedData),
        // disabled: this.contectMenuDisable
      },
      {
        label: 'Change Display to CPSC',
        disabled: true
      },
      {
        label: 'Delete',
        command: (event) => this.OndeleteDialog(tempRightClickSelectedData),
        // disabled: this.contectMenuDisable
      },
      {
        label: 'Restore from Recycle Bin',
        command: (event) => this.OnRestoreItemDialog(tempRightClickSelectedData),
        // disabled: this.contectMenuDisable
      },
      {
        label: 'Refresh from Database',
        command: (event) => this.OnRefreshFromDatabase(tempRightClickSelectedData),
        // disabled: this.contectMenuDisable
      }
    ];

    // if (this.rowSelectedData === undefined || this.rowSelectedData.length === 0) {
    this.rowSelectedData = [];
    // }
  }

  reset() {
    // console.log("data", this.first);
    this.first = 0;
  }


  multiselectcheckbox(event) {
    this.treeService.treeselectionsync.next(this.rowSelectedData);
    this.treeService.rowSelectDatatableValue.next(this.rowSelectedData);

  }

  backbutton() {
    // console.log("////////actual tree node", this.treeId);
    // console.log("////////parent node", this.treeId.parent);
    if (this.treeId.parent !== undefined) {
      var data = { parent: this.treeId.parent, ownerTreeId: this.ownerTreeId };
      this.treeService.treeDatatablesync.emit(data);
    }
  }

  // // Added by mprabh24 on 4/17/2020 - START
  // tmpSelectedData: DataTableHeaders[];
  // tmp_1_SelectedData: DataTableHeaders[];
  // // Added by mprabh24 on 4/17/2020 - END


  oneCallTmpSelectedData: DataTableHeaders[];
  oneCallTmpSelectedData_1: DataTableHeaders;
  parentDetails: ParentDetails;



  getDatatable() {
    // console.log('//////////OWNER TREE ID////////////////', this.ownerTreeId);
    // console.log('//////////SHARED TAB ACTIVE ID////////////////', this.treeService.selectedTab);

    this.tableComponent.reset();
    this.first = 0;
    this.rowSelectedData = [];

    let tmpSelectedData: DataTableHeaders[] = [];
    let tmp_1_SelectedData: DataTableHeaders[] = [];

    // console.log('///////////////////', this.selectedData, '//////////////////', this.selectedData.length);
    if (this.treeId.label == "Show More") {
      if (this.selectedData && this.selectedData.length) {
        tmpSelectedData = [];
        tmpSelectedData = this.selectedData;
        this.selectedData = [];
      } else {
        this.selectedData = [];
      }
    } else {
      this.selectedData = [];
    }

    this.loading = true;
    console.log("/////treeId//////DATATABLE I/P////////////", this.treeId);

    if (this.treeId.data == undefined) {
      this.selectedData = [];
      this.loading = false;
    } else {
      // Single call logic
      this.singleCallTreeAndDataTableSubscibe = this.treeService.singleCallTreeAndDataTable.subscribe((response: any) => {
        this.treeService.singleCallFlag = true;
        this.oneCallTmpSelectedData = [];
        // console.log('//////////OWNER TREE ID////////////////', this.ownerTreeId);
        // console.log('//////////SHARED TAB ACTIVE ID////////////////', this.treeService.selectedTab);
        // console.log('//////////TAB ID////////////////', this.treeId.tabId);
        // console.log('/////////////INSIDE SINGLE CALL TREE AND DATATABLE SUBSCRIBE/////////////', response);
        if (response.children && response.children.length) {
          for (var dataTableResult of response.children) {
            // console.log('dataTableResult-----', dataTableResult);
            this.oneCallTmpSelectedData_1 = new DataTableHeaders();
            this.oneCallTmpSelectedData_1.id = dataTableResult.data[0].id;
            this.oneCallTmpSelectedData_1.name = dataTableResult.data[0].label;
            this.oneCallTmpSelectedData_1.type = dataTableResult.data[0].type;
            this.oneCallTmpSelectedData_1.typeLabel = dataTableResult.data[0].typeLabel;
            this.oneCallTmpSelectedData_1.lockOwner = dataTableResult.data[0].lockOwner;
            this.oneCallTmpSelectedData_1.version = dataTableResult.data[0].version;
            this.oneCallTmpSelectedData_1.size = dataTableResult.data[0].size;
            this.oneCallTmpSelectedData_1.dateModified = dataTableResult.data[0].dateModified;
            this.oneCallTmpSelectedData_1.owner = dataTableResult.data[0].owner;
            this.oneCallTmpSelectedData_1.description = dataTableResult.data[0].description;

            this.oneCallTmpSelectedData_1.subsquentId = dataTableResult.data[0].subsquentId;
            this.oneCallTmpSelectedData_1.parentLabel = dataTableResult.data[0].parentLabel;
            this.oneCallTmpSelectedData_1.rootLabel = dataTableResult.data[0].rootLabel;

            if (dataTableResult.data[0].actualItemType) {
              this.oneCallTmpSelectedData_1.actualItemType = dataTableResult.data[0].actualItemType;
            }
            if (dataTableResult.data[0].externalUrl) {
              this.oneCallTmpSelectedData_1.externalUrl = dataTableResult.data[0].externalUrl;
            }

            // ADDITIONAL DETAILS FOR CHILD NODE
            this.parentDetails = new ParentDetails();
            this.parentDetails.id = response.id;
            this.parentDetails.name = response.label;
            this.parentDetails.type = response.type;
            this.parentDetails.typeLabel = response.typeLabel;
            this.parentDetails.itemType = response.itemType;
            this.parentDetails.parentLabel = response.parentLabel;
            this.parentDetails.rootLabel = response.rootLabel;
            this.oneCallTmpSelectedData_1.parentDetails = this.parentDetails;

            this.oneCallTmpSelectedData_1.treedata = dataTableResult.data[0];

            if (dataTableResult.collapsedIcon) {
              this.oneCallTmpSelectedData_1.collapsedIcon = dataTableResult.collapsedIcon;
            }
            if (dataTableResult.expandedIcon) {
              this.oneCallTmpSelectedData_1.expandedIcon = dataTableResult.expandedIcon;
            }
            if (dataTableResult.styleClass) {
              this.oneCallTmpSelectedData_1.styleClass = dataTableResult.styleClass;
            }

            this.oneCallTmpSelectedData_1.leaf = dataTableResult.leaf;
            this.oneCallTmpSelectedData.push(this.oneCallTmpSelectedData_1);
          }
        } else {
          // this.treeId = response;
          if (this.treeId.data) {
            // console.log('////////data/////////',this.treeId.data);
            this.oneCallTmpSelectedData_1 = new DataTableHeaders();
            this.oneCallTmpSelectedData_1.id = this.treeId.data[0].id;
            this.oneCallTmpSelectedData_1.name = this.treeId.data[0].label;
            this.oneCallTmpSelectedData_1.type = this.treeId.data[0].type;
            this.oneCallTmpSelectedData_1.typeLabel = this.treeId.data[0].typeLabel;
            this.oneCallTmpSelectedData_1.lockOwner = this.treeId.data[0].lockOwner;
            this.oneCallTmpSelectedData_1.version = this.treeId.data[0].version;
            this.oneCallTmpSelectedData_1.size = this.treeId.data[0].size;
            this.oneCallTmpSelectedData_1.dateModified = this.treeId.data[0].dateModified;
            this.oneCallTmpSelectedData_1.owner = this.treeId.data[0].owner;
            this.oneCallTmpSelectedData_1.description = this.treeId.data[0].description;

            this.oneCallTmpSelectedData_1.subsquentId = this.treeId.data[0].subsquentId;
            this.oneCallTmpSelectedData_1.parentLabel = this.treeId.data[0].parentLabel;
            this.oneCallTmpSelectedData_1.rootLabel = this.treeId.data[0].rootLabel;

            if (this.treeId.data[0].actualItemType) {
              this.oneCallTmpSelectedData_1.actualItemType = this.treeId.data[0].actualItemType;
            }
            if (this.treeId.data[0].externalUrl) {
              this.oneCallTmpSelectedData_1.externalUrl = this.treeId.data[0].externalUrl;
            }

            //NEED TO SET PARENT DETAILS.

            this.oneCallTmpSelectedData_1.treedata = this.treeId.data[0];
            this.oneCallTmpSelectedData.push(this.oneCallTmpSelectedData_1);
          }
        }

        tmp_1_SelectedData = [];
        tmp_1_SelectedData = this.oneCallTmpSelectedData;
        if (tmpSelectedData && tmpSelectedData.length) {
          // console.log('TEMP SELECTED DATA LENGTH', this.tmpSelectedData.length);
          // console.log('TEMP 1 SELECTED DATA LENGTH', this.tmp_1_SelectedData.length);
          tmpSelectedData.pop();
          Array.prototype.push.apply(tmpSelectedData, tmp_1_SelectedData);
          this.selectedData = tmpSelectedData;
          tmpSelectedData = [];
          tmp_1_SelectedData = [];
          this.loading = false;
        } else if (this.treeId.label !== "Show More") {
          this.selectedData = [];
          this.selectedData = this.oneCallTmpSelectedData;
          // console.log('this.selectedData', this.selectedData);
          this.loading = false;
        }
      });

      console.log('//////////ELSE - GETDATABLE//////////////', this.treeService.singleCallFlag);
      if (!this.treeService.singleCallFlag) {
        // console.log('///////////////// INSIDE SINGLE CALL FLAG CONDITION ///////////////////');
        this.oneCallTmpSelectedData = [];
        var treeId = this.treeId;
        if (treeId) {
          if (treeId.children && treeId.children.length) {
            for (var dataTableResult of treeId.children) {
              // console.log('dataTableResult-----', dataTableResult.data[0]);
              this.oneCallTmpSelectedData_1 = new DataTableHeaders();
              this.oneCallTmpSelectedData_1.id = dataTableResult.data[0].id;
              this.oneCallTmpSelectedData_1.name = dataTableResult.data[0].label;
              this.oneCallTmpSelectedData_1.type = dataTableResult.data[0].type;
              this.oneCallTmpSelectedData_1.typeLabel = dataTableResult.data[0].typeLabel;
              this.oneCallTmpSelectedData_1.lockOwner = dataTableResult.data[0].lockOwner;
              this.oneCallTmpSelectedData_1.version = dataTableResult.data[0].version;
              this.oneCallTmpSelectedData_1.size = dataTableResult.data[0].size;
              this.oneCallTmpSelectedData_1.dateModified = dataTableResult.data[0].dateModified;
              this.oneCallTmpSelectedData_1.owner = dataTableResult.data[0].owner;
              this.oneCallTmpSelectedData_1.description = dataTableResult.data[0].description;

              this.oneCallTmpSelectedData_1.subsquentId = dataTableResult.data[0].subsquentId;
              this.oneCallTmpSelectedData_1.parentLabel = dataTableResult.data[0].parentLabel;
              this.oneCallTmpSelectedData_1.rootLabel = dataTableResult.data[0].rootLabel;

              if (dataTableResult.data[0].actualItemType) {
                this.oneCallTmpSelectedData_1.actualItemType = dataTableResult.data[0].actualItemType;
              }
              if (dataTableResult.data[0].externalUrl) {
                this.oneCallTmpSelectedData_1.externalUrl = dataTableResult.data[0].externalUrl;
              }

              // ADDITIONAL DETAILS FOR CHILD NODE
              this.parentDetails = new ParentDetails();
              this.parentDetails.id = treeId.data[0].id;
              this.parentDetails.name = treeId.data[0].label;
              this.parentDetails.type = treeId.data[0].type;
              this.parentDetails.typeLabel = treeId.data[0].typeLabel;
              this.parentDetails.itemType = treeId.data[0].itemType;
              this.parentDetails.parentLabel = treeId.data[0].parentLabel;
              this.parentDetails.rootLabel = treeId.data[0].rootLabel;
              this.oneCallTmpSelectedData_1.parentDetails = this.parentDetails;

              this.oneCallTmpSelectedData_1.treedata = dataTableResult.data[0];
              this.oneCallTmpSelectedData.push(this.oneCallTmpSelectedData_1);
            }
          }

          tmp_1_SelectedData = [];
          tmp_1_SelectedData = this.oneCallTmpSelectedData;
          if (tmpSelectedData && tmpSelectedData.length) {
            // console.log('TEMP SELECTED DATA LENGTH', this.tmpSelectedData.length);
            // console.log('TEMP 1 SELECTED DATA LENGTH', this.tmp_1_SelectedData.length);
            tmpSelectedData.pop();
            Array.prototype.push.apply(tmpSelectedData, tmp_1_SelectedData);
            this.selectedData = tmpSelectedData;
            tmpSelectedData = [];
            tmp_1_SelectedData = [];
            this.loading = false;
          } else if (this.treeId.label !== "Show More") {
            this.selectedData = [];
            this.selectedData = this.oneCallTmpSelectedData;
            // console.log('this.selectedData', this.selectedData);
            this.loading = false;
          }
        }
      }
    }
  }

  OnRowDoubleClick(data: any, event) {
    console.log("////////ON ROW DOUBLE CLICK EVENT CALL///////////////", data)
    // datatable double click file download Start
    if (data) {
      if (data.typeLabel !== 'show') {
        this.checkOutFile = new CheckOut();
        this.checkOutFile.id = data.id;
        this.checkOutFile.label = data.name;
        if (data.type) {
          this.checkOutFile.type = data.type.toString();
        }
        var extension = this.checkOutFile.label.split('.').pop();
        console.log('Extension:', extension);
        this.extensionCheck = this.getExtensionType(extension);
        console.log('Double click Extension:', this.extensionCheck);
        console.log('type:', this.checkOutFile.type);
        if ((this.checkOutFile.type == '32') || (this.extensionCheck && this.checkOutFile.type == '65')) {
          console.log('Data table double click File Download:', this.checkOutFile);
          this.attemptCount = 0;
          this.downloadSize = '';
          //this.loaderdisplay = true;
          this.fileDownloadPreSignedUrl(this.checkOutFile);
          // this.treeService.commonFileDownload.next(this.checkOutFile);
        } else {
          if (data.type == 64 || (data.type == 65 && data.actualItemType != undefined && data.actualItemType == 64)) {
            console.log('/////INSIDE - 1/////////');
            if (data.externalUrl) {
              var strVal = data.externalUrl;
              var url = "";
              if (strVal.length > 4) {
                var value = strVal.substring(0, 4);
                if (value == "http") {
                  url = strVal;
                }
                else {
                  url = "http://" + strVal;
                }
                window.open(url);
              } else {
                alert("not valid url");
              }
            }
          } else {
            this.refreshTreeAndTable(data);
          }
        }
      } else {
        this.refreshTreeAndTable(data);
      }
    }
    //End
    /*****Static event object****/
  };

  OncheckInDialog = (rowDatas: any) => {
    console.log('rowDatas length--->', rowDatas.length);
    if (rowDatas.length == 0) {
      // console.log('You must select a folder in the APDM vault for the file to be checked in to. You havent selected any.');
      this.apdmInfoError.emit(true);
      this.apdmInfoErrorMsg = 'You must select a folder in the APDM vault for the file to be checked in to.<br/> You havent selected any.';
      this.apdmInfoMessageEvent.emit(this.apdmInfoErrorMsg);
    } else if (rowDatas.length > 1) {
      // console.log('You may check in to one item at a time <br/> Please select any one item to check in');
      this.apdmInfoError.emit(true);
      this.apdmInfoErrorMsg = 'You may check in to one item at a time.<br/> Please select any one item to check in';
      this.apdmInfoMessageEvent.emit(this.apdmInfoErrorMsg);
    } else if (rowDatas.length == 1) {
      // console.log('RowDatas', rowDatas);
      // console.log('rowDatas[0].treedata ', rowDatas[0].treedata == undefined ? rowDatas[0].treedata : rowDatas[0].treedata.data[0].id);
      // console.log('rowDatas[0].id', rowDatas[0].id);
      this.getParentFolderForFiles(rowDatas[0].id);
    }
  };

  getParentFolderForFiles(node: string) {
    // console.log('<------getParentFolderForFiles node------>',node);
    if (node) {
      var selectedNode;
      this.checkInService.getParentFolderForFiles(node).subscribe((data: CheckInGetParentDetails) => {
        console.log('getParentFolderForFiles --> ', data);
        this.checkInGetParentDetails = new CheckInGetParentDetails();
        this.checkInGetParentDetails = data;
        if (this.checkInGetParentDetails.childItemDEType == 32) {// || (this.checkInGetParentDetails.childType == 65 && node.data.actualItemType == 32)) {
          selectedNode = this.checkInGetParentDetails.parentItemDEId;
          console.log('this.checkinService.selectedNodeItemId--->', this.checkInService.selectedNodeItemId);
          console.log('this.checkinService.selectedNodeItemId selectedNode--->', selectedNode);
          this.doInitialUpload(selectedNode);
        } else {
          this.doInitialUpload(node);
        }
      })
    }
  }

  doInitialUpload(node: string) {
    this.checkInService.doInitialUpload(node).subscribe((event) => {
      if (event instanceof HttpResponse) {
        console.log('doInitialUpload', event);
        var result = event.body;
        if (result === 'success') {
          this.checkInService.selectedNodeItemId = node;
          console.log('this.checkInService.selectedNodeItemId', this.checkInService.selectedNodeItemId);
          this.checkIn.emit(true);
        } else if (result === 'CheckInError') {
          console.log('CheckInError');
          this.checkInError.emit(true);
        } else if (result === 'APDMError') {
          console.log('APDMError');
          this.apdmError.emit(true);
        }
      }
    });
  }

  OncheckOutDialog(data: any) {
    console.log('Checkout from data table');
    console.log(data);
    console.log("data Size:", data.length);

    if (data.length == 1) {
      console.log('inside the single file');
      this.download = new CheckOut();
      if (data[0]) {
        this.download.id = data[0].id;
        this.download.label = data[0].name;
        this.download.type = data[0].type;
        console.log('this.download:', this.download);
        var extension = this.download.label.split('.').pop();
        console.log('Extension:', extension);
        this.extensionCheck = this.getExtensionType(extension);
        console.log('this.extensionCheck:', this.extensionCheck);
        if ((this.download.type == '32') || (this.extensionCheck && this.download.type == '65')) {
          // Single file download
          console.log('File Download');
          this.attemptCount = 0;
          this.downloadSize = '';
          this.fileDownloadPreSignedUrl(this.download);

        } else {
          //Single Folder Download
          console.log('Folder Download');
          let id: any[] = [];
          id.push(this.download.id);
          var size: string = "";
          var sizeDetails: string = "";
          this.checkOutService.checkSizeBeforeCheckout(id).subscribe(resp => {
            console.log('resp:' + resp);
            sizeDetails = resp;

            size = sizeDetails.split("KB", 1)[0];
            console.log('size:' + size);
            size = size.replace(/,/g, '');
            var actualSize = parseFloat(size);

            var maxLimit = environment.folderDownloadMaxLimit;
			if (actualSize > maxLimit) {
				var marker = 1024; // Change to 1000 if required
				var decimal = 2; // Change as required
				var gigaBytes = marker * marker;
				var totalSize = (actualSize / gigaBytes).toFixed(decimal);
				var maxLimitSize = (maxLimit / gigaBytes).toFixed(0);
				this.checkoutSizeMessage = true;
				this.checkoutMessage = "The size (" + totalSize + " GB) of the folder(s)/file(s) you have selected for check out exceeds the maximum limit of "+maxLimitSize+" GB and cannot be downloaded.\n Please unselect some of the folder(s)/file(s) and try again.";
			}
			else if (actualSize <= maxLimit && actualSize > 2097153.0) {
              var marker = 1024; // Change to 1000 if required
              var decimal = 2; // Change as required
              var gigaBytes = marker * marker;
              var totalSize = (actualSize / gigaBytes).toFixed(decimal);
              console.log('email');
              this.checkoutSizeMessage = true;
              this.checkoutMessage = "Size:" + totalSize + " \n GB.Your total Check out size is more than 2 GB. This will take some time.\n So we will notify through email, once your download is ready.";
              this.checkOutService.checkoutSendEmail(this.download,totalSize).subscribe(data => {

              }, error => {
                console.log('Folder Download Error message', error);
                this.folderloaderdisplay = false;
                // if (error && error.status === 504) {
                //   this.errorMsg = '';
                //   this.errorMsg = 'Unable to download file/folder due to larger size';
                //   this.fileDownloadErrorMsg = true;
                // }
              });
            } else {
              this.folderloaderdisplay = true;
              this.folderdownloadsubscribe = this.checkOutService.checkOutNew(this.download).subscribe((resp: HttpResponse<CheckoutResponse>) => {
								if (resp) {
									console.log('resp.status:', resp.status)
									console.log('Response:', resp);
									const url = resp.body.url;
									// let file = new Blob([resp.body]);
									// saveAs(file, resp.headers.get('FileName'));
									// Download the file from the URL
									this.downloadFileFromUrl(url);
									this.folderloaderdisplay = false;
									this.checkOutData = [];
									//console.log('Path:', resp.headers.get('Path'));
									this.tempPath = new TempPath();
									this.tempPath.path = resp.headers.get('Path');
									console.log('tempPath:', this.tempPath);
									this.checkOutService.deleteTempPath(this.tempPath).subscribe(data => {

										},
										error => {
											console.log('error in deletepath:', error);
								});
						}
				}, error => {
                console.log('Folder Download Error message', error);
                this.folderloaderdisplay = false;
                if (error && error.status === 504) {
                  this.errorMsg = '';
                  this.errorMsg = 'Unable to download file/folder due to larger size';
                  this.fileDownloadErrorMsg = true;
                }
              });
            }
          });

        }
      }
    } else {
      // Multi File Download
      console.log('Multi File Download.....')
      this.dataTableSelectedRecords = [];
      this.checkOutData = [];
      this.dataTableSelectedRecords = data;
      let id: any[] = [];
      this.dataTableSelectedRecords.forEach(element => {
        this.checkoutrecord = element;
        id.push(this.checkoutrecord.id);
        this.checkOutData.push({
          id: this.checkoutrecord.id,
          label: this.checkoutrecord.name,
          type: this.checkoutrecord.type,
          version: this.checkoutrecord.version
        });
      });


      var size: string = "";
      var sizeDetails: string = "";
      this.checkOutService.checkSizeBeforeCheckout(id).subscribe(resp => {
        console.log('resp:' + resp);
        sizeDetails = resp;

        size = sizeDetails.split("KB", 1)[0];
        console.log('size:' + size);
        size = size.replace(/,/g, '');
        var actualSize = parseFloat(size);

        var maxLimit = environment.folderDownloadMaxLimit;
        if (actualSize > maxLimit) {
			var marker = 1024; // Change to 1000 if required
			var decimal = 2; // Change as required
			var gigaBytes = marker * marker;
			var totalSize = (actualSize / gigaBytes).toFixed(decimal);
			var maxLimitSize = (maxLimit / gigaBytes).toFixed(0);
			this.checkoutSizeMessage = true;
			this.checkoutMessage = "The size (" + totalSize + " GB) of the folder(s)/file(s) you have selected for check out exceeds the maximum limit of "+maxLimitSize+" GB and cannot be downloaded.\n Please unselect some of the folder(s)/file(s) and try again.";
        }
        else if (actualSize <= maxLimit && actualSize > 2097153.0) {
          var marker = 1024; // Change to 1000 if required
          var decimal = 2; // Change as required
          var gigaBytes = marker * marker;
          var totalSize = (actualSize / gigaBytes).toFixed(decimal);
          console.log('email');
          this.checkoutSizeMessage = true;
          this.checkoutMessage = "Size:" + totalSize + " \n GB.Your total Check out size is more than 2 GB. This will take some time.\n So we will notify through email, once your download is ready.";
          this.checkOutService.checkoutSendEmailDatatable(this.checkOutData,totalSize).subscribe(data => {


          }, error => {
            console.log('Folder Download Error message', error);
            this.folderloaderdisplay = false;
            // if (error && error.status === 504) {
            //   this.errorMsg = '';
            //   this.errorMsg = 'Unable to download file/folder due to larger size';
            //   this.fileDownloadErrorMsg = true;
            // }
          });
        }
        else {
          console.log('this.checkOutData Multiple File', this.checkOutData);
          this.checkOutMsg.emit(true);
          this.folderloaderdisplay = true;
          this.folderdownloadsubscribe = this.checkOutService.dataTableCheckoutNew(this.checkOutData).subscribe((resp: HttpResponse<CheckoutResponse>) => {
            if (resp) {
				console.log('resp.status:', resp.status)
				console.log('Response:', resp);
				const url = resp.body.url;
                // let file = new Blob([resp.body]);
                // saveAs(file, resp.headers.get('FileName'));
				// Download the file from the URL
				this.downloadFileFromUrl(url);
                this.folderloaderdisplay = false;
                this.checkOutData = [];
                //console.log('Path:', resp.headers.get('Path'));
				this.tempPath = new TempPath();
				this.tempPath.path = resp.headers.get('Path');
				console.log('tempPath:', this.tempPath);
				this.checkOutService.deleteTempPath(this.tempPath).subscribe(data => {

								},
									error => {
										console.log('error in deletepath:', error);
									});

            }
          }, error => {
            console.log('Folder Download Error message', error);
            this.folderloaderdisplay = false;
            if (error && error.status === 504) {
              this.errorMsg = '';
              this.errorMsg = 'Unable to download file/folder due to larger size';
              this.fileDownloadErrorMsg = true;
            }
          });
        }
      });

    }
  }
	
	private downloadFileFromUrl(url: string) {
		// Create an anchor element and programmatically click it to trigger the download
		const a = document.createElement('a');
		a.href = url;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}

  closeCheckoutMessage() {
    this.checkoutSizeMessage = false;
  }

  stopFolderDownload() {
    this.stopDownloadFile = false;
    this.folderloaderdisplay = false;
    this.errorMsg = "File/Folder Download Cancelled.";
    this.fileDownloadErrorMsg = true;
    this.folderdownloadsubscribe.unsubscribe();
  }

  OnViewPropertieDialog(data: any) {
    console.log('OnViewDialog Method datatable');
    console.log('datatable', data);
    this.viewPropertiesValue = new ViewProperties();
    console.log(data.length);

    if (data.length == 0) {
      console.log('length Checking');
      this.viewPropertiesValue.size = 0;
    } else if (data.length > 1) {
      console.log('greater than...');
      this.viewPropertiesValue.size = data.length;
    }
    if (data) {
      console.log('inside the data:', data);
      this.viewPropertiesValue.itemId = data[0].id;
      this.viewPropertiesValue.type = data[0].type;
      this.viewPropertiesValue.label = data[0].name;
      this.viewPropertiesValue.nodeTypeLabel = data[0].typeLabel;
      this.viewPropertiesValue.folderType = data[0].folderType;
      this.viewPropertiesValue.parentLabel = data[0].parentLabel;
      this.viewPropertiesValue.subsquentLabel = data[0].subsquentLabel;
      this.viewPropertiesValue.depth = data[0].depth;
      this.viewPropertiesValue.version = data[0].version;
      this.viewPropertiesValue.simpleFolderParent = data[0].simpleFolderParent;
      this.viewPropertiesValue.simpleFolderLabel = data[0].simpleFolderLabel;
      console.log('this.viewPropertiesValue ,,...:', this.viewPropertiesValue);
      this.viewProperties.emit(this.viewPropertiesValue);

    }
    // if (data[0] && data[0].treedata.data[0]) {
    //   console.log('get view property', data[0].treedata.data[0])
    //   this.viewPropertiesValue.itemId = data[0].treedata.data[0].id;
    //   this.viewPropertiesValue.type = data[0].treedata.data[0].type;
    //   this.viewPropertiesValue.label = data[0].treedata.data[0].label
    //   this.viewPropertiesValue.nodeTypeLabel = data[0].treedata.data[0].typeLabel
    //   this.viewPropertiesValue.folderType = data[0].treedata.data[0].folderType;
    //   this.viewPropertiesValue.parentLabel = data[0].treedata.data[0].parentLabel;
    //   this.viewPropertiesValue.subsquentLabel = data[0].treedata.data[0].subsquentLabel;
    //   this.viewPropertiesValue.depth = data[0].treedata.data[0].depth;
    //   console.log('data[0].treedata.data[0]', this.viewPropertiesValue);
    // }else{
    //   console.log('data datatable:',data);
    // }
    //this.viewProperties.emit(this.viewPropertiesValue);
  }


  ngOnDestroy() {
    console.log('///////DATATABLE ON-DESTROY METHOD CALLED/////////////');
    if (this.subsciption) {
      this.subsciption.unsubscribe();
    }
    if (this.subsciptiondatatable) {
      this.subsciptiondatatable.unsubscribe();
    }
    if (this.checkOutdataTableSubscibe) {
      this.checkOutdataTableSubscibe.unsubscribe();
    }
    // uncheck the checkbox on tab change
    if (this.subsciptiontabchange) {
      this.subsciptiontabchange.unsubscribe();
    }
    if (this.singleCallTreeAndDataTableSubscibe) {
      this.singleCallTreeAndDataTableSubscibe.unsubscribe();
    }
    if (this.treeDatatableIdSubscibe) {
      this.treeDatatableIdSubscibe.unsubscribe();
    }
    if (this.treeDatatableclearSubscibe) {
      this.treeDatatableclearSubscibe.unsubscribe();
    }
    if (this.resetDataTableOnTreeSelectfilemenu) {
      this.resetDataTableOnTreeSelectfilemenu.unsubscribe();
    }
    if (this.resetDataTableOnTreeSelect) {
      this.resetDataTableOnTreeSelect.unsubscribe();
    }

  }

  OnCutDialog = (data: any) => {
    console.log('OnCutDialog ----->', data);
    let selectedTree: any[] = [];
    selectedTree = data;
    var allItemIds = "";
    var allFlag = true;
    var usrMessage = "";

    selectedTree.forEach(element => {
      /***Start***/
      if (element) {
        let type = element.type;
        let typeLabel = element.typeLabel;
        let itemId = element.id;
        var nodeType = type;
        var nodeTypeLabel = typeLabel;
        if ((nodeType == 1003 && nodeTypeLabel == "CPSC Domain") ||
          (nodeType == 1003 && nodeTypeLabel == "Prefix") ||
          (nodeType == 1003 && nodeTypeLabel == "Part Base") ||
          (nodeType == 1003 && nodeTypeLabel == "Program") ||
          (nodeType == 1003 && nodeTypeLabel == "Trans Program") ||
          (nodeType == 1003 && nodeTypeLabel == "Drive Train") ||
          (nodeType == 1003 && nodeTypeLabel == "Design Team") ||
          (nodeType == 1003 && nodeTypeLabel == "Vehicle Program") ||
          (nodeType == 1003 && nodeTypeLabel == "Model Year") ||
          (nodeType == 1003 && nodeTypeLabel == "All") ||
          (nodeType == 1003 && nodeTypeLabel == "Program") ||
          (nodeType == 1003 && nodeTypeLabel == "V Engine Program") ||
          (nodeType == 1003 && nodeTypeLabel == "I Engine Program") ||
          (nodeType == 1003 && nodeTypeLabel == "Displacement")) {
          allFlag = false;
        }
        console.log('<----allFlag---->', allFlag);
        if (allFlag) {
          allItemIds += itemId + ",";
        } else {
          usrMessage += "\n" + itemId;
        }
      }
      /***End***/
    });
    this.treeService.pasteType = "CUT";
    this.treeService.copyIds = allItemIds;
    // console.log(this.treeService.copyIds)
    // console.log(allFlag);
    if (!allFlag) {
      alert("The following item can not be cut: \n \n" + usrMessage)
    }
  };

  OnCopyDialog = (data: any) => {
    console.log('copy dialog:', data);
    let selectedTree: any[] = [];
    selectedTree = data;
    var allItemIds = "";
    var allFlag = true;
    var usrMessage = "";

    selectedTree.forEach(element => {
      /***Start***/
      if (element) {
        console.log("selectedtree inside method" + element);
        let type = element.type;
        let typeLabel = element.typeLabel;
        let itemId = element.id;

        var nodeType = type;
        var nodeTypeLabel = typeLabel;

        if ((nodeType == 1003 && nodeTypeLabel == "CPSC Domain") ||
          (nodeType == 1003 && nodeTypeLabel == "Prefix") ||
          (nodeType == 1003 && nodeTypeLabel == "Part Base") ||
          (nodeType == 1003 && nodeTypeLabel == "Program") ||
          (nodeType == 1003 && nodeTypeLabel == "Trans Program") ||
          (nodeType == 1003 && nodeTypeLabel == "Drive Train") ||
          (nodeType == 1003 && nodeTypeLabel == "Design Team") ||
          (nodeType == 1003 && nodeTypeLabel == "Vehicle Program") ||
          (nodeType == 1003 && nodeTypeLabel == "Model Year") ||
          (nodeType == 1003 && nodeTypeLabel == "All") ||
          (nodeType == 1003 && nodeTypeLabel == "Program") ||
          (nodeType == 1003 && nodeTypeLabel == "V Engine Program") ||
          (nodeType == 1003 && nodeTypeLabel == "I Engine Program") ||
          (nodeType == 1003 && nodeTypeLabel == "Displacement")) {
          allFlag = false;
        }
        // console.log('<----allFlag---->', allFlag);
        if (allFlag) {
          allItemIds += itemId + ",";
        } else {
          usrMessage += "\n" + itemId;
        }
      }
      /***End***/
    });
    this.treeService.pasteType = "COPY";
    this.treeService.copyIds = allItemIds;
    //alert("copyIds");
    if (!allFlag) {
      alert("The following item can not be copied: \n \n" + usrMessage);
    }
    console.log('Copy List:', this.treeService.copyIds)
  };

  OnPasteDialog(data: any) {
    console.log('Paste Dialog:', data[0]);
    console.log("pasteDestinationId", data[0].id);
    console.log("pasteType", this.treeService.pasteType);
    console.log('this.rowSelectedData:', this.rowSelectedData)
    // var selectedTree = data;
    var destId = data[0].id + ",";
    // var rootNode = selectedTree.selectedNode;
    // var allItemIds = "";

    if (this.treeService.pasteType == 'COPY') {
      this.treeService.showProgress(true);
      this.copyPasteService.doCopyFile(this.treeService.copyIds, destId).subscribe((data) => {
        console.log('<----COPY RESPONSE---->', data)
        this.onPasteCallBack(data);
      });
    }

    if (this.treeService.pasteType == 'CUT') {
      this.treeService.showProgress(true);
      this.copyPasteService.doCutPasteFile(this.treeService.copyIds, destId).subscribe((data) => {
        console.log('<----CUT RESPONSE---->', data)
        this.onPasteCallBack(data);
      });
    }

  };

  OnPasteShortCutDialog(data: any) {
    console.log("OnPasteShortCutDialog*************", data);
    console.log("pasteType", this.treeService.pasteType);
    // var selectedTree = data;
    var destId = data[0].id + ",";
    // var rootNode = selectedTree.selectedNode;
    // var allItemIds = "";
    this.treeService.showProgress(true);
    this.copyPasteService.doPasteShortcut(this.treeService.copyIds, destId).subscribe((data) => {
      console.log('<---- PASTE SHORTCUT---->', data)
      this.onPasteShortCutCallBack(data);
    });
    // YAHOO.util.Connect.asyncRequest('GET', sUrl, callback2, null);
  };

  OndeleteDialog = (data: any) => {
    // console.log("OndeleteDialog***************", data);
    var selectedNode = null;
    selectedNode = data;
    var allIds = "";
    var excludeIds = "";
    var parentLabel = "other"

    selectedNode.forEach((rowSelectData) => {
      // console.log('element----->', rowSelectData);
      let temp: any = rowSelectData;
      // console.log(temp.id);
      // console.log(temp.type)

      let type = temp.type;
      let itemId = temp.id;
      let label = temp.name;
      let parentLabel_in_Datatable = temp.parentLabel;
      let subsquentId = temp.subsquentId;

      if (subsquentId === 'NO') {
        alert("Top-Level items may not be deleted");
        return false;
      }

      if (type == 1003) {
        excludeIds += " <br/>" + label;
      } else {
        allIds += itemId + ",";
      }
      if (parentLabel_in_Datatable == "Recycle Bin" || temp.rootLabel == "Recycle Bin") {
        parentLabel = "Recycle Bin"
      }
    });

    // console.log('<=======allIds====excludeIds======>', allIds, excludeIds);
    if (parentLabel == "Recycle Bin") {
      if (excludeIds && excludeIds.length) {
        alert("The following Vault Items cannot be deleted " + excludeIds);
      } else {
        // console.log('allIds', allIds);
        this.deleteService.doPreDelete(allIds).subscribe((data) => {
          // console.log('doPreDelete data----->', data);
          if (data.result) {
            this.callBack1(data.result.deleteItemApi.message, allIds, parentLabel);
          } else {
            alert(data.error.errorCode);
          }
        });
      }
    } else {
      if (excludeIds && excludeIds.length) {
        alert("The following Vault Items cannot be deleted " + excludeIds);
      } else {
        this.deleteService.doPreMarkDelete(allIds).subscribe((data) => {
          // console.log('doPreMarkDelete data----->', data);
          if (data.result) {
            this.callBack1(data.result.deleteItemApi.message, allIds, parentLabel);
          } else {
            alert(data.error.errorCode);
          }
        });
      }
    }
  };

  OnRestoreItemDialog = (data: any) => {
    console.log('OnRestoreItemDialog data----->', data);
    var selectedNode = data;//getActualSelectedTree();
    // var rootNode = selectedTree.selectedNode;
    // var depth = rootNode.depth;
    var allItemIds = "";
    // var parentLabel = "other"


    for (let rowSelectData of selectedNode) {
      console.log('element----->', rowSelectData);
      let temp: any = rowSelectData;
      // console.log('id --->', temp.id);
      // console.log('type --->', temp.type)
      // console.log('Parent --->', temp.parentLabel)
      let parentLabel_in_Datatable = temp.parentLabel;
      if (parentLabel_in_Datatable == "Recycle Bin" || temp.rootLabel == "Recycle Bin") {
        // parentLabel = "Recycle Bin"
        allItemIds += temp.id + ",";
      }
    }
    // commented by mprabh24 on 15/7/2020
    // selectedNode.forEach((rowSelectData) => {
    //   console.log('element----->', rowSelectData);
    //   let temp: any = rowSelectData;
    //   // console.log('id --->', temp.id);
    //   // console.log('type --->', temp.type)
    //   // console.log('Parent --->', temp.parentLabel)
    //   let parentLabel_in_Datatable = temp.parentLabel;
    //   if (parentLabel_in_Datatable == "Recycle Bin") {
    //     // parentLabel = "Recycle Bin"
    //     allItemIds += temp.id + ",";
    //   }
    // });
    // console.log('allItemIds --->', allItemIds);
    if (allItemIds.length > 0) {//if (parentLabel == "Recycle Bin") {



      // Commented by mprabh24 on 9/7/2020 -Start
      //if (selectedNode) {
      //allItemIds = selectedNode[0].id + ",";
      //}
      //console.log('allItemIds --->', allItemIds);
      //if (allItemIds !== '') {//if (parentLabel == "Recycle Bin") {
      // Commented by mprabh24 on 9/7/2020 -End

      //	alert(rootNode.data.label + " Restore items from Recycle Bin calling .."+ allItemIds);
      // YAHOO.util.Connect.asyncRequest('GET', 'deleteItemAction?actionID=doPreRestore&itemIds=' + allItemIds, callback2, null);
      this.deleteService.doPreRestore(allItemIds).subscribe((data) => {
        // console.log('doPreRestore data----->', data);
        if (data.result) {
          this.OnRestoreItemCallback2(data, allItemIds);
        } else if (data.error) {
          alert(data.error.errorCode);
        }
      });
      //	alert("After pre-restore Request "+ allItemIds);
    }
  };

  OnRefreshFromDatabase = (data: any) => {
    var treeRetention = this.treeService.trees[this.treeService.selectedTab].selectedNodeRetention;
    // console.log('////////ON REFRESH FROM DATABASE //////TREE RETENTION////', treeRetention);
    // console.log('////////ON REFRESH FROM DATABASE ////DATATABLE ROW//////', this.rowSelectedData[0]);
    if (treeRetention) {
      if (data && data[0]) {
        if (data[0].type.toString() === '32' || data[0].type.toString() === '64') {
          // console.log('/////////CHECK//////////');
          if (treeRetention.children && treeRetention.children.length) {
            treeRetention.children = [];
            var currentTreeNode = treeRetention;
            this.treeService.trees[this.treeService.selectedTab].loadNode(currentTreeNode);
          } else {
            // console.log('/////TREE///RETENTION///PARENT///////////////', treeRetention.parent);
            this.treeService.trees[this.treeService.selectedTab].selectedFile2 = treeRetention.parent;
            this.treeService.trees[this.treeService.selectedTab].selectedFile2.children = [];
            var currentTreeNode = this.treeService.trees[this.treeService.selectedTab].selectedFile2;
            this.treeService.trees[this.treeService.selectedTab].loadNode(currentTreeNode);
          }
        } else {
          // console.log('///////////////ON REFRESH FROM DATABASE ////////ELSE////////////');
          if (data && data.length) {
            if (treeRetention.children && treeRetention.children.length) {
              // console.log('/////////TREE RENTION//////CHILDREN EXIST////////////////////');
              treeRetention.children.forEach(file => {
                // console.log('///////////////////////////////',data.name,'/////////////////////////////',file.label);
                if (data[0].name.trim() === file.label.trim()) {
                  // console.log('///////////INSIDE IF////////////');
                  this.treeService.trees[this.treeService.selectedTab].selectedFile2 = file;
                  this.treeService.trees[this.treeService.selectedTab].selectedFile2.children = [];
                  var currentTreeNode = this.treeService.trees[this.treeService.selectedTab].selectedFile2;
                  this.treeService.trees[this.treeService.selectedTab].loadNode(currentTreeNode);
                }
              });
            } else {
              // console.log('/////////TREE RENTION//////CHILDREN NOT EXIST////////////////////');
              this.treeService.trees[this.treeService.selectedTab].loadNode(treeRetention);
            }
          }
        }
      }
    }
  };

  onPasteCallBack(data: any) {
    var paste_creation = data;

    this.treeService.showProgress(false);
    if (paste_creation.result) {
      var api = paste_creation.result.copyPasteItemApi === undefined ? paste_creation.result.cutPasteItemApi : paste_creation.result.copyPasteItemApi;
      this.treeService.showAlert(api.message);
      console.log('this.rowSelectedData Paste:', this.rowSelectedData);
      console.log(' this.globalRightClickRowSelectedData:', this.globalRightClickRowSelectedData);

      this.refreshTreeAndTable(this.globalRightClickRowSelectedData[0]);
      //this.refreshTreeAndTable(this.rowSelectedData[0]);
    }
    else if (paste_creation.error) {
      this.treeService.showAlert(paste_creation.error.errorCode);
    }
    if (this.cutNode != null) {
      // resetNode(cutNode);
      this.cutNode = null;
    }
  }

  onPasteShortCutCallBack(data: any) {
    var shortcut_creation = data;

    this.treeService.showProgress(false);
    if (shortcut_creation.result) {
      var api = shortcut_creation.result.copyPasteItemApi === undefined ? shortcut_creation.result.cutPasteItemApi : shortcut_creation.result.copyPasteItemApi;
      this.treeService.showAlert(api.message);
      console.log(' this.globalRightClickRowSelectedData:', this.globalRightClickRowSelectedData);
      this.refreshTreeAndTable(this.globalRightClickRowSelectedData[0]);
      //this.refreshTreeAndTable(this.rowSelectedData[0]);
    }
    else if (shortcut_creation.error) {
      this.treeService.showAlert(shortcut_creation.error.errorCode);
    }
  }

  OnRestoreItemCallback2(data: any, allItemIds: any) {
    var resultStr = "";

    console.log('this.rowSelectedData ----->', this.rowSelectedData);
    // if (this.rowSelectedData.toString() == "Recycle Bin") { //parentLabel
    //	alert(" If Block");
    //alert(" delItems_details "+ delItems_details.length);
    resultStr += "APDM will attempt to restore all files and folders to their original" + '\n';
    resultStr += " location. If for some reason that's not possible, APDM will " + '\n';
    resultStr += "put them in the Restore Bin with the names changed to <itemID>_<name>." + '\n';
    resultStr += "The last know path will be added to the Description field of each item." + '\n\n';
    resultStr += "" + '\n\n' + "Do you want to RESTORE?"
    var r;
    // alert(" ALl IDs Len :: " +allItemIds.length);
    if (allItemIds.length > 0) {
      r = confirm(resultStr);
    }
    if (r == true) {
      this.deleteService.doRestore(allItemIds).subscribe((data) => {
        console.log('doRestore data----->', data);
        if (data.result) {
          this.OnRestoreItemCallBack3(data);
        } else {
          alert(data.error.errorCode);
        }
      });
    } else {
      alert("Cancelling the Restore operation  ");
    }
  }

  callBack1(resultData: any, allIds: any, parentLabel: any) {
    // console.log('/// Start Callback 1 ///', this.rowSelectedData.toString());
    // console.log('/// Start Callback 1 ///', this.rowSelectedData);
    // var delItems_details: any[] = [];
    // let allIds: string = "";
    // let excludeIds: string = "";
    // console.log(this.rowSelectedData[0].treedata.data[0].type)
    // if (this.rowSelectedData[0].treedata.data[0].type == '1003') { //.type 1003
    //   excludeIds += " <br/>" + this.rowSelectedData.toString();//.label;
    // } else {
    //   allIds = this.rowSelectedData[0].treedata.data[0].id;//.id + ",";
    // }
    // console.log('<----- callBack1 ----->', allIds);
    // var delItems_details = null;
    var resultStr = "";
    if (resultData) {//if (resultData === 'success') {
      // console.log('/// success ///');
      this.deleteItemResponse = <DeleteItemResponse[]>JSON.parse(resultData);
      // console.log(this.deleteItemResponse);
      // console.log('/// this.deleteItemResponse length///', this.deleteItemResponse.length);
      // console.log('/// this.rowSelectedData.parentLabel////////', parentLabel);
      if (this.deleteItemResponse != null && parentLabel != "Recycle Bin") {
        // this.rowSelectedData[0].treedata.parent.label != "Recycle Bin") { //.parentLabel

        resultStr += "The following VAULT";

        if (this.deleteItemResponse.length == 1) {
          resultStr += " Item ";
        } else if (this.deleteItemResponse.length > 1) {
          resultStr += " Items ";
        }
        resultStr += "will be deleted:" + '\n\n';

        for (let delItem of this.deleteItemResponse) {
          //console.log("delItem....", delItem);

          //resultStr += delItem.label + '\n';

          resultStr += delItem.label + '\n';

          var itemCount = delItem.count;
          if (itemCount == 1) {

          } else if (itemCount == 2) {
            resultStr += " (Warning : contains " + (itemCount - 1) + " Item )" + '\n';
          } else if (itemCount > 2) {
            resultStr += " (Warning : contains " + (itemCount - 1) + " Items )" + '\n';
          }
        }
        resultStr += "" + '\n\n' + "Do you want to DELETE it?"
        // console.log('/// resultStr ///', resultStr);
        if (resultStr.length > 0) {
          var r;
          if (resultStr.length > 0) {
            r = window.confirm(resultStr);
          }
          if (r == true) {
            console.log(allIds);
            this.deleteService.doMarkDelete(allIds).subscribe((data) => {
              console.log('doMarkDelete accept---->', data)
              if (data.result) {
                alert(data.result.deleteItemApi.message);
                if (this.treeService.trees[this.treeService.selectedTab].selectedNodeRetention) {
                  this.treeService.trees[this.treeService.selectedTab].selectedFile2 = this.treeService.trees[this.treeService.selectedTab].selectedNodeRetention;
                  this.treeService.trees[this.treeService.selectedTab].selectedFile2.children = [];
                  var currentTreeNode = this.treeService.trees[this.treeService.selectedTab].selectedFile2;
                  this.treeService.trees[this.treeService.selectedTab].loadNode(currentTreeNode);
                }
              }
            });
          } else {
            console.log('doMarkDelete reject---->')
          }
        }
      } else if (this.deleteItemResponse != null && parentLabel == "Recycle Bin") {
        // this.rowSelectedData[0].treedata.parent.label == "Recycle Bin") {//.parentLabel
        resultStr += "The following VAULT";
        if (this.deleteItemResponse.length == 1) {
          resultStr += " Item ";
        } else if (this.deleteItemResponse.length > 1) {
          resultStr += " Items ";
        }
        resultStr += "will be deleted:" + '\n\n';
        for (let delItem of this.deleteItemResponse) {
          resultStr += delItem.label + '\n';
        }
        resultStr += "" + '\n\n' + "Do you want to DELETE it?"
        if (resultStr.length > 0) {
          var r;
          if (resultStr.length > 0) {
            r = confirm(resultStr);
          }
          if (r == true) {
            console.log(allIds);
            this.deleteService.doDelete(allIds).subscribe((data) => {
              console.log('doDelete accept---->', data)
              if (data.result) {
                alert(data.result.deleteItemApi.message);
                if (this.treeService.trees[this.treeService.selectedTab].selectedNodeRetention) {
                  this.treeService.trees[this.treeService.selectedTab].selectedFile2 = this.treeService.trees[this.treeService.selectedTab].selectedNodeRetention;
                  this.treeService.trees[this.treeService.selectedTab].selectedFile2.children = [];
                  var currentTreeNode = this.treeService.trees[this.treeService.selectedTab].selectedFile2;
                  this.treeService.trees[this.treeService.selectedTab].loadNode(currentTreeNode);
                }
              } else {
                // alert();
              }
            });
            // var deleteUrl1 = 'deleteItemAction?actionID=doDelete&itemIds=' + allIds;
            // YAHOO.util.Connect.asyncRequest('GET', deleteUrl1, callback2, null);
          } else {
            console.log('doDelete reject---->')
          }
        }
      }
    } else {
      alert("Error while Deleting");
    }
    // console.log('/// END Callback ///');
  }

  OnRestoreItemCallBack3(data: any) {
    var msg = data;
    if (msg.result) {
      alert(msg.result.restoreItemApi.message);
      if (this.treeService.trees[this.treeService.selectedTab].selectedNodeRetention) {
        console.log('/////////////OnRefreshFromDatabase////////1////////////', this.treeService.trees[this.treeService.selectedTab].selectedNodeRetention);
        if (this.treeService.trees[this.treeService.selectedTab].selectedNodeRetention.parent) {
          this.treeService.trees[this.treeService.selectedTab].selectedNodeRetention.parent.children = [];
          this.treeService.trees[this.treeService.selectedTab].loadNode(this.treeService.trees[this.treeService.selectedTab].selectedNodeRetention.parent);
        } else {
          this.treeService.trees[this.treeService.selectedTab].selectedNodeRetention.children = [];
          this.treeService.trees[this.treeService.selectedTab].loadNode(this.treeService.trees[this.treeService.selectedTab].selectedNodeRetention);
        }
      }
    } else {
      alert(msg.error.errorCode);
      if (this.treeService.trees[this.treeService.selectedTab].selectedNodeRetention) {
        console.log('/////////////OnRefreshFromDatabase////////2////////////', this.treeService.trees[this.treeService.selectedTab].selectedNodeRetention);
        if (this.treeService.trees[this.treeService.selectedTab].selectedNodeRetention.parent) {
          this.treeService.trees[this.treeService.selectedTab].selectedNodeRetention.parent.children = [];
          this.treeService.trees[this.treeService.selectedTab].loadNode(this.treeService.trees[this.treeService.selectedTab].selectedNodeRetention.parent);
        } else {
          this.treeService.trees[this.treeService.selectedTab].selectedNodeRetention.children = [];
          this.treeService.trees[this.treeService.selectedTab].loadNode(this.treeService.trees[this.treeService.selectedTab].selectedNodeRetention);
        }
      }
    }
  }

  onRowSelected(event) {
    console.log('/////////////////ON ROW SELECTED/////////////////////////////', event);
    // console.log('rowSelectedData', this.rowSelectedData);
    // console.log('////////ON DATATABLE ROW SELECTED EVENT//////ACTIVE TAB/////', this.treeService.selectedTab);
    // console.log('////////ON DATATABLE ROW SELECTED EVENT////////////////',this.treeService.currentTreeSelectionNode);
    this.treeService.treeSelectionRetention[this.treeService.selectedTab].selectedNodeRetention = this.treeService.currentTreeSelectionNode;

    this.treeService.treeSelectedEmitter.emit(null);
    this.treeService.dataTableSelectedEmitter.emit(this.rowSelectedData);
    this.fileMenuTreeDataTableData.emit(this.rowSelectedData);

    // Selected row shared to File menu
    this.treeService.rowSelectDatatableValue.next(this.rowSelectedData);

    if (this.rowSelectedData && this.rowSelectedData.length) {
      this.contectMenuDisable = false;
    }
    //File Menu Checkout
    if (event) {
      if (event.data) {
        this.fileMenuDownload = new CheckOut();
        this.fileMenuDownload.id = event.data.id;
        this.fileMenuDownload.label = event.data.name;
        this.fileMenuDownload.type = event.data.typeLabel;
        if (this.fileMenuDownload) {
          this.checkOutService.fileMenuCheckOut = new CheckOut();
          this.checkOutService.fileMenuCheckOut = this.fileMenuDownload;
        }
      }
    }

    // highlight color change
    this.treeService.treeselectionsync.next(this.rowSelectedData);
    //this.treeService.treeselectionsync.next({message1:this.rowSelectedData, message2:'rowselect'});
    // console.log('this.contectMenuDisable  ---> ', this.contectMenuDisable);
  }

  //added by mprabh24 on 12/23/2019
  onRowUnSelected(event) {
    // console.log('event row unSelect ', event);
    // console.log('//////////ON- ROW UN SELECT EVENT////////////', this.rowSelectedData);
    console.log('rowSelectedData length ', this.rowSelectedData.length);

    this.treeService.treeSelectedEmitter.emit(null);
    this.treeService.dataTableSelectedEmitter.emit(this.rowSelectedData);
    this.fileMenuTreeDataTableData.emit(this.rowSelectedData);

    // Selected row shared to File menu
    this.treeService.rowSelectDatatableValue.next(this.rowSelectedData);

    if (this.rowSelectedData.length == 0) {
      this.contectMenuDisable = true;
    }
    // console.log('this.contectMenuDisable  ---> ', this.contectMenuDisable);
    //this.treeService.treeselectionsync.next({message3:this.rowSelectedData, message4:'rowunselect'});
    //this.treeService.treeselectionsync.next({message3:'rowunselect'});
    this.treeService.treeunselectionsync.next(this.rowSelectedData);
  }

  onContextMenuSelected(event) {
    console.log('//////////ON CONTEXT MENU SELECTED////////////////////', event);
    console.log('/////////////CURRENT TREE SELECTION NODE///////////////////////////', this.treeService.currentTreeSelectionNode);

    // if (this.rowSelectedData && this.rowSelectedData.length == 0) {
    //   this.rowSelectedData.push(event.data);
    // }

    // console.log('onContextMenuSelected rowSelectedData ', this.rowSelectedData);
    this.contextMenuRowSelectedData.push(event.data);
    this.contectMenuDisable = false;
    // console.log('onContextMenuSelected contextMenuRowSelectedData ', this.contextMenuRowSelectedData);
    this.setContextMenu(event.data);
    this.treeService.resetTreeTableOnDataSelectevent.next(null);
  }

  OnEditPropertieDialog(data: DataTableHeaders[]) {
    // console.log('OnEditPropertieDialog Method', data);
    this.editProperties.emit(data);
  }

  tmpRowSelectedData: DataTableHeaders[];

  onPage(event) {
    console.log('onPage event ===>', event);
    if (this.rowSelectedData.length === 0) {
      this.rowSelectedData = [];
    } else if (this.contextMenuRowSelectedData.length > 0) {
      // this.tmpRowSelectedData = [];
      // this.contextMenuRowSelectedData.forEach(contextMenuData => {
      //   console.log('contextMenuData ====>', contextMenuData.name);
      //   console.log('this.rowSelectedData.indexOf(contextMenuData) ====>', this.rowSelectedData.indexOf(contextMenuData));
      // });
      // console.log('contextMenuRowSelectedData onPage else!!!!!!!!1', this.contextMenuRowSelectedData);
      // console.log('rowSelectedData onPage else!!!!!!!!1', this.rowSelectedData);
      // this.tmpRowSelectedData = this.rowSelectedData;
      // this.rowSelectedData = [];
      // this.contextMenuRowSelectedData = [];
      // console.log('contextMenuRowSelectedData onPage else!!!!!!!!1', this.contextMenuRowSelectedData);
      // console.log('rowSelectedData onPage else!!!!!!!!2', this.rowSelectedData);
    }
    // else if (this.rowSelectedData.length > 0) {
    //   this.tmpRowSelectedData = [];
    //   this.tmpRowSelectedData = this.rowSelectedData;
    //   this.rowSelectedData = [];
    // }

    // if (this.rowSelectedData.length === 0) {
    //   this.rowSelectedData = this.tmpRowSelectedData;
    //   this.tmpRowSelectedData = [];
    //   console.log('rowSelectedData onPage else!!!!!!!!3', this.rowSelectedData);
    // }
  }

  refreshTreeAndTable(data: any) {
    console.log('///////////REFRESH TREE AND TABLE////////////////', data);
    if (this.treeService.trees[this.treeService.selectedTab].selectedFile2) {
      // console.log('///////////SELETED TAB////////////////');
      this.treeService.trees[this.treeService.selectedTab].selectedFile2.children.forEach(file => {
        if (data.name.trim() === file.label.trim()) {
          this.treeService.trees[this.treeService.selectedTab].selectedFile2 = file;
          var treeData = { parent: this.treeService.trees[this.treeService.selectedTab].selectedFile2, ownerTreeId: this.ownerTreeId };
          this.treeService.treeDatatablesync.emit(treeData);
        }
      });
    } else if (this.treeService.trees[this.treeService.selectedTab].selectedNodeRetention) {
      // console.log('///////////TAB RETENTION////////////////');
      this.treeService.trees[this.treeService.selectedTab].selectedNodeRetention.children.forEach(file => {
        if (data.name.trim() === file.label.trim()) {
          this.treeService.trees[this.treeService.selectedTab].selectedFile2 = file;
          this.treeService.trees[this.treeService.selectedTab].selectedFile2.children = [];
          var currentTreeNode = this.treeService.trees[this.treeService.selectedTab].selectedFile2;
          this.treeService.trees[this.treeService.selectedTab].loadNode(currentTreeNode);
        }
      });
    }
  }

  stopDownloadFunc() {
    this.stopDownloadFile = true;
  }

  fileDownloadPreSignedUrl(checkOutData: CheckOut) {
    console.log('inside the fileDownloadPreSignedUrl method:', checkOutData)
    this.checkOutService.fileCheckOutPreSignedUrl(checkOutData).subscribe((resp: FileDownloadUrl) => {
      console.log('resp.url:', resp.url);
      console.log('resp.errorMsg:', resp.errorMsg);
      this.percentDone = 0;
      this.fileSize = '0';
      this.totalSize = '0';
      if (resp.url && resp.url.includes('htm')) {
        window.open(resp.url, "_blank");
      }
      if (resp && resp.url && !(resp.url.includes('htm'))) {

        this.req = new HttpRequest('GET', resp.url, {
          reportProgress: true,
          observe: 'response',
          responseType: 'blob',
        });

        console.log('req:', this.req);
        let checkOutunSubscibe = this.http.request(this.req).subscribe((event: HttpEvent<any>) => {
          console.log('first event:', event);
          this.loaderdisplay = true;
          //this.downloadSize = ''

          if (event.type === HttpEventType.DownloadProgress) {
            if (this.stopDownloadFile) {
              this.stopDownloadFile = false;
              this.loaderdisplay = false;
              this.errorMsg = "File/Folder Download Cancelled.";
              this.fileDownloadErrorMsg = true;
              checkOutunSubscibe.unsubscribe();
            } else {
              this.percentDone = Math.round(100 * event.loaded / event.total);
              //console.log('Percent:', this.percentDone);
              var marker = 1024; // Change to 1000 if required
              var decimal = 2; // Change as required
              var kiloBytes = marker; // One Kilobyte is 1024 bytes
              var megaBytes = marker * marker; // One MB is 1024 KB
              var gigaBytes = marker * marker * marker; // One GB is 1024 MB
              var teraBytes = marker * marker * marker * marker; // One TB is 1024 GB
              if (event.total) {
                if (event.total < kiloBytes)
                  this.totalSize = event.total + " Bytes";
                else if (event.total < megaBytes)
                  this.totalSize = (event.total / kiloBytes).toFixed(decimal) + " KB";
                else if (event.total < gigaBytes)
                  this.totalSize = (event.total / megaBytes).toFixed(decimal) + " MB";
                else
                  this.totalSize = (event.total / gigaBytes).toFixed(decimal) + " GB";
              }

              if (event.loaded) {
                if (event.loaded < kiloBytes)
                  this.fileSize = event.loaded + " Bytes";
                else if (event.loaded < megaBytes)
                  this.fileSize = (event.loaded / kiloBytes).toFixed(decimal) + " KB";
                else if (event.loaded < gigaBytes)
                  this.fileSize = (event.loaded / megaBytes).toFixed(decimal) + " MB";
                else
                  this.fileSize = (event.loaded / gigaBytes).toFixed(decimal) + " GB";

              }


              // console.log('totalSize:', this.totalSize);
              // console.log('this.fileSize:', this.fileSize);
              this.downloadSize = this.fileSize + ' / ' + this.totalSize;
            }
          } else if (event.type === HttpEventType.Response) {
            this.loaderdisplay = false;
            console.log('event:', event);
            console.log('event body:', event.body);
            console.log('File is completely downloaded!');
            let file = new Blob([event.body]);
            saveAs(file, checkOutData.label);
          }
        }, err => {
          console.log('Error:', err);
          this.attemptCount++;
          console.log('this.attemptCount:', this.attemptCount)
          if (this.attemptCount < 3) {
            this.downloadSize = '';
            this.downloadSize = 'Retry attempt ' + this.attemptCount;
            this.fileDownloadPreSignedUrl(checkOutData);
          } else {
            this.loaderdisplay = false;
          }
        });
      } else {
        console.log('else file download:', resp.errorMsg)
        if (resp && resp.errorMsg) {
          console.log('else else');
          this.errorMsg = '';
          this.errorMsg = resp.errorMsg;
          this.fileDownloadErrorMsg = true;
        }
      }

      // if (resp) {
      // 	this.checkOutService.downloadFile(resp).subscribe((resp: HttpResponse<Blob>) => {
      // 		console.log('blob response:', resp);
      // 		console.log('blob response:', resp.status);
      // 		console.log('blob response:', resp.statusText);
      // 		console.log('blob response:', resp.type);
      // 		let file = new Blob([resp.body]);
      // 		console.log(file.size + " bytes file downloaded. File type: ", file.type);
      // 		saveAs(file, data.label);
      // 	},
      // 	err => {
      // 		alert("Problem while downloading the file.");
      // 		console.log('Error :',err);
      // 		console.error(err);
      // 	  }
      // 	);
      // }
    }, error => {
      console.log('get parent error:', error)
    });
  }

  closeErrorMsg() {
    this.fileDownloadErrorMsg = false;
  }

  getExtensionType(type: string) {
    if (type == 'java' || type == 'zip' || type == 'jpg' || type == 'jpeg' || type == 'gif' ||
      type == 'png' || type == 'xls' || type == 'xlsx' || type == 'doc' || type == 'docx' || type == 'pdf' ||
      type == 'ppt' || type == 'rar' || type == 'tar' || type == 'mp3' || type == 'txt' || type == 'xml' ||
      type == 'html' || type == 'htm' || type == 'jar' || type == 'class' || type == 'msi' || type == 'exe' || type.length <= 4) {
      return true;
    } else {
      return false;
    }
  }

  onHideContextMenu() {
    console.log("onHideContextMenu()");
  }
}
