import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Item, Trans_Program } from 'app/core/model/itemAdminModels';

@Component({
  selector: 'trans-program-object',
  templateUrl: './trans-program-object.component.html',
  styleUrls: ['./trans-program-object.component.css']
})
export class TransProgramObjectComponent implements OnInit {

  transProgramObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()
  @Input('screenType') screenType: string;
  @Input('item') item: Item;
  @Input('transProgram') transProgram: Trans_Program;
  @Input('showfield') showfield: boolean;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
    this._updateTransProgramObjectForm();
  }

  _formClear() {
    this.transProgramObjectForm = this.formBuilder.group({
      id: [''],
      oldName: [''],
      continuous: [''],
      changeLevel: [''],
      config: [''],
      rmsProgramName: [''],
      altLabel: [''],
      comparatorProgram: [''],
      curApproval: [''],
      workTaskNum: [''],
      listDomains: [''],
      torqueCapacity: [''],
      endOfLife: [''],
      comments: [''],
      lifeCode: [''],
      driveTrain: [''],
      modelYearEnd: [''],
      jobOne: [''],
      pmtDomain: [''],
      modelYear: [''],
      brand: [''],
      numSpeeds: [''],
      leadVehicle: [''],
      kickOff: ['']
    });
    this.formReady.emit(this.transProgramObjectForm);
  }

  _updateTransProgramObjectForm() {
    this.transProgramObjectForm.patchValue({
      id: this.item.id,
      oldName: this.transProgram.transprog_old_name,
      continuous: this.transProgram.transprog_continuous,
      changeLevel: this.transProgram.transprog_change_level,
      config: this.transProgram.transprog_config,
      rmsProgramName: this.transProgram.transprog_rms_prog_name,
      altLabel: this.transProgram.transprog_alt_label,
      comparatorProgram: this.transProgram.transprog_comp_prog,
      curApproval: this.transProgram.transprog_cur_approval,
      workTaskNum: this.transProgram.transprog_work_task_num,
      listDomains: this.transProgram.transprog_list_domain,
      torqueCapacity: this.transProgram.transprog_torque_capacity,
      endOfLife: this.transProgram.end_of_life,
      comments: this.transProgram.transprog_comments,
      lifeCode: this.transProgram.transprog_life_code,
      driveTrain: this.transProgram.transprog_drive_train,
      modelYearEnd: this.transProgram.transprog_model_year_end,
      jobOne: this.transProgram.job_one,
      pmtDomain: this.transProgram.transprog_pmt_domain,
      modelYear: this.transProgram.transprog_model_year,
      brand: this.transProgram.transprog_brand,
      numSpeeds: this.transProgram.transprog_num_speeds,
      leadVehicle: this.transProgram.transprog_lead_vehicle,
      kickOff: this.transProgram.kick_off
    })
  }

}
