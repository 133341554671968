import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Item, Optegra } from 'app/core/model/itemAdminModels';

@Component({
  selector: 'optegra-item-object',
  templateUrl: './optegra-item-object.component.html',
  styleUrls: ['./optegra-item-object.component.css']
})
export class OptegraItemObjectComponent implements OnInit {

  optegraItemObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()
  @Input('screenType') screenType: string;
  @Input('item') item: Item;
  @Input('optegraItem') optegraItem: Optegra;
  @Input('showfield') showfield: boolean;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
    this._updateOptegraItemObjectForm();
  }

  _formClear() {
    this.optegraItemObjectForm = this.formBuilder.group({
      id: [''],
      comments: [''],
      partType: [''],
      revision: [''],
      optegraStatus: [''],
      migratedOn: ['']
    });
    this.formReady.emit(this.optegraItemObjectForm);
  }

  _updateOptegraItemObjectForm() {

    this.optegraItemObjectForm.patchValue({

      id: [this.item.id],
      comments: [this.optegraItem.optegra_comments],
      partType: [this.optegraItem.optegra_part_type],
      revision: [this.optegraItem.optegra_revision],
      optegraStatus: [this.optegraItem.optegra_status],
      migratedOn: [this.optegraItem.optegra_migrated_on]

    })

  }

}
