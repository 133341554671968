import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders} from '@angular/common/http';
import { environment } from "../../../environments/environment";
import {listAfrFilesForFolderRequest} from "../model/retentionfolderlevel";
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class RetentionService {

  private teamURI = environment.apdmServiceUrl;

  private headers: HttpHeaders;

  constructor(private http: HttpClient) { }


  public getretentionTable(){
  //  console.log(this.teamURI+'dataview',result );


    return this.http.get(this.teamURI+'getRetCode',{
      headers: this.headers,
      });
  }

  public getretention() {
    //  console.log(this.teamURI+'dataview',result );


    return this.http.get(this.teamURI + 'getRetScheduleListAfr/', {
      headers: this.headers,
    });
  }


  public getAfrFolderLevel( cdsid: String  , year: number){
    //  console.log(this.teamURI+'dataview',result );
      return this.http.post(this.teamURI+'listAfrFolderLevel/'+year , cdsid);
    }

    public getViewAfrFolderLevel( cdsid: listAfrFilesForFolderRequest){
      //  console.log(this.teamURI+'dataview',result );


        return this.http.post(this.teamURI+'listAfrFilesForFolder',cdsid);
      }

    public deleteAFRFolderLevel( deleteObj: listAfrFilesForFolderRequest){
        return this.http.post(this.teamURI+'deleteAFRFolderLeval',deleteObj);
    }
    public updateAFRFolderLevel( updateObj: listAfrFilesForFolderRequest): Observable<string>{
      console.log('tes1111t:',updateObj)
      return this.http.post(this.teamURI+'updateafrfolderlevel',updateObj, {
        responseType: 'text',
      });
    }
    public deleteAFRItem(deleteAFRItemObj: listAfrFilesForFolderRequest){
      return this.http.post(this.teamURI+'deleteAFRitem',deleteAFRItemObj);
    }
    editAFRItem( editAFRItemObj: listAfrFilesForFolderRequest): Observable<string>{
      return this.http.post(this.teamURI+'editafr1',editAFRItemObj, {
        responseType: 'text',
      });
    }

    updateViewSuspensionOrder(updateSuspensionObj: listAfrFilesForFolderRequest): Observable<string> {
      return this.http.post(this.teamURI + 'updateSuspensionOrder', updateSuspensionObj, {
        responseType: 'text',
      });
    }
    updateViewSuspensionOrderFolder(updateSuspensionObj: listAfrFilesForFolderRequest): Observable<string> {
      return this.http.post(this.teamURI + 'updateSuspensionOrderFolder', updateSuspensionObj, {
        responseType: 'text',
      });
    }
}
