import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ViewPersonResponse } from 'app/core/model/viewPersonModel';


@Component({
  selector: 'view-person',
  templateUrl: './view-person.component.html',
  styleUrls: ['./view-person.component.css']
})
export class ViewPersonComponent implements OnInit {
  @ViewChild('menuItems') menu: MenuItem[];
  @Input('viewPerson') viewPerson: ViewPersonResponse;
 viewPersonForm: FormGroup;
  PropertieItems: MenuItem[];
  activeItem: MenuItem;
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    console.log(this.viewPerson);
 
    this.PropertieItems = [
      { label: 'Description', id: 'description' },
      { label: 'Member of', id: 'member' },
      { label: 'Access Sharing', id: 'access_share' },

    ];
    this.viewPersonForm = this.formBuilder.group({
    });
    this.activeItem = this.PropertieItems[0];
  }
  activateMenu() {
    this.activeItem = this.menu['activeItem'];
    console.log(this.activeItem.id);
  }


}
