export class CheckOut {
  id: string;
  label: string;
  type: string;
  version: string;

}

export class FileDownloadUrl {
  url: string;
  errorMsg: string;
  urlType: string;
}
export class TempPath{
path:string;
}