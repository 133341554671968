import { Component, OnInit } from '@angular/core';
import { RetentionHeaders, RetentionHeaderResposne } from '../../core/model/afr-retention';

import { RetentionFolderLevelHeaders, listAfrFilesForFolderRequest, FileView } from '../../core/model/retentionfolderlevel';
import { RetentionService } from '../../core/service/retention.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'afr-fordlevel',
  templateUrl: './afr-fordlevel.component.html',
  styleUrls: ['./afr-fordlevel.component.css']
})
export class AfrFordlevelComponent implements OnInit {

  RetentionFolderLevelData: RetentionFolderLevelHeaders[];
  retention: any[];
  cols: any[];
  viewcols: any[];
  subsciptiondatatable: Subscription;
  subsciptiondeleteRow: Subscription;
  viewfolderlevel: listAfrFilesForFolderRequest;
  ViewFileData: any;
  viewFolder: boolean;
  cdsid: string = "";
  year: number;
  fileName:string;
  refreshViewFiles:string ="";
  refreshRetCode:string = "";
  constructor(private service: RetentionService, private _Activatedroute: ActivatedRoute) { }

  ngOnInit() {
    this._Activatedroute.paramMap.subscribe(params => {
      this.cdsid = params.get('cdsid');
      this.year = Number(params.get('year'))
    });

    this.cols = [
      { field: 'parentFolder', header: 'Parent Folder' },
      { field: 'fileName1', header: 'File/Folder Name' },
      { field: 'retention', header: 'Retention' },
      { field: 'suspOrder', header: 'Suspension Order' },
      { field: 'delete', header: 'Delete' },
      { field: 'viewFiles', header: 'View Files' },
      { field: 'count', header: 'No of Files' },
      { field: 'update', header: 'Update' },
    ];

    this.subsciptiondatatable = this.service.getAfrFolderLevel(this.cdsid, this.year).subscribe((data: any) => {
      console.log('afrfolderlevel', data);
      this.RetentionFolderLevelData = data;
    }, (error) => {
    });

    this.subsciptiondatatable = this.service.getretention().subscribe((data: any) => {
      this.retention = data;
    }, (error) => {
    });
  }
  refresh(id,retCode,label){
    console.log('Label:',label);
    this.fileName ="";
    this.refreshViewFiles = "";
    this.refreshRetCode = "";
    this.refreshViewFiles = id;
    this.fileName = label;
    if (retCode && retCode.id !== undefined) {
      this.refreshRetCode = retCode.id;
    } else {
      this.refreshRetCode = retCode;
    }    
    console.log('Refresh:',this.refreshViewFiles);
    console.log('Refresh:',this.refreshRetCode);
  }

  viewsubItem(viewFiles, retcode) {     
    console.log("View File:", viewFiles);
    console.log("Retcode:", retcode);  
this.viewfolderlevel = new listAfrFilesForFolderRequest();
    this.viewfolderlevel.cdsid = this.cdsid;
    this.viewfolderlevel.itemid = viewFiles;
    this.viewfolderlevel.retstatus = retcode;
    this.viewfolderlevel.year = String(this.year);
    console.log("ViewFolderLevel Input:", this.viewfolderlevel);
    this.subsciptiondatatable = this.service.getViewAfrFolderLevel(this.viewfolderlevel).subscribe((data: FileView) => {
      console.log('ViewFolderLevel Output:', data);
      this.ViewFileData = data;
          this.viewFolder = true;
      this.viewcols = [
        { field: 'fileFolderName', header: 'File/Folder Name' },
        { field: 'retention', header: 'Retention' },
        { field: 'suspOrder', header: 'Suspension Order' },
        { field: 'lastModifiedDate', header: 'Last Modified Date' },
        { field: 'delete', header: 'Delete' },
        { field: 'update', header: 'Update' },
      ];
    }, (error) => {
    })
  }

  deleteAFRFolderLevelItem(deleteRow) {
    console.log('deleteRow:',deleteRow)
    let name = 'Are you sure to delete ' +deleteRow.fileName1 +' containing '+deleteRow.count+' file(s)?';
    let status = confirm(name);
    if (status == true) {
      this.viewfolderlevel = new listAfrFilesForFolderRequest();
      this.viewfolderlevel.cdsid = this.cdsid;
      this.viewfolderlevel.itemid = deleteRow.id;
      this.viewfolderlevel.year = String(this.year);

      this.subsciptiondeleteRow = this.service.deleteAFRFolderLevel(this.viewfolderlevel).subscribe((data: any) => {
        console.log('afrfolderlevelDelete', data);
        if (data === 1) {
          alert('Deleted Successfully.');
          this.ngOnInit();
        }

      }, (error) => {
      })
    }
    else {
      return false;
    }
  }

  updateAFRFolderLevelItem(updateRow) {
    console.log('updateRow:', updateRow);
    console.log('updateRow.retention.id:', updateRow.retention.id);
    console.log('updateRow.retention:', updateRow.retention);
    this.viewfolderlevel = new listAfrFilesForFolderRequest();
    this.viewfolderlevel.cdsid = this.cdsid;
    this.viewfolderlevel.itemid = updateRow.id;
    this.viewfolderlevel.year = String(this.year);
    if (updateRow.retention && updateRow.retention.id !== undefined) {
      this.viewfolderlevel.retstatus = updateRow.retention.id;
    } else {
      this.viewfolderlevel.retstatus = updateRow.retention
    }
    this.viewfolderlevel.sorder = updateRow.suspOrder;
    console.log('Susp:', updateRow.suspOrder);
    console.log('Viewfolderlevel:', this.viewfolderlevel);
    if (updateRow.suspOrder) {
      console.log('updateViewSuspensionOrderFolder Input:', this.viewfolderlevel);
      this.subsciptiondatatable = this.service.updateViewSuspensionOrderFolder(this.viewfolderlevel).subscribe((data: string) => {
        console.log('updateViewSuspensionOrderFolder Output:', data);

        if (data === 'Successfully Updated') {
          alert('Suspension order applied  successfully and item has been removed from the list');
        }
        this.ngOnInit();
      }, (error) => {
      })
    } else {
      this.subsciptiondeleteRow = this.service.updateAFRFolderLevel(this.viewfolderlevel).subscribe((data: string) => {
        console.log('afrfolderlevelUpdate', data);
        alert(data);
        this.ngOnInit();
      }, (error) => {
      })
    }
  }

  deleteAFRItem(deleteItem) {
    console.log('DeleteItem:', deleteItem);
    let status = confirm("Are you sure want to delete the file?");
    if (status == true) {
      this.viewfolderlevel = new listAfrFilesForFolderRequest();
      this.viewfolderlevel.cdsid = this.cdsid;
      this.viewfolderlevel.itemid = deleteItem.id;
      this.viewfolderlevel.year = String(this.year);
      console.log('DeleteAFRItem:', this.viewfolderlevel);
      this.subsciptiondeleteRow = this.service.deleteAFRItem(this.viewfolderlevel).subscribe((data: any) => {
        console.log('DeleteAFRItem:', data);
        if (data === 1) {
          alert('Deleted Successfully.');
          this.viewsubItem(this.refreshViewFiles, this.refreshRetCode);
        }
      }, (error) => {
      });
    }
    else {
      return false;
    }
  }

  editAfr1(editItem) {
    console.log('EditItem:', editItem);
    this.viewfolderlevel = new listAfrFilesForFolderRequest();
    this.viewfolderlevel.cdsid = this.cdsid;
    this.viewfolderlevel.itemid = editItem.id;
    this.viewfolderlevel.year = String(this.year);
    if (editItem.retention && editItem.retention.id !== undefined) {
      this.viewfolderlevel.retstatus = editItem.retention.id;
    } else {
      this.viewfolderlevel.retstatus = editItem.retention
    }
    this.viewfolderlevel.sorder = editItem.suspOrder;
    console.log('Susp:', editItem.suspOrder);
    console.log('viewfolderlevel:', this.viewfolderlevel);
    if (editItem.suspOrder) {
      console.log('EditAfr1 Input:', this.viewfolderlevel);
      this.subsciptiondatatable = this.service.updateViewSuspensionOrder(this.viewfolderlevel).subscribe((data: string) => {
        console.log('EditAfr1 Output:', data);
        if (data === 'Successfully Updated') {
          alert('Suspension order applied  successfully and item has been removed from the list');
        }
        this.viewsubItem(this.refreshViewFiles, this.refreshRetCode);
      }, (error) => {
      })
    } else {
      console.log('EditAfr1 Input:', this.viewfolderlevel);
      this.subsciptiondeleteRow = this.service.editAFRItem(this.viewfolderlevel).subscribe((data: string) => {
        console.log('EditAfr1 Output:', data);
        alert(data);
        this.viewsubItem(this.refreshViewFiles, this.refreshRetCode)

      }, (error) => {
      })
    }
  }
  close() {
    this.ngOnInit();
  }

}
