import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/primeng';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OstMatricsService } from 'app/core/service/ost-matrics.service';
import { HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { OstMatricsReportRequestApi, OstReport } from 'app/core/model/teamAdministrationModel';
import { UrlConsumerService } from 'app/oauth/url-consumer.service';
import { SharedService } from '../../core/service/shared.service';
import { ISubscription } from "rxjs/Subscription";

@Component({
  selector: 'ost-metrics-portal',
  templateUrl: './ost-metrics-portal.component.html',
  styleUrls: ['./ost-metrics-portal.component.css']
})
export class OstMetricsPortalComponent implements OnInit {
  navMenuItems: MenuItem[];
  homePortal: boolean = false;
  checkKeyPage: boolean = false;
  downloadFilePage: boolean = false;
  previousDayPage: boolean = false;
  lastDaysPage: boolean = false;
  lastDaysReportsPage: boolean = false;
  dateRangePage: boolean = false;
  checkForm: FormGroup;
  downloadForm: FormGroup;
  dateForm: FormGroup;
  submittedCheck: boolean = false;
  submittedDownload: boolean = false;
  submittedDate: boolean = false;
  keyName: string;
  messageDisplay: string;
  dateResponse: boolean = false;
  ostMatrics = new OstMatricsReportRequestApi();
  atleastDate: boolean = false;
  greaterDate: boolean = false;
  futureDate: boolean = false;
  lessDate: boolean = false;
  reportTable: OstReport[] = [];
  elaspedtimeReport: string;
  endDateMsg: string;
  startDateMsg: string;
  userName: string;
  cdsId: string;
  userId: string;
  previousDaySubscript: ISubscription;
  lastSeventhDaySubscript: ISubscription;
  lastThirteethDaySubscript: ISubscription;
  isPreviousDay: boolean = false;
  isLastDay: boolean = false;
  isReportDay: boolean = false;
  constructor(private formBuilder: FormBuilder, private ostMatricsService: OstMatricsService, private urlService: UrlConsumerService, private shared: SharedService) { }

  ngOnInit() {
    this.navMenuItems = [{
      label: 'Check Key',
      command: () => this.checkKey()
    },
    {
      label: 'Download File',
      command: () => this.downloadFile()
    },
    {
      label: 'Previous Day Report',
      command: () => this.previousDay()
    },
    {
      label: 'Last 7 Days Report',
      command: () => this.lastDays()
    },
    {
      label: 'Last 30 Days Report',
      command: () => this.lastDaysReports()
    },
    {
      label: 'Date Range Report',
      command: () => this.dateRange()
    },
    ]

    this.checkForm = this.formBuilder.group({
      key: ['', Validators.required]
    });
    this.downloadForm = this.formBuilder.group({
      bucketKey: ['', Validators.required]
    });
    this.dateForm = this.formBuilder.group({
      status: [''],
      startDate: ['', Validators.required],
      EndDate: ['', Validators.required],
    });

   /* this.userName = this.urlService.userInfo.fullName;
    this.cdsId = this.urlService.userInfo.cdsId;
    console.log(' this.userName', this.userName);
    console.log('this.cdsId', this.cdsId);
    if (this.cdsId) {
      this.ostMatricsService.getCdsID(this.cdsId).subscribe((data: string) => {
        if (data) {
          this.homePortal = true;
          this.userId = data;

        }
      });
    }*/

	  this.ostMatricsService.getPersonInfo().subscribe(data => {
		  this.homePortal = true;
		  console.log('data ============>', data);
		  this.userName = data.name;
		  this.userId = data.id;
  	});
  }

  dateRange() {
    this.clear();
    this.previousDayUnsubscribe();
    this.lastSeventhDayUnsubscribe();
    this.lastThirteethDayUnsubscribe();
    this.shared.showProgress(false);
    this.dateForm.get('startDate').setValue('');
    this.dateForm.get('EndDate').setValue('');
    // this.dateForm.setValue({EndDate:''});
    this.dateRangePage = true;
    this.lastDaysReportsPage = false;
    this.lastDaysPage = false;
    this.previousDayPage = false;
    this.downloadFilePage = false;
    this.checkKeyPage = false;
    this.homePortal = false;
    this.dateResponse = false;

  }
  lastDaysReports() {
    this.clear();
    this.isReportDay = true;
    this.previousDayUnsubscribe();
    this.lastSeventhDayUnsubscribe();
    console.log('lastDaysReports');
    this.ostMatrics = new OstMatricsReportRequestApi();
    this.ostMatrics.status = 'monthly';
    this.ostMatrics.startDate = '';
    this.ostMatrics.lastDate = '';
    this.shared.showProgress(true);
    this.lastThirteethDaySubscript = this.ostMatricsService.getReport(this.ostMatrics).subscribe((data: OstReport[]) => {
      this.shared.showProgress(false);
      if (data) {
        this.reportTable = [];
        this.reportTable = data;
        this.lastDaysReportsPage = true;
        if (this.reportTable && this.reportTable[4]) {
          this.elaspedtimeReport = this.reportTable[4].elaspedtime;
        }
        console.log(this.elaspedtimeReport);
      }
    });
    this.dateRangePage = false;
    this.lastDaysPage = false;
    this.previousDayPage = false;
    this.downloadFilePage = false;
    this.checkKeyPage = false;
    this.homePortal = false;
    this.dateResponse = false;
  }
  lastDays() {
    this.clear();
    this.isLastDay = true;
    this.previousDayUnsubscribe();
    this.lastThirteethDayUnsubscribe();
    console.log('lastDays');
    this.ostMatrics = new OstMatricsReportRequestApi();
    this.ostMatrics.status = 'weekly';
    this.ostMatrics.startDate = '';
    this.ostMatrics.lastDate = '';
    this.shared.showProgress(true);
    this.lastSeventhDaySubscript = this.ostMatricsService.getReport(this.ostMatrics).subscribe((data: OstReport[]) => {
      this.shared.showProgress(false);
      if (data) {
        this.reportTable = [];
        this.reportTable = data;
        this.lastDaysPage = true;
        if (this.reportTable && this.reportTable[4]) {
          this.elaspedtimeReport = this.reportTable[4].elaspedtime;
        }
        console.log(this.elaspedtimeReport);
      }
    });
    this.dateRangePage = false;
    this.lastDaysReportsPage = false;
    this.previousDayPage = false;
    this.downloadFilePage = false;
    this.checkKeyPage = false;
    this.homePortal = false;
    this.dateResponse = false;
  }
  previousDay() {
    this.clear();
    this.isPreviousDay = true;
    this.lastSeventhDayUnsubscribe();
    this.lastThirteethDayUnsubscribe();
    console.log('previousDay');
    this.ostMatrics = new OstMatricsReportRequestApi();
    this.ostMatrics.status = 'daily';
    this.ostMatrics.startDate = '';
    this.ostMatrics.lastDate = '';
    this.shared.showProgress(true);
    this.previousDaySubscript = this.ostMatricsService.getReport(this.ostMatrics).subscribe((data: OstReport[]) => {
      console.log('data data:', data);
      this.shared.showProgress(false);
      if (data) {
        this.reportTable = data;
        this.previousDayPage = true;
        if (this.reportTable && this.reportTable[4]) {
          this.elaspedtimeReport = this.reportTable[4].elaspedtime;
        }
        console.log(this.elaspedtimeReport);
      }
    });
    this.dateRangePage = false;
    this.lastDaysReportsPage = false;
    this.lastDaysPage = false;
    this.downloadFilePage = false;
    this.checkKeyPage = false;
    this.homePortal = false;
    this.dateResponse = false;
  }
  downloadFile() {
    this.clear();
    console.log('downloadFile');
    this.dateRangePage = false;
    this.lastDaysReportsPage = false;
    this.lastDaysPage = false;
    this.previousDayPage = false;
    this.downloadFilePage = true;
    this.checkKeyPage = false;
    this.homePortal = false;
    this.dateResponse = false;
  }
  checkKey() {
    this.clear();
    console.log('checkKey');
    this.dateRangePage = false;
    this.lastDaysReportsPage = false;
    this.lastDaysPage = false;
    this.previousDayPage = false;
    this.downloadFilePage = false;
    this.checkKeyPage = true;
    this.homePortal = false;
    this.dateResponse = false;
  }

  check() {
    this.submittedCheck = true;
    if (this.checkForm.valid) {
      this.keyName = this.checkForm.get('key').value;
      console.log('/////////////KEY NAME///////////////', this.keyName);
      this.ostMatricsService.getCheckKey(this.encodeValue(this.keyName)).subscribe(data => {
        console.log('///////////////key///////////////////////', data);
        this.messageDisplay = data;
      });
    }
  }

  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }

  download() {
    this.submittedDownload = true;
    if (this.downloadForm.valid) {
      this.keyName = '';
      this.keyName = this.downloadForm.get('bucketKey').value;
      console.log('//////////KEYNAME//////////////////',this.keyName);
      this.ostMatricsService.getDownload(this.encodeValue(this.keyName)).subscribe((resp: HttpResponse<Blob>) => {
        let file = new Blob([resp.body]);
        saveAs(file, resp.headers.get('FileName'))
      });
    }
  }

  dateSubmit() {
    this.submittedDate = true;
    if (this.dateForm.valid && this.dateValidation(this.dateForm.get('startDate').value, this.dateForm.get('EndDate').value)) {
      this.clearDateValidation();
      this.ostMatrics = new OstMatricsReportRequestApi();
      this.ostMatrics.startDate = this.dateForm.get('startDate').value;
      this.ostMatrics.lastDate = this.dateForm.get('EndDate').value;
      this.ostMatrics.status = 'daterange';
      this.ostMatrics.tempDate = this.dateForm.get('EndDate').value;
      this.startDateMsg = this.ostMatrics.startDate;
      this.endDateMsg = this.ostMatrics.lastDate;
      console.log(this.ostMatrics);
      this.shared.showProgress(true);
      this.ostMatricsService.getReport(this.ostMatrics).subscribe((data: OstReport[]) => {
        this.shared.showProgress(false);
        if (data) {
          this.reportTable = data;
          this.dateResponse = true;
          this.dateRangePage = false;
          if (this.reportTable && this.reportTable[4]) {
            this.elaspedtimeReport = this.reportTable[4].elaspedtime;
          }
          console.log(this.elaspedtimeReport);
        }
      });
    }
  }

  get f() {
    return this.checkForm.controls;
  }

  get down() {
    return this.downloadForm.controls;
  }

  get datef() {
    return this.dateForm.controls;
  }

  clear() {
    this.submittedCheck = false;
    this.submittedDownload = false;
    this.submittedDate = false;
    this.messageDisplay = '';
  }

  dateValidation(startDate: string, EndDate: string): boolean {
    console.log('Date Validation');
    console.log(startDate);
    console.log(EndDate);
    var currentDate = new Date();
    console.log('currentDate', currentDate);
    var date1 = new Date(startDate);
    console.log('date1', date1);
    var date2 = new Date(EndDate);
    console.log('date2', date2);
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    console.log(Difference_In_Days);

    if (startDate === EndDate) {
      this.clearDateValidation();
      console.log('The range must be at least one day apart.');
      this.atleastDate = true;
      return false;
    } else if (Difference_In_Days > 60) {
      this.clearDateValidation();
      console.log('Date Range Should not exceed 60 days.');
      this.greaterDate = true;
      return false;
    } else if (currentDate < date1 || currentDate < date2) {
      this.clearDateValidation();
      console.log('You cannot enter a date in the future!.');
      this.futureDate = true;
      return false;
    } else if (date2 < date1) {
      this.clearDateValidation();
      console.log('Start Date should be less than Enddate.');
      this.lessDate = true;
      return false;
    }
    return true;
  }

  clearDateValidation() {
    this.atleastDate = false;
    this.greaterDate = false;
    this.futureDate = false;
    this.lessDate = false;
  }

  exportAsXLSX(fileName: string): void {
    console.log('exportAsXLSX');
    this.ostMatricsService.exportAsExcelFile(this.reportTable, fileName);
  }

  previousDayUnsubscribe() {
    if (this.isPreviousDay) {
      this.previousDaySubscript.unsubscribe();
    }
  }

  lastSeventhDayUnsubscribe() {
    if (this.isLastDay) {
      this.lastSeventhDaySubscript.unsubscribe();
    }
  }

  lastThirteethDayUnsubscribe() {
    if (this.isReportDay) {
      this.lastThirteethDaySubscript.unsubscribe();
    }
  }
}
