import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ItemInitiationForm2 } from 'app/core/model/itemAdminModels';

@Component({
  selector: 'jlrgroupregister-for-req',
  templateUrl: './jlrgroupregister-for-req.component.html',
  styleUrls: ['./jlrgroupregister-for-req.component.css']
})
export class JlrgroupregisterForReqComponent implements OnInit {

  jlrGroupRegisterReq: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>();
  autoFreezeOptions: ItemInitiationForm2[];

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.autoFreezeOptions = [
      { label: 'y', value: 'y' },
      { label: 'n', value: 'n' }
    ];
    this._formClear();
  }


  _formClear() {
    this.jlrGroupRegisterReq = this.formBuilder.group({
      autoFreeze: ''
    });
    this.formReady.emit(this.jlrGroupRegisterReq);
  }

}
