import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Team, ViewPersonResponse } from 'app/core/model/viewPersonModel';
import { ViewProperties } from 'app/core/model/viewProperties';
import { ViewPropertiesService } from 'app/core/service/view-properties.service';

@Component({
  selector: 'team-description',
  templateUrl: './team-description.component.html',
  styleUrls: ['./team-description.component.css']
})
export class TeamDescriptionComponent implements OnInit {

  ViewPropertieForm: FormGroup;
  @Input('viewTeamDescription') viewTeamDescription: Team;
  viewProperties = new ViewProperties();
  viewPersonResponse = new ViewPersonResponse();
  pageTitle: string;
  viewTeam: boolean = false;
  viewPerson: boolean = false;
  viewPropertieDisplay: boolean = false;
  display:boolean;
  loaderdisplay = false;
  constructor(private formBuilder: FormBuilder, private viewPropertiesService: ViewPropertiesService) { }

  ngOnInit() {
    console.log('view team.....:',this.viewTeamDescription);
  
    this.ViewPropertieForm = this.formBuilder.group({
      id: [null],
      name: [null],
      steward: [null],
      created_on: [null],
      modified_on: [null],
      type: [null],
      comments: [null],
      stewardId: [null],
      members: [null],
      joined_on: [null],
      typeDesc: [null],
      stewardName: [null],
      strId: [null],
      strCreated_on: [null],
      strModified_on: [null],
      totalStorage: [null],
    });
    this.viewTeamDescription.totalStorage = 'Press Button to calculate--This can take several minutes';
    this.ViewPropertieForm.setValue(this.viewTeamDescription);
    // this.ViewPropertieForm.setValue({
    //   teamname: this.viewTeamDescription.name,
    //   teamId: this.viewTeamDescription.id,
    //   type: this.viewTeamDescription.typeDesc,
    //   steward: this.viewTeamDescription.stewardName,
    //   comment: this.viewTeamDescription.comments,
    //   createdOn: this.viewTeamDescription.created_on,
    //   lastmodified: this.viewTeamDescription.modified_on,


    // });
  }
  teaminfo(id: string, name: string) {
    console.log('id.....', id);
    console.log('name.....', name);
    this.viewProperties = new ViewProperties();
    this.viewProperties.cdsIdName = name;
    this.viewProperties.itemId = id;
    console.log(this.viewProperties);
    this.viewPropertiesService.viewCheckType(this.viewProperties).subscribe((data: ViewPersonResponse) => {
      console.log(data);
      this.viewPersonResponse = new ViewPersonResponse();
      this.viewPersonResponse = data;
      console.log(this.viewPersonResponse);
      if (this.viewPersonResponse) {
        if (this.viewPersonResponse.viewType == 'Team') {
          this.pageTitle = 'View Team';
          this.viewPropertieDisplay = true;
          this.viewTeam = true;
        } else if (this.viewPersonResponse.viewType == 'Person') {
          this.pageTitle = 'View Person';
          this.viewPropertieDisplay = true;
          this.viewPerson = true;
        }
      }
    });

  }
  totalStorage(value) {
    console.log('totalStorage',value);
    if (value) {
      this.loaderdisplay = true;
      this.viewProperties.itemId = value;
      console.log(this.viewProperties.itemId)
      this.viewPropertiesService.viewTotalStorage(this.viewProperties).subscribe((data: ViewProperties) => {
        console.log('folder Size', data);
        if (data) {
          this.loaderdisplay = false;
          this.ViewPropertieForm.get('totalStorage').setValue(data.size);
        }
      });
    }
  }

	closePersonTeam() {

		this.viewPropertieDisplay = false;

	}

}
