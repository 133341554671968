import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'davlocktoken-for-opt',
  templateUrl: './davlocktoken-for-opt.component.html',
  styleUrls: ['./davlocktoken-for-opt.component.css']
})
export class DavlocktokenForOptComponent implements OnInit {

  davLockTokenOpt: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {

    this._formClear();
  }

  _formClear() {
    this.davLockTokenOpt = this.formBuilder.group({
      startedOn: '',
      depth: '',
    });
    this.formReady.emit(this.davLockTokenOpt);
  }

}
