import { Component, OnInit } from '@angular/core';
import { AfrReportService} from '../../core/service/afr-report.service';
import { Subscription } from 'rxjs';
import {AFRReport} from '../../core/model/afr-reports';

@Component({
  selector: 'afr-report',
  templateUrl: './afr-report.component.html',
  styleUrls: ['./afr-report.component.css']
})
export class AfrReportComponent implements OnInit {

  cols: any[];
  Afrreport: AFRReport[];
  constructor( private service: AfrReportService) { }

  subsciptiondatatable: Subscription;

  ngOnInit() { 

//     Department: "2449110PD"
// Manager: "251776172"
// ManagerName: "ganeshd1"
// Org: "null"
// Status: " Not Completed"
// cdsId: "vvenka61"
// count: 45


    this.cols = [
      { field: 'cdsId', header: 'CDS ID'},
      { field: 'count', header: 'No of Files' },
      // { field: 'Department', header: 'Department'},
      // { field: 'Manager', header: 'Manager ID'},
      // { field: 'ManagerName', header: 'Manager'},
      // { field: 'Org', header: 'Organization'},
      { field: 'Status', header: 'STATUS' },
    ];

    this.subsciptiondatatable = this.service.postAFRTable().subscribe((data: any) => {

    this.Afrreport = data.data;
     
 
      console.log('afrReport', data);
 
 
    }, (error) => {
      
    })
 
 
  }

}
