import { Component, OnInit, Input } from '@angular/core';
import { MemberOnTeams } from 'app/core/model/viewPersonModel';

@Component({
  selector: 'member-list',
  templateUrl: './member-list.component.html',
  styleUrls: ['./member-list.component.css']
})
export class MemberListComponent implements OnInit {
  @Input('viewMemberList') viewMemberList: MemberOnTeams[];
  selectedData: any[];
  rowSelected: any[];

  cols: any[];

  constructor() { }

  ngOnInit() {
    console.log('viewMemberList:', this.viewMemberList);
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'type', header: 'Type' },
      { field: 'date', header: 'Joined On' }
    ];
  }

}
