import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'subject-opt',
  templateUrl: './subject-opt.component.html',
  styleUrls: ['./subject-opt.component.css']
})
export class SubjectOptComponent implements OnInit {

  
  subjectOptObjectForm:FormGroup;  
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
  }

  _formClear() {
    this.subjectOptObjectForm = this.formBuilder.group({

      comments: ['']

    });
    this.formReady.emit(this.subjectOptObjectForm);
  }

}
