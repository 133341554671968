import { NgModule, OnInit } from '@angular/core';
import { Routes, RouterModule , PreloadAllModules } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { HomeComponent } from './home/home.component';
import { AccountRequestComponent } from './home/account-request/account-request.component';
import { TeamCreateComponent } from './home/team-create/team-create.component';
import { WorkInstructionsComponent } from './home/work-instructions/work-instructions.component';
import { FaqComponent } from './help/faq/faq.component';
import { ApdmHelpComponent } from './help/apdm-help/apdm-help.component';
import { WebClientComponent } from './web-client/web-client.component';
import { UnderConstructionComponent } from './error-page/under-construction/under-construction.component';
import { AccStatusComponent } from './home/acc-status/acc-status.component';
import { TeamModifyComponent } from './home/team-modify/team-modify.component';
import { MainPageComponent } from './home/main-page/main-page.component';
import { UrlConsumerService } from './oauth/url-consumer.service';
import { AuthGuardService } from './oauth/auth-guard.service';
import { OAuthCallbackComponent } from './oauth/oauth-callback.component';
import { LoginComponent } from './oauth/login.component';
import {EmailDownloadComponent} from "./EmailDownload/email-download.component";
import {UploadFromUrlComponent} from "./upload-from-url/upload-from-url.component";

export const routes: Routes = [
	{
		path: 'access_token',
		component: OAuthCallbackComponent,
		canActivate: [UrlConsumerService]
	},
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path: '',
		redirectTo: '/home/main-page',
		pathMatch: 'full'
	},
	{
		path: 'upload/:parentItemId',
		component: UploadFromUrlComponent,
		canActivate: [AuthGuardService]
	},
	// {
	// 	path: '',
	// 	redirectTo: 'webdav',
	// 	pathMatch: 'full'
	// },
	{
		path: 'under-Construction',
		component: UnderConstructionComponent,
		canActivate: [AuthGuardService]
	},
	// {
	// 	path: 'webdav',
	// 	component: WebdavComponent,
	// 	canActivate: [AuthGuardService]
	// },
	{
		path: 'admin',
		loadChildren: 'app/admin/admin.module#AdminModule',
		canActivate: [AuthGuardService]
	},
	{
		path: 'webclient',
		loadChildren: 'app/web-client/webclientmodule.module#WebclientmoduleModule',
		canActivate: [AuthGuardService]

	},
	{
		path: 'faq',
		component: FaqComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'apdm-help',
		component: ApdmHelpComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'home',
		component: HomeComponent,
		children: [
			{
				path: 'acc-request',
				component: AccountRequestComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'acc-status',
				component: AccStatusComponent,
				canActivate: [AuthGuardService]
			}
			, {
				path: 'team-create',
				component: TeamCreateComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'work-instruct',
				component: WorkInstructionsComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'team-modify',
				component: TeamModifyComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'main-page',
				component: MainPageComponent,
				canActivate: [AuthGuardService]
			},

		],
		canActivate: [AuthGuardService]
	},
	{
		path: 'email-download',
		component: EmailDownloadComponent,
		canActivate: [AuthGuardService]
	}
	,
	{
		path: 'email-download/:mail',
		component: EmailDownloadComponent,
		canActivate: [AuthGuardService]
	}
];

@NgModule({
	//imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
	imports: [RouterModule.forRoot(routes, { useHash: true , preloadingStrategy: PreloadAllModules  })],
	exports: [RouterModule],
	providers: []
})
export class AppRoutingModule implements OnInit {

	ngOnInit() {
		console.log('<---AppRoutingModule OnInit--->')
	}

}
