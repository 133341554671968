export class ApplicationInfoModel {
    id: string;
    appName: string;
    teamName: string;
    itemNo: string;
    appId:string;
}

export class TeamInfo {
    id: string;
    label: string;
    // type: string;
}

export class result {
    id: string;
    appName: string;
    teamInfo: TeamInfo;
    itemNo: string;
    appId:string;
     hidProcessFlag:string;
}

export class response {
    result : any ;
}