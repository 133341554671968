import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {WebdavModule} from './app/webdav/webdav.module';
import {environment} from './environments/environment';

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
// platformBrowserDynamic().bootstrapModule(WebdavModule)
// 	.then(ref => {
// 		if ('serviceWorker' in navigator && environment.production) {
// 			navigator.serviceWorker.register('./ngsw-worker.js');
// 		}

// 		if (window['ngRef']) {
// 			window['ngRef'].destroy();
// 		  }
// 		  window['ngRef'] = ref;
// 	})
// 	.catch(err => console.log(err));
