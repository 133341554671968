import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable } from 'rxjs';
import { OstMatricsReportRequestApi } from '../model/teamAdministrationModel';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class OstMatricsService {

  private apdmServiceBackEndUrl = environment.apdmServiceUrl;
  private headers: HttpHeaders;

  constructor(private http: HttpClient) { }

  getCheckKey(data: string): Observable<string> {
    return this.http.get(this.apdmServiceBackEndUrl + 'ostkey/' + data, {
      responseType: 'text',
    });
  }
	getPersonInfo():Observable<any>{
		return this.http.get(this.apdmServiceBackEndUrl + 'applicationPersonInfo' , {
			// responseType: 'text',
			headers: this.headers

		});

	}
  getDownload(data: string): Observable<HttpResponse<Blob>> {
    return this.http.get(this.apdmServiceBackEndUrl + 'downloadOSTFile/' + data, {
      headers: this.headers,
      observe: 'response',
      responseType: 'blob',
    });
  }

  getReport(data: OstMatricsReportRequestApi) {
    console.log(data);
    return this.http.post(this.apdmServiceBackEndUrl + 'reportForOstMetrics', data);
  }

  getCdsID(cdsId: string) {
    return this.http.post(this.apdmServiceBackEndUrl + 'getCDSId' , cdsId);
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    console.log('exportAsExcelFile');
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    console.log('worksheet', worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    console.log('saveAsExcelFile');
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
}
