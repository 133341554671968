import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import DOMPurify from 'dompurify';

@Component({
	selector: 'person-for-opt',
	templateUrl: './person-for-opt.component.html',
	styleUrls: ['./person-for-opt.component.css']
})
export class PersonForOptComponent implements OnInit {
	personOptForm: FormGroup;
	sanitizedLocation: SafeHtml;

	@Output() formReady = new EventEmitter<FormGroup>()

	constructor(private formBuilder: FormBuilder, private sanitizer: DomSanitizer) {
		this.personOptForm = this.formBuilder.group({
			location: ['']

		});

		this.personOptForm.get('location').valueChanges.subscribe(value => {
			this.sanitizedLocation = this.sanitizeHtml(value);
		});
	}

	ngOnInit() {
		const locationControl = this.personOptForm.get('location');
		if (locationControl && locationControl.value) {
			this.sanitizedLocation = this.sanitizeHtml(locationControl.value);
		}
		this._formClear();
	}


	sanitizeHtml(html: string): SafeHtml {
		const cleanHtml = DOMPurify.sanitize(html);
		return this.sanitizer.bypassSecurityTrustHtml(cleanHtml);

	}
	_formClear() {
		this.personOptForm = this.formBuilder.group({
			country: [''],
			password: [''],
			application: [''],
			lastName: [''],
			unit: [''],
			division: [''],
			empType: [''],
			hasAccess: [''],
			reason: [''],
			name: [''],
			location: [''],
			organization: [''],
			email: [''],
			authorizer: [''],
			mailDrop: [''],
			firstName: [''],
			office: [''],
			department: [''],
			reauthorizedOn: [''],
			role: [''],
			businessCase: [''],
			phone: ['']
		});
		this.formReady.emit(this.personOptForm);
	}


}