import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'team-opt',
  templateUrl: './team-opt.component.html',
  styleUrls: ['./team-opt.component.css']
})
export class TeamOptComponent implements OnInit {

  teamOptObjectForm:FormGroup;  
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
  }

  _formClear() {
    this.teamOptObjectForm = this.formBuilder.group({
      name: [''],
      comments: ['']

    });
    this.formReady.emit(this.teamOptObjectForm);
  }

}
