import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'epimfile-for-opt',
  templateUrl: './epimfile-for-opt.component.html',
  styleUrls: ['./epimfile-for-opt.component.css']
})
export class EpimfileForOptComponent implements OnInit {

  epimFileOptObjectForm:FormGroup;  
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
  }

  _formClear() {
    this.epimFileOptObjectForm = this.formBuilder.group({
      dispOrder: [''],
      
      
    });
    this.formReady.emit(this.epimFileOptObjectForm);
  }

}
