export class CheckInModel {
    public name: string;
    public directory: any;
    public tmpDirectory: any;
    public size: number;
    public modified: Date;
    public status: string;
    public file: File;    
    public progressValue: number = 0;
}


export class DoValidateCheckInModel {
    public name: string;
    public directory: any;
}


export class CheckInGetParentDetails {
    public parentItemDEId;
    public parentItemDEType;
    public childItemDEId;
    public childItemDEType;

    

    // constructor(checkInGetParentDetailsResponse?: any) {
    //     //"{"childItemDEType":"61","childItemDEId":"250393246","parentItemDEType":"61","parentItemDEId":"250392741"}"
    //     this.parentId = checkInGetParentDetailsResponse.parentItemDEId;
    //     this.parentType = checkInGetParentDetailsResponse.parentItemDEType;
    //     this.childID = checkInGetParentDetailsResponse.childItemDEId;
    //     this.childType = checkInGetParentDetailsResponse.childItemDEType;
    // }
}

export class PreSignedURL {

	public url:any;
	public key:any;
    public itemId:any;
    public filesize:any;
    public filename:any;
    public selectedNode:any

}

