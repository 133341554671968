import { Component, OnInit, Input } from '@angular/core';
import { AccessShare } from 'app/core/model/viewPersonModel';

@Component({
  selector: 'share-access',
  templateUrl: './share-access.component.html',
  styleUrls: ['./share-access.component.css']
})
export class ShareAccessComponent implements OnInit {
  @Input('viewShareAccess') viewShareAccess: AccessShare[];
  cols: any[];
  selectedData: any[];
  rowSelected: any[];
  constructor() { }

  ngOnInit() {
    console.log('viewShareAccess:', this.viewShareAccess);
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'has_access', header: 'Has Access ?' },
      { field: 'date', header: 'Joined On' },
      { field: 'firstName', header: 'First Name' },
      { field: 'lastName', header: 'Last Name' }
    ]; 
  }

}
