import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppreauthService } from 'app/core/service/appreauth.service';
import { AccountAuthNotificationBO, AccountAuthResponseApi, AccountAuth, AccountContent } from 'app/core/model/admin-reauthorization';

@Component({
  selector: 'account-auth-component',
  templateUrl: './account-auth-component.component.html',
  styleUrls: ['./account-auth-component.component.css']
})
export class AccountAuthComponentComponent implements OnInit {

  cols: any[];
  accountContentData: AccountContent[] = [];
  accountAuth: AccountAuth = new AccountAuth();
  _AccountAuthNotificationBO: AccountAuthNotificationBO = new AccountAuthNotificationBO();
  isError: string = '';

  constructor(private _Activatedroute: ActivatedRoute, private appreauth: AppreauthService) {
  }

  ngOnInit() {
    this.cols = [
      { field: 'cdsId', header: 'CDSID' },
      { field: 'application', header: 'Application' },
      { field: 'approved', header: 'Approved' },
      { field: 'denied', header: 'Denied' },
      { field: 'doNotWork', header: 'Does not work for me' },
      { field: 'bizCase', header: 'Business Case' },
    ];

    this._Activatedroute.paramMap.subscribe(params => {
      console.log('this._Activatedroute.paramMap=================>', params);
      console.log(params.get('authYearStr'));
      console.log(params.get('authCompletionStr'));
      console.log(params.get('authReminderNoStr'));
      console.log(params.get('approverCdsidsStr'));
      console.log(params.get('teamIdsStr'));
      this._AccountAuthNotificationBO.authYearStr = params.get('authYearStr');
      this._AccountAuthNotificationBO.AuthCompletionDateStr = params.get('authCompletionStr');
      this._AccountAuthNotificationBO.AuthReminderStr = params.get('authReminderNoStr');
      this._AccountAuthNotificationBO.approverCdsids = [];
      this._AccountAuthNotificationBO.approverCdsids.push(params.get('approverCdsidsStr'));
      this._AccountAuthNotificationBO.teamIds = [];
      this._AccountAuthNotificationBO.teamIds.push(params.get('teamIdsStr'));
    });

    this._Activatedroute.queryParams.subscribe(params => {
      console.log('this._Activatedroute.queryParams=================>', params);
      // console.log(params['authYearStr']);
      // console.log(params['authCompletionStr']);
      // console.log(params['authReminderNoStr']);
      // console.log(params['approverCdsidsStr']);
      // console.log(params['teamIdsStr']);
      // this._AccountAuthNotificationBO.authYearStr = params['authYearStr'];
      // this._AccountAuthNotificationBO.AuthCompletionDateStr = params['authCompletionStr'];
      // this._AccountAuthNotificationBO.AuthReminderStr = params['authReminderNoStr'];
      // this._AccountAuthNotificationBO.approverCdsids = [];
      // this._AccountAuthNotificationBO.approverCdsids.push(params['approverCdsidsStr']);
      // this._AccountAuthNotificationBO.teamIds = [];
      // this._AccountAuthNotificationBO.teamIds.push(params['teamIdsStr']);
    });

    this.appreauth.init(this._AccountAuthNotificationBO).subscribe((data: AccountAuthResponseApi) => {
      console.log('getappreauth', data);
      this.isError = data.isError;
      // this.accountAuthData = data.accountAuth;
      // let map = JSON.stringify(data.accountAuth.cdsidAccountContents)
      // console.log('/////////map///////////', map);

      // let accountAuthMap: Map<string, string[]> = data.accountAuth.cdsidAccountContents;
      // console.log('/////////accountAuthMap///////////', accountAuthMap);
      if (data.accountAuth) {
        this.accountAuth = data.accountAuth;
        this.accountContentData = data.accountAuth.accountContent;
      }
      // console.log('/////////accountAuth///////////', this.accountContentData);
    }, (error) => {
      console.log(error);
    });
  }

  validateBusinessCase(){

	  if (this.accountContentData) {
		  for (var i = 0; i < this.accountContentData.length; i++) {
			  if(this.accountContentData[i].bizCase == null || this.accountContentData[i].bizCase.trim() == ""){
				  alert("Business Case is mandatory. Please enter the business case for the blank records.");
				  return false;
			  }

		  }
		  return true;
	  }
  }
  submit() {
    this.accountAuth.accountContent = this.accountContentData;
	if(this.validateBusinessCase()){
		this.appreauth.submit(this.accountAuth).subscribe((data) => {
			console.log('submit', data);
			this.isError = 'submit';
			// this.accountContentData = data.accountAuth.accountContent;
		}, (error) => {
			console.log(error);
		});
	}

  }

}
