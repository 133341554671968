export class DataTableHeaders {
    treedata?: any;
    treeNode?: any;
    id?: string;
    name?: string;
    lockOwner?: string;
    version?: string;
    size?: number;
    dateModified?: string;
    type?: string;
    typeLabel?: string;
    owner?: string;
    description?: string;
    message?: string;

    subsquentId?: string;
    parentLabel?: string;
    rootLabel?: string;

    parentDetails: ParentDetails;

    collapsedIcon?: any;
    expandedIcon?: any;
    styleClass?: any;
    leaf?: any;

    actualItemType?: any;
    externalUrl?: any;

}

export class DataTableHeaderResposne extends DataTableHeaders {
    data: DataTableHeaders[];
}

export class ParentDetails {
    id: string;
    name: string;
    type: string;
    typeLabel: string;
    itemType: string;
    parentLabel: string;
    rootLabel: string;
}





