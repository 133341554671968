import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { createAclService } from '../../core/service/createAcl-service';
import { ExpeiredFiles, LeftCompany } from '../../core/model/afr-reports';
import { Table } from 'primeng/components/table/table';

@Component({
  selector: 'afr-reports',
  templateUrl: './afr-reports.component.html',
  styleUrls: ['./afr-reports.component.css'],
  providers: [createAclService]
})
export class AfrReportsComponent implements OnInit {
  @ViewChild(Table) tableComponent: Table;
  createAfrreportsform: FormGroup;
  submitted: boolean = false;
  warningMsg: string;
  warningdisplay: boolean = false;
  cols: any[];
  CompletCol: any[];
  leftcols: any[];
  ExpiredFiles: ExpeiredFiles[];
  CompletAFR: any;
  leftcompany: LeftCompany[];
  Rendertable: boolean = false;

  completeafr: boolean = false;
  leftc: boolean = false;

  constructor(private formBuilder: FormBuilder, private service: createAclService) { }

  ngOnInit() {

    this.cols = [
      { field: 'personId', header: 'Person ID' },
      { field: 'personName', header: 'Person Name' },
      { field: 'managerId', header: 'Manager ID' },
      { field: 'managerName', header: 'Manager Name' },
      { field: 'fileCount', header: 'File Count' },
    ];


    this.leftcols = [
      { field: 'appId', header: 'APP ID' },
      { field: 'actionDate', header: 'Date' },
      { field: 'currApdmStatus', header: 'Status' },
      { field: 'personId', header: 'Person ID' },
      { field: 'cdsid', header: 'CDSID' },

    ];

    this.createAfrreportsform = this.formBuilder.group({
      year: ['', Validators.required]
    });


  }


  get f() {
    return this.createAfrreportsform.controls;
  }



  onSubmit(type: string) {
    console.log(type);
    let year: string = this.createAfrreportsform.get('year').value;
    if (this.createAfrreportsform.valid) {
      if (type === "left") {
        this.Rendertable = false;
        this.completeafr = false;
        if (this.tableComponent) {
          this.tableComponent.reset();
        }

        console.log('1');
        this.service.generateLeftCompany().subscribe((data: LeftCompany[]) => {

          this.leftcompany = data;


          console.log(this.leftcompany, "left company data");
          if (this.leftcompany) {

            this.leftc = true;
            console.log(data);
          }

        })

      } else if (type === "Expires") {
        this.leftc = false;
        this.completeafr = false;
        if (this.tableComponent) {
          this.tableComponent.reset();
        }
        console.log('2');
        this.service.generateExpired(year).subscribe((data: any) => {
          console.log(data);

          this.ExpiredFiles = data;

          if (this.ExpiredFiles) {
            console.log(data, "data rendered");

            this.Rendertable = true;

          }
        })

      } else if (type === "completed") {
        console.log('3');

        this.Rendertable = false;
        this.leftc = false;
        if (this.tableComponent) {
          this.tableComponent.reset();
        }
        this.service.afterCompletedList(year).subscribe((data: any) => {
          this.CompletAFR = data;
          console.log(data , "complete");
          if (this.CompletAFR) {
            this.completeafr = true;
            console.log(data);
          }

        })
      }

    } else {
      this.warningMsg = "Check All mandatory Fields"
      this.warningdisplay = true;
    }
  }

}
