export class ModifyInput {
    cdsIdPattern: string;
    personId: string;
    selectedData: string[];
}

export class PersonList {
    id: number;
    application: string;
    authorizer: string;
    building: string;
    businessCase: string;
    company: string;
    country: string;
    createdOn: string;
    department: string;
    division: string;
    email: string;
    empType: string;
    firstName: string;
    lastName: string;
    hasAccess: string;
    hasCdsRecord: string;
    location: string;
    mailDrop: string;
    modifiedOn: string;
    office: string;
    organization: string;
    password: string;
    phone: string;
    reauthorizedOn: string;
    reason: string;
    reportsTo: string;
    role: string;
    steward: string;
    storageClass: number;
    unit: string;
    cdsId: string;
    lastAuthorizedOn: string;
     buildingName: string;
	 departmentCode: string;
	 fordMailDrop: string;
	 fordBusinessUnitCode: string;
     managerRole: string 
     errorMessage: string;
     stewardId:number;
     reportsToId:number;
     authorizerId:number;
     companyId:number;
     buildingId:number;

}

export class ApplicationMembership {
    personId: number;
    appId: number;
    appName: string;
    businessCase: string;
    teamId: string;
    authorizer:string;
     selectStatus:string;
}

export class TeamData {
    teamId: number;
    teamName: string;
    cdsId:string;
}
export class TeamAdminAcl {
    item: string;
    label: string;
    query: string;
    write: string;
    read: string;
    create: string;
    delete: string;
    teamId: string;
    cdsId:string;
}
export class TeamAcl {
    item: number;
    team: string;
    label: string;
    query: string;
    write: string;
    read: string;
    create: string;
    delete: string;
}

export class PersonData {
    personData: PersonList;
    applicationMembership: ApplicationMembership[];
}

export class ModifyListResponse {
    personData: PersonData;
    teamData: TeamData[];
    personAclData: TeamAdminAcl[];
    teamAclData: TeamAcl[];
}

export class ReviewAction{
     fromCdsId:string;
	 toCdsId:string;
	 selectedCheckBox:string[];
}

export class Subject {
    label: string;
    value: string;
}

export class DeleteUserTeamResponse{
   team:TeamData[];
    message:string;
}
export class DeletePersonAclResponse{
    person:TeamAdminAcl[] ;
	 message:string;
}
export class UpdateTeamMembershipResponse{
  app: ApplicationMembership[];
	 message:string;
}

