import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'engine-for-req',
  templateUrl: './engine-for-req.component.html',
  styleUrls: ['./engine-for-req.component.css']
})
export class EngineForReqComponent implements OnInit {

  engineForReqObjectForm:FormGroup;  
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
  }

  _formClear() {
    this.engineForReqObjectForm = this.formBuilder.group({
      serialNum: '',  
    });
    this.formReady.emit(this.engineForReqObjectForm);
  }

}
