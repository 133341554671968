import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Item, JlrGroupRegister } from 'app/core/model/itemAdminModels';

@Component({
  selector: 'jlr-group-register-object',
  templateUrl: './jlr-group-register-object.component.html',
  styleUrls: ['./jlr-group-register-object.component.css']
})
export class JlrGroupRegisterObjectComponent implements OnInit {

  jlrGroupRegisterObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()
  @Input('screenType') screenType: string;
  @Input('item') item: Item;
  @Input('jlrGroupRegisterItem') JlrGroupRegisterItem: JlrGroupRegister;
  @Input('showfield') showfield: boolean;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
    this._updatejlrGroupRegisterObjectForm();
  }
  
  _formClear() {
    this.jlrGroupRegisterObjectForm = this.formBuilder.group({
      id: [''],
      autoFreeze: [''],
      checkInFilter: ['']
    });
    this.formReady.emit(this.jlrGroupRegisterObjectForm);
  }

  _updatejlrGroupRegisterObjectForm() {

    this.jlrGroupRegisterObjectForm.patchValue({
      id: this.item.id,
      autoFreeze: this.JlrGroupRegisterItem.auto_freeze,
      checkInFilter: this.JlrGroupRegisterItem.check_in_filter
    })

  }

}
