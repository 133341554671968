import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'company-for-opt',
  templateUrl: './company-for-opt.component.html',
  styleUrls: ['./company-for-opt.component.css']
})
export class CompanyForOptComponent implements OnInit {

  companyOptObjectForm:FormGroup;  
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
  }

  _formClear() {
    this.companyOptObjectForm = this.formBuilder.group({
      name: [''],
      fullName: [''],
      comments: [''],
    
      
    });
    this.formReady.emit(this.companyOptObjectForm);
  }
}
