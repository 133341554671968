import { Component, OnInit } from '@angular/core';
import { UrlConsumerService } from 'app/oauth/url-consumer.service';
import { ItemAdministrationService } from 'app/core/service/item-administration.service';

@Component({
  selector: 'admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {
  apdmUserID: String = "";
  apdmUserName: String = "";
  apdmAccess: String = "Apdm Admin Portal";

  constructor(private urlConsumerService: UrlConsumerService, private itemAdministrationService: ItemAdministrationService ) { }

  ngOnInit() {

    this.itemAdministrationService.getPersonInfo().subscribe(data => {
      console.log('data ============>', data);
      this.apdmUserID = data.id;
      this.apdmUserName = data.name;
      //alert(data);
    });
  //  console.log(this.itemAdministrationService.getPersonInfo());
    
  }



}
