import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {MenuItem} from 'primeng/api';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ViewPersonResponse } from 'app/core/model/viewPersonModel';

@Component({
  selector: 'view-team',
  templateUrl: './view-team.component.html',
  styleUrls: ['./view-team.component.css']
})
export class ViewTeamComponent implements OnInit {

  @ViewChild('menuItems') menu: MenuItem[];

  @Input('viewTeamDetails') viewTeamDetails: ViewPersonResponse;
  ViewPropertieForm: FormGroup;

  PropertieItems: MenuItem[];
  activeItem: MenuItem;
  constructor(private router: Router, private formBuilder: FormBuilder) { }

  ngOnInit() {
    console.log(this.viewTeamDetails);
    this.PropertieItems = [
      {label: 'Description',
       id: 'teamdescription'},
      {label: 'People on Team', 
        id: 'peopleteam'},

  ];
  this.activeItem = this.PropertieItems[0];
  }


//   this.PropertieItems = [
//     {label: 'Description',
//      id: 'teamdescription',
//      routerLink: [{outlet:{ teamdetails:['teamdescription']}}]},
//     {label: 'People on Team', 
//       id: 'peopleteam',
//       routerLink:[{outlet:{ teamdetails:['peopleteam']}}]},

// ];
  activateMenu(){
    this.activeItem =this.menu['activeItem'];
  
    console.log(this.activeItem.id);
  }

}
