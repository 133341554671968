import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'std-type-opt',
  templateUrl: './std-type-opt.component.html',
  styleUrls: ['./std-type-opt.component.css']
})
export class StdTypeOptComponent implements OnInit {

  stdTypeOptObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
  }

  _formClear() {
    this.stdTypeOptObjectForm = this.formBuilder.group({
      name: '',
      comments: 'Object created using APDM Admin Portal',
      tableName: ''
    });
    this.formReady.emit(this.stdTypeOptObjectForm);
  }

}
