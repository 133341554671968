import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UrlConsumerService } from 'app/oauth/url-consumer.service';
import { Subscription } from 'rxjs';
import { SharedService } from '../core/service/shared.service';

@Component({
  selector: 'admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  haveAccess: boolean;
  mySubscription: any;
  errorMsg: boolean = false;
  displayAlert: boolean = false;
  loaderdisplay: boolean = false;
  Messageresponse: string = '';
  receiveProgressSubscriptiotn: Subscription;
  receiveMessageSubscriptiotn: Subscription;
  superUserError: boolean = false;

  isAccountAuthUrl: boolean = false;
  isShowAdminmenu: boolean = true;
  createUserAccess: boolean;
  AdminAccess: boolean;
  APDMPortalUser: boolean;
  APDMPortalSuperUser: boolean;
  GSDQA: boolean;
  ReadOnlyAccess: boolean;


  constructor(private router: Router, private urlConsumerService: UrlConsumerService, private shared: SharedService) {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });

    this.receiveProgressSubscriptiotn = this.shared.receiveprogress().subscribe(flag => {
      this.loaderdisplay = flag;
    });

    this.receiveMessageSubscriptiotn = this.shared.receivealert().subscribe(message => {
      if (message) {
        this.Messageresponse = message;
        this.displayAlert = true;
      }
    });

    this.shared.receiveadminmenu().subscribe(obj => {
			
      this.isShowAdminmenu = (sessionStorage.getItem('afrlink')) == 'true'? false : true; 
		  });

    console.log(' ====> AdminComponent constructor====>', router);
    console.log(' ====> AdminComponent constructor====>', router.url);
    if (router.url.includes('accountAuth')) {
      this.isAccountAuthUrl = true;
    }

    this.isShowAdminmenu = (sessionStorage.getItem('afrlink')) == 'true'? false : true;
    this.urlConsumerService.getadminaccess().subscribe((data: any) => {
      
      // String str[] = {"AdminAccess","APDMPortalSuperUser","APDMPortalUser","OtherAdminAccess","CreateUserAccess","ReadOnlyAccess","GSD-QA","GSDTeam"};
      
      // data = "['GSD-QA','APDMPortalUser','AdminAccess']";
      if(data.indexOf('AdminAccess') > -1)
        this.AdminAccess = true;
      if(data.indexOf('CreateUserAccess') > -1 || data.indexOf('GSD-QA') > -1)
        this.createUserAccess = true;
      if(data.indexOf('GSD-QA') > -1)
        this.GSDQA = true;
      if(data.indexOf('APDMPortalUser') > -1)
        this.APDMPortalUser = true;
      if(data.indexOf('APDMPortalSuperUser') > -1)
        this.APDMPortalSuperUser = true;
      if(data.indexOf('ReadOnlyAccess') > -1)
        this.ReadOnlyAccess = true;  

      let currentURL = sessionStorage.redirectURL;
      console.log("fired sucess", data)  
      console.log("currentURL", currentURL);

      if (
        ((currentURL == '/admin-portal' || currentURL == '/admin-portal/login') && data.length > 2)
    || this.APDMPortalSuperUser
    || this.GSDQA
    || this.GSDQA && this.APDMPortalUser && this.restrictAPDMPortalUser(currentURL)
    || this.GSDQA && this.allowGSDQAScreen(currentURL)
    ||  this.AdminAccess && this.APDMPortalUser && this.restrictAPDMPortalUser(currentURL)
    ||  (this.AdminAccess && currentURL.indexOf('ost-metrics') > -1) 
    ||  (this.ReadOnlyAccess && this.APDMPortalUser && currentURL.indexOf('notifyUser') == -1 && this.restrictAPDMPortalUser(currentURL))
    ||  (this.ReadOnlyAccess && currentURL.indexOf('ost-metrics') > -1) 
    || (this.createUserAccess && this.APDMPortalUser && this.restrictAPDMPortalUser(currentURL))
    || this.allowCommonPages(currentURL)
    ) {
        // return true;
        this.haveAccess = true;
        this.errorMsg = false;
      } else {
        if(data.length == 2)
        {
          this.haveAccess = false;
          this.errorMsg = true;
        }
        else{
          this.superUserError = true;
        }
        //alert('You do not have access to this page, Please request one from http://www.itconnect.ford.com .');
      }
    }, (error) => {
      console.log("fired error", error.message)
      //alert(error);
    })
  }

  allowCommonPages(currentPage)
  {
    let allow = false;
    let pages = ['afrhome','retentionfolderlevel','retentioncodelevel','retention','showDeletedLog'];
    pages.forEach(function(value){
      if(currentPage.indexOf(value) > -1)
        allow = true;
    })
    return allow;
  }

  restrictAPDMPortalUser(currentPage)
  {
    let allow = true;
    let pages = ['notifyUser','appreauthorization','AnnualFileReview/runAnnualFileReview'];
    pages.forEach(function(value){
      if(currentPage.indexOf(value) > -1)
        allow = false;
    })
    return allow;
  }

  allowGSDQAScreen(currentPage)
  {
    let allow = false;
    let pages = ['create-user','modifyUser','listUser','team-create','list-team','listmember-team'];
    pages.forEach(function(value){
      if(currentPage.indexOf(value) > -1)
        allow = true;
    })
    return allow;
  }

  ngOnInit() {
    // console.log(' ====> AdminComponent ngOnInit====>', this.haveAccess);
    // this.haveAccess = false;
  }

  closeMessageWindow() {
    this.displayAlert = false;
  }

  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
    if (this.receiveMessageSubscriptiotn) {
      this.receiveMessageSubscriptiotn.unsubscribe();
    }
    if (this.receiveProgressSubscriptiotn) {
      this.receiveProgressSubscriptiotn.unsubscribe();
    }
  }

}
