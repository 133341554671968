import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'ptnvhqos-report-for-opt',
  templateUrl: './ptnvhqos-report-for-opt.component.html',
  styleUrls: ['./ptnvhqos-report-for-opt.component.css']
})
export class PtnvhqosReportForOptComponent implements OnInit {

  ptnvhqosReportOptForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
  }

  _formClear() {
    this.ptnvhqosReportOptForm = this.formBuilder.group({
      engineDisplacement: [''],
      ptnvhqosVehicleConfigDesc: [''],
      driverSeatType: [''],
      otherVehicleSpecs: [''],
      engineCyclinderConfig: [''],
      vehBuildLevel: [''],
      ptnvhqosCondition: [''],
      gearSelectorLocation: [''],
      bodyStyle: [''],
      vehOptionOrPackages: [''],
      vehicleProgram: [''],
      wheelType: [''],
      trimLevel: [''],
      OrigDate: [''],
      fuelType: [''],
      market: [''],
      vehicleType: [''],
      tireSize: [''],
      tireManufature: [''],
      noOfCylinders: [''],
      endDate: [''],
      assblyPlantCode: [''],
      testMetricDesc: [''],
      valveTrainConfig: [''],
      ptnvhqosVehicleConfig: [''],
      OrigTime: [''],
      vehicleModel: [''],
      startDate: [''],
      valvesPerCylinder: [''],
      roofOptions: [''],
      tireType: [''],
      ptnvhqosVin: [''],
      drivelineType: [''],
      seatMaterial: [''],
      noOfDoors: [''],
      transmissionType: ['']


    });
    this.formReady.emit(this.ptnvhqosReportOptForm);
  }

}
