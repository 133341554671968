import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'std-activity-for-opt',
  templateUrl: './std-activity-for-opt.component.html',
  styleUrls: ['./std-activity-for-opt.component.css']
})
export class StdActivityForOptComponent implements OnInit {

  stdActOptObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
  }

  _formClear() {
    this.stdActOptObjectForm = this.formBuilder.group({
      mixinType: '',
      comments: 'Object created using APDM Admin Portal',
      swizzleType: '',
    });
    this.formReady.emit(this.stdActOptObjectForm);
  }

}
