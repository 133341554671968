import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'part-sdrc-item-req',
  templateUrl: './part-sdrc-item-req.component.html',
  styleUrls: ['./part-sdrc-item-req.component.css']
})
export class PartSdrcItemReqComponent implements OnInit {

  partSdrcItemReqForm:FormGroup;  
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {

    this._formClear();
  }

  _formClear() {
    this.partSdrcItemReqForm = this.formBuilder.group({
      fordNum: [''],
      ideasId: [''],
      fordId: ['']
    });
    this.formReady.emit(this.partSdrcItemReqForm);
  }

}
