import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { ItemAdministrationService } from 'app/core/service/item-administration.service';
import { ItemTypeList, ModifyProgram, ItemModifyForm } from 'app/core/model/itemAdminModels';

@Component({
  selector: 'modify-program',
  templateUrl: './modify-program.component.html',
  styleUrls: ['./modify-program.component.css']
})
export class ModifyProgramComponent implements OnInit {

  modifyProgramFormGroup: FormGroup;
  sub;
  type: string = '';
  itemLabel: string = '';
  ownerCds: string = '';
  parentId: string = '';
  itemLabelDropDown: ItemTypeList;
  modifyProgram = new ModifyProgram();
  itemLabelOptions: ItemTypeList[];
  selectedItemType: ItemTypeList[];
  itemModifyForm = new ItemModifyForm();
  showItemObjectForm: boolean = false;
  showTextBoxProgram: boolean = false;
  showDropDownProgram: boolean = false;
  programErrorMsg: boolean = false;
  typeSelected: string[];
  programErrorStatus: string;
  
  constructor(private formBuilder: FormBuilder, private _Activatedroute: ActivatedRoute,
    public _ItemAdministrationService: ItemAdministrationService,
    private router: Router) {

  }


  ngOnInit() {
    this.typeSelected = ['43'];
    this.showTextBoxProgram = true;
    this.showDropDownProgram = false;
    this.sub = this._Activatedroute.paramMap.subscribe(params => {
      console.log(params);
      this.type = params.get('type');
    });

    this.modifyProgramFormGroup = this.formBuilder.group({
      programLabel: '',
      ownerCdsid: '',
      programType: '',
      programId: '',
      programName: '',
      chkShowAcl: true
    });
  }

  fetchProfile() {
    console.log("fetchProfile");
  }

  fetchItems() {
    this._getItemLabel();
  }

  validateInteger(strValue: string) {
    var objRegExp = /(^-?\d\d*$)/;
    return objRegExp.test(strValue);
  }

  _getItemLabel() {

    this.modifyProgram = new ModifyProgram();
    this.modifyProgram.label = this.modifyProgramFormGroup.get('programLabel').value;
    this.modifyProgram.itemTypes = [];
    console.log('this.typeSelected:', this.typeSelected);
    this.modifyProgram.itemTypes = this.typeSelected;
    //this.modifyProgram.itemTypes.push(this.modifyProgramFormGroup.get('programType').value);
    this.modifyProgram.owner = this.modifyProgramFormGroup.get('ownerCdsid').value;
    // this.modifyProgram.parent = this.parentId;
    console.log('this.modifyProgram:', this.modifyProgram)
    this._ItemAdministrationService.getItemLabel(this.modifyProgram).subscribe(data => {
      console.log('getItemLabel----->', data);
      if (data[0].itemLabelList) {
        this.showTextBoxProgram = false;
        this.showDropDownProgram = true;
        this.itemLabelOptions = data[0].itemLabelList;


        this.modifyProgramFormGroup.patchValue({
          programName: data[0].itemLabelList[0]
        })

        //this.modifyProgramFormGroup.get('programLabel').setValue('');
        //this.typeSelected = ['43'];
        //this.modifyProgramFormGroup.get('ownerCdsid').setValue('');



      } else if (data[0].message) {
        console.log('alert:', data[0].message)
        // alert(data[0].message)
        this.programErrorStatus = data[0].message;
        this.programErrorMsg = true;
      }
    });
  }
  // setProgramTypes(): any {
  //   console.log('--------------',this.itemLabelOptions[0]);
  //  var programType = this.itemLabelOptions[0];
  // }

  itemLabelMethod($event) {
    console.log('itemLabelMethod $event', $event);
    this.itemLabelDropDown = new ItemTypeList();
    this.itemLabelDropDown.label = $event.label;
    this.itemLabelDropDown.id = $event.id;
  }

  getItemData() {
    // console.log('this.itemLabelDropDown', this.itemLabelDropDown);
    // if (this.itemLabelDropDown == undefined) {
    //   this.modifyProgram.label = this.itemLabelOptions[0].label
    //   this.modifyProgram.id = this.itemLabelOptions[0].id
    // } else {
    //   this.modifyProgram.label = this.itemLabelDropDown.label;
    //   this.modifyProgram.id = this.itemLabelDropDown.id;
    // }

    // this._ItemAdministrationService.getItemData(this.modifyProgram).subscribe(data => {
    //   console.log('getItemData----->', data);
    //   if (data) {
    //     this.itemModifyForm = data;
    //     this.showItemObjectForm = true;
    //   }
    // });

    console.log(this.modifyProgramFormGroup.controls['programLabel'].value);
    console.log(this.modifyProgramFormGroup.controls['ownerCdsid'].value);
    console.log(this.modifyProgramFormGroup.controls['programType'].value);
    console.log(this.modifyProgramFormGroup.controls['programId'].value);
    console.log(this.modifyProgramFormGroup.controls['programName'].value);
    console.log(this.modifyProgramFormGroup.controls['chkShowAcl'].value);

    if (this.type == 'modify') {
      this.router.navigate(['/admin-portal/modifyItem/modify/'], {
        queryParams: {
          screenId: "modifyProgram",
          owner: this.modifyProgramFormGroup.controls['ownerCdsid'].value,
          programType: this.modifyProgramFormGroup.controls['programType'].value,
          programId: this.modifyProgramFormGroup.controls['programId'].value,
          programName: this.setProgramName()
        }
      });
    } else if (this.type == 'list') {
      this.router.navigate(['/admin-portal/listItem/list/'], {
        queryParams: {
          screenId: "listProgram",
          owner: this.modifyProgramFormGroup.controls['ownerCdsid'].value,
          programType: this.modifyProgramFormGroup.controls['programType'].value,
          programId: this.modifyProgramFormGroup.controls['programId'].value,
          programName: this.setProgramName()
        }
      });
    }


  }


  setProgramName(): any {
    if (this.showDropDownProgram) {
      console.log('----dropdown----->');
      return this.modifyProgramFormGroup.controls['programName'].value.label; 
    }

    if (this.showTextBoxProgram) {
      console.log('----text----->');
      return this.modifyProgramFormGroup.controls['programName'].value; 
    }
  }



  close() {
    this.programErrorMsg = false;
    this.ngOnInit();
  }
  
  onChange() {
    const latestCity = this.typeSelected[this.typeSelected.length - 1];
    this.typeSelected.length = 0;
    this.typeSelected.push(latestCity);
  }


}
