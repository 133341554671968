import { Component, OnInit } from '@angular/core';
import { RetentionCodeLevelHeaders, RetentionCodeLevelHeaderResposne, listAfrFilesForFolderRequest, FileView } from '../../core/model/retentioncodelevel';
import { RetentionCodeLevelService } from '../../core/service/retention-code-level.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'afr-retentioncode',
  templateUrl: './afr-retentioncode.component.html',
  styleUrls: ['./afr-retentioncode.component.css']
})
export class AfrRetentioncodeComponent implements OnInit {

  RetentionFolderLevelData: RetentionCodeLevelHeaders[];
  cols: any[];
  viewcols: any[];
  subsciptiondatatable: Subscription;

  viewfolderlevel: listAfrFilesForFolderRequest;

  ViewFileData: RetentionCodeLevelHeaderResposne;
  viewFolder: boolean;
  retention: any[];

  cdsid: string = "";
  year: number;
  refreshViewFiles:string ="";
  refreshRetCode:string = "";
  fileName:string;
  constructor(private service: RetentionCodeLevelService, private _Activatedroute: ActivatedRoute) { }

  ngOnInit() {

    this._Activatedroute.paramMap.subscribe(params => {
      this.cdsid = params.get('cdsid');
      this.year = Number(params.get('year'))
    });

    this.cols = [
      { field: 'id', header: ' Id' },
      { field: 'fileFolderName', header: 'File/Folder Name' },
      { field: 'retention', header: 'Retention' },
      { field: 'suspOrder', header: 'Suspension Order' },
      { field: 'delete', header: 'Delete' },
      { field: 'viewFiles', header: 'View Files' },
      { field: 'count', header: 'No of Files' },
      { field: 'update', header: 'Update' },
    ];

    this.subsciptiondatatable = this.service.getAfrCodeLevel(this.cdsid, this.year).subscribe((data: any) => {
      this.RetentionFolderLevelData = data;
      console.log('afrcodelevel', this.RetentionFolderLevelData);
    }, (error) => {
    });

    this.subsciptiondatatable = this.service.getretention().subscribe((data: any) => {
      this.retention = data;
      console.log('retention', this.retention);
    }, (error) => {
    });
  }

  refresh(id,retCode,label){
    console.log('Label:',label);
    this.fileName ="";
    this.refreshViewFiles = "";
    this.refreshRetCode = "";
    this.refreshViewFiles = id;
    this.fileName = label;
    if (retCode && retCode.id !== undefined) {
      this.refreshRetCode = retCode.id;
    } else {
      this.refreshRetCode = retCode;
    }    
    console.log('Refresh:',this.refreshViewFiles);
    console.log('Refresh:',this.refreshRetCode);
  }

  viewsubItem(viewFiles, retcode) {
    console.log("view File:", viewFiles);
    console.log("retcode:", retcode);
    
    this.viewfolderlevel = new listAfrFilesForFolderRequest();
    this.viewfolderlevel.cdsid = this.cdsid;
    this.viewfolderlevel.retcode = viewFiles;
    this.viewfolderlevel.retstatus = retcode;
    this.viewfolderlevel.year = String(this.year);
    console.log("ViewFolderLevel Input:", this.viewfolderlevel);
    this.subsciptiondatatable = this.service.getViewAfrFolderLevel(this.viewfolderlevel).subscribe((data: any) => {
      console.log('ViewFolderLevel Output:', data);
      this.ViewFileData = data;
      this.viewcols = [
        { field: 'fileFolderName', header: 'File/Folder Name' },
        { field: 'retention', header: 'Retention' },
        { field: 'suspOrder', header: 'Suspension Order' },
        { field: 'lastModifiedDate', header: 'Last Modified Date' },
        { field: 'delete', header: 'Delete' },
        { field: 'update', header: 'Update' },
      ];
      this.viewFolder = true;
    }, (error) => {
    });
  }

  deleteAFRRetentionLevelItem(deleteRow) {
    let name = 'Are you sure to delete ' +deleteRow.fileFolderName +' containing '+deleteRow.count+' file(s)?';
    let status = confirm(name);
    if (status == true) {
      this.viewfolderlevel = new listAfrFilesForFolderRequest();
      this.viewfolderlevel.cdsid = this.cdsid;
      this.viewfolderlevel.itemid = deleteRow.id;
      this.viewfolderlevel.year = String(this.year);

      this.subsciptiondatatable = this.service.deleteAFRRetentionLevelItem(this.viewfolderlevel).subscribe((data: any) => {
        console.log('afrretentionlevelDelete', data);
        //if (data === 1) {
          alert('Deleted Successfully.');
          this.ngOnInit();
        //}
      }, (error) => {
      })
    }
    else {
      return false;
    }
  }

  updateAFRFolderLevelItem(updateRow) {
    console.log('Update AFR Retention LEvel Input:', updateRow);
    this.viewfolderlevel = new listAfrFilesForFolderRequest();
    this.viewfolderlevel.cdsid = this.cdsid;
    this.viewfolderlevel.itemid = updateRow.id;
    this.viewfolderlevel.year = String(this.year);
    if (updateRow.retention && updateRow.retention.id !== undefined) {
      this.viewfolderlevel.retstatus = updateRow.retention.id;
    } else {
      this.viewfolderlevel.retstatus = updateRow.retention
    }
    this.viewfolderlevel.sorder = updateRow.suspOrder;
    console.log('Susp:', updateRow.suspOrder);
    console.log('updateAFRRetentionLevel input:', this.viewfolderlevel);

    if (updateRow.suspOrder) {
      console.log('updateSuspensionOrderRetCode Input:', this.viewfolderlevel);
      this.subsciptiondatatable = this.service.updateSuspensionOrderRetCode(this.viewfolderlevel).subscribe((data: string) => {
        console.log('updateSuspensionOrderRetCode Output:', data);

        if (data === 'Successfully Updated') {
          alert('Suspension order applied  successfully and item has been removed from the list');
        }

        this.ngOnInit();
      }, (error) => {
      })
    } else {
      console.log('updateAFRRetentionLevel input:', this.viewfolderlevel);
      this.subsciptiondatatable = this.service.updateAFRRetentionLevel(this.viewfolderlevel).subscribe((data: string) => {
        console.log('updateAFRRetentionLevel output:', data);
        alert(data);
        this.ngOnInit();
      }, (error) => {
      })
    }
  }

  deleteAFRItem(deleteItem) {
    console.log('DeleteItem:', deleteItem);
    let status = confirm("Are you sure want to delete the file?");
    if (status == true) {
      this.viewfolderlevel = new listAfrFilesForFolderRequest();
      this.viewfolderlevel.cdsid = this.cdsid;
      this.viewfolderlevel.itemid = deleteItem.id;
      this.viewfolderlevel.year = String(this.year);
      console.log('DeleteAFRItem:', this.viewfolderlevel);
      this.subsciptiondatatable = this.service.deleteAFRItem(this.viewfolderlevel).subscribe((data: any) => {
        console.log('DeleteAFRItem:', data);
        //if (data === 1) {
          alert('Deleted Successfully.');
          this.viewsubItem(this.refreshViewFiles, this.refreshRetCode);
        //}
      }, (error) => {
      })
    }
    else {
      return false;
    }
  }

  editAfr1(editItem) {
    console.log('EditItem:', editItem);
    this.viewfolderlevel = new listAfrFilesForFolderRequest();
    this.viewfolderlevel.cdsid = this.cdsid;
    this.viewfolderlevel.itemid = editItem.id;
    this.viewfolderlevel.year = String(this.year);
    this.viewfolderlevel.sorder = editItem.suspOrder;
    console.log('Susp:', editItem.suspOrder);
    if (editItem.retention && editItem.retention.id !== undefined) {
      this.viewfolderlevel.retstatus = editItem.retention.id;
    } else {
      this.viewfolderlevel.retstatus = editItem.retention
    }
    console.log('viewfolderlevel:', this.viewfolderlevel);
    if (editItem.suspOrder) {
      console.log('EditAfr1 Input:', this.viewfolderlevel);
      this.subsciptiondatatable = this.service.updateViewSuspensionOrder(this.viewfolderlevel).subscribe((data: string) => {
        console.log('EditAfr1 Output:', data);
        if (data === 'Successfully Updated') {
          alert('Suspension order applied  successfully and item has been removed from the list');
        }
        this.viewsubItem(this.refreshViewFiles, this.refreshRetCode);
      }, (error) => {
      })
    } else {
      console.log('EditAfr1 Input:', this.viewfolderlevel);
      this.subsciptiondatatable = this.service.editAFRItem(this.viewfolderlevel).subscribe((data: string) => {
        console.log('EditAfr1 Output:', data);
        alert(data);
        this.viewsubItem(this.refreshViewFiles, this.refreshRetCode);
      }, (error) => {
      });
    }

  }
  close() {
    this.ngOnInit();
  }


}
