import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from 'rxjs/internal/Observable';
import { DeleteItemRequest } from '../model/DeleteItemRequest';

@Injectable({
  providedIn: 'root'
})
export class DeleteService {

  selectedNodeItemId: any;
  private apdmServiceBackEndUrl = environment.apdmServiceUrl
  private headers: HttpHeaders;
  cdsId: any;
  token: any;
  deleteItemRequest: DeleteItemRequest;


  constructor(private http: HttpClient) {
    this.token = JSON.parse(sessionStorage.getItem('strAccessToken'));
    if (this.token) {
      this.cdsId = this.token.CommonName;
    }
    if (this.cdsId !== undefined) {
      this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
    } else {
      this.cdsId = 'Invalid';
      this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
    }
  }

  doPreMarkDelete(itemIds: string): Observable<any> {
    //console.log('cdsId   ====>', this.cdsId);
    console.log('itemIds   ====>', itemIds);
    this.deleteItemRequest = new DeleteItemRequest();
    this.deleteItemRequest.itemId = itemIds;
    return this.http.post<any>(this.apdmServiceBackEndUrl + 'premarkdelete', this.deleteItemRequest, {
      headers: this.headers,
    });
  }

  doMarkDelete(itemIds: string): Observable<any> {
    //console.log('cdsId   ====>', this.cdsId);
    console.log('itemIds   ====>');
    console.log(itemIds)
    this.deleteItemRequest = new DeleteItemRequest();
    this.deleteItemRequest.itemId = itemIds;
    return this.http.post<any>(this.apdmServiceBackEndUrl + 'markdelete', this.deleteItemRequest, {
      headers: this.headers,
    });
  }

  doPreDelete(itemIds: string): Observable<any> {
    //console.log('cdsId   ====>', this.cdsId);
    console.log('itemIds   ====>');
    console.log(itemIds)
    this.deleteItemRequest = new DeleteItemRequest();
    this.deleteItemRequest.itemId = itemIds;
    return this.http.post<any>(this.apdmServiceBackEndUrl + 'predelete', this.deleteItemRequest, {
      headers: this.headers
    });
  }

  doDelete(itemIds: string): Observable<any> {
    //console.log('cdsId   ====>', this.cdsId);
    console.log('itemIds   ====>');
    console.log(itemIds)
    this.deleteItemRequest = new DeleteItemRequest();
    this.deleteItemRequest.itemId = itemIds;
    return this.http.post<any>(this.apdmServiceBackEndUrl + 'dodelete', this.deleteItemRequest, {
      headers: this.headers
    });
  }

  doPreRestore(itemIds: string): Observable<any> {
    //console.log('cdsId   ====>', this.cdsId);
    console.log('itemIds   ====>', itemIds);
    this.deleteItemRequest = new DeleteItemRequest();
    this.deleteItemRequest.itemId = itemIds;
    return this.http.post<any>(this.apdmServiceBackEndUrl + 'PreRestore', this.deleteItemRequest, {
      headers: this.headers
    });
  }


  doRestore(itemIds: string): Observable<any> {
    //console.log('cdsId   ====>', this.cdsId);
    console.log('itemIds   ====>', itemIds);
    this.deleteItemRequest = new DeleteItemRequest();
    this.deleteItemRequest.itemId = itemIds;
    return this.http.post<any>(this.apdmServiceBackEndUrl + 'restore', this.deleteItemRequest, {
      headers: this.headers
    });
  }

  doLock(itemIds: string): Observable<any> {
    //console.log('cdsId   ====>', this.cdsId);
    console.log('itemIds   ====>', itemIds);
    return this.http.post<any>(this.apdmServiceBackEndUrl + 'lock/' + itemIds, {
      headers: this.headers
    });
  }

  doUnLock(itemIds: string): Observable<any> {
   // console.log('cdsId   ====>', this.cdsId);
    console.log('itemIds   ====>', itemIds);
    return this.http.post<any>(this.apdmServiceBackEndUrl + 'unLock/' + itemIds, {
      headers: this.headers
    });
  }







}
