import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from "@angular/common/http";
import { TeamCreate, TeamDetail, TeamAdminAcl, TeamMembers } from '../model/teamAdministrationModel';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeamAdministrationService {
  teamNameView: string;
  teamType:string[];
  personErrorMsg :string;

  private apdmServiceBackEndUrl = environment.apdmServiceUrl;
  constructor(private http: HttpClient) { }
  teamAdminCreate(data: TeamCreate): Observable<string> {
    console.log(data);
    return this.http.post(this.apdmServiceBackEndUrl + 'teamadmincreation', data, {
      responseType: 'text',
    });
  }
  teamAdminSearch(data: TeamCreate) {
    console.log(data);
    return this.http.post(this.apdmServiceBackEndUrl + 'teamadminsearch', data);
  }
  teamAdminGetData(data: TeamCreate) {
    console.log(data);
    return this.http.post(this.apdmServiceBackEndUrl + 'teamadmingetdata', data);
  }
  teamAdminGetDataMember(data: TeamCreate) {
    console.log(data);
    return this.http.post(this.apdmServiceBackEndUrl + 'teamadminmembership', data);
  }
  modifyTeam(data: TeamCreate): Observable<string>{
    console.log(data);
    return this.http.post(this.apdmServiceBackEndUrl + 'modifyteam', data,{
      responseType: 'text', 
    });
  }
  deleteTeam(data: TeamCreate): Observable<string> {
    console.log(data);
    return this.http.post(this.apdmServiceBackEndUrl + 'deleteteam', data, {
      responseType: 'text',
    });
  }
  deleteMember(data: TeamDetail[]) {
    console.log(data);
    return this.http.post(this.apdmServiceBackEndUrl + 'deletemembers', data);
  }
  modifyACLs(data: TeamAdminAcl[]){
    console.log(data);
    return this.http.post(this.apdmServiceBackEndUrl + 'modifyacls', data);
  }
  deleteACLs(data: TeamAdminAcl[]){
    console.log(data);
    return this.http.post(this.apdmServiceBackEndUrl + 'deleteacls', data);
  }
  deleteMarkTeamMember(data: TeamMembers[]){
    console.log(data);
    return this.http.post(this.apdmServiceBackEndUrl + 'deletemarkteammember', data);
  }

}
