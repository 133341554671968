import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'type-opt',
  templateUrl: './type-opt.component.html',
  styleUrls: ['./type-opt.component.css']
})
export class TypeOptComponent implements OnInit {

  typeOptObjectForm:FormGroup;  
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
  }

  _formClear() {
    this.typeOptObjectForm = this.formBuilder.group({
      name: [''],
      comments: [''],
      tableName: ['']
   
    });
    this.formReady.emit(this.typeOptObjectForm);
  }

}
