export class GetTeamRequest {
    cdsName: string;
}

export class OteamListApi {
    id: string;
    teamType: string;
    teamName: string;
    steward: string;

}

export class Result extends OteamListApi {
    oteamListApi: OteamListApi[];
}

export class TeamgetResponseModel extends Result {
    result: Result;
    error: string;
}

