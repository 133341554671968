import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Item, Drawing } from 'app/core/model/itemAdminModels';

@Component({
  selector: 'drawing-object',
  templateUrl: './drawing-object.component.html',
  styleUrls: ['./drawing-object.component.css']
})
export class DrawingObjectComponent implements OnInit {

  drawingObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()
  @Input('screenType') screenType: string;
  @Input('item') item: Item;
  @Input('drawing') drawing: Drawing;
  @Input('showfield') showfield: boolean;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
    this._updateDrawingObjectForm();
  }

  _formClear() {
    this.drawingObjectForm = this.formBuilder.group({
      id: [''],
      num: [''],
      name: [''],
      brassTag: [''],
      purchaseOrder: [''],
      comments: [''],
      vendor: ['']
    });
    this.formReady.emit(this.drawingObjectForm);
  }

  _updateDrawingObjectForm() {
    this.drawingObjectForm.patchValue({
      id: this.item.id,
      num: this.drawing.drawing_num,
      name: this.drawing.drawing_name,
      brassTag: this.drawing.drawing_brass_tag,
      purchaseOrder: this.drawing.drawing_purchase_order,
      comments: this.drawing.drawing_comments,
      vendor: this.drawing.drawing_vendor
    })
  }

}
