import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Item, PtnvhqosTest } from 'app/core/model/itemAdminModels';

@Component({
  selector: 'ptnvhqos-test-object',
  templateUrl: './ptnvhqos-test-object.component.html',
  styleUrls: ['./ptnvhqos-test-object.component.css']
})
export class PtnvhqosTestObjectComponent implements OnInit {

  ptnvhqosTestObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()
  @Input('screenType') screenType: string;
  @Input('item') item: Item;
  @Input('ptnvhqosTest') ptnvhqosTest: PtnvhqosTest;
  @Input('showfield') showfield: boolean;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
    this._updatePtnvhqosTestObjectForm();
  }

  _formClear() {
    this.ptnvhqosTestObjectForm = this.formBuilder.group({
      ptnvhqosSpeedDev: [''],
      ptnvhqosOrder3: [''],
      ptnvhqosFanSpeedHighLimit: [''],
      ptnvhqosVehicleConfigDesc: [''],
      driverSeatType: [''],
      ambHumidityUnits: [''],
      ptnvhqosThreshStreeing: [''],
      polynomialRange: [''],
      ptnvhqosMaxFanSpeed: [''],
      engineCylinderConfiguration: [''],
      ptnvhqosHighSweepSpeed: [''],
      ptnvhqosTransTempUnits: [''],
      ptnvhqosOrderCutWidth: [''],
      ptnvhqosMinFanSpeed: [''],
      ambAirPressureUnits: [''],
      idleRpmQuality: [''],
      ptnvhqosVibOrderOpt: [''],
      gearSelectorLocation: [''],
      ambHumidity: [''],
      bodyStyle: [''],
      vehOptionsOrPackages: [''],
      vehicleProgram: [''],
      ptnvhqosFanSpeedAvg: [''],
      ptnvhqosIdleVer: [''],
      defaultSndFftSize: [''],
      market: [''],
      tireManufacture: [''],
      ptnvhqosWotVer: [''],
      ptnvhqosPotProc: [''],
      numberOfCylinders: [''],
      ptnvhqosFftOptMethod: [''],
      assblyplantCode: [''],
      ptnvhqosSndOrderOpt: [''],
      ptnvhqosThreshCheck: [''],
      ptnvhqoshighAnalSpeed: [''],
      vehicleModel: [''],
      roofOptions: [''],
      ptnvhqosSweepPavgFactor: [''],
      freqreSolution: [''],
      ptnvhqosVin: [''],
      tireType: [''],
      ambWindSpeed: [''],
      driveLineType: [''],
      numberOfDoors: [''],
      ptnvhqosFanSpeedLowLimit: [''],
      engineDisplacement: [''],
      ptnvhqosLowAnalSpeed: [''],
      ptnvhqosTransTemp: [''],
      ambAirPressure: [''],
      ptnvhqosFanSppedMin: [''],
      ptnvhqosOrder2: [''],
      otherVehicleSpecs: [''],
      ptnvhqosneruProc: [''],
      vehBuildLevel: [''],
      ambTemp: [''],
      ptnvhqosNeruVer: [''],
      defaultVibFftSize: [''],
      ptnvhqosCondition: [''],
      ambTempUnits: [''],
      ptnvhqosFanSpeedMax: [''],
      wheelType: [''],
      trimLevel: [''],
      ambWindSpeedUnits: [''],
      ptnvhqosLowSweepSpeed: [''],
      ptnvhqosTestDescription: [''],
      ptnvhqosSpeedsTdDev: [''],
      ptnvhqosSweepCheck: [''],
      id: [''],
      ptnvhqosTheshMethod: [''],
      ptnvhqosOrder4: [''],
      baseFileName: [''],
      ptnvhqosWotProc: [''],
      ptnvhqosOrderCutMethod: [''],
      fuelType: [''],
      origDate: [''],
      ptnvhqosThreshSeat: [''],
      ptnvhqosPotVer: [''],
      ptnvhqosOperator: [''],
      ptnvhqosOrder1: [''],
      numRepairs: [''],
      vehBuildDate: [''],
      tireSize: [''],
      vehicleType: [''],
      percentRepairs: [''],
      ptnvhqosOpCondition: [''],
      numberOfFailed: [''],
      ptnvhqosFanRangeType: [''],
      ptnvhqosDoOrderData: [''],
      ptnvhqosVehicleConfig: [''],
      valveTrainConfig: [''],
      origTime: [''],
      ptnvhqosTargetSpeed: [''],
      valvesperCylinder: [''],
      ptnvhqosTestCondition: [''],
      ptnvhqosIdleProc: [''],
      assblyPlantLoc: [''],
      seatMaterial: [''],
      transmissionType: ['']
    });
    this.formReady.emit(this.ptnvhqosTestObjectForm);
  }

  _updatePtnvhqosTestObjectForm() {
    this.ptnvhqosTestObjectForm.patchValue({
      ptnvhqosSpeedDev: this.ptnvhqosTest.ptnvhqos_test_speeddev,
      ptnvhqosOrder3: this.ptnvhqosTest.ptnvhqos_test_order3,
      ptnvhqosFanSpeedHighLimit: this.ptnvhqosTest.ptnvhqos_test_fanspeedhighlimit,
      ptnvhqosVehicleConfigDesc: this.ptnvhqosTest.ptnvhqos_test_vehicle_config_disc,
      driverSeatType: this.ptnvhqosTest.ptnvhqos_test_driver_seat_type,
      ambHumidityUnits: this.ptnvhqosTest.ptnvhqos_test_amb_humidity_units,
      ptnvhqosThreshStreeing: this.ptnvhqosTest.ptnvhqos_test_thresh_steering,
      polynomialRange: this.ptnvhqosTest.ptnvhqos_test_polynomial_range,
      ptnvhqosMaxFanSpeed: this.ptnvhqosTest.ptnvhqos_test_maxfanspeed_100perc,
      engineCylinderConfiguration: this.ptnvhqosTest.ptnvhqos_test_engine_cyl_config,
      ptnvhqosHighSweepSpeed: this.ptnvhqosTest.ptnvhqos_test_highsweepspeed,
      ptnvhqosTransTempUnits: this.ptnvhqosTest.ptnvhqos_test_trans_temp_units,
      ptnvhqosOrderCutWidth: this.ptnvhqosTest.ptnvhqos_test_ordercut_width,
      ptnvhqosMinFanSpeed: this.ptnvhqosTest.ptnvhqos_test_minfanspeed_operc,
      ambAirPressureUnits: this.ptnvhqosTest.ptnvhqos_test_amb_airpress_units,
      idleRpmQuality: this.ptnvhqosTest.ptnvhqos_test_idlerpmquality,
      ptnvhqosVibOrderOpt: this.ptnvhqosTest.ptnvhqos_test_vib_orderopt,
      gearSelectorLocation: this.ptnvhqosTest.ptnvhqos_test_gear_sele_loc,
      ambHumidity: this.ptnvhqosTest.ptnvhqos_test_amb_humidity,
      bodyStyle: this.ptnvhqosTest.ptnvhqos_test_body_style,
      vehOptionsOrPackages: this.ptnvhqosTest.ptnvhqos_test_veh_opt_or_pack,
      vehicleProgram: this.ptnvhqosTest.ptnvhqos_test_vehicle_program,
      ptnvhqosFanSpeedAvg: this.ptnvhqosTest.ptnvhqos_test_fanspeedavg,
      ptnvhqosIdleVer: this.ptnvhqosTest.ptnvhqos_test_idle_ver,
      defaultSndFftSize: this.ptnvhqosTest.ptnvhqos_test_default_snd_fftsize,
      market: this.ptnvhqosTest.ptnvhqos_test_market,
      tireManufacture: this.ptnvhqosTest.ptnvhqos_test_tire_manuf,
      ptnvhqosWotVer: this.ptnvhqosTest.ptnvhqos_test_wot_ver,
      ptnvhqosPotProc: this.ptnvhqosTest.ptnvhqos_test_pot_proc,
      numberOfCylinders: this.ptnvhqosTest.ptnvhqos_test_num_cyl,
      ptnvhqosFftOptMethod: this.ptnvhqosTest.ptnvhqos_test_fftopt_method,
      assblyplantCode: this.ptnvhqosTest.ptnvhqos_test_assblyplantcode,
      ptnvhqosSndOrderOpt: this.ptnvhqosTest.ptnvhqos_test_snd_orderopt,
      ptnvhqosThreshCheck: this.ptnvhqosTest.ptnvhqos_test_threshcheck,
      ptnvhqoshighAnalSpeed: this.ptnvhqosTest.ptnvhqos_test_highanalspeed,
      vehicleModel: this.ptnvhqosTest.ptnvhqos_test_vehicle_model,
      roofOptions: this.ptnvhqosTest.ptnvhqos_test_roof_opt,
      ptnvhqosSweepPavgFactor: this.ptnvhqosTest.ptnvhqos_test_sweepavgfactor,
      freqreSolution: this.ptnvhqosTest.ptnvhqos_test_freqresolution,
      ptnvhqosVin: this.ptnvhqosTest.ptnvhqos_test_vin,
      tireType: this.ptnvhqosTest.ptnvhqos_test_tire_type,
      ambWindSpeed: this.ptnvhqosTest.ptnvhqos_test_amb_windspeed,
      driveLineType: this.ptnvhqosTest.ptnvhqos_test_driveline_type,
      numberOfDoors: this.ptnvhqosTest.ptnvhqos_test_no_of_doors,
      ptnvhqosFanSpeedLowLimit: this.ptnvhqosTest.ptnvhqos_test_fanspeedlowlimit,
      engineDisplacement: this.ptnvhqosTest.ptnvhqos_test_engine_displacement,
      ptnvhqosLowAnalSpeed: this.ptnvhqosTest.ptnvhqos_test_lowanalspeed,
      ptnvhqosTransTemp: this.ptnvhqosTest.ptnvhqos_test_trans_temp,
      ambAirPressure: this.ptnvhqosTest.ptnvhqos_test_amb_airpressure,
      ptnvhqosFanSppedMin: this.ptnvhqosTest.ptnvhqos_test_fanspeedmin,
      ptnvhqosOrder2: this.ptnvhqosTest.ptnvhqos_test_order2,
      otherVehicleSpecs: this.ptnvhqosTest.ptnvhqos_test_other_vehicle_spec,
      ptnvhqosneruProc: this.ptnvhqosTest.ptnvhqos_test_neru_proc,
      vehBuildLevel: this.ptnvhqosTest.ptnvhqos_test_vehbuildlevel,
      ambTemp: this.ptnvhqosTest.ptnvhqos_test_amb_temp,
      ptnvhqosNeruVer: this.ptnvhqosTest.ptnvhqos_test_neru_ver,
      defaultVibFftSize: this.ptnvhqosTest.ptnvhqos_test_default_vib_fftsize,
      ptnvhqosCondition: this.ptnvhqosTest.ptnvhqos_test_condition,
      ambTempUnits: this.ptnvhqosTest.ptnvhqos_test_amb_temp_units,
      ptnvhqosFanSpeedMax: this.ptnvhqosTest.ptnvhqos_test_fanspeedmax,
      wheelType: this.ptnvhqosTest.ptnvhqos_test_wheel_type,
      trimLevel: this.ptnvhqosTest.ptnvhqos_test_trim_level,
      ambWindSpeedUnits: this.ptnvhqosTest.ptnvhqos_test_amb_windspeed_units,
      ptnvhqosLowSweepSpeed: this.ptnvhqosTest.ptnvhqos_test_lowsweepspeed,
      ptnvhqosTestDescription: this.ptnvhqosTest.ptnvhqos_test_description,
      ptnvhqosSpeedsTdDev: this.ptnvhqosTest.ptnvhqos_test_speedstddev,
      ptnvhqosSweepCheck: this.ptnvhqosTest.ptnvhqos_test_sweepcheck,
      id: this.item.id,
      ptnvhqosTheshMethod: this.ptnvhqosTest.ptnvhqos_test_threshmethod,
      ptnvhqosOrder4: this.ptnvhqosTest.ptnvhqos_test_order4,
      baseFileName: this.ptnvhqosTest.ptnvhqos_test_basefilename,
      ptnvhqosWotProc: this.ptnvhqosTest.ptnvhqos_test_wot_proc,
      ptnvhqosOrderCutMethod: this.ptnvhqosTest.ptnvhqos_test_ordercut_method,
      fuelType: this.ptnvhqosTest.ptnvhqos_test_fuel_type,
      origDate: this.ptnvhqosTest.ptnvhqos_test_orig_date,
      ptnvhqosThreshSeat: this.ptnvhqosTest.ptnvhqos_test_thresh_seat,
      ptnvhqosPotVer: this.ptnvhqosTest.ptnvhqos_test_pot_ver,
      ptnvhqosOperator: this.ptnvhqosTest.ptnvhqos_test_operator,
      ptnvhqosOrder1: this.ptnvhqosTest.ptnvhqos_test_order1,
      numRepairs: this.ptnvhqosTest.ptnvhqos_test_num_repairs,
      vehBuildDate: this.ptnvhqosTest.ptnvhqos_test_veh_build_date,
      tireSize: this.ptnvhqosTest.ptnvhqos_test_tire_size,
      vehicleType: this.ptnvhqosTest.ptnvhqos_test_vehicle_type,
      percentRepairs: this.ptnvhqosTest.ptnvhqos_test_percent_repairs,
      ptnvhqosOpCondition: this.ptnvhqosTest.ptnvhqos_test_opcondition,
      numberOfFailed: this.ptnvhqosTest.ptnvhqos_test_numberoffailed,
      ptnvhqosFanRangeType: this.ptnvhqosTest.ptnvhqos_test_fanrangetype,
      ptnvhqosDoOrderData: this.ptnvhqosTest.ptnvhqos_test_doeorderdata,
      ptnvhqosVehicleConfig: this.ptnvhqosTest.ptnvhqos_test_vehicle_config,
      valveTrainConfig: this.ptnvhqosTest.ptnvhqos_test_valvetrainconfig,
      origTime: this.ptnvhqosTest.ptnvhqos_test_orig_time,
      ptnvhqosTargetSpeed: this.ptnvhqosTest.ptnvhqos_test_targetspeed,
      valvesperCylinder: this.ptnvhqosTest.ptnvhqos_test_per_cyl,
      ptnvhqosTestCondition: this.ptnvhqosTest.ptnvhqos_test_testcondition,
      ptnvhqosIdleProc: this.ptnvhqosTest.ptnvhqos_test_idle_proc,
      assblyPlantLoc: this.ptnvhqosTest.ptnvhqos_test_assblyplantloc,
      seatMaterial: this.ptnvhqosTest.ptnvhqos_test_seat_material,
      transmissionType: this.ptnvhqosTest.ptnvhqos_test_transmission_type
    })
  }

}
