import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'std-engie-arch-opt',
  templateUrl: './std-engie-arch-opt.component.html',
  styleUrls: ['./std-engie-arch-opt.component.css']
})
export class StdEngieArchOptComponent implements OnInit {

  stdEngineArchOptObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {

    this._formClear();
  }

  _formClear() {
    this.stdEngineArchOptObjectForm = this.formBuilder.group({
      guiOrder: ['']
    });
    this.formReady.emit(this.stdEngineArchOptObjectForm);
  }

}
