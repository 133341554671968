import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Item, AttachedFile, ItemInitiationForm2 } from 'app/core/model/itemAdminModels';
import { AdminDateFormatPipe } from 'app/shared/admin-date-format.pipe';

@Component({
  selector: 'file-object',
  templateUrl: './file-object.component.html',
  styleUrls: ['./file-object.component.css']
})
export class FileObjectComponent implements OnInit {

  fileObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()
  @Input('screenType') screenType: string;
  @Input('item') item: Item;
  @Input('attachedFile') attachedFile: AttachedFile;
  @Input('showfield') showfield: boolean;
  @Input('retentionStatusList') retentionStatusOptions: ItemInitiationForm2[];

  isDefaultOptions: ItemInitiationForm2[];
  isEncryptedOptions: ItemInitiationForm2[];


  constructor(private formBuilder: FormBuilder, public _AdminDateFormatPipe: AdminDateFormatPipe) { }

  ngOnInit() {
    this._formClear();
    this.isDefaultOptions = [
      { label: 'y', value: 'y' },
      { label: 'n', value: 'n' }
    ];
    this.isEncryptedOptions = [
      { label: 'y', value: 'y' },
      { label: 'n', value: 'n' }
    ];
    this._updateFileObjectForm();
  }
  _formClear() {
    this.fileObjectForm = this.formBuilder.group({
      id: [''],
      createdOn: [''],
      actualServer: [''],
      fmcCryptKey: [''],
      stMode: [''],
      accessedOn: [''],
      origStoredSize: [''],
      compression: [''],
      isDefault: [''],
      viewCount: [''],
      checkSum: [''],
      storedSize: [''],
      backUpPath: [''],
      externalMimeType: [''],
      comments: [''],
      mimeType: [''],
      deleteFromDiskOn: null,
      retStatus: [''],
      actualSize: [''],
      mimeParam: [''],
      path: [''],
      isEncrypted: [''],
      retCheckPoint: [''],
      version: [''],
      mimeSubType: [''],
      modifiedOn: [''],
      owner: [''],
      publishedOn: ['']
    });
    this.formReady.emit(this.fileObjectForm);
  }
  _updateFileObjectForm() {
    console.log('showfield-------------->', this.showfield);
    this.fileObjectForm.patchValue({
      id: this.item.id,
      createdOn: this._AdminDateFormatPipe.transform(this.attachedFile.created_on),
      actualServer: this.attachedFile.file_actual_server,
      fmcCryptKey: this.attachedFile.file_fmccrypt_key,
      stMode: this.attachedFile.file_st_mode,
      accessedOn: this._AdminDateFormatPipe.transform(this.attachedFile.accessed_on),
      origStoredSize: this.attachedFile.file_orig_stored_size,
      compression: this.attachedFile.file_compression,
      isDefault: this.setIsDefaultAndIsEncrytValue(this.attachedFile.file_is_default),
      viewCount: this.attachedFile.file_view_count,
      checkSum: this.attachedFile.file_checksum,
      storedSize: this.attachedFile.file_stored_size,
      backUpPath: this.attachedFile.file_backup_path,
      externalMimeType: this.attachedFile.file_external_mime_type,
      comments: this.attachedFile.file_comments,
      mimeType: this.attachedFile.file_mime_type,
      deleteFromDiskOn: this.attachedFile.file_deleted_from_disk_on ? new Date(this._AdminDateFormatPipe.transform(this.attachedFile.file_deleted_from_disk_on)) : null,
      retStatus: this.setRetStatus(this.attachedFile.file_ret_status),
      actualSize: this.attachedFile.file_actual_size,
      mimeParam: this.attachedFile.file_mime_param,
      path: this.attachedFile.file_path,
      isEncrypted: this.setIsDefaultAndIsEncrytValue(this.attachedFile.file_is_encrypted),
      retCheckPoint: this.attachedFile.file_ret_checkpoint,
      version: this.attachedFile.file_version,
      mimeSubType: this.attachedFile.file_mime_subtype,
      modifiedOn: this._AdminDateFormatPipe.transform(this.attachedFile.modified_on),
      owner: this.attachedFile.attached_owner,
      publishedOn: this.attachedFile.published_on
    })

    // if (this.attachedFile.file_deleted_from_disk_on) {
    //   this.fileObjectForm.patchValue({
    //     deleteFromDiskOn: new Date(this._AdminDateFormatPipe.transform(this.attachedFile.file_deleted_from_disk_on)) 
    //   })
    // }


  }
  setRetStatus(file_ret_status: string): any {    
    switch (file_ret_status) {
      case 'a':
        return { value: 'a', label: 'Active' }        
      case 'c':
        return { value: 'c', label: 'Checking In' }        
      case 'd':
        return { value: 'd', label: 'Deleted' }        
      case 'e':
        return { value: 'e', label: 'Expired' }        
      case 'f':
        return { value: 'f', label: 'Frozen' }        
      case 'o':
        return { value: 'o', label: 'Obsolete' }        
      case 'h':
        return { value: 'h', label: 'Pending Approval' }        
      case 'r':
        return { value: 'r', label: 'Pending Deletion' }        
      case 'p':
        return { value: 'p', label: 'Pending Freeze' }        
      default:
        return { value: 'a', label: 'Active' }        
    }
  }

  setIsDefaultAndIsEncrytValue(file_is_default: string): any {
    if (file_is_default === 'y') {
      var isDefault = { label: 'y', value: 'y' }
      return isDefault;
    } else {
      var isDefault = { label: 'n', value: 'n' }
      return isDefault;
    }
  }


}
