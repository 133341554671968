import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'ref-opt',
  templateUrl: './ref-opt.component.html',
  styleUrls: ['./ref-opt.component.css']
})
export class RefOptComponent implements OnInit {

  refOptObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
  }

  _formClear() {
    this.refOptObjectForm = this.formBuilder.group({
      toID: [''],
      toType: ['']
    });
    this.formReady.emit(this.refOptObjectForm);
  }

}
