import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from "../../../environments/environment";
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpEventType } from '@angular/common/http';
import { CheckOut, TempPath } from '../model/checkout';
import 'rxjs/add/operator/map';
import {CheckoutResponse} from "../../web-client/tree-view/tree-view.interfce";

@Injectable({
  providedIn: 'root'
})
export class CheckOutService {

  private teamURI = environment.apdmServiceUrl;
  private downloadServiceURI = environment.apdmServiceDownloadUrl;
  public envName = environment.envName;
  public headers: HttpHeaders;
  cdsId: any;
  token: any;
  fileMenuCheckOut: CheckOut;

  constructor(private http: HttpClient) {
    this.token = JSON.parse(sessionStorage.getItem('strAccessToken'));
    if (this.token) {
      this.cdsId = this.token.CommonName;
    }
    //console.log(' cdsid--->', this.cdsId);
    if (this.cdsId !== undefined) {
      this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
    } else {
      this.cdsId = 'Invalid';
      this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
    }
  }

  checkOut(data: any): Observable<HttpResponse<Blob>> {
    console.log("folder checkbout");
    return this.http.post(this.downloadServiceURI + 'foldercheckout', data, {
      headers: this.headers,
      observe: 'response',
      responseType: 'blob',
    })as Observable<HttpResponse<Blob>>;
  }
	
	checkOutNew(data: any): Observable<HttpResponse<CheckoutResponse>> {
		console.log("folder checkbout");
		const url = this.downloadServiceURI + 'foldercheckout';
		console.log("Constructed URL: ", url); // Log the URL to the console
		console.log("folder checkout");
		return this.http.post<CheckoutResponse>(url, data, {
			headers: this.headers,
			observe: 'response',
			responseType: 'json',
		});
	}

  deleteTempPath(path: TempPath) {
    return this.http.post(this.downloadServiceURI + 'deletetemppath', path);
  }

  fileCheckOutPreSignedUrl(data: any) {
    console.log("file checkbout");
    return this.http.post(this.teamURI + 'filecheckout', data);
  }
	/*getCheckoutPath():Observable<string>{
  	console.log("to checkout path");
		return this.http.get<string>(this.teamURI + 'getCheckoutPath',{headers: this.headers , responseType: 'text' as 'json'});
	}*/

  checkoutSendEmail(data: any, totalSize: any) {
    console.log('File size:', totalSize)
    return this.http.post(this.downloadServiceURI + 'foldercheckoutSendEmail/' + totalSize, data);
  }

  checkoutSendEmailDatatable(data: any[], totalSize) {
    return this.http.post(this.downloadServiceURI + 'checkoutSendEmailDatatable/' + totalSize, data);
  }

  checkSizeBeforeCheckout(id: any[]): Observable<string> {
    return this.http.post<string>(this.downloadServiceURI + 'checkSizeBeforeCheckout', id, { headers: this.headers, responseType: 'text' as 'json' });
  }

  downloadFile(downloadLink: string): Observable<any> {
    console.log('downloadLink:', downloadLink);
    // return this.http.get(downloadLink, {
    //   observe: 'response',
    //   responseType: 'blob',
    //   reportProgress: true,
    // });
    const req = new HttpRequest('GET', downloadLink, {
      reportProgress: true,
    });

    this.http.request(req).subscribe(event => {
      // Via this API, you get access to the raw event stream.
      // Look for download progress events.
      if (event.type === HttpEventType.DownloadProgress) {
        // This is an download progress event. Compute and show the % done:
        const percentDone = Math.round(100 * event.loaded / event.total);
        console.log(`File is ${percentDone}% downloaded.`);
      } else if (event instanceof HttpResponse) {
        console.log('File is completely downloaded!');
      }
    });
    return;
  }

  // downloadFile(downloadLink: string): Observable<HttpResponse<Blob>> {
  //   console.log('downloadLink:', downloadLink);
  //   return this.http.get(downloadLink, {
  //     observe: 'response',
  //     responseType: 'blob',
  //   });
  // }

  fileCheckOut(data: any): Observable<HttpResponse<Blob>> {
    console.log("file checkbout");
    return this.http.post(this.teamURI + 'filecheckout', data, {
      headers: this.headers,
      observe: 'response',
      responseType: 'blob',
    });
  }

  dataTableCheckout(data: CheckOut[]): Observable<HttpResponse<Blob>> {
    return this.http.post(this.downloadServiceURI + 'dataTableCheckout', data, {
      headers: this.headers,
      observe: 'response',
      responseType: 'blob'
    });
  }
	
	dataTableCheckoutNew(data: CheckOut[]): Observable<HttpResponse<CheckoutResponse>>  {
		const url = this.downloadServiceURI + 'dataTableCheckout';
		console.log("Constructed URL: ", url); // Log the URL to the console
		console.log("folder checkout");
		return this.http.post<CheckoutResponse>(url, data, {
			headers: this.headers,
			observe: 'response',
			responseType: 'json',
		});
  }

  onClickCheckout(data: CheckOut): Observable<HttpResponse<Blob>> {
    return this.http.post(this.teamURI + 'onClickCheckout', data, {
      headers: this.headers,
      observe: 'response',
      responseType: 'blob'
    });
  }

  emptyMsg() {
    console.log('empty method');
    return this.http.get(this.teamURI + 'emptyapi');
  }
}
