import { Component, OnInit, Input } from '@angular/core';
import { AccessShare } from 'app/core/model/viewPersonModel';

@Component({
  selector: 'team-people',
  templateUrl: './team-people.component.html',
  styleUrls: ['./team-people.component.css']
})
export class TeamPeopleComponent implements OnInit {
  @Input('viewTeamPeople') viewTeamPeople: AccessShare[];
  selectedData: any[];
  rowSelected: any[];
  cols: any[];
  constructor() { }

  ngOnInit() {
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'has_access', header: 'Has Access ?' },
      { field: 'date', header: 'Joined On' },
      { field: 'firstName', header: 'First Name' },
      { field: 'lastName', header: 'Last Name' }
    ];
  }

}
