import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ost-metrics',
  templateUrl: './ost-metrics.component.html',
  styleUrls: ['./ost-metrics.component.css']
})
export class OstMetricsComponent implements OnInit {
  ostMetrics:boolean=false;

  constructor() { }

  ngOnInit() {
    this.ostMetrics = true;
  }
  close(){
    this.ostMetrics =false;
  }

}
