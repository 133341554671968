import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {createAclModel, createItemsresposneModel} from '../../core/model/createAclModel';
import {createAclService} from '../../core/service/createAcl-service';

@Component({
	selector: 'create-acl',
	templateUrl: './create-acl.component.html',
	styleUrls: ['./create-acl.component.css'],
	providers: [createAclService]
})
export class CreateAclComponent implements OnInit {

	createAclForm: FormGroup;
	createaclModel: createAclModel;
	submitted: boolean = false;
	response: string;
	flagDisplay: boolean = false;
	errorDisplay: boolean = false;


	constructor(private formBuilder: FormBuilder, private service: createAclService) {

	}

	ngOnInit() {
		this.createAclForm = this.formBuilder.group({
			itemId: ['', Validators.required],
			teamId: ['', Validators.required],
			query: [''],
			read: [''],
			write: [''],
			create: [''],
			delete: [''],
		});
	}

	get f() {
		return this.createAclForm.controls;
	}

	aclCreate() {
		this.submitted = true;
		this.errorDisplay = false;
		this.createaclModel = this.createAclForm.value;
		// console.log('creation acl');
		var numbers = /^[0-9]+$/;
		if (this.createaclModel.itemId == '' && !(this.createaclModel.teamId == '')) {
			this.response = 'Valid Item ID is required';
			this.errorDisplay = true;
			this.createAclForm.patchValue({
				itemId: 0,
			});
		} else if (!(this.createaclModel.itemId == '') && this.createaclModel.teamId == '') {
			this.response = 'Valid Team ID is required';
			this.errorDisplay = true;
			this.createAclForm.patchValue({
				teamId: 0,
			});
		} else {
			if (!(this.createaclModel.itemId.match(numbers)) || !(this.createaclModel.teamId.match(numbers))) {
				console.log('Valid Item ID is required::///');
				this.response = 'Valid Item ID is required';
				this.errorDisplay = true;
				this.createAclForm.patchValue({
					itemId: 0,
					teamId: 0,
				});
			} else if (this.createaclModel.query !== '' && !(this.createaclModel.query.match(numbers))) {
				this.response = 'Invalid query is entered.';
				this.errorDisplay = true;
			} else if (this.createaclModel.read !== '' && !(this.createaclModel.read.match(numbers))) {
				this.response = 'Invalid read is entered.';
				this.errorDisplay = true;
			} else if (this.createaclModel.create !== '' && !(this.createaclModel.create.match(numbers))) {
				this.response = 'Invalid create is entered.';
				this.errorDisplay = true;
			} else if (this.createaclModel.write !== '' && !(this.createaclModel.write.match(numbers))) {
				this.response = 'Invalid write is entered.';
				this.errorDisplay = true;
			} else if (this.createaclModel.delete !== '' && !(this.createaclModel.delete.match(numbers))) {
				this.response = 'Invalid delete is entered.';
				this.errorDisplay = true;
			} else {
				if (this.createAclForm.valid) {
					console.log('creation acl', this.createAclForm.value);
					this.service.setinsertACL(this.createaclModel).subscribe((data: createItemsresposneModel) => {
						console.log('data', data);
						if (data.result) {
							this.response = data.message;
							this.errorDisplay = true;
							// alert(data.message);
						} else {
							this.response = data.message;
							this.errorDisplay = true;
							//alert(data.message);
						}
					});
				} else {
					this.response = 'Check all Mandatory Fields !!';
					this.flagDisplay = true;
				}
			}
		}
	}

	close() {
		this.errorDisplay = false;

	}


}
