import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { DatePipe } from '@angular/common';
import { CheckInService } from '../../core/service/check-in.service';
import { HttpResponse, HttpEventType, HttpClient, HttpParameterCodec } from '@angular/common/http';
import { ConfirmationService } from 'primeng/api';
import { CheckInModel, DoValidateCheckInModel, CheckInGetParentDetails, PreSignedURL } from '../../core/model/checkInModel';
import { FileSizePipe } from 'app/shared/file-size.pipe';
import {isNull} from "util";

@Component({
  selector: 'check-in',
  templateUrl: './check-in.component.html',
  styleUrls: ['./check-in.component.css'],
  // providers: [ConfirmationService]
})
export class CheckInComponent implements OnInit, HttpParameterCodec {

  @Output() closeCheckIn = new EventEmitter<any>();

  @ViewChild('folderInput')
  myFolderInputVariable: ElementRef;

  value: number = 0;
  @Input() parentPath: string;
  uploadStatus: string;

  successCount: number = 0;
  failureCount: number = 0;
  totalCount: number = 0;
  stopUpload: boolean = false;
  totalSize: number = 0;
  sucessSize: number = 0;
  failureSize: number = 0;

  closeDisplay: boolean = false;
  logData: any[] = [];
  checkInForm: FormGroup;
  folder: string;
  dovalidateFlag: any;
  uploadedFiles: CheckInModel[] = [];
  uploadedFolderFiles: CheckInModel[] = [];
  uploadedFileNames: DoValidateCheckInModel[] = [];
  uploadedFolderFileNames: DoValidateCheckInModel[] = [];
  progress: { percentage: number } = { percentage: 0 };
  selectedFiles: FileList;
  checkInModel = new CheckInModel();
  doValidateCheckInModel = new DoValidateCheckInModel();
  doValidateFolderCheckInModel = new DoValidateCheckInModel();
  checkInGetParentDetails = new CheckInGetParentDetails();
  preSignedURL: PreSignedURL;
  preSignedURL_1: PreSignedURL;
  preSignedURL_2: PreSignedURL;

  folderUploadMsg: string = "Maximum of 15 GB allowed per file";
  fileUploadMsg: string = "Maximum of 15 GB allowed per file";

  constructor(private formBuilder: FormBuilder, private datePipe: DatePipe, private checkinService: CheckInService,
    private confirmationService: ConfirmationService, private fileSizePipe: FileSizePipe, private http: HttpClient) {

  }

  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }

  ngOnInit() {
    // console.log('check IN Nginit.............');
    this.removeAll();
    this.removeAllFolderFiles();
    this.checkInForm = this.formBuilder.group({
      logData: [null, []]
    });
    this.value = 0;
    this.totalSize = 0;
    this.totalCount = 0;
    this.resetSuccessFailureCount();
    this.logData.push({ name: 'Started at:' + this.returnCurrentDate(), color: "black" });
    // this.checkInForm.get('logData').setValue('Started at:' + this.returnCurrentDate());
  }

  removeAll() {
    this.uploadedFiles = [];
    this.uploadedFileNames = [];
    this.value = 0;
    this.totalSize = 0;
    this.uploadSize = '';
    this.resetSuccessFailureSize();
    this.totalCount = 0;
    this.uploadStatus = 'Uploading';
    this.resetSuccessFailureCount();
    this.logData = [];
    this.checkInModel = new CheckInModel();
    this.doValidateCheckInModel = new DoValidateCheckInModel();
    this.checkInGetParentDetails = new CheckInGetParentDetails();
    this.folderUploadMsg = 'Maximum of 15 GB allowed per file';
    this.fileUploadMsg = 'Maximum of 15 GB allowed per file';

    this.rowSelectedDataForuploadedFiles = [];
    this.rowSelectedDataForuploadedFolders = [];
  }


  removeAllFolderFiles() {
    this.myFolderInputVariable.nativeElement.value = "";
    this.uploadedFolderFiles = [];
    this.uploadedFolderFileNames = [];
    this.value = 0;
    this.totalSize = 0;
    this.resetSuccessFailureSize();
    this.totalCount = 0;
    this.uploadStatus = 'Uploading';
    this.resetSuccessFailureCount();
    this.logData = [];
    this.checkInModel = new CheckInModel();
    this.doValidateFolderCheckInModel = new DoValidateCheckInModel();
    this.folderUploadMsg = 'Maximum of 15 GB allowed per file';
    this.fileUploadMsg = 'Maximum of 15 GB allowed per file';

    this.rowSelectedDataForuploadedFiles = [];
    this.rowSelectedDataForuploadedFolders = [];
  }

  private resetSuccessFailureSize() {
    this.sucessSize = 0;
    this.checkInfileSize = '0';
    this.failureSize = 0;
  }

  get f() {
    return this.checkInForm.controls;
  }

  filesPicked(files) {
    console.log('<-- Folder Upload Start --->');
    this.folderUploadMsg = 'Please click on the "upload" button to start the folder upload ! (Maximum of 15 GB allowed per file)';
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const path = file.webkitRelativePath.split('/');
      // console.log('file--->', file);
      // console.log('path--->', path);
      // upload file using path
      // ...

      if (file.size < 16106127361) {   // 16106127360 Bytes = 15 GB


        //Dovalidate List
        this.doValidateFolderCheckInModel = new DoValidateCheckInModel();

        //Folder Upload List
        this.checkInModel = new CheckInModel();
        this.checkInModel.name = file.name;
        this.checkInModel.size = file.size;
        this.totalSize = this.totalSize + file.size;
        this.checkInModel.modified = file.lastModifiedDate;
        this.checkInModel.file = file;
        this.checkInModel.status = "";
        var re = /\,/gi;
        //this.checkInModel.directory = path.toString().replace(re, "/");
        //this.checkInModel.tmpDirectory = path.toString().replace(re, "@@");
        var directory = "";
        var tmpdirectory = "";
        for (let i = 1; i <= path.length; i++) {
          if (i != path.length) {
            directory = directory + path[i - 1] + "/";
            tmpdirectory = tmpdirectory + path[i - 1] + "@@";
          } else {
            directory = directory + path[i - 1];
            tmpdirectory = tmpdirectory + path[i - 1];
          }
        }
        this.checkInModel.directory = directory;
        this.checkInModel.tmpDirectory = tmpdirectory;

        // COMMENTED ON 6/10/2020 BY MPRABH24
        // this.doValidateFolderCheckInModel = this.checkInModel.directory + file.name;

        // ADDED ON 6/10/2020 BY MPRABH24
        this.doValidateFolderCheckInModel.name = path[0];
        this.doValidateFolderCheckInModel.directory = directory;

        console.log("Folder this.checkInModel", this.checkInModel);
        if (!this.uploadedFolderFiles.some((item) => item.directory == this.checkInModel.directory)) {
          this.uploadedFolderFiles.push(this.checkInModel);
          this.uploadedFolderFileNames.push(this.doValidateFolderCheckInModel);
        } else {
          this.logData.push({ name: this.returnCurrentDate() + this.checkInModel.name + ' is already exists in the selected list', color: "red" });
          // this.checkInForm.get('logData').setValue(this.returnCurrentDate() + this.checkInModel.name + 'is already exists in the selected list');
          // console.log("Thu Jul 04 19:02:16 IST 2019 : C:\DaFsLogFile.log is already exists in the selected list");
        }
      } else {
        this.logData.push({ name: file.name + '- file size should not be greater than 15 GB', color: "red" });
      }
    }
  }

  onFolderUpload() {
    // console.log('<--- On Folder Upload -->');
    this.folderUploadMsg = 'Maximum of 15 GB allowed per file';
    if (this.uploadedFolderFiles && this.uploadedFolderFiles.length) {
      // console.log('this.checkinService.selectedNodeItemId --> ', this.checkinService.selectedNodeItemId);
      this.doFolderUpload(this.checkinService.selectedNodeItemId);
      // console.log('this.uploadedFolderFiles --> ', this.uploadedFolderFiles)
    } else {
      this.logData.push({ name: 'Please select atleast one folder to upload...', color: "red" });
      alert("Please select atleast one folder to upload...");
    }
  }

  onFileSelected(event) {
    // console.log(event);
    // console.log('itemId--->', this.checkinService.selectedNodeItemId);
    this.fileUploadMsg = 'Please click on the "upload" button to start the file upload ! (Maximum of 15 GB allowed per file)';
    for (let file of event.files) {

      // console.log('FILE SIZE---->', file.size);
		if (file.size < 16106127361) {   // 16106127360 Bytes = 15 GB

        // console.log('.....OK....');
        this.doValidateCheckInModel = new DoValidateCheckInModel();
        this.doValidateCheckInModel.name = file.name;
        //File Upload List
        this.checkInModel = new CheckInModel();
        this.checkInModel.name = file.name;
        this.checkInModel.size = file.size;
        this.totalSize = this.totalSize + file.size;
        this.checkInModel.modified = file.lastModifiedDate;
        // var datePipe = new DatePipe("en-US");
        //   var test = datePipe.transform(this.checkInModel.modified, 'd MMM y h:mm a');
        //   console.log('test---->',test);
        this.checkInModel.file = file;
        this.checkInModel.status = "";
        // console.log("this.checkInModel", this.checkInModel);
        if (!this.uploadedFiles.some((item) => item.name == this.checkInModel.name)) {
          this.uploadedFiles.push(this.checkInModel);
          this.uploadedFileNames.push(this.doValidateCheckInModel);
        } else {
          this.logData.push({ name: this.returnCurrentDate() + this.checkInModel.name + 'is already exists in the selected list', color: "red" });
        }
      } else {
        this.logData.push({ name: file.name + '- file size should not be greater than 15 GB', color: "red" });
      }
    }
  }

  returnCurrentDate(): String {
    return formatDate(new Date(), 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');
  }

  // Check whether pre-signed Url is working or not
  // onFileUpload(event) {
  //   console.log('event-------------------->', event);
  //   this.uploadFile(event);
  // }

  // Testing
  uploadFile(file: any) {
    this.checkinService.generatePreSignedURLForUpload('250418913', this.encodeValue(file.files[0].name)).subscribe(data => {
      console.log('--------data------------', data);
      if(isNull(data)){
		  alert('File Name contains \\ / : * ? " < > | or other restricted characters. Please try with different File Name.');
	  }
      this.preSignedURL = data;
      this.checkinService.uploadfileAWSS3(this.preSignedURL.url, file.files[0]).subscribe(event => {
        console.log('----->', event);
      });
    });
  }

  onFileUpload(event) {
    // let isUploadReady: boolean = false;
    this.fileUploadMsg = 'Maximum of 15 GB allowed per file';
    if (this.uploadedFiles && this.uploadedFiles.length) {
      // console.log('this.checkinService.selectedNodeItemId --> ', this.checkinService.selectedNodeItemId);
      this.doFileUpload(this.checkinService.selectedNodeItemId);
    } else {
      this.logData.push({ name: 'Please select atleast one file to upload...', color: "red" });
      alert("Please select atleast one file to upload...");
    }
  }

  doFileUpload(selectedNode: string) {
    this.checkinService.doValidateList(this.uploadedFileNames, selectedNode, " file(s)").subscribe(event => {
      if (event.type === HttpEventType.Response) {
        // console.log('<--- Do Validate is completed --->', event);
        this.dovalidateFlag = event.body;
        if (!(this.dovalidateFlag === 'No Duplicate' || this.dovalidateFlag === 'Validation Failed')) {
          // console.log('<--- this.dovalidateFlag--->', this.dovalidateFlag);
          // console.log('............');
          this.confirmationService.confirm({
            message: this.dovalidateFlag,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              this.logData.push({ name: 'File Uploading Started', color: "green" });
              this.value = 0;
              this.resetSuccessFailureSize();
              this.uploadStatus = 'Uploading';
              this.resetSuccessFailureCount();
              this.totalCount = this.uploadedFiles.length;
              this.pushFileToAWSs3(selectedNode, 0);
            },
            reject: () => {
              this.logData.push({ name: 'Upload Cancelled.', color: "red" });
            }
          });
          // console.log('Condition loop...!', event.body);
        } else {
          this.logData.push({ name: 'File Uploading Started', color: "blue" });
          this.value = 0;
          this.resetSuccessFailureSize();
          this.uploadStatus = 'Uploading';
          this.resetSuccessFailureCount();
          this.totalCount = this.uploadedFiles.length;
          this.pushFileToAWSs3(selectedNode, 0);
        }
      }
    });
  }

  stopUploadFunc() {
    this.stopUpload = true;
  }

  pushFileToAWSs3(selectedNode: string, i: number) {
    // console.log('////////////pushFileToAWSs3///////i//////', i);
    let element = this.uploadedFiles[i];
    // console.log('pushFileToAWSs3---------------------', element);
    this.uploadedFiles[i].status = "Inprogress";
    // console.log('////////////ENCODE/////////',this.encodeValue(element.name))
    this.checkinService.generatePreSignedURLForUpload(selectedNode, this.encodeValue(element.name)).subscribe(data => {
      // console.log('PreSigned URL For File Upload data-------------->', data);
      this.logData.push({ name: 'Ready for File Upload using PreSigned URL' + selectedNode + element.name, color: "green" });
      this.preSignedURL = data;
      // console.log('///////////this.preSignedURL///////////////', this.preSignedURL);
      this.checkinService.uploadfileAWSS3(this.preSignedURL.url.toString(), element.file).subscribe(event => {
        // console.log('Uploading file from Angular to Amazon S3----->', event);
        this.logData.push({ name: 'Uploading file from Angular to Amazon S3', color: "green" });
        this.uploadStatus = "Uploading";
        // console.log('event----,', event);
        if (event.type === HttpEventType.Sent) {
          this.logData.push({ name: `Uploading file "${this.uploadedFiles[i].name}" of size "${this.fileSizePipe.transform(this.uploadedFiles[i].size)}".`, color: "blue" });
        } else if (event.type === HttpEventType.UploadProgress) {
          //event.stop();
          if (this.stopUpload) {
            this.logData.push({ name: 'File Upload Cancelled by the User', color: "red" });
            this.stopUpload = false;
            this.value = 0;
            for (let j = i; j < this.uploadedFiles.length; j++) {
              this.uploadedFiles[j].status = "Cancelled";
            }
            this.uploadStatus = "Cancelled";
            event.stop();
          } else {
            this.value = Math.round(100 * event.loaded / event.total);
            // this.uploadedFiles[i].progressValue = Math.round(100 * event.loaded / event.total);
            //console.log('Percent:', this.value);
            var marker = 1024; // Change to 1000 if required
            var decimal = 2; // Change as required
            var kiloBytes = marker; // One Kilobyte is 1024 bytes
            var megaBytes = marker * marker; // One MB is 1024 KB
            var gigaBytes = marker * marker * marker; // One GB is 1024 MB
            var teraBytes = marker * marker * marker * marker; // One TB is 1024 GB
            if (event.total) {
              if (event.total < kiloBytes)
                this.checkIntotalSize = event.total + " Bytes";
              else if (event.total < megaBytes)
                this.checkIntotalSize = (event.total / kiloBytes).toFixed(decimal) + " KB";
              else if (event.total < gigaBytes)
                this.checkIntotalSize = (event.total / megaBytes).toFixed(decimal) + " MB";
              else
                this.checkIntotalSize = (event.total / gigaBytes).toFixed(decimal) + " GB";
            }
            if (event.loaded) {
              if (event.loaded < kiloBytes)
                this.checkInfileSize = event.loaded + " Bytes";
              else if (event.loaded < megaBytes)
                this.checkInfileSize = (event.loaded / kiloBytes).toFixed(decimal) + " KB";
              else if (event.loaded < gigaBytes)
                this.checkInfileSize = (event.loaded / megaBytes).toFixed(decimal) + " MB";
              else
                this.checkInfileSize = (event.loaded / gigaBytes).toFixed(decimal) + " GB";
            }
            // console.log('totalSize:', this.totalSize);
            // console.log('this.checkInfileSize:', this.checkInfileSize);
            this.uploadSize = this.checkInfileSize + ' / ' + this.checkIntotalSize;
          }
        } else if (event.type === HttpEventType.ResponseHeader) {

        } else if (event.type === HttpEventType.Response) {
          if (event.status == 500) {
            this.logData.push({ name: `File "${this.uploadedFiles[i].name}" was failed to upload in AWS S3 BUCKET!`, color: "green" });
            this.uploadedFiles[i].status = "Failed";
            this.uploadStatus = "Completed";
            this.failureCount++;
          } else if (event.status == 200) {
            this.logData.push({ name: `File "${this.uploadedFiles[i].name}" was completely uploaded in AWS S3 BUCKET!`, color: "green" });
            this.uploadedFiles[i].status = "Success";
            this.uploadStatus = "Completed";
            this.value = 0;
            this.successCount++;
            this.sucessSize = this.sucessSize + this.uploadedFiles[i].size;
            this.insertFileDetails2DB(selectedNode, element, i);
          }
          i++;
          if (i < this.uploadedFiles.length) {
            this.pushFileToAWSs3(selectedNode, i);
          }
        }
        if (this.totalCount == (this.successCount + this.failureCount)) {
          this.value = 100;
        }
      }, error => {
        console.log('--------------------->', error);
        this.uploadedFiles[i].status = "Failed";
        this.uploadStatus = "Completed";
        this.failureCount++;
        console.log('---------i------------>', i);
        // Testing purpose
        // this.insertFileDetails2DB(selectedNode, element, i);
        i++;
        if (i < this.uploadedFiles.length) {
          this.pushFileToAWSs3(selectedNode, i);
        }
      });
    });
  }

  private insertFileDetails2DB(selectedNode: string, element: CheckInModel, i: number) {
    this.preSignedURL_1 = new PreSignedURL();
    this.preSignedURL_1.selectedNode = selectedNode;
    this.preSignedURL_1.filename = element.name;
    this.preSignedURL_1.key = this.preSignedURL.key;
    this.preSignedURL_1.filesize = element.size;
    this.preSignedURL_1.itemId = this.preSignedURL.itemId;
    this.checkinService.pushFileToStorage(this.preSignedURL_1).subscribe(event => {
      if (event.type === HttpEventType.ResponseHeader) {
        console.log('this.checkinService.pushFileToStorage HttpEventType.ResponseHeader <====> event.type', event);
        if (event.status == 500) {
          this.logData.push({ name: `File "${this.preSignedURL_1.filename}" was failed to save/update in DB!`, color: "red" });
          // ADDED BY MPRABH24 ON 10/6/2020 - START
          this.uploadedFiles[i].status = "Failed";
          this.uploadStatus = "Completed";
          this.failureCount++;
          // ADDED BY MPRABH24 ON 10/6/2020 - END
        } else if (event.status == 200) {
          this.logData.push({ name: `File "${this.preSignedURL_1.filename}" was completely uploaded!`, color: "green" });
          // this.uploadedFiles[i].status = "Success";
          // this.uploadStatus = "Completed";
          // this.value = 0;
          // this.successCount++;
          // this.sucessSize = this.sucessSize + this.uploadedFiles[i].size;
        }
      }
    }, error => {
      // ADDED BY MPRABH24 ON 10/6/2020 - START
      console.log('///////////ERROR INSERT FILE DETAILS 2 DB////////////', error);
      // this.logData.push({ name: `File was failed to save/update in DB! "${error}"`, color: "red" });
      this.uploadedFiles[i].status = "Failed";
      this.uploadStatus = "Completed";
      this.failureCount++;
      // ADDED BY MPRABH24 ON 10/6/2020 - END
    });
  }

  doFolderUpload(selectedNode: string) {
    console.log('<--- do Folder Upload -->', this.uploadedFolderFileNames);
    console.log('<--- do Folder Upload -->', this.uploadedFolderFiles);
    this.checkinService.doValidateList(this.uploadedFolderFileNames, selectedNode, " folders(s)").subscribe(event => {
      if (event.type === HttpEventType.Response) {
        // console.log('<--- Do Validate is completed --->', event);
        this.dovalidateFlag = event.body;
        if (!(this.dovalidateFlag === 'No Duplicate' || this.dovalidateFlag === 'Validation Failed')) {
          // console.log('<--- this.dovalidateFlag--->', this.dovalidateFlag);
          // console.log('............');
          this.confirmationService.confirm({
            message: this.dovalidateFlag,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              this.logData.push({ name: 'File Uploading Started', color: "green" });
              this.value = 0;
              this.resetSuccessFailureSize();
              this.uploadStatus = 'Uploading';
              this.resetSuccessFailureCount();
              this.totalCount = this.uploadedFolderFiles.length;
              this.pushFolderFilesToAWSs3(selectedNode, 0);
            },
            reject: () => {
              this.logData.push({ name: 'Upload Cancelled.', color: "red" });
            }
          });
          // console.log('Condition loop...!', event.body);
        } else {
          this.logData.push({ name: 'File Uploading Started', color: "green" });
          this.value = 0;
          this.resetSuccessFailureSize();
          this.uploadStatus = 'Uploading';
          this.resetSuccessFailureCount();
          this.totalCount = this.uploadedFolderFiles.length;
          this.pushFolderFilesToAWSs3(selectedNode, 0);
        }
      }
    });
  }

  pushFolderFilesToAWSs3(selectedNode: string, i: number) {

    let element = this.uploadedFolderFiles[i];
    this.uploadedFolderFiles[i].status = "Inprogress";

    this.checkinService.fileUploadActionDoUpload(selectedNode, this.encodeValue(element.tmpDirectory.toString())).subscribe(getFolderItemId => {
      if (getFolderItemId.type === HttpEventType.Response) {
        var result = getFolderItemId.body;
        if (result.toString().indexOf("ERROR") < 0) {
          var splitted = result.toString().split("/");
          console.log('///////FOLDER UPLOAD///LINK//SPLITTED//', splitted.length);
          console.log('///////FOLDER UPLOAD///ITEM//ID//', splitted[splitted.length - 1]);
          var folderItemId = splitted[splitted.length - 1];
          this.checkinService.generatePreSignedURLForUpload(folderItemId, this.encodeValue(element.name)).subscribe(data => {
            console.log('PreSigned URL For File Upload data-------------->', data);
            this.logData.push({ name: 'Ready for File Upload using PreSigned URL' + folderItemId + element.name, color: "green" });
            this.preSignedURL = data;
            this.checkinService.uploadfileAWSS3(this.preSignedURL.url.toString(), element.file).subscribe(event => {
              this.uploadStatus = "Uploading";
              if (event.type === HttpEventType.Sent) {
                this.logData.push({ name: `Uploading file "${this.uploadedFolderFiles[i].name}" of size "${this.fileSizePipe.transform(element.size)}".`, color: "blue" });
              } else if (event.type === HttpEventType.UploadProgress) {
                if (this.stopUpload) {
                  this.logData.push({ name: 'Upload Cancelled by the User', color: "red" });
                  this.stopUpload = false;
                  this.value = 0;
                  for (let j = i; j < this.uploadedFolderFiles.length; j++) {
                    this.uploadedFolderFiles[j].status = "Cancelled";
                  }
                  this.uploadStatus = "Cancelled";
                  event.stop();
                } else {
                  this.value = Math.round(100 * event.loaded / event.total);
                  // this.uploadedFiles[i].progressValue = Math.round(100 * event.loaded / event.total);
                  //console.log('Percent:', this.value);
                  var marker = 1024; // Change to 1000 if required
                  var decimal = 2; // Change as required
                  var kiloBytes = marker; // One Kilobyte is 1024 bytes
                  var megaBytes = marker * marker; // One MB is 1024 KB
                  var gigaBytes = marker * marker * marker; // One GB is 1024 MB
                  var teraBytes = marker * marker * marker * marker; // One TB is 1024 GB
                  if (event.total) {
                    if (event.total < kiloBytes)
                      this.checkIntotalSize = event.total + " Bytes";
                    else if (event.total < megaBytes)
                      this.checkIntotalSize = (event.total / kiloBytes).toFixed(decimal) + " KB";
                    else if (event.total < gigaBytes)
                      this.checkIntotalSize = (event.total / megaBytes).toFixed(decimal) + " MB";
                    else
                      this.checkIntotalSize = (event.total / gigaBytes).toFixed(decimal) + " GB";
                  }

                  if (event.loaded) {
                    if (event.loaded < kiloBytes)
                      this.checkInfileSize = event.loaded + " Bytes";
                    else if (event.loaded < megaBytes)
                      this.checkInfileSize = (event.loaded / kiloBytes).toFixed(decimal) + " KB";
                    else if (event.loaded < gigaBytes)
                      this.checkInfileSize = (event.loaded / megaBytes).toFixed(decimal) + " MB";
                    else
                      this.checkInfileSize = (event.loaded / gigaBytes).toFixed(decimal) + " GB";
                  }

                  this.uploadSize = this.checkInfileSize + ' / ' + this.checkIntotalSize;
                }
              } else if (event.type === HttpEventType.ResponseHeader) {

              } else if (event.type === HttpEventType.Response) {
                // console.log('HttpEventType.ResponseHeader <====> event.type', event);
                if (event.status == 500) {
                  this.logData.push({ name: `File "${this.uploadedFolderFiles[i].name}" was failed to upload in AWS S3 BUCKET!`, color: "red" });
                  this.uploadedFolderFiles[i].status = "Failed";
                  this.uploadStatus = "Completed";
                  this.failureCount++;
                } else if (event.status == 200) {
                  this.logData.push({ name: `File "${this.uploadedFolderFiles[i].name}" was completely uploaded in AWS S3 BUCKET!`, color: "green" });
                  this.uploadedFolderFiles[i].status = "Success";
                  this.uploadStatus = "Completed";
                  this.value = 0;
                  this.successCount++;
                  this.sucessSize = this.sucessSize + this.uploadedFolderFiles[i].size;
                  this.insertFolderDetails2DB(folderItemId, element, i, data);
                }
                i++;
                if (i < this.uploadedFolderFiles.length) {
                  this.pushFolderFilesToAWSs3(selectedNode, i);
                }
              }
              if (this.totalCount == (this.successCount + this.failureCount)) {
                this.value = 100;
              }
            }, error => {
              // console.log('--------------------->', error);
              // this.logData.push({ name: `File was failed to upload in AWS S3 BUCKET! "${error}"`, color: "red" });
              this.uploadedFolderFiles[i].status = "Failed";
              this.uploadStatus = "Completed";
              this.failureCount++;
              // console.log('---------i------------>', i);
              i++;
              if (i < this.uploadedFolderFiles.length) {
                this.pushFolderFilesToAWSs3(selectedNode, i);
              }
            });
          });

        }
      }
    }, error => {
      // console.log('--------------------->', error);
      // this.logData.push({ name: `File/Folder directory was failed to create in APDM DB! "${error}"`, color: "red" });
      this.uploadedFolderFiles[i].status = "Failed";
      this.uploadStatus = "Completed";
      this.failureCount++;
      // console.log('---------i------------>', i);
      i++;
      if (i < this.uploadedFolderFiles.length) {
        this.pushFolderFilesToAWSs3(selectedNode, i);
      }
    });
  }

  private insertFolderDetails2DB(selectedNode: string, element: CheckInModel, i: number, data: any) {
    // console.log('<--Start Folder Upload-->');
    this.preSignedURL_1 = new PreSignedURL();
    this.preSignedURL_1.selectedNode = selectedNode;
    this.preSignedURL_1.filename = element.name;
    this.preSignedURL_1.key = data.key;
    this.preSignedURL_1.filesize = element.size;
    this.preSignedURL_1.itemId = data.itemId;
    this.checkinService.pushFileToStorage(this.preSignedURL_1).subscribe(storageEvent => {
      if (storageEvent.type === HttpEventType.Response) {
        if (storageEvent.status == 500) {
          this.logData.push({ name: `File "${this.preSignedURL_1.filename}" was failed to save/update in DB!`, color: "red" });
          this.uploadedFolderFiles[i].status = "Failed";
          this.uploadStatus = "Completed";
          this.failureCount++;
        } else if (storageEvent.status == 200) {
          this.logData.push({ name: `File "${this.preSignedURL_1.filename}" was successfully saved/updated in DB!`, color: "green" });
        }
      }
    }, error => {
      console.log('--------------------->', error);
      // this.logData.push({ name: `File was failed to save/update in DB! "${error}"`, color: "red" });
      this.uploadedFolderFiles[i].status = "Failed";
      this.uploadStatus = "Completed";
      this.failureCount++;
    });
  }

  private resetSuccessFailureCount() {
    this.successCount = 0;
    this.failureCount = 0;
  }

  checkInfileSize: string = '0';
  checkIntotalSize: string = '0';
  uploadSize: string;

  onUploadProgress(event) {
    // console.log("progress event", event.progress)
    this.value = event.progress;
  }

  remove(item: any, i: number) {
    var tmpUploadFiles = this.uploadedFiles[i];
    // console.log('tmpUploadFiles------>',tmpUploadFiles);
    this.uploadedFiles.splice(i, 1);
    this.uploadedFileNames.splice(i, 1);
    this.totalCount = this.uploadedFiles.length;
    this.totalSize = this.totalSize - tmpUploadFiles.size;
    // console.log("selected item ", item)
    // console.log("removed item ", i)
  }

  removeFolderFile(item: any, i: number) {
    var tmpUploadFiles = this.uploadedFolderFiles[i];
    // console.log('tmpUploadFiles------>',tmpUploadFiles);
    this.uploadedFolderFiles.splice(i, 1);
    this.uploadedFolderFileNames.splice(i, 1);
    this.totalCount = this.uploadedFolderFiles.length;
    this.totalSize = this.totalSize - tmpUploadFiles.size;
    // console.log("selected item ", item)
    // console.log("removed item ", i)
  }

  browse(event: any) {
    console.log(event);
  }

  clearLog() {
    this.logData = [];
  }

  close() {
    this.closeDisplay = true;
  }

  closeDialog() {
    this.closeDisplay = false;
    this.closeCheckIn.emit(this.closeDisplay);
  }

  onSubmit() {
  }

  colors = [
    { status: "Success", color: "green" },
    { status: "Inprogress", color: "blue" },
    { status: "Failure", color: "red" }
  ]


  getTheColor(status) {
    return status;
    // console.log(this.colors.filter(item => item.status === status)[0].color);
    // return this.colors.filter(item => item.status === status)[0].color // could be better written, but you get the idea
  }

  rowSelectedDataForuploadedFiles: CheckInModel[] = [];
  rowSelectedDataForuploadedFolders: CheckInModel[] = [];

  multiSelectCheckBoxFile(event) {
    // console.log('///////SELECTED FILES IN FILE UPLOAD/////////', this.rowSelectedDataForuploadedFiles);
  }

  multiSelectCheckBoxFolder(event) {
    // console.log('///////SELECTED FILES IN FOLDER UPLOAD/////////', this.rowSelectedDataForuploadedFolders);
  }

  removeSelectedRow() {
    console.log('///////SELECTED FILES IN FILE UPLOAD/////////', this.rowSelectedDataForuploadedFiles);
    console.log('///////SELECTED FILES IN FOLDER UPLOAD/////////', this.rowSelectedDataForuploadedFolders);

    var tmpFileRowSelected = this.rowSelectedDataForuploadedFiles;
    var tmpFolderRowSelected = this.rowSelectedDataForuploadedFolders;

    this.rowSelectedDataForuploadedFiles = [];
    this.rowSelectedDataForuploadedFolders = [];

    if (tmpFileRowSelected && tmpFileRowSelected.length) {
      if (this.uploadedFiles && this.uploadedFiles.length) {
        if (tmpFileRowSelected.length === this.uploadedFiles.length) {
          // this.fileUploadMsg = '';
          // this.uploadedFiles = [];
          this.removeAll();
        }
      }
    }

    if (tmpFolderRowSelected && tmpFolderRowSelected.length) {
      if (this.uploadedFolderFiles && this.uploadedFolderFiles.length) {
        if (tmpFolderRowSelected.length === this.uploadedFolderFiles.length) {
          // this.folderUploadMsg = '';
          // this.uploadedFolderFiles = [];
          this.removeAllFolderFiles();
        }
      }
    }

    if (tmpFileRowSelected && tmpFileRowSelected.length && this.uploadedFileNames && this.uploadedFileNames.length) {
      let tmpUploadedFileNames = this.uploadedFileNames;
      for (let file of tmpFileRowSelected) {
        for (let fileNames of tmpUploadedFileNames) {
          // console.log('///////File Names///////', fileNames, '//////File/////', file.name);
          if (fileNames.name == file.name) {
            const index = this.uploadedFileNames.indexOf(fileNames, 0);
            // console.log('///////Index///////', index, '///////////', fileNames);
            if (index > -1) {
              this.uploadedFileNames.splice(index, 1);
            }
          }
        }
      }
    } else {
      // this.logData.push({ name: "Kindly please select any row in file upload to remove.", color: "red" });
    }

    if (tmpFolderRowSelected && tmpFolderRowSelected.length && this.uploadedFolderFileNames && this.uploadedFolderFileNames.length) {
      let tmpUploadedFolderFileNames = this.uploadedFolderFileNames;
      for (let file of tmpFolderRowSelected) {
        for (let fileNames of tmpUploadedFolderFileNames) {
          if (fileNames.directory == file.directory) {
            const index = this.uploadedFolderFileNames.indexOf(fileNames, 0);
            // console.log('///////Index///////', index, '///////////', fileNames);
            if (index > -1) {
              this.uploadedFolderFileNames.splice(index, 1);
            }
          }
        }
      }
    } else {
      // this.logData.push({ name: "Kindly please select any row in file upload to remove.", color: "red" });
    }


    if (tmpFileRowSelected && tmpFileRowSelected.length && this.uploadedFiles && this.uploadedFiles.length) {
      for (let file of tmpFileRowSelected) {
        const index = this.uploadedFiles.indexOf(file, 0);
        // console.log('///////Index///////', index, '///////////', file);
        if (index > -1) {
          this.uploadedFiles.splice(index, 1);
        }
      }
    } else {
      // this.logData.push({ name: "Kindly please select any row in file upload to remove.", color: "red" });
    }

    if (tmpFolderRowSelected && tmpFolderRowSelected.length && this.uploadedFolderFiles && this.uploadedFolderFiles.length) {
      for (let file of tmpFolderRowSelected) {
        const index = this.uploadedFolderFiles.indexOf(file, 0);
        // console.log('///////Index///////', index, '///////////', file);
        if (index > -1) {
          this.uploadedFolderFiles.splice(index, 1);
        }
      }
    } else {
      // this.logData.push({ name: "Kindly please select any row in folder upload to remove.", color: "red" });
    }
  }

}
