import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { childnode } from '../../core/model/treeViewModel'


@Injectable({
	providedIn: 'root'
})
export class TreeViewService {

	private teamURI = environment.apdmServiceUrl;
	treeBaseID = new EventEmitter<any>();


	cdsId: any;

	token: any;

	private headers: HttpHeaders;

	constructor(private http: HttpClient) {

		this.token = JSON.parse(sessionStorage.getItem('strAccessToken'));


		if (this.token) {
			this.cdsId = this.token.CommonName;
		}



		//console.log(' teamcreation-cdsid--->', this.cdsId);

		if (this.cdsId !== undefined) {
			this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
		} else {
			this.cdsId = 'Invalid';
			this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');

		}


	}

	getInputTreebaseId(id: number) {
		return this.http.get(this.teamURI + 'treeview/' + id, {
			headers: this.headers,
		});
	}

	getParentTreeView(label: string) {
		return this.http.get(this.teamURI + 'parent/' + label, {
			headers: this.headers,
		});

	}

	getChildTreeView(treeObject: childnode) {
		return this.http.post(this.teamURI + 'child', treeObject, {
			headers: this.headers,
		});
	}

	getChild(treeObject: childnode) {
		// console.log("getChild" ,treeObject)
		return this.http.post(this.teamURI + 'parent', treeObject, {
			headers: this.headers,
		})
	}


	getSearchChild(treeObject: childnode) {
		//console.log("getChild" ,treeObject)
		return this.http.post(this.teamURI + 'search/getChild', treeObject, {
			headers: this.headers,
		})
	}

	getSearchLastChild(treeObject: childnode) {
		//console.log("getChild" ,treeObject)
		return this.http.post(this.teamURI + 'search/getlastchild', treeObject, {
			headers: this.headers,
		})
	}




}