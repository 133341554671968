import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from 'rxjs/internal/Observable';
import { DeleteItemRequest, CutPasteItemRequest } from '../model/DeleteItemRequest';

@Injectable({
  providedIn: 'root'
})
export class CopyPasteService {

  selectedNodeItemId: any;
  private apdmServiceBackEndUrl = environment.apdmServiceUrl
  private headers: HttpHeaders;
  cdsId: any;
  token: any;
  cutPasteItemRequest: CutPasteItemRequest;


  constructor(private http: HttpClient) {
    this.token = JSON.parse(sessionStorage.getItem('strAccessToken'));
    if (this.token) {
      this.cdsId = this.token.CommonName;
    }
    //console.log(' cdsid--->', this.cdsId);
    if (this.cdsId !== undefined) {
      this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
    } else {
      this.cdsId = 'Invalid';
      this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
    }
  }

  doCopyFile(srcItemIds: string, destItemIds: string): Observable<any> {
    //console.log('cdsId   ====>', this.cdsId);
    console.log('srcItemIds   ====>', srcItemIds);
    console.log('destItemIds   ====>', destItemIds);
    this.cutPasteItemRequest = new CutPasteItemRequest();
    this.cutPasteItemRequest.sourceId = srcItemIds;
    this.cutPasteItemRequest.destinationId = destItemIds;
    return this.http.post<any>(this.apdmServiceBackEndUrl + 'copypaste', this.cutPasteItemRequest, {
      headers: this.headers,
    });
  }

  doCutPasteFile(srcItemIds: string, destItemIds: string): Observable<any> {
    //console.log('cdsId   ====>', this.cdsId);
    console.log('srcItemIds   ====>', srcItemIds);
    console.log('destItemIds   ====>', destItemIds);
    this.cutPasteItemRequest = new CutPasteItemRequest();
    this.cutPasteItemRequest.sourceId = srcItemIds;
    this.cutPasteItemRequest.destinationId = destItemIds;
    return this.http.post<any>(this.apdmServiceBackEndUrl + 'cutpaste', this.cutPasteItemRequest, {
      headers: this.headers,
    });
  }

  doPasteShortcut(srcItemIds: string, destItemIds: string): Observable<any> {
   // console.log('cdsId   ====>', this.cdsId);
    console.log('srcItemIds   ====>', srcItemIds);
    console.log('destItemIds   ====>', destItemIds);
    this.cutPasteItemRequest = new CutPasteItemRequest();
    this.cutPasteItemRequest.sourceId = srcItemIds;
    this.cutPasteItemRequest.destinationId = destItemIds;
    return this.http.post<any>(this.apdmServiceBackEndUrl + 'pasteshortcut', this.cutPasteItemRequest, {
      headers: this.headers,
    });
  }

}
