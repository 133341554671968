import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import {UserNotification} from "../model/usernotify";



@Injectable({
  providedIn: 'root'
})
export class UsernotifyService {

  private usernotifyURI = environment.apdmServiceUrl;

  private headers: HttpHeaders;


  cdsId : any;

	token: any;

  constructor(private http: HttpClient) {


    this.token = JSON.parse(sessionStorage.getItem('strAccessToken'));


		if(this.token)
		{
		  this.cdsId = this.token.CommonName;
		}



		//console.log(' teamcreation-cdsid--->', this.cdsId);

		if (this.cdsId !== undefined ) {
		  this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
		} else {
		  this.cdsId = 'Invalid';
		  this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');

		}
  }



  ToUserList() {
    return this.http.get(this.usernotifyURI + 'selectone');
  }


  Templateload(templateID){

    return this.http.get(this.usernotifyURI + 'notifymsg/' + templateID ,{
			headers: this.headers,
		  });

  }


  postUserNotificationEmail(mailContent :UserNotification ) {

    console.log("postUserNotificationEmail" , mailContent );
    return this.http.post(this.usernotifyURI + 'sendmail', mailContent,{
			headers: this.headers,
		  });
  }





}
