import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'vehicle-prog-opt',
  templateUrl: './vehicle-prog-opt.component.html',
  styleUrls: ['./vehicle-prog-opt.component.css']
})
export class VehicleProgOptComponent implements OnInit {

  vehicleProgramOptObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
  }


  _formClear() {
    this.vehicleProgramOptObjectForm = this.formBuilder.group({
      changeLevel: '',
      compProg: '',
      listDomain: '',
      comments: 'Object created using APDM Admin Portal',
      endOfLife: '',
      modelYearEnd: '',
      jobOne: '',
      pmtDomain: '',
      kickOff: '',
    });
    this.formReady.emit(this.vehicleProgramOptObjectForm);
  }
}
