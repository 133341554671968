
import { Injectable } from '@angular/core';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Router } from '@angular/router';

import { tap } from 'rxjs/operators';



@Injectable()

export class HttpRequestInterceptor implements HttpInterceptor {

	constructor(private router: Router) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {



		request = request.clone({

			setHeaders: {

				'Authorization': 'Bearer ' + sessionStorage.getItem('encodedAccessToken'),

				'Y7fQPGwn': '1'

			}

		});


		console.log('Intercepter Starts-->')



		return next.handle(request).pipe(tap(() => { },



			(err: any) => {

				console.log('Intercepter Before HttpErrorResponse-->')

				if (err instanceof HttpErrorResponse) {

					console.log('Intercepter -->', request.url)

					if (err.status == 401) {

						console.log('Intercepter 401 -->', request.url)

						this.router.navigate(['login']);

					}

					return;

				}

			}));

	}

}

