import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { ReauthInitiateBO, AccountAuthNotificationBO, AccountAuth } from '../model/admin-reauthorization';

@Injectable({
	providedIn: 'root'
})
export class AppreauthService {

	private appreauth = environment.apdmServiceUrl;
	private headers: HttpHeaders;
	cdsId: any;
	token: any;

	constructor(private http: HttpClient) {
		this.token = JSON.parse(sessionStorage.getItem('strAccessToken'));
		if (this.token) {
			this.cdsId = this.token.CommonName;
		}
		//console.log(' teamcreation-cdsid--->', this.cdsId);
		if (this.cdsId !== undefined) {
			this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
		} else {
			this.cdsId = 'Invalid';
			this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
		}
	}

	getappreauth() {
		console.log("getappreauth");
		return this.http.get(this.appreauth + 'appreauthorization', {
			headers: this.headers,
		});
	}

	performAccount(data: ReauthInitiateBO) {
		return this.http.post(this.appreauth + 'performAcctReauth', data);
	}

	sendMail(data: ReauthInitiateBO) {
		return this.http.post(this.appreauth + 'submitNotification' , data);
	}

	init(_AccountAuthNotificationBO: AccountAuthNotificationBO) {
		return this.http.post(this.appreauth + 'init', _AccountAuthNotificationBO);
	}

	submit(accountAuth: AccountAuth) {
		return this.http.post(this.appreauth + 'submit', accountAuth);
	}


}
