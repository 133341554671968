import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { delay, debounceTime } from 'rxjs/operators';

import { UrlConsumerService } from '../../oauth/url-consumer.service';
import { TeamModifyService } from '../../core/service/team-modify.service';
import { TeamModifyResponse } from '../../core/model/teamModificationModel';
import { GetTeamRequest, TeamgetResponseModel, OteamListApi } from '../../core/model/teamModificationRequest';
import { PersonList, TeamList, OteamGetApi, FormData } from '../../core/model/teamModificationModel';
import { Message } from 'primeng/components/common/api';
import { Subject } from 'rxjs';


@Component({
	selector: 'team-modify',
	templateUrl: './team-modify.component.html',
	styleUrls: ['./team-modify.component.css']

})

export class TeamModifyComponent implements OnInit, OnDestroy {
	private _success = new Subject();
	modifyTeamForm: FormGroup;
	isSubmitted: boolean = false;
	isSubmittedtable : boolean = false;
	isModified: boolean = false;
	isTeamModified: boolean = true;
	isStewardModified: boolean = false;
	submitted = false;
	count: number = 0;
	loading: boolean;
	teamnames: OteamListApi[];
	teams: TeamgetResponseModel;
	teamgetRequest = new GetTeamRequest();
	selectedTeam: OteamListApi = null;
	teamModifyResponse: TeamModifyResponse;
	personList: PersonList[];
	selectedPersonList: PersonList[];
	teamList: TeamList[];
	selectedteamList: TeamList[];
	formData: FormData = new FormData();
	response: any;
	message = [];
	personCols: any[];
	teamCols:any[];
	errorMessage = false;
	successMessage = false;
	isUnAuthorizedUser = false;
	cdsId = '';
	unAuthorizedUserMessage = "You're not authorized for any of the team, Please,contact the help desk and " +
		"report that you need assistance getting an APDM account";
	msgs: Message[] = [];
	// router: Router;

	constructor(private teamservice: TeamModifyService, private formBuilder: FormBuilder, private router: Router, private urlService: UrlConsumerService) {
		console.log('<--Team Modify Constructor-->');
		this.cdsId = urlService.userInfo.cdsId;
	}

	get f() {
		return this.modifyTeamForm.controls;
	}


	ngOnInit() {
		
		this.getTeamData();
		this.modifyTeamForm = this.formBuilder.group({
			id: [''],
			createdOn: [''],
			name: ['', [Validators.required]],
			comments: [''],
			steward: ['', Validators.required],
			teamType: [''],
			modifiedDate: [''],
			teamAddMembers: [''],
			strCreated_on: [''],
			strModified_on: [''],
		});

		this.personCols = [
			{ field: 'id', header: 'Member Ids', display: 'table-cell' },
			{ field: 'personName', header: 'Personal Members of Team', display: 'table-cell' }
			
		  ];

		  this.teamCols = [
			{ field: 'id', header: 'Member Ids', display: 'table-cell' },
			{ field: 'teamName', header: 'Team Members of Team', display: 'table-cell' }
			
		  ];
	}

	get id() {
		return this.modifyTeamForm.get('id')
	}

	getTeamData() {

		this.teamgetRequest.cdsName = this.cdsId;//"mprabh24";
		this.isSubmittedtable = false;
		this.isSubmitted = false;
		this.isUnAuthorizedUser = true;
		this.teamservice.getTeamData(this.teamgetRequest).subscribe((data: TeamgetResponseModel) => {
			this.teams = data;
			if (this.teams.result != null) {
				this.teamnames = this.teams.result.oteamListApi;
				this.isUnAuthorizedUser = true;
			} else {
				this.isUnAuthorizedUser = false;
			}
		});
	}

	callOnchange() {
		if (this.selectedTeam == null) {
			this.errorHandiling(null, "Please select a Team");
		} else {
			this.isSubmitted = true;
			this.isSubmittedtable = true;
			this.clearMessage();
			this.teamservice.postTeamModificationRequest(this.selectedTeam).subscribe((data: TeamModifyResponse) => {
				this.teamModifyResponse = data;
				//this.teamModifyResponse.result.formData.steward
				this.teamModifyResponse.result.oteamGetApi.formData.steward;
				this.modifyTeamForm.setValue(this.teamModifyResponse.result.oteamGetApi.formData);
				this.personList = this.teamModifyResponse.result.oteamGetApi.personList;
				this.teamList = this.teamModifyResponse.result.oteamGetApi.teamList;
				console.log('data :::::',data);
			});
			return this.teamnames;

		}
	}

	deletePersonList() {
		this.isSubmitted = true;
		let idValue: number = this.id.value;
		this.teamservice.deletePersonTableRequest(idValue, this.selectedPersonList).subscribe(response => {
			this.errorHandiling(response, "Selected Personal Members Data Deleted Succesfully.");
			this.isSubmitted = false;
			this.isSubmittedtable = true;
		});
	}

	deleteTeamList() {
		this.isSubmitted = true;
		let idValue: number = this.id.value;
		this.teamservice.deleteTeamTableRequest(idValue, this.selectedteamList).subscribe(response => {
			this.errorHandiling(response, "Selected Team Members Data Deleted Succesfully..");
			this.isSubmitted = false;
			this.isSubmittedtable = true;
		});
	}

	onSubmit() {
		//this.submitted = true;
		this.isTeamModified = false;
		this.isModified = true;
		if (this.modifyTeamForm.invalid) {
			return;
		} else {
			//this.isSubmitted = true;
			this.formData = this.modifyTeamForm.value;
			console.log('team object modification :::',this.modifyTeamForm.value);
			 console.log("steward",this.formData.steward);
			 console.log("cdsid", this.teamModifyResponse.result.oteamGetApi.formData.steward);
			 console.log("equals",this.formData.steward == this.teamModifyResponse.result.oteamGetApi.formData.steward)

			this.teamservice.postupdateTeamModifyRequest(this.formData).subscribe(data => {
				//this.isSubmitted = true;
				this.response = data;
				this.errorHandiling(this.response, "Team Modified Successfully..");
				//console.log("response old steward: ", this.teamModifyResponse.result.formData.steward );
				//console.log("response new steward : " , this.response.result.oteamGetApi.formData.steward);
				  if(this.formData.steward != this.teamModifyResponse.result.oteamGetApi.formData.steward){
					  this.isStewardModified = true;
					  console.log("equals")
				  }
				//console.log("")
				this.modifyTeamForm.setValue(this.response.result.oteamGetApi.formData);
				
			})
		}
	}

	errorHandiling(response: any, serviceCallMessage: string) {
		if (response != null) {
			if (response.result) {
				this.successMessage = true;
				this.errorMessage = false;
				this.msgs = [];
				this.msgs.push({ severity: 'success', detail: serviceCallMessage });
				this.isSubmitted = false;
				this.Ondelay();
				
			} else if (response.error) {
				this.errorMessage = true;
				this.successMessage = false;
				this.msgs = [];
				this.msgs.push({ severity: 'error', detail: response.error.errorCode });
				this.isSubmitted = false;
			}
		} else {
			this.errorMessage = true;
			this.successMessage = false;
			this.msgs = [];
			this.msgs.push({ severity: 'error', detail: serviceCallMessage });
			this.isSubmitted = false;
		}
		

	}

	clearMessage() {
		this.msgs = [];
	}

	Ondelay() {
		this.teamservice.getTeamData(this.teamgetRequest).subscribe(data => {
			setTimeout(() => {
				this.clearMessage();
				this.teamservice.postTeamModificationRequest(this.selectedTeam).subscribe((data: TeamModifyResponse) => {
					this.teamModifyResponse = data;
					this.modifyTeamForm.setValue(this.teamModifyResponse.result.oteamGetApi.formData);
					this.personList = this.teamModifyResponse.result.oteamGetApi.personList;
					this.teamList = this.teamModifyResponse.result.oteamGetApi.teamList;
				});
				//this.callOnchange();
				//this.getTeamData();
				this.selectedPersonList = [];
				this.selectedteamList = [];
			}, 3000)
		});
	}

	ngOnDestroy(): void {

	}

}
