import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'jlrgroupregister-for-opt',
  templateUrl: './jlrgroupregister-for-opt.component.html',
  styleUrls: ['./jlrgroupregister-for-opt.component.css']
})
export class JlrgroupregisterForOptComponent implements OnInit {

  jlrGroupRegisterOpt:FormGroup;  
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {

    this._formClear();
  }

  _formClear() {
    this.jlrGroupRegisterOpt = this.formBuilder.group({
      checkInFilter: ['']

    });
    this.formReady.emit(this.jlrGroupRegisterOpt);
  }

}
