import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'test-lab-req',
  templateUrl: './test-lab-req.component.html',
  styleUrls: ['./test-lab-req.component.css']
})
export class TestLabReqComponent implements OnInit {
  testLabReqObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
  }


  _formClear() {
    this.testLabReqObjectForm = this.formBuilder.group({
      testRqNo: ['']
    });
    this.formReady.emit(this.testLabReqObjectForm);
  }

}
