import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { ItemInitiationForm2, ItemTypeList } from 'app/core/model/itemAdminModels';
import { FormGroup, FormBuilder } from '@angular/forms';
import { cpscCpmt } from 'app/core/model/editPropertiesModels';

import { EditPropertiesService } from 'app/core/service/edit-properties.service';
import { SearchService } from 'app/core/service/search.service';
import { Result } from 'app/core/model/searchModel';
import { SharedService } from 'app/core/service/shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'item-object-for-create-item',
  templateUrl: './item-object-for-create-item.component.html',
  styleUrls: ['./item-object-for-create-item.component.css']
})
export class ItemObjectForCreateItemComponent implements OnInit, OnDestroy {

  itemObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()
  @Input('securityLevelList') securityLevelOptions: ItemInitiationForm2[];
  @Input('retentionScheduleList') retentionScheduleOptions: ItemInitiationForm2[];
  @Input('approvalList') approvalOptions: ItemInitiationForm2[];
  @Input('defaultServerList') defaultServerOptions: ItemInitiationForm2[];
  @Input('ownerCds') ownerCds: string;
  @Input('selectedItemType') selectedItemType: ItemTypeList;
  @Input('label') label: string;
  @Input('domainOptions') domainOptions: ItemInitiationForm2[];

  itemIdSubscibe: Subscription;



  notifyOnReadDeniedOptions: ItemInitiationForm2[];
  lockOnCheckoutOptions: ItemInitiationForm2[];
  keppAllVersionOptions: ItemInitiationForm2[];

  defaultServer: ItemInitiationForm2;
  defaultapproval: ItemInitiationForm2;

  cpscCPmtSuggestions: cpscCpmt[];

  ownerSuggestions: Result;
  stewrdSuggestions: Result;

  constructor(private formBuilder: FormBuilder, private _EditPropertiesService: EditPropertiesService,
    private search: SearchService, public sharedService: SharedService) {
  }


  ngOnInit() {
    this.defaultServer = {
      label: 'main',
      value: 'ptap11.dearborn.ford.com:88'
    }
    this.defaultapproval = {label: "None", value: "N"};
    this._formClear();
    this.notifyOnReadDeniedOptions = [
      { label: 'Yes', value: 'y' },
      { label: 'No', value: 'n' }
    ];
    this.lockOnCheckoutOptions = [
      { label: 'Yes', value: 'y' },
      { label: 'No', value: 'n' }
    ];
    this.keppAllVersionOptions = [
      { label: 'Yes', value: 'y' },
      { label: 'No', value: 'n' }
    ];


  }

  _formClear() {
    console.log('_formClear this.ownerCds', this.ownerCds);
    this.itemObjectForm = this.formBuilder.group({
      id: '',
      owner: this.ownerCds,
      steward: '',
      label: this.label,
      defaultServer: this.defaultServer,
      expirationDate: '',
      retSchedule: { label: "# TR+6M - Work in Process", value: "#TR+6M" },
      parent: '0',
      milestone: '',
      notifyOnReadDenied: '',
      program: '',
      approvalRequired: this.defaultapproval,
      holdCount: '',
      lockOnCheckOut: '',
      type: this.selectedItemType.label,
      securityLevel: '',
      keppAllVersion: '',
      leader: '',
      domain: '',
    });
    this.setItemId();
    this.formReady.emit(this.itemObjectForm);
  }

  setItemId() {
    this.itemIdSubscibe = this.sharedService.createItemId.subscribe(
      (response: any) => {
        // console.log('getItemId data---------------->', response);
        this.itemObjectForm.patchValue({
          id: response
        })
      });
  }



  cpscCPmtSearch(event) {
    this._EditPropertiesService.getCpscCpmt('LOVDOMAIN', event.query).subscribe(data => {
      this.cpscCPmtSuggestions = data;
    });
  }

  Ownersearch(event) {
    this.search.getOwnerResult(event.query).subscribe((data: Result) => {
      this.ownerSuggestions = data;
    });
  }

  StewrdOwnersearch(event) {
    this.search.getOwnerResult(event.query).subscribe((data: Result) => {
      this.stewrdSuggestions = data;
    });
  }

  ngOnDestroy() {
    this.itemIdSubscibe.unsubscribe();
  }

}
