import { Component, OnInit, isDevMode } from '@angular/core';
import { TeamCreation } from "../../core/model/teamcreation";
import { TeamcreationService } from "../../core/service/team-creation.service";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';


@Component({
	selector: 'team-create',
	templateUrl: './team-create.component.html',
	styleUrls: ['./team-create.component.css']
})
export class TeamCreateComponent implements OnInit {
	[x: string]: any;
	creationForm: FormGroup;
	submitted = false;
	teamCreation = new TeamCreation();
	display = false;
	data: any;
	successMessage = false;
	errorMessage = false;
	exTeamCreationList: any[];


	constructor(private teamcreationService: TeamcreationService, private formBuilder: FormBuilder) {

	}

	get f() {
		// console.log('form');
		return this.creationForm.controls;
	}

	ngOnInit() {
		this.exTeamCreationList = [
			{ teamType: 'NVH Section of the AP Dept. in VEE', teamName: 'Vee-ap-nvh' },
			{ teamType: 'A group of all Java Programmers at Jaguar', teamName: 'Jag-java-programmers' },
			{ teamType: 'A group of all system administrators at Ford', teamName: 'Ford-admin' }
		];

		this.creationForm = this.formBuilder.group({
			teamAdmin: [null, [Validators.required, Validators.maxLength(8)]],
			teamName: [null, [Validators.required]],
			teamDescription: [null, [Validators.required]],
			teamMember: [null, [Validators.required]]
		});

		this.display = false;
		this.successMessage = false;
		this.errorMessage = false;

	}

	public noWhitespace(control: FormControl) {
		let isWhitespace = (control.value || '').trim().length === 0;
		let isValid = !isWhitespace;
		return isValid ? null : { 'whitespace': true }
	}

	public onSubmit() {
		this.submitted = true;
		console.log(this.creationForm.value)
		if (this.creationForm.valid) {
			this.teamCreation = this.creationForm.value;
			this.teamcreationService.sendRequest(this.teamCreation).subscribe(response => {
				this.data = response;
				if (this.data.result) {
					this.display = true;
					this.successMessage = true;
					this.errorMessage = false;
				} else if (this.data.error) {
					this.display = true;
					this.errorMessage = true;
					this.successMessage = false;
				}
			},
				error => {
				});

		}
	}

	close() {
		this.display = false;
		this.creationForm.reset();
		this.submitted = false;
	}
}
