import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Item, PtnvhqosReport } from 'app/core/model/itemAdminModels';

@Component({
  selector: 'ptnvhqos-report-object',
  templateUrl: './ptnvhqos-report-object.component.html',
  styleUrls: ['./ptnvhqos-report-object.component.css']
})
export class PtnvhqosReportObjectComponent implements OnInit {

  ptnvhqosReportObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()
  @Input('screenType') screenType: string;
  @Input('item') item: Item;
  @Input('ptnvhqosReport') ptnvhqosReport: PtnvhqosReport;
  @Input('showfield') showfield: boolean;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
    this._updatePtnvhqosReportObjectForm();
  }

  _formClear() {
    this.ptnvhqosReportObjectForm = this.formBuilder.group({
      engineDisplacement: [''],
      ptnvhqosVehicleConfigDesc: [''],
      driverSeatType: [''],
      otherVehicleSpecs: [''],
      vehBuildlevel: [''],
      engineCylinderConfiguration: [''],
      ptnvhqosCondition: [''],
      gearSelectorLocation: [''],
      vehOptionsOrPackages: [''],
      bodyStyle: [''],
      wheelType: [''],
      vehicleProgram: [''],
      trimLevel: [''],
      id: [''],
      fuelType: [''],
      origDate: [''],
      market: [''],
      vehicleType: [''],
      tireManufacture: [''],
      tireSize: [''],
      numberOfCylinders: [''],
      endDate: [''],
      assblyPlantCode: [''],
      testMetricDesc: [''],
      ptnvhqosVehicleConfig: [''],
      valveTrainConfig: [''],
      origTime: [''],
      vehicleModel: [''],
      startDate: [''],
      roofOptions: [''],
      valvesperCylinder: [''],
      tireType: [''],
      ptnvhqosVin: [''],
      drivelineType: [''],
      seatMaterial: [''],
      assblyPlantLoc: [''],
      numberOfDoors: [''],
      transmissionType: ['']
    });
    this.formReady.emit(this.ptnvhqosReportObjectForm);
  }

  _updatePtnvhqosReportObjectForm() {
    this.ptnvhqosReportObjectForm.patchValue({
      engineDisplacement: this.ptnvhqosReport.ptnvhqos_report_eng_displacement,
      ptnvhqosVehicleConfigDesc: this.ptnvhqosReport.ptnvhqos_report_vehicle_configdesc,
      driverSeatType: this.ptnvhqosReport.ptnvhqos_report_driver_seat_type,
      otherVehicleSpecs: this.ptnvhqosReport.ptnvhqos_report_other_vehicle_specs,
      vehBuildlevel: this.ptnvhqosReport.ptnvhqos_report_vehbuildlevel,
      engineCylinderConfiguration: this.ptnvhqosReport.ptnvhqos_report_eng_cyl_config,
      ptnvhqosCondition: this.ptnvhqosReport.ptnvhqos_report_condition,
      gearSelectorLocation: this.ptnvhqosReport.ptnvhqos_report_gear_sel_location,
      vehOptionsOrPackages: this.ptnvhqosReport.ptnvhqos_report_veh_opt_or_pack,
      bodyStyle: this.ptnvhqosReport.ptnvhqos_report_body_style,
      wheelType: this.ptnvhqosReport.ptnvhqos_report_wheel_type,
      vehicleProgram: this.ptnvhqosReport.ptnvhqos_report_vehicle_prog,
      trimLevel: this.ptnvhqosReport.ptnvhqos_report_trim_level,
      id: this.item.id,
      fuelType: this.ptnvhqosReport.ptnvhqos_report_fuel_type,
      origDate: this.ptnvhqosReport.ptnvhqos_report_orig_date,
      market: this.ptnvhqosReport.ptnvhqos_report_market,
      vehicleType: this.ptnvhqosReport.ptnvhqos_report_vehicle_type,
      tireManufacture: this.ptnvhqosReport.ptnvhqos_report_tire_manufacture,
      tireSize: this.ptnvhqosReport.ptnvhqos_report_tire_size,
      numberOfCylinders: this.ptnvhqosReport.ptnvhqos_report_num_cylinder,
      endDate: this.ptnvhqosReport.ptnvhqos_report_end_date,
      assblyPlantCode: this.ptnvhqosReport.ptnvhqos_report_assblyplanloc,
      testMetricDesc: this.ptnvhqosReport.ptnvhqos_report_test_metric_desc,
      ptnvhqosVehicleConfig: this.ptnvhqosReport.ptnvhqos_report_vehicle_config,
      valveTrainConfig: this.ptnvhqosReport.ptnvhqos_report_valvetrainconfig,
      origTime: this.ptnvhqosReport.ptnvhqos_report_orig_time,
      vehicleModel: this.ptnvhqosReport.ptnvhqos_report_vehicle_model,
      startDate: this.ptnvhqosReport.ptnvhqos_report_start_date,
      roofOptions: this.ptnvhqosReport.ptnvhqos_report_roof_options,
      valvesperCylinder: this.ptnvhqosReport.ptnvhqos_report_valves_per_cylinder,
      tireType: this.ptnvhqosReport.ptnvhqos_report_tire_type,
      ptnvhqosVin: this.ptnvhqosReport.ptnvhqos_report_vin,
      drivelineType: this.ptnvhqosReport.ptnvhqos_report_driveline_type,
      seatMaterial: this.ptnvhqosReport.ptnvhqos_report_seat_material,
      assblyPlantLoc: this.ptnvhqosReport.ptnvhqos_report_assblyplanloc,
      numberOfDoors: this.ptnvhqosReport.ptnvhqos_report_number_of_doors,
      transmissionType: this.ptnvhqosReport.ptnvhqos_report_transmission_type
    })
  }

}
