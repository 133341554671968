import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'trans-pgm-for-req',
  templateUrl: './trans-pgm-for-req.component.html',
  styleUrls: ['./trans-pgm-for-req.component.css']
})
export class TransPgmForReqComponent implements OnInit {

  transProgramReqObjectForm:FormGroup;  
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
  }
    
  _formClear() {
    this.transProgramReqObjectForm = this.formBuilder.group({
      altLabel: '',
      curApproval: '',
      brand: '',
      arch: '',
      numSpeeds: '',
      driveTrain: '',
      torqueCapacity: '',
      modelYear: ''
    });
    this.formReady.emit(this.transProgramReqObjectForm);
  }

}
