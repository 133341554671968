import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'trans-config-for-req',
  templateUrl: './trans-config-for-req.component.html',
  styleUrls: ['./trans-config-for-req.component.css']
})
export class TransConfigForReqComponent implements OnInit {

  transConfigReqObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
  }

  _formClear() {
    this.transConfigReqObjectForm = this.formBuilder.group({
      curApproval: [''],
      transProgram: ['']
    });
    this.formReady.emit(this.transConfigReqObjectForm);
  }

}
