import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { listAfrFilesForFolderRequest } from "../model/retentionfolderlevel";
import { Observable } from 'rxjs/internal/Observable';
@Injectable({
  providedIn: 'root'
})
export class RetentionCodeLevelService {

  private teamURI = environment.apdmServiceUrl;

  private headers: HttpHeaders;

  constructor(private http: HttpClient) { }


  public getretention() {
    //  console.log(this.teamURI+'dataview',result );


    return this.http.get(this.teamURI + 'getRetScheduleListAfr/', {
      headers: this.headers,
    });
  }




  public getAfrCodeLevel(cdsid: String, year: number) {
    //  console.log(this.teamURI+'dataview',result );
    return this.http.post(this.teamURI + 'listAfrRetSchedule/' + year, cdsid);
  }

  public getViewAfrFolderLevel(cdsid: listAfrFilesForFolderRequest) {
    //  console.log(this.teamURI+'dataview',result );


    return this.http.post(this.teamURI + 'listAfrFilesForFolder', cdsid);
  }




  public deleteAFRRetentionLevelItem(deleteObj: listAfrFilesForFolderRequest) {
    return this.http.post(this.teamURI + 'deleteretcodelevelafr', deleteObj);
  }
  public updateAFRRetentionLevel(updateObj: listAfrFilesForFolderRequest): Observable<string> {
    return this.http.post(this.teamURI + 'updateafrretcodelevel', updateObj, {
      responseType: 'text',
    });
  }
  public deleteAFRItem(deleteAFRItemObj: listAfrFilesForFolderRequest) {
    return this.http.post(this.teamURI + 'deleteAFRitem', deleteAFRItemObj);
  }
  editAFRItem(editAFRItemObj: listAfrFilesForFolderRequest): Observable<string> {
    return this.http.post(this.teamURI + 'editafr1', editAFRItemObj, {
      responseType: 'text',
    });
  }
  updateViewSuspensionOrder(updateSuspensionObj: listAfrFilesForFolderRequest): Observable<string> {
    return this.http.post(this.teamURI + 'updateSuspensionOrder', updateSuspensionObj, {
      responseType: 'text',
    });
  }
  updateSuspensionOrderRetCode(updateSuspensionObj: listAfrFilesForFolderRequest): Observable<string> {
    return this.http.post(this.teamURI + 'updateSuspensionOrderRetCode', updateSuspensionObj, {
      responseType: 'text',
    });
  }

}
