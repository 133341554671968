import { Component, OnInit, Input } from '@angular/core';
import { MenuItem } from 'primeng/primeng';
import { SharedService } from '../../core/service/shared.service';
import { UrlConsumerService } from '../../../app/oauth/url-consumer.service';

@Component({
  selector: 'admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.css']
})
export class AdminMenuComponent implements OnInit {

  navMenuItems: MenuItem[];
  @Input('ShowCreateAccess') ShowCreateAccess;
  @Input('ShowAdminAccess') ShowAdminAccess;
  @Input('ShowReadonlyAccess') ShowReadonlyAccess;
  

  constructor(private sharedService: SharedService, private consumerservice: UrlConsumerService) { }

  ngOnInit() {
    console.log("ShowCreateAccess",this.ShowCreateAccess);
    console.log("ShowAdminAccess",this.ShowAdminAccess);
    console.log("ShowAdminAccess",this.ShowReadonlyAccess);
    this.getMainMenuValue();
    this.navMenuItems = [
			{
        label: 'Application Administration',
        visible: this.ShowAdminAccess? this.ShowAdminAccess : 
                this.ShowCreateAccess?this.ShowCreateAccess : 
                this.ShowReadonlyAccess?this.ShowReadonlyAccess: false,
				items: [
          {
            label: 'User Administration'            
					},
					{
						label: 'Create User',
						routerLink: 'create-user',
            icon: 'pi pi-angle-double-right',
            visible : this.ShowAdminAccess? this.ShowAdminAccess : 
                      this.ShowCreateAccess?this.ShowCreateAccess : this.ShowReadonlyAccess == true?false: true
					},
					{
						label: 'Modify User',
						routerLink: 'modifyUser',
            icon: 'pi pi-angle-double-right',
            visible : this.ShowAdminAccess? this.ShowAdminAccess : 
                      this.ShowCreateAccess?this.ShowCreateAccess : this.ShowReadonlyAccess == true?false: true
          },
          {
						label: 'List User',
						routerLink: 'listUser',
            icon: 'pi pi-angle-double-right'
          },
          {
						label: 'Transfer User Files',
						routerLink: 'transferUserFile',
            icon: 'pi pi-angle-double-right',
            visible: this.ShowAdminAccess? this.ShowAdminAccess : 
            this.ShowCreateAccess && this.ShowReadonlyAccess ?true : 
            this.ShowCreateAccess == true? false:
            this.ShowReadonlyAccess?this.ShowReadonlyAccess: true,
          },
        ]
      },
					{
            label: 'Application Team Administration',
            visible: this.ShowAdminAccess?this.ShowAdminAccess : 
                      this.ShowReadonlyAccess?this.ShowReadonlyAccess :false,
            items: [
					  {
						label: 'Application Info',
						routerLink: 'applicationInfo',
						icon: 'pi pi-angle-double-right'

          }]
					},
          {
            label: 'Team Administration',
            visible: this.ShowAdminAccess? this.ShowAdminAccess : 
                    this.ShowCreateAccess?this.ShowCreateAccess :
                    this.ShowReadonlyAccess?this.ShowReadonlyAccess: false,
            items:[	{
              label: 'Create Team',
              routerLink: 'team-create',
              icon: 'pi pi-angle-double-right',
              visible : this.ShowAdminAccess? this.ShowAdminAccess : 
                        this.ShowCreateAccess?this.ShowCreateAccess : this.ShowReadonlyAccess == true?false: true
            },
            {
              label: 'Modify Team',
              routerLink: 'team-modify',
              icon: 'pi pi-angle-double-right',
              visible: this.ShowAdminAccess? this.ShowAdminAccess : 
              this.ShowCreateAccess && this.ShowReadonlyAccess ?false : 
              this.ShowReadonlyAccess == true?false :
              this.ShowCreateAccess == true?false : true
            },
            {
              label: 'List Team',
              routerLink: 'list-team',
              icon: 'pi pi-angle-double-right'
            }	,
            {
              label: 'List Team Membership',
              routerLink: 'listmember-team',
              icon: 'pi pi-angle-double-right'
              
            }	]
					},
        {
          label: 'Program Administration',
          visible: this.ShowAdminAccess?this.ShowAdminAccess : 
                   this.ShowReadonlyAccess?this.ShowReadonlyAccess :  false,
          items:[{
            label: 'Modify Program',
            routerLink: 'modifyProgram/modify',
            icon: 'pi pi-angle-double-right',
            visible: this.ShowAdminAccess?this.ShowAdminAccess : false            
          },
          {
            label: 'List Program',
            routerLink: 'modifyProgram/list',
            icon: 'pi pi-angle-double-right',
            visible: this.ShowAdminAccess?this.ShowAdminAccess : 
                  this.ShowReadonlyAccess?this.ShowReadonlyAccess :false,
          }]
        },
        {
          label: 'Item Administration',
          visible: this.ShowAdminAccess?this.ShowAdminAccess : 
                   this.ShowReadonlyAccess?this.ShowReadonlyAccess :  false,
          items:[{
            label: 'Create Item',
            routerLink: 'createItem',
            icon: 'pi pi-angle-double-right',
            visible: this.ShowAdminAccess?this.ShowAdminAccess : false
          },
          {
            label: 'List Item',
            routerLink: 'listItem/list',
            icon: 'pi pi-angle-double-right',
            visible: this.ShowAdminAccess?this.ShowAdminAccess : 
                  this.ShowReadonlyAccess?this.ShowReadonlyAccess :false,
          },
          {
            label: 'Modify Item',
            routerLink: 'modifyItem/modify',
            icon: 'pi pi-angle-double-right',
            visible: this.ShowAdminAccess?this.ShowAdminAccess : false
          },
        ]
        },
        {
          label: 'Access Control Lists',
          visible: this.ShowAdminAccess?this.ShowAdminAccess : false,
          items:[{
            label: 'Create an ACL',
            routerLink: 'createAcl',
						icon: 'pi pi-angle-double-right'
          }]
        },
        {
          label: 'Application Management',
          visible: this.ShowAdminAccess?this.ShowAdminAccess :
                  this.ShowReadonlyAccess?this.ShowReadonlyAccess :false,
          items:[{
            label: 'User Notification',
            routerLink: 'notifyUser',
						icon: 'pi pi-angle-double-right'
          },
          {
						label: 'APDM Usage Charts',
            url: 'http://cc14887.itek.ford.com/cc14011/apdm/',
            icon: 'pi pi-angle-double-right',
						target: '_blank',
					}]
        },


			{
        label: 'Annual Access Review (AAR)',
        visible: this.ShowAdminAccess?this.ShowAdminAccess : false,
				items: [
					{
						label: 'Application Reauthorization',
						routerLink: 'appreauthorization',
						icon: 'pi pi-angle-double-right'

					}
				]

      },
      {
        label: 'Annual File Review (AFR)Annual Access Review (AAR)',
        visible: this.ShowAdminAccess?this.ShowAdminAccess : false,
				items: [
					{
						label: 'Initiate Annual File Review',
						routerLink: 'AnnualFileReview/runAnnualFileReview',
            icon: 'pi pi-angle-double-right',
            command: () => this.updateAFRinfo()

          },
          {
            label: 'AFR Reports',
            // visible: this.ShowAdminAccess?this.ShowAdminAccess : false,
						routerLink: 'afrReports',
						icon: 'l'

					}
				]

			},	{
        label: 'APDM OST Portal',
        visible: this.ShowAdminAccess?this.ShowAdminAccess :
                this.ShowReadonlyAccess?this.ShowReadonlyAccess :false,
				items: [
					{
						label: 'Metrics',
						routerLink: 'ost-metrics',
						icon: 'pi pi-angle-double-right'

					}
				]

      },	{
        label: 'DB Usage Details',
        visible: this.ShowAdminAccess?this.ShowAdminAccess :
                  this.ShowReadonlyAccess?this.ShowReadonlyAccess :false,
				items: [
					{
						label: 'Details',
						url: 'https://edcdb1507.md2.ford.com/Reports/Pages/Report.aspx?ItemPath=%2fOEM%2fOEM+Oracle+DB+Space+Usage',
            icon: 'pi pi-angle-double-right',
            target: '_blank',

					}
				]

      },
		];
  }

  getMainMenuValue() {
    this.sharedService.mainMenuSubj.subscribe((data: any) => {
      const ele = document.querySelectorAll(".ui-menubar-root-list > li:nth-child(3)")[0];
      const aEle = ele.querySelector('a');
      //aEle.classList.remove('ui-state-active');
      if (data === 'Admin') {
        aEle.classList.add('ui-state-active');
      } else {
        aEle.classList.remove('ui-state-active');
      }
     });
  }

  updateAFRinfo(){
      console.log("main menu")
      this.sharedService.updateAFRLink({cdsid:this.consumerservice.userInfo.cdsId, year:(new Date()).getFullYear()})
  }

}
