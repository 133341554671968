import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Person, ViewPersonResponse } from 'app/core/model/viewPersonModel';
import { ViewPropertiesService } from 'app/core/service/view-properties.service';
import { ViewProperties } from 'app/core/model/viewProperties';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import DOMPurify from 'dompurify';
@Component({
  selector: 'owner-description',
  templateUrl: './owner-description.component.html',
  styleUrls: ['./owner-description.component.css']
})
export class OwnerDescriptionComponent implements OnInit {

  ownerDescriptionForm: FormGroup;

  sanitizedLocation: SafeHtml;
  display: boolean;
  @Input('ownerDesc') ownerDesc: Person;
  viewProperties = new ViewProperties();
  viewPersonResponse = new ViewPersonResponse();
  pageTitle: string;
  viewPropertieDisplay: boolean = false;
  viewTeam: boolean = false;
  viewPerson: boolean = false;
  loaderdisplay = false;
  checked: boolean = true;


  constructor(private formBuilder: FormBuilder, private viewPropertiesService: ViewPropertiesService, private sanitizer: DomSanitizer) {
	  this.ownerDescriptionForm = this.formBuilder.group({
		  location: ['']

	  });

	  this.ownerDescriptionForm.get('location').valueChanges.subscribe(value => {
		  this.sanitizedLocation = this.sanitizeHtml(value);
	  });
  }


  ngOnInit() {
    this.viewPropertieDisplay = false;
    console.log("owner description data:", this.ownerDesc);
    this.ownerDescriptionForm = this.formBuilder.group({
      id: [null],
      name: [null],
      has_access: [null],
      steward: [null],
      created_on: [null],
      modified_on: [null],
      reports_to: [null],
      company: [null],
      building: [null],
      has_cds_record: [null],
      password: [null],
      first_name: [null],
      last_name: [null],
      email: [null],
      phone: [null],
      office: [null],
      mail_drop: [null],
      department: [null],
      country: [null],
      unit: [null],
      division: [null],
      organization: [null],
      location: [null],
      application: [null],
      role: [null],
      business_case: [null],
      authorizer: [null],
      reauthorized_on: [null],
      reason: [null],
      storage_class: [null],
      emptype: [null],
      stewardName: [null],
      buildingName: [null],
      authName: [null],
      reportsTOName: [null],
      storage_className: [null],
      companyName: [null],
      totalStorageUsed: [null],
      strReauthorized_On: [null],
      strCreated_on: [null],
      strModified_on: [null],
      strReauthorizedTime: [null],
    });
    this.ownerDescriptionForm.setValue(this.ownerDesc);
    if (this.ownerDesc.has_access == 'y') {
      console.log(this.ownerDesc.has_access);
      this.ownerDescriptionForm.get('has_access').setValue('Active');
    } else if (this.ownerDesc.has_access == 'w') {
      console.log(this.ownerDesc.has_access);
      this.ownerDescriptionForm.get('has_access').setValue('Web Only');
    } else if (this.ownerDesc.has_access == 'n') {
      console.log(this.ownerDesc.has_access);
      this.ownerDescriptionForm.get('has_access').setValue('Disabled');
    }
	  const initialLocationValue = this.ownerDescriptionForm.get('location');
	  if (initialLocationValue && initialLocationValue.value) {
		  this.sanitizedLocation = this.sanitizeHtml(initialLocationValue.value);
	  }


  }
	sanitizeHtml(html: string): SafeHtml {
		const cleanHtml = DOMPurify.sanitize(html);
		return this.sanitizer.bypassSecurityTrustHtml(cleanHtml);
	}



  viewinfo(id: string, name: string) {
    console.log('id##########:', id);
    console.log('name########:', name);
    this.viewProperties = new ViewProperties();
    this.viewProperties.cdsIdName = name;
    this.viewProperties.itemId = id;
    console.log('Input param:', this.viewProperties)
    this.viewPropertiesService.viewCheckType(this.viewProperties).subscribe((data: ViewPersonResponse) => {
      console.log('Output param:', data);
      this.viewPersonResponse = new ViewPersonResponse();
      this.viewPersonResponse = data;
      if (this.viewPersonResponse) {
		  if (this.viewPersonResponse.viewType == 'Team') {
          console.log('Output www:', this.viewPersonResponse.viewType);
          this.pageTitle = 'View Team';
          this.viewPropertieDisplay = true;
          this.viewTeam = true;
          this.viewPerson = false;
        } else if (this.viewPersonResponse.viewType == 'Person') {
          this.pageTitle = 'View Person';
          this.viewPropertieDisplay = true;
          this.viewPerson = true;
          this.viewTeam = false;
        }
      }
    });
  }
  totalStorage(value) {
    console.log('totalStorage', value);
    if (value) {
      this.loaderdisplay = true;
      this.viewProperties.itemId = value;
      console.log(this.viewProperties.itemId)
      this.viewPropertiesService.viewTotalStorage(this.viewProperties).subscribe((data: ViewProperties) => {
        console.log('folder Size', data.size);
        if (data) {
          this.loaderdisplay = false;
          this.ownerDescriptionForm.get('totalStorageUsed').setValue(data.size);
        }
      });
    }
  }
  closePersonTeam() {
    console.log('close button');
    this.viewPersonResponse = new ViewPersonResponse();
    this.viewPropertieDisplay = false;
     this.viewPerson = false;
     this.viewTeam = false;
  }

}
