import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'part-sdrc-item-opt',
  templateUrl: './part-sdrc-item-opt.component.html',
  styleUrls: ['./part-sdrc-item-opt.component.css']
})
export class PartSdrcItemOptComponent implements OnInit {

  partSdrcItemOptForm:FormGroup;  
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {

    this._formClear();
  }

  _formClear() {
    this.partSdrcItemOptForm = this.formBuilder.group({
      ideasNum: [''],
      tdmModifiedOn: [''],
      fordName: [''],
      ideasName: [''],
      displayOrder: [''],
      tdmCreator: [''],
      ideasRevision: [''],
      ideasVersion: [''],
    });
    this.formReady.emit(this.partSdrcItemOptForm);
  }

}
