import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HomeComponent} from '../home/home.component'
import {HomeNavComponent} from '../home/home-nav/home-nav.component';
import {TeamCreateComponent} from '../home/team-create/team-create.component';
import {WorkInstructionsComponent} from '../home/work-instructions/work-instructions.component';
import {AccountRequestComponent} from '../home/account-request/account-request.component'
import {RouterModule} from '@angular/router';
import {AccStatusComponent} from '../home/acc-status/acc-status.component';
import {HttpClientModule} from '@angular/common/http'
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {TableModule} from "primeng/table";
import {InputTextareaModule} from "primeng/inputtextarea";
import {ButtonModule} from "primeng/button";
import {DialogModule} from 'primeng/dialog';
import { PanelMenuModule } from 'primeng/panelmenu';

import {TeamModifyComponent} from './team-modify/team-modify.component';
import {DropdownModule} from 'primeng/dropdown';
import {PasswordModule, PanelModule} from 'primeng/primeng';
import {CardModule} from 'primeng/card';
import { GrowlModule, MenubarModule, SidebarModule } from 'primeng/primeng';
import {MenuModule} from 'primeng/menu';

import {MainPageComponent} from './main-page/main-page.component'
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';

@NgModule({
	imports: [
		CommonModule,
		RouterModule.forRoot([]),
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		InputTextModule,
		InputTextareaModule,
		TableModule,
		ButtonModule,
		FormsModule,
		DialogModule,
		DropdownModule,
		PasswordModule,
		PanelModule,
		CardModule,
		PanelMenuModule,

		GrowlModule,
		MenubarModule,
		SidebarModule,
		MenuModule,
		ReactiveFormsModule,
		MessagesModule,
		MessageModule


  ],
  declarations: [
	HomeComponent,
	HomeNavComponent,
	TeamCreateComponent,
	WorkInstructionsComponent,
	AccountRequestComponent,
	AccStatusComponent,
	TeamModifyComponent,
	MainPageComponent
  ],
  exports:[
	HomeComponent,
	HomeNavComponent,
	TeamCreateComponent,
	WorkInstructionsComponent,
	AccountRequestComponent,
	AccStatusComponent,
	TeamModifyComponent,
	SidebarModule,
	MainPageComponent
  ]
})
export class HomeModule {
}

