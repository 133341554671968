import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Item, Testlab } from 'app/core/model/itemAdminModels';

@Component({
  selector: 'test-lab-object',
  templateUrl: './test-lab-object.component.html',
  styleUrls: ['./test-lab-object.component.css']
})
export class TestLabObjectComponent implements OnInit {

  testLabObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()
  @Input('screenType') screenType: string;
  @Input('item') item: Item;
  @Input('testlab') testlab: Testlab;
  @Input('showfield') showfield: boolean;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
    this._updateTestLabObjectForm();
  }

  _formClear() {
    this.testLabObjectForm = this.formBuilder.group({
      id: [''],
      certTest: [''],
      testType: [''],
      testRqNo: [''],
      workTaskNum: [''],
      testDate: [''],
      testTitle: [''],
      unitUnderTest: ['']
    });
    this.formReady.emit(this.testLabObjectForm);
  }

  _updateTestLabObjectForm() {
    this.testLabObjectForm.patchValue({
      id: this.item.id,
      certTest: this.testlab.test_cert,
      testType: this.testlab.test_type,
      testRqNo: this.testlab.test_rq_num,
      workTaskNum: this.testlab.test_work_task_num,
      testDate: this.testlab.test_date,
      testTitle: this.testlab.test_title,
      unitUnderTest: this.testlab.test_unit_under_test
    })
  }

}
