import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'part-prefix-opt',
  templateUrl: './part-prefix-opt.component.html',
  styleUrls: ['./part-prefix-opt.component.css']
})
export class PartPrefixOptComponent implements OnInit {

  partPrefixOptForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
  }

  _formClear() {
    this.partPrefixOptForm = this.formBuilder.group({
      prefix: ['']
    });
    this.formReady.emit(this.partPrefixOptForm);
  }

}
