import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'std-engie-fuel-opt',
  templateUrl: './std-engie-fuel-opt.component.html',
  styleUrls: ['./std-engie-fuel-opt.component.css']
})
export class StdEngieFuelOptComponent implements OnInit {

  stdEngineFuelOptObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
  }

  _formClear() {
    this.stdEngineFuelOptObjectForm = this.formBuilder.group({
      guiOrder: ['']
    });
    this.formReady.emit(this.stdEngineFuelOptObjectForm);
  }

}
