import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'stdpartname-for-req',
  templateUrl: './stdpartname-for-req.component.html',
  styleUrls: ['./stdpartname-for-req.component.css']
})
export class StdpartnameForReqComponent implements OnInit {

  stdPartNameReqObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
  }

  _formClear() {
    this.stdPartNameReqObjectForm = this.formBuilder.group({
      base: [''],
      designTeam: [''],
      name: ['']
    });
    this.formReady.emit(this.stdPartNameReqObjectForm);
  }
}
