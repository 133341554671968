import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Item, JlrCaeFolder } from 'app/core/model/itemAdminModels';

@Component({
  selector: 'jlr-cae-folder-object',
  templateUrl: './jlr-cae-folder-object.component.html',
  styleUrls: ['./jlr-cae-folder-object.component.css']
})
export class JlrCaeFolderObjectComponent implements OnInit {

  jlrCaeFolderObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()
  @Input('screenType') screenType: string;
  @Input('item') item: Item;
  @Input('jlrCaeFolderItem') jlrCaeFolderItem: JlrCaeFolder;
  @Input('showfield') showfield: boolean;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
    this._updateJlrCaeFolderObjectForm();
  }

  _formClear() {
    this.jlrCaeFolderObjectForm = this.formBuilder.group({
      id: [''],
      comments: [''],
      keywords: [''],
      migratedOn: [''],
      projectId: ['']
    });
    this.formReady.emit(this.jlrCaeFolderObjectForm);
  }

  _updateJlrCaeFolderObjectForm() {

    this.jlrCaeFolderObjectForm.patchValue({
      id: this.item.id,
      comments: this.jlrCaeFolderItem.commnets,
      keywords: this.jlrCaeFolderItem.keywords,
      migratedOn: this.jlrCaeFolderItem.migrated_on,
      projectId: this.jlrCaeFolderItem.project_id
    })

  }

}
