import { Component, EventEmitter, Output } from '@angular/core';
import { MenuItem } from 'primeng/primeng';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { UrlConsumerService } from '../../app/oauth/url-consumer.service';
import { Title } from '@angular/platform-browser';
import { environment } from 'environments/environment';
import { SharedService } from '../core/service/shared.service';
import { AuthGuardService } from '../oauth/auth-guard.service';

@Component({
	selector: 'app-nav',
	templateUrl: './nav.component.html',
	styleUrls: ['./nav.component.css']
})
export class NavComponent {

	@Output() closeClicked = new EventEmitter<boolean>();
	@Output() homeMenuClicked = new EventEmitter<boolean>();
	navMenuItems: MenuItem[];
	cdsId: any;
	currentEnvLink: any;
	display: boolean = false;
	currentEnvironment = environment.envName;
	urlMergeLink: string;
	webcdisplay: boolean = false;
	
	closeHamMenu = () => {
		this.homeMenuClicked.emit(true);
		this.closeClicked.emit(true);
	};

	constructor(private router: Router, private _location: Location, private titleService: Title,
		private urlService: UrlConsumerService, private auth: AuthGuardService,
		private shared: SharedService) {

		console.log('<-- NavComponent constructor -->');
		//console.log('environment search:::::',environment.apdmServiceUrl)

		this.navMenuItems = [
			{
				label: 'Home',
				routerLink: '/home/main-page',
				command: (event) => this.setDocTitle('Home')
			},
			{
				label: 'Web Client',
				routerLink: '/webclient',
				command: (event) => this.setDocTitle('APDM Web Client')
			},
			{
				label: 'Admin Portal',
				routerLink: '/admin-portal/login',
				command: (event) => this.setDocTitle('Admin'),
			},
			{
				label: 'Help',
				items: [
					{
						label: 'User Manual',
						url: environment.redirectUrl+'/assets/image/APDMUserManual1.pdf',
						target: '_blank'
					},
					{separator : true},
					{
						label: 'Online Training',
						url: environment.redirectUrl+'/assets/image/training/html/index_home.html',
						target: '_blank'
						//label: 'Online Training',
						//routerLink: '/under-Construction',
						//command: (event) => this.setDocTitle('Online Training')
					},
					{separator : true},
					{
						label: 'Support',
						url: environment.redirectUrl+'/assets/image/ITSM_quickhelp.html',
						target: '_blank'
					},
					{separator : true},
					{
						label: 'About APDM',
						command: this.showDialog
					},
					{separator : true},
					{
						label: 'FAQ',
						url: environment.redirectUrl+'/assets/image/faq.html',
						target: '_blank'
					},
					/*{separator : true},
					{
						label: 'Ford Enterprise Portal',
						url: 'https://www.at.ford.com/',
						target: '_blank',
						command: this.closeHamMenu
					},*/
					/*{separator : true},*/
					{
						label: 'Email Download',
						routerLink: '/email-download',
						command: (event) => this.setDocTitle('Email Download'),
						visible: false
					}
				]
			}
		];
	}

	setDocTitle(title: string) {
		// console.log('current title:::::' + this.titleService.getTitle());

			this.titleService.setTitle(title);
			this.webcdisplay = false;
			this.shared.storeMainMenu(title);
			sessionStorage.setItem('afrlink','false');

		// console.log("webclient if", this.webcdisplay);
		// console.log("webclientaccess", this.auth.webclientaccess)
		// console.log("webclient", title)

		if (title == "APDM Web Client") {
			console.log("webclient if", title);
			if (this.auth.webclientaccess !== undefined && this.auth.webclientaccess !== 'success') {
				//window.open(title,'_blank');
				this.webcdisplay = true;
			}
		}else{
			console.log("webclient else", title);
			//window.open(title,'_blank');
			this.webcdisplay = false;
		}

		// this.urlService.getadminaccess().subscribe((data: String) => {
		// 	console.log("fired sucess", data)
		// 	var result = data;
		// 	if (result) {
		// 		this.disableAdminPortal = false;
		// 	} else {
		// 		alert("You do not have access to this page, Please request one from http://www.itconnect.ford.com .");
		// 		this.disableAdminPortal = true;
		// 	}
		// }, (error) => {
		// 	console.log("fired error", error.message)
		// 	this.disableAdminPortal = true;
		// })
	}




	closeacess() {
		this.webcdisplay = false;
	}

	showDialog = () => {
		this.display = true;
		this.cdsId = this.urlService.userInfo.cdsId
		if (this.currentEnvironment == 'LOCAL') {
			this.currentEnvLink = 'https://localhost:4200';
		} else if (this.currentEnvironment == 'DEV') {
			this.currentEnvLink = 'https://wwwdev.apdmadm-dev.ford.com';
		} else if (this.currentEnvironment == 'QA') {
			this.currentEnvLink = 'https://wwwqa.apdmadm-qa.ford.com';
		} else if (this.currentEnvironment == 'Prod') {
			this.currentEnvLink = 'https://www.apdmadm.ford.com';
		}
	};
	
}
