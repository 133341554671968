import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'engine-for-opt',
  templateUrl: './engine-for-opt.component.html',
  styleUrls: ['./engine-for-opt.component.css']
})
export class EngineForOptComponent implements OnInit {

  engineOptObjectForm:FormGroup;  
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
  }

  _formClear() {
    this.engineOptObjectForm = this.formBuilder.group({
      wrktskNum: '',
      part: '',      
    });
    this.formReady.emit(this.engineOptObjectForm);
  }

}
