import { Component, OnInit } from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'work-instructions',
  templateUrl: './work-instructions.component.html',
  styleUrls: ['./work-instructions.component.css']
})
export class WorkInstructionsComponent implements OnInit {
	environmentRedirectURL = environment.redirectUrl;
  constructor() { }

  ngOnInit() {
  }

}
