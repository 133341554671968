import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from "@angular/router";
import { CheckOutService } from "../core/service/check-out.service";
import { environment } from "../../environments/environment";
import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from "@angular/common/http";
import { saveAs } from 'file-saver';
import { decode } from "punycode";


@Component({
	selector: 'email-download',
	templateUrl: './email-download.component.html',
	styleUrls: ['./email-download.component.css']
})
export class EmailDownloadComponent implements OnInit {

	message: string = '';
	errmessage: string = '';
	cdsid: string = '';
	source: string = '';
	//path: string = '';
	params: string = '';
	isPath: boolean = false;
	percentDone: number = 0;
	fileSize: string = '0';
	totalSize: string = '0';
	downloadSize: string;
	req: any;
	fileName: string;
	fileDownloadErrorMsg: boolean = false;
	fullPath: string[];
	url:string = '';
	constructor(private titleService: Title, private route: ActivatedRoute, private checkOutService: CheckOutService, private http: HttpClient) {
		this.source = this.route.snapshot.params['mail'];
		console.log('source:', this.source);
	}

	ngOnInit() {
		this.titleService.setTitle('Email Download');
		this.cdsid = sessionStorage.getItem('cdsId');
		if (this.source) {
			console.log('params:' + this.source);
			this.params = this.source;
			this.params = this.params.replace(/@/g, "/");
			console.log('New Param:', this.params);

			this.fileName = decodeURI(this.params.substr(this.params.indexOf("$") + 1, this.params.length));
			console.log('fileName:' + this.fileName);
			//console.log('fileName:'+decode(this.fileName));
			console.log('fileName:' + decodeURI(this.fileName));
			console.log('fileName:' + decodeURIComponent(this.fileName));
			//if (this.source.includes(this.cdsid)) {

			this.percentDone = 0;
			if (this.params != '') {
				console.log('finalPath:' + this.params);
				this.fullPath = this.params.split('$');
				console.log('Full Path:', this.fullPath);				
				this.isPath = true;
				if(this.fullPath&&  this.fullPath[0]){
					this.url = this.fullPath[0];
					console.log('URL:', this.url);
					this.message =  this.fileName+' GB';
					window.location.href = this.url;
				}
				// console.log('URL:', this.url);
				// this.req = new HttpRequest('GET', this.url, {
				// 	reportProgress: true,
				// 	observe: 'response',
				// 	responseType: 'blob'
				// });
				
				// console.log('Request Input:', this.req);
				// this.http.request(this.req).subscribe((event: HttpEvent<any>) => {
				// 	if (event.type === HttpEventType.DownloadProgress) {
				// 		this.message = "Downloading " + this.fileName + " ....";
				// 		this.percentDone = Math.round(100 * event.loaded / event.total);
				// 		var marker = 1024; // Change to 1000 if required
				// 		var decimal = 2; // Change as required
				// 		var kiloBytes = marker; // One Kilobyte is 1024 bytes
				// 		var megaBytes = marker * marker; // One MB is 1024 KB
				// 		var gigaBytes = marker * marker * marker; // One GB is 1024 MB
				// 		var teraBytes = marker * marker * marker * marker; // One TB is 1024 GB
				// 		if (event.total) {
				// 			if (event.total < kiloBytes)
				// 				this.totalSize = event.total + " Bytes";
				// 			else if (event.total < megaBytes)
				// 				this.totalSize = (event.total / kiloBytes).toFixed(decimal) + " KB";
				// 			else if (event.total < gigaBytes)
				// 				this.totalSize = (event.total / megaBytes).toFixed(decimal) + " MB";
				// 			else
				// 				this.totalSize = (event.total / gigaBytes).toFixed(decimal) + " GB";
				// 		}
				// 		if (event.loaded) {
				// 			if (event.loaded < kiloBytes)
				// 				this.fileSize = event.loaded + " Bytes";
				// 			else if (event.loaded < megaBytes)
				// 				this.fileSize = (event.loaded / kiloBytes).toFixed(decimal) + " KB";
				// 			else if (event.loaded < gigaBytes)
				// 				this.fileSize = (event.loaded / megaBytes).toFixed(decimal) + " MB";
				// 			else
				// 				this.fileSize = (event.loaded / gigaBytes).toFixed(decimal) + " GB";
				// 		}
				// 		this.downloadSize = this.fileSize + ' / ' + this.totalSize;
				// 	}
				// 	else if (event.type === HttpEventType.Response) {
				// 		console.log('event:', event);
				// 		console.log('event body:', event.body);
				// 		console.log('File is completely downloaded!');
				// 		let file = new Blob([event.body]);
				// 		this.fileName = this.fileName.replace('%20', '');
				// 		console.log('Replace FileName:', this.fileName);
				// 		saveAs(file, this.fileName + '.zip');
				// 		this.message = this.fileName + " got downloaded successfully."
				// 	}
				// }, error => {
				// 	console.log('Error:', error);
				// 	if (error) {
				// 		console.log('error msg');

				// 		this.errmessage = '';
				// 		this.errmessage = 'Issues encountered. Unable to download file/folder!';
				// 		this.fileDownloadErrorMsg = true;

				// 	}
				// });
			} else {
				this.errmessage = 'No Downloads Present';
			}
			// }
			// else {
			// 	this.errmessage = 'No Downloads Present';
			// }
		}
		else {
			this.errmessage = 'This page is only for email download';
		}
	}
	closeErrorMsg() {
		this.fileDownloadErrorMsg = false;
	}
}