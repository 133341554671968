import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'url-opt',
  templateUrl: './url-opt.component.html',
  styleUrls: ['./url-opt.component.css']
})
export class UrlOptComponent implements OnInit {

  urlOptObjectForm:FormGroup;  
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
  }

  _formClear() {
    this.urlOptObjectForm = this.formBuilder.group({
      toUrl: ['']  
 
    });
    this.formReady.emit(this.urlOptObjectForm);
  }

}
