import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'person-for-req',
  templateUrl: './person-for-req.component.html',
  styleUrls: ['./person-for-req.component.css']
})
export class PersonForReqComponent implements OnInit {

  personReqForm:FormGroup;  
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {

    this._formClear();
  }

  _formClear() {
    this.personReqForm = this.formBuilder.group({
      building: [''],
      hasCdsRecord: [''],
      company: [''],
      reportsTo: [''],
      storageClass: ['']
    });
    this.formReady.emit(this.personReqForm);
  }


}
