export class TeamCreate {
    teamId: string;
    teamName: string;
    steward: string;
    comments: string;
    teamType: string[];
    memberList: string;
    selectedTeams: string[];
    modifiedOn: string;
    createdOn: string;
    type: string;
    isAclFlag: string;
    isMembersFlag: string;
    userData:Subject[];
   

}
export class TeamType {
    id: number;
    value: string;
}
export class TeamDetail {
    id: string;
    name: string;
    type: string;
}

export class TeamAdminAcl {
    item: string;
    label: string;
    query: string;
    write: string;
    read: string;
    create: string;
    delete: string;
    teamId:string;
}

export class TeamAdminResponse {
    teamCreate: TeamCreate;
    teamDetail: TeamDetail[];
    teamAdminAcl: TeamAdminAcl[];
     teamMembers:TeamMembers[];
    type:string;
    teamIndirectAcl:TeamIndirectAcl[];
}
export class TeamIndirectAcl{
    item: string;
    label: string;
    query: string;
    write: string;
    read: string;
    create: string;
    delete: string;
    teamId:string;
    team:string;
}
export class OstMatricsReportRequestApi {
    status: string;
    startDate: string;
    lastDate: string;
    tempDate: string;
}
export class OstMatricsReportResponseApi {

    totalfiles_sizeinTB: string;
    totalpercentageoccupied: string;
    totalFiles: string;
    file_greaterthan_1GB: string;
    elaspedtime: string;
    totalFiles_d: string;
    totalfiles_sizeinMB: string;
    comments_file_greaterthan_1GB: string;
    comments_totalFiles_d: string;
    comments_totalFiles: string;
    comments_ValuesinGB: string;
}
export class OstReport {
    metrics: string;
    value: string;
    comments: string;
    elaspedtime: string;

}
export class UserData {
     id:number;
	 name:string;
}
export class Subject {
    label: string;
    value: string;
}
export class TeamMembers {
	 id: string;
	 name: string;
	 type: string;

}

export class TeamModifyResponse{
     teamCreate:TeamCreate;
	 message:string;

}
export class TeamDetailResponse{
     teamDetails:TeamDetail[];
	 message:string;
}

export class TeamAclResponse{
     teamAcl:TeamAdminAcl[];
	 message:string;
}
export class TeamMemberResponse{
    teamMembers:TeamMembers[];
	 message:string;
}




