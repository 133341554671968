import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FaqComponent} from '../help/faq/faq.component';
import {ApdmHelpComponent} from '../help/apdm-help/apdm-help.component';
import {EmailDownloadComponent} from "../EmailDownload/email-download.component";
import {BrowserModule} from "@angular/platform-browser";
import {
	CheckboxModule,
	ConfirmationService, ConfirmDialogModule,
	DropdownModule,
	MessageModule,
	MessagesModule,
	ProgressBarModule
} from "primeng/primeng";
import {UploadFromUrlComponent} from "./upload-from-url.component";
import {SharedModule} from "../shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FileSizePipe} from "../shared/file-size.pipe";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {HttpRequestInterceptor} from "../../services/http-request-interceptor";
import {CheckInComponent} from "../web-client/check-in/check-in.component";

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		ReactiveFormsModule,
		DropdownModule,
		CheckboxModule,
		MessagesModule,
		MessageModule,
		ConfirmDialogModule
	],
	declarations: [
		UploadFromUrlComponent,
		CheckInComponent
	],
	exports: [
		UploadFromUrlComponent,
		CheckInComponent
	],
	providers: [FileSizePipe,ConfirmationService, {
		provide: HTTP_INTERCEPTORS,
		useClass: HttpRequestInterceptor,
		multi: true
	}]
})
export class UploadModule {
}
