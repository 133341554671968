import { Component, OnInit } from '@angular/core';
import { UsernotifyService } from '../../core/service/usernotify.service';
import { Selectone, Template, EmailContent, UserNotification } from '../../core/model/usernotify';
import { SharedService } from '../../core/service/shared.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Quill } from 'quill';

@Component({
  selector: 'user-notify',
  templateUrl: './user-notify.component.html',
  styleUrls: ['./user-notify.component.css']
})
export class UserNotifyComponent implements OnInit {

  type: any;
  selectone: Selectone;
  template: Template[];

  usernotify: FormGroup;

  emailtemplate: EmailContent[];

  Subject: string = '';
  Content: string;
  Annualresult: boolean;
  leftCdsId: any[];
  successCdsId: any[];



  constructor(private usernotfy: UsernotifyService, private formBuilder: FormBuilder, private shared: SharedService) {

    this.usernotfy.ToUserList().subscribe((data: Selectone) => {
      this.selectone = data;

      console.log("security level", this.selectone);


    });
  }

  ngOnInit() {


    this.usernotify = this.formBuilder.group({
      touser: [''],
      subject: [this.Subject],
      storedlist: [''],
      emaillist: [''],
      template: [''],
      emailcontent: [''],
    });

    this.template = [
      { label: 'App. Server UP', value: "App_UP" },
      { label: 'App. Server DOWN', value: "App_DOWN" },
      { label: 'Db. Server UP', value: "Db_UP" },
      { label: 'Db. Server DOWN', value: "Db_DOWN" },
      { label: 'Oracle Instance PTOPRD10 UP', value: "PTOPRD10_UP" },
      { label: 'Oracle Instance PTOPRD10 DOWN', value: "PTOPRD10_DOWN" },
      { label: 'Oracle Instance PTODWP10 UP', value: "PTODWP10_UP" },
      { label: 'Oracle Instance PTODWP10 DOWN', value: "PTODWP10_DOWN" },
      { label: 'APDM Thick Client Decommission', value: "APDM_DECOM" },
    ];

  }


  OnTemplateload(event) {

    console.log(event.value, "Selected value");


    this.usernotfy.Templateload(event.value.value).subscribe((data: EmailContent[]) => {
      this.emailtemplate = data;

      console.log("template", this.emailtemplate);



      if (this.emailtemplate !== undefined && this.emailtemplate.length > 0) {

        this.Subject = this.emailtemplate[0].subject;
        this.Content = this.emailtemplate[0].content;

        this.usernotify.patchValue({
          subject: this.Subject,
        });

        console.log("template", data);



        console.log("content", this.Content);


      }

    });

  }


  userNotification() {

    console.log("form value", this.usernotify.value);
    this.shared.showProgress(true);
    this.usernotfy.postUserNotificationEmail(this.usernotify.value).subscribe((data: any) => {
      this.selectone = data;
      this.shared.showProgress(false);
      this.shared.showAlert(this.selectone);
      console.log('this.selectone',this.selectone);
    },error=>{
      this.shared.showProgress(false);
      this.shared.showAlert(error.error.text);
      console.log("user notification callback error",error);
    });

  }




}
