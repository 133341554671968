import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'power-train-for-opt',
  templateUrl: './power-train-for-opt.component.html',
  styleUrls: ['./power-train-for-opt.component.css']
})
export class PowerTrainForOptComponent implements OnInit {

  powerTrainOptForm:FormGroup;  
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {

    this._formClear();
  }

  _formClear() {
    this.powerTrainOptForm = this.formBuilder.group({
      transProgram: [''],
      engineProgram: ['']
    });
    this.formReady.emit(this.powerTrainOptForm);
  }

}
