import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'ptnvhqos-test-for-opt',
  templateUrl: './ptnvhqos-test-for-opt.component.html',
  styleUrls: ['./ptnvhqos-test-for-opt.component.css']
})
export class PtnvhqosTestForOptComponent implements OnInit {

  ptnvhqosTestOptForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
  }

  _formClear() {
    this.ptnvhqosTestOptForm = this.formBuilder.group({
      ptnvhqosSpeedDev: [''],
      ptnvhqosOrder3: [''],
      ptnvhqosFansSpeedHighlimit: [''],
      ptnvhqosVehicleConfigDesc: [''],
      driverSeatType: [''],
      ambHumidityUnits: [''],
      ptnvhqosThreshSteering: [''],
      polynomialRange: [''],
      ptnvhqosMaxFanSpeed: [''],
      engineCylinderConfirmation: [''],
      ptnvhqosHighSweepsSpeed: [''],
      ptnvhqosMinFanSpeedOperc: [''],
      ptnvhqosOrderCutWidth: [''],
      ptnvhqosTransTempUnits: [''],
      ambAirPressureUnits: [''],
      idlerPmQuality: [''],
      ptnvhqosVibOrderOpt: [''],
      gearSelectorLocation: [''],
      ambHumidity: [''],
      vehOptionsOrPackages: [''],
      bodyStyle: [''],
      vehicleProgram: [''],
      ptnvhqosFanSpeedDavg: [''],
      ptnvhqosIdleVer: [''],
      market: [''],
      defaultSndFftsize: [''],
      ptnvhqosPotProc: [''],
      ptnvhqosWotVer: [''],
      tireManufacture: [''],
      numberOfCylinder: [''],
      ptnvhqosFftOptMethod: [''],
      ptnvhqosSndOrderOpt: [''],
      assblyPlantCode: [''],
      ptnvhqosThresCheck: [''],
      ptnvhqosHighanalSpeed: [''],
      vehicleModel: [''],
      roofOptions: [''],
      ptnvhqosSweepavFactor: [''],
      ptnvhqosVin: [''],
      tireType: [''],
      freqresolution: [''],
      drivelineType: [''],
      ambWindSpeed: [''],
      noOfDoors: [''],
      ptnvhqosFansSpeedLowLimit: [''],
      engineDisplacement: [''],
      ptnvhqosLowanalSpeed: [''],
      ptnvhqosFanSpeedLimit: [''],
      baseFileName: [''],
      ptnvhqosCondition: [''],
      ambAirPressure: [''],
      ptnvhqosTransTemp: [''],
      ptnvhqosOrder2: [''],
      ptnvhqosNeruProc: [''],
      otherVehicleSpecs: [''],
      ptnvhqosNeruVer: [''],
      ambTemp: [''],
      vehBuildLevel: [''],
      defaultVibFftSize: [''],
      ambTempUnits: [''],
      wheelType: [''],
      ptnvhqosLowSweepSpeed: [''],
      ambWindSpeedUnits: [''],
      trimLevel: [''],
      ptnvhqosTestDesc: [''],
      ptnvhqosSpeedStdDev: [''],
      ptnvhqosSweepCheck: [''],
      ptnvhqosThreshMethod: [''],
      ptnvhqosOrder4: [''],
      ptnvhqosWotProc: [''],
      ptnvhqosOrderCutMethod: [''],
      fuelType: [''],
      origDate: [''],
      ptnvhqosThreshSeat: [''],
      ptnvhqosPotVer: [''],
      ptnvhqosOperator: [''],
      ptnvhqosOrder1: [''],
      numRepairs: [''],
      vehBuildDate: [''],
      vehicleType: [''],
      tireSize: [''],
      percentRepairs: [''],
      ptnvhqosOpCondition: [''],
      numberOfFailed: [''],
      ptnvhqosFanRangeType: [''],
      ptnvhqosValveTrainConfig: [''],
      ptnvhqosVehicleConfig: [''],
      origTime: [''],
      ptnvhqosTargetSpeed: [''],
      valuesPerCylinder: [''],
      ptnvhqosTestCondition: [''],
      ptnvhqosIdleProc: [''],
      assblyPlantLoc: [''],
      seatMaterial: [''],
      transmissionType: [''],

    });
    this.formReady.emit(this.ptnvhqosTestOptForm);
  }

}
