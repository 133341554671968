export class ViewFile {
    category: string;
    period: string;
    retCode: string;
    title: string;
}


export class RetentionFolderLevelHeaders {
    count: number;
    delete: string;
    fileName: string;
    fileName1: string;
    id: string;
    parentFolder: string;
    retention: string;
    suspOrder: string;
    update: string;
    viewFiles: string;
}

export class listAfrFilesForFolderRequest {
    year: string;
    cdsid: string;
    itemid: string;
    retstatus: string;
    sorder: string;
}




export class FileView {
    delete: string;
    fileFolderName: string;
    id: string;
    lastModifiedDate: string;
    retention: string;
    suspOrder: string;
    update: string;
    fileUrl:string;
}




export class RetentionFolderLevelHeaderResposne extends RetentionFolderLevelHeaders {
    data: RetentionFolderLevelHeaders[];
}