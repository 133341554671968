import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../../environments/environment";
import { TreeNode } from 'primeng/api';
import { Subject, BehaviorSubject } from 'rxjs';
import { TreeViewComponent } from "../../web-client/tree-view/tree-view.component";
import { SearchOptegraComponent } from 'app/web-client/search/search-optegra/search-optegra.component';
import { FileMenuComponent } from 'app/web-client/file-menu/file-menu.component';
import { DatatableComponent } from 'app/web-client/datatable/datatable.component';


@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private teamURI = environment.apdmServiceUrl;

  treedata: TreeNode;

  // There are 13 trees, so initialize an empty array of 13 elements
  // The index will be used later to identify the right tree
  // So whenever a tree is loaded, it assigns itself into it's respective position in the  array
  trees: TreeViewComponent[] = [null, null, null, null, null, null, null, null, null, null, null, null, null];

  dataTables: DatatableComponent[] = [null, null, null, null, null, null, null, null, null, null, null, null, null];

  // USED FOR RETENTION ON DATATABLE CHECK AND USER TRY TO NAGVIGATE TO ANOTHER TAB AND COME BACK TO PREVIOUS TAB - TREE SELECTION NODE
  treeSelectionRetention: TreeViewComponent[] = [null, null, null, null, null, null, null, null, null, null, null, null, null];

  selectedTab: number;

  treeBaseID = new Subject<any>();

  intialTreeBaseId = new EventEmitter<any>();

  treeDatatableId = new EventEmitter<any>();

  treeDatatablelabel = new EventEmitter<any>();

  treeDatatablesync = new EventEmitter<TreeNode>();

  treeDatatabledata = new EventEmitter<TreeNode>();

  searchMenu = new EventEmitter<boolean>();

  searchQueryBuilder = new Subject<any>();

  searchResult = new Subject<any>();

  treeNodeSelectedEmitter = new EventEmitter<any>();

  treenodeemitterfromparent = new EventEmitter<any>();

  treenodeforProgram = new EventEmitter<any>();

  activeItemTab = new EventEmitter<any>();

  activeTabfromParent = new EventEmitter<any>();

  viewTreeDatatablesync = new EventEmitter<TreeNode>();


  viewporp = new Subject<any>();
  //  viewEngineporp = new Subject <any>();

  searchTreeNodeSelected = new Subject<any>();

  private datatablerowSubject = new BehaviorSubject(false);

  private Treedata = new BehaviorSubject(this.treedata);
  currentMessage = this.Treedata.asObservable();
  checkOutProperties = new Subject<any>()
  checkOutDatatable = new Subject<any>();
  dataTableSelectedEmitter = new EventEmitter<any>();
  treeSelectedEmitter = new EventEmitter<any>();

  contenttreeDatatablesync = new EventEmitter<TreeNode>();
  contenttreeDatatableId = new EventEmitter<any>();

  webclientaccess = new Subject<boolean>()


  createBook = new EventEmitter<any>();

  viewEngineResponseEmitter = new EventEmitter<any>();

  // uncheck checkbox for tab change 

  tabchangeevent = new Subject<any>();

  // START HIGHLIGHT(SELECTION) CHANGES FOR TREE AND DATATABLE
  treeselectionsync = new Subject<any>();
  treeunselectionsync = new Subject<any>();
  currentTreeSelectionNode: any;
  // END HIGHLIGHT(SELECTION) CHANGES FOR TREE AND DATATABLE

  resetDataTableOnTreeSelectevent = new Subject<any>();
  resetTreeTableOnDataSelectevent = new Subject<any>();
  resetDataTableOnTreeSelecteventfilemenu = new Subject<any>();
  resetTreeTableOnDataSelecteventfilemenu = new Subject<any>();


  // Store the id's to be copied
  copyIds: any;
  pasteType: any;
  editporp = new Subject<any>();
  checkInProp = new Subject<any>();
  defaultNode: any;
  defaultOnChange: any;


  createItemId = new Subject<any>();

  //delete through filemenu 
  filemenudelete = new Subject<any>();
  changeSelect: boolean = false;
  commonFileDownload = new Subject<any>();
   searchName = new Subject<any>();
   searchFilter = new Subject<any>();
  formCall = new EventEmitter<any>();
  mainMenuSubj = new BehaviorSubject("a");
  constructor(private http: HttpClient) {
    console.log("emit service", this.searchResult);
  }

  TreeNode(message: TreeNode) {
    console.log("treenode", message);
    this.Treedata.next(message)
  }

  clearDataTableSelectRow(flag: boolean) {
    this.datatablerowSubject.next(flag);
  }
  isDataTableSelectRow(): Observable<any> {
    return this.datatablerowSubject.asObservable();
  }

  private progress = new Subject<any>();
  private alert = new Subject<any>();
  private tabmenuedit = new Subject<any>();
  private afrlinkupdate = new Subject<any>();
  private adminmenu = new Subject<any>();

  receiveprogress(): Observable<any> {
    return this.progress.asObservable();
  }
  receivealert(): Observable<any> {
    return this.alert.asObservable();
  }
  
  receivetabmenuedit(): Observable<any> {
    return this.tabmenuedit.asObservable();
  }

  receiveAFRLink(): Observable<any> {
    return this.afrlinkupdate.asObservable();
  }

  receiveadminmenu(): Observable<any> {
    return this.adminmenu.asObservable();
  }

  showProgress(flag: boolean) {
    this.progress.next(flag);
  }

  showAlert(message: any) {
    this.alert.next(message);
  }

  updatetabmenuedit(flag: any) {
    this.tabmenuedit.next(flag);
  }

  updateAFRLink(info: any) {
    this.afrlinkupdate.next(info);
  }

  updateadminmenu(obj: any) {
    this.adminmenu.next(obj);
  }

  storeMainMenu(title: string) {
		this.mainMenuSubj.next(title);
	}

  rowSelectDatatableValue = new Subject<any>();

  singleCallTreeAndDataTable = new Subject<any>();

  singleCalltreeDatatableId = new EventEmitter<any>();

  singleCallFlag: boolean = false;

  searchOptegraComponent:SearchOptegraComponent;
  fileMenuComponent:FileMenuComponent;
  searchScreen: boolean = false;

}
