import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Item, Engine } from 'app/core/model/itemAdminModels';

@Component({
  selector: 'engine-object',
  templateUrl: './engine-object.component.html',
  styleUrls: ['./engine-object.component.css']
})
export class EngineObjectComponent implements OnInit {

  engineObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()
  @Input('screenType') screenType: string;
  @Input('item') item: Item;
  @Input('engine') engine: Engine;
  @Input('showfield') showfield: boolean;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
    this._updateEngineObjectForm();
  }

  _formClear() {
    this.engineObjectForm = this.formBuilder.group({
      id: [''],
      serialNum: [''],
      workTaskNum: [''],
      part: ['']
    });
    this.formReady.emit(this.engineObjectForm);
  }
  _updateEngineObjectForm() {
    this.engineObjectForm.patchValue({
      id: [this.item.id],
      serialNum: [this.engine.engine_serial_num],
      workTaskNum: [this.engine.engine_worktask_num],
      part: [this.engine.engine_part]
    })
  }

}
