import { Component, OnInit } from '@angular/core';
import { AccountStatusService } from "../../core/service/account-status.service";
import { UrlConsumerService } from '../../oauth/url-consumer.service';

@Component({
	selector: 'acc-status',
	templateUrl: './acc-status.component.html',
	styleUrls: ['./acc-status.component.css']
})
export class AccStatusComponent implements OnInit {
	accountStatusLists = [];
	cdsId = '';

	constructor(private accountStatusService: AccountStatusService, private urlService: UrlConsumerService) {
		console.log('<--Account Status Constructor-->');
		this.cdsId = this.urlService.userInfo.cdsId;
		this.accountStatusService.getAccountStatus().subscribe(data => {
			if (data.result) {
				this.accountStatusLists.push(data.result.oaccountStatusApi);
			} else if (data.error) {

			}
		})
	}

	ngOnInit() {
		console.log('<--Account Status Init-->');
	
	}

}
