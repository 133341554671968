import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { Adminroutes } from '../admin/admin-route';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { AdminComponent } from '../admin/admin.component';
import { AdminMenuComponent } from './admin-menu/admin-menu.component';
import { SharedModule } from './../shared/shared.module';
import { CreateUserComponent } from '../admin/create-user/create-user.component';
import { CreateTeamComponent } from './create-team/create-team.component';
import { ModifyTeamComponent } from './modify-team/modify-team.component';
import { ModifyUserComponent } from './modify-user/modify-user.component';
import { ListUserComponent } from './list-user/list-user.component';
import { TransferUserComponent } from './transfer-user/transfer-user.component';
import { CreateAclComponent } from './create-acl/create-acl.component';
import { ListTeamComponent } from './list-team/list-team.component';
import { ListmembershipTeamComponent } from './listmembership-team/listmembership-team.component';
import { UserNotifyComponent } from './user-notify/user-notify.component';
import { OstMetricsComponent } from './ost-metrics/ost-metrics.component';
import { ApplicationInfoComponent } from './application-info/application-info.component';
import { AfrReportsComponent } from './afr-reports/afr-reports.component';
import { InitialAfrComponent } from './initial-afr/initial-afr.component';
import { AfrMenuComponent } from './afr-menu/afr-menu.component';
import { AfrInitalProcessComponent } from './afr-inital-process/afr-inital-process.component';
import { AfrReportComponent } from './afr-report/afr-report.component';
import { AfrHelpComponent } from './afr-help/afr-help.component';
import { AfrFordlevelComponent } from './afr-fordlevel/afr-fordlevel.component';
import { AfrRetentioncodeComponent } from './afr-retentioncode/afr-retentioncode.component';
import { RetentionComponent } from './retention/retention.component';
import { OstMetricsPortalComponent } from './ost-metrics-portal/ost-metrics-portal.component';
import { ModifyProgramComponent } from './modify-program/modify-program.component';

import { AppReauthorizationComponent } from './app-reauthorization/app-reauthorization.component';


import { ListItemComponent } from './list-item/list-item.component';
import { ListboxModule } from 'primeng/listbox';
import { ItemObjectComponent } from './item-object/item-object.component';
import { TestLabObjectComponent } from './test-lab-object/test-lab-object.component';
import { DrawingObjectComponent } from './drawing-object/drawing-object.component';
import { EngineObjectComponent } from './engine-object/engine-object.component';
import { EngineProgramObjectComponent } from './engine-program-object/engine-program-object.component';
import { FileObjectComponent } from './file-object/file-object.component';
import { FolderObjectComponent } from './folder-object/folder-object.component';
import { JlrCaeFolderObjectComponent } from './jlr-cae-folder-object/jlr-cae-folder-object.component';
import { JlrGroupRegisterObjectComponent } from './jlr-group-register-object/jlr-group-register-object.component';
import { OptegraItemObjectComponent } from './optegra-item-object/optegra-item-object.component';
import { PartObjectComponent } from './part-object/part-object.component';
import { PtnvhqosTestObjectComponent } from './ptnvhqos-test-object/ptnvhqos-test-object.component';
import { PtnvhqosReportObjectComponent } from './ptnvhqos-report-object/ptnvhqos-report-object.component';
import { QueryObjectComponent } from './query-object/query-object.component';
import { RefObjectComponent } from './ref-object/ref-object.component';
import { SdrcItemObjectComponent } from './sdrc-item-object/sdrc-item-object.component';
import { TransProgramObjectComponent } from './trans-program-object/trans-program-object.component';
import { UrlObjectComponent } from './url-object/url-object.component';
import { VehicleProgramObjectComponent } from './vehicle-program-object/vehicle-program-object.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { AfrHomeComponent } from './afr-home/afr-home.component';
import { CreateItemComponent } from './create-item/create-item.component';
import { StdActivityForReqComponent } from './std-activity-for-req/std-activity-for-req.component';
import { StdActivityForOptComponent } from './std-activity-for-opt/std-activity-for-opt.component';

import { AttachedattrForReqComponent } from './attachedattr-for-req/attachedattr-for-req.component';
import { AttachedattrForOptComponent } from './attachedattr-for-opt/attachedattr-for-opt.component';
import { AttachedfileForReqComponent } from './attachedfile-for-req/attachedfile-for-req.component';
import { AttachedfileForOptComponent } from './attachedfile-for-opt/attachedfile-for-opt.component';
import { BuildingForOptComponent } from './building-for-opt/building-for-opt.component';
import { CompanyForOptComponent } from './company-for-opt/company-for-opt.component';

import { StddomainForOptComponent } from './stddomain-for-opt/stddomain-for-opt.component';
import { DrawingForOptComponent } from './drawing-for-opt/drawing-for-opt.component';
import { EngineForReqComponent } from './engine-for-req/engine-for-req.component';
import { EngineForOptComponent } from './engine-for-opt/engine-for-opt.component';
import { EngineconfigForReqComponent } from './engineconfig-for-req/engineconfig-for-req.component';
import { EngineconfigForOptComponent } from './engineconfig-for-opt/engineconfig-for-opt.component';
import { EpdbookForReqComponent } from './epdbook-for-req/epdbook-for-req.component';
import { EpdbookForOptComponent } from './epdbook-for-opt/epdbook-for-opt.component';
import { EpimdrawingForOptComponent } from './epimdrawing-for-opt/epimdrawing-for-opt.component';
import { EpimfileForOptComponent } from './epimfile-for-opt/epimfile-for-opt.component';
import { FiledataForOptComponent } from './filedata-for-opt/filedata-for-opt.component';
import { FolderForOptComponent } from './folder-for-opt/folder-for-opt.component';
import { JlrcaefolderForOptComponent } from './jlrcaefolder-for-opt/jlrcaefolder-for-opt.component';
import { JlrgroupregisterForReqComponent } from './jlrgroupregister-for-req/jlrgroupregister-for-req.component';
import { JlrgroupregisterForOptComponent } from './jlrgroupregister-for-opt/jlrgroupregister-for-opt.component';
import { DavlocktokenForReqComponent } from './davlocktoken-for-req/davlocktoken-for-req.component';
import { DavlocktokenForOptComponent } from './davlocktoken-for-opt/davlocktoken-for-opt.component';
import { NvhmodaltestbookForOptComponent } from './nvhmodaltestbook-for-opt/nvhmodaltestbook-for-opt.component';
import { OptegraitemForOptComponent } from './optegraitem-for-opt/optegraitem-for-opt.component';
import { PartForReqComponent } from './part-for-req/part-for-req.component';
import { PartForOptComponent } from './part-for-opt/part-for-opt.component';
import { FilessdrcitmspartsForReqComponent } from './filessdrcitmsparts-for-req/filessdrcitmsparts-for-req.component';
import { FilessdrcitmspartsForOptComponent } from './filessdrcitmsparts-for-opt/filessdrcitmsparts-for-opt.component';
import { PartbaseForOptComponent } from './partbase-for-opt/partbase-for-opt.component';


import { ExternalRefOptComponent } from './external-ref-opt/external-ref-opt.component';
import { StdTypeOptComponent } from './std-type-opt/std-type-opt.component';
import { TransPgmForReqComponent } from './trans-pgm-for-req/trans-pgm-for-req.component';
import { TransPgmForOptComponent } from './trans-pgm-for-opt/trans-pgm-for-opt.component';
import { TransConfigForReqComponent } from './trans-config-for-req/trans-config-for-req.component';

import { TeamForOptComponent } from './team-for-opt/team-for-opt.component';
import { StdSubjectForOptComponent } from './std-subject-for-opt/std-subject-for-opt.component';
import { StdValveTrainForOptComponent } from './std-valve-train-for-opt/std-valve-train-for-opt.component';
import { SdrcItemForOptComponent } from './sdrc-item-for-opt/sdrc-item-for-opt.component';
import { RosterForReqComponent } from './roster-for-req/roster-for-req.component';
import { RosterForOptComponent } from './roster-for-opt/roster-for-opt.component';

import { PtnvhqosTestForOptComponent } from './ptnvhqos-test-for-opt/ptnvhqos-test-for-opt.component';
import { PtnvhqosReportForOptComponent } from './ptnvhqos-report-for-opt/ptnvhqos-report-for-opt.component';
import { PowerTrainForOptComponent } from './power-train-for-opt/power-train-for-opt.component';
import { PersonForOptComponent } from './person-for-opt/person-for-opt.component';
import { PersonForReqComponent } from './person-for-req/person-for-req.component';
import { VehicleProgReqComponent } from './vehicle-prog-req/vehicle-prog-req.component';
import { VehicleProgOptComponent } from './vehicle-prog-opt/vehicle-prog-opt.component';
import { VehicleConfigOptComponent } from './vehicle-config-opt/vehicle-config-opt.component';
import { VehicleConfigReqComponent } from './vehicle-config-req/vehicle-config-req.component';
import { UrlOptComponent } from './url-opt/url-opt.component';
import { TypeOptComponent } from './type-opt/type-opt.component';

import { TestLabOptComponent } from './test-lab-opt/test-lab-opt.component';
import { TestLabReqComponent } from './test-lab-req/test-lab-req.component';
import { TeamOptComponent } from './team-opt/team-opt.component';
import { SubjectOptComponent } from './subject-opt/subject-opt.component';
import { StdTransArchOptComponent } from './std-trans-arch-opt/std-trans-arch-opt.component';
import { StdEngieFuelOptComponent } from './std-engie-fuel-opt/std-engie-fuel-opt.component';
import { StdEngieArchOptComponent } from './std-engie-arch-opt/std-engie-arch-opt.component';
import { StdDriveTrainArchOptComponent } from './std-drive-train-arch-opt/std-drive-train-arch-opt.component';
import { StdBodyStyleOptComponent } from './std-body-style-opt/std-body-style-opt.component';

import { RetScheduleOptComponent } from './ret-schedule-opt/ret-schedule-opt.component';
import { RefOptComponent } from './ref-opt/ref-opt.component';
import { QueryOptComponent } from './query-opt/query-opt.component';
import { PartPrefixOptComponent } from './part-prefix-opt/part-prefix-opt.component';

import { StdpartnameForReqComponent } from './stdpartname-for-req/stdpartname-for-req.component';
import { PartSdrcItemReqComponent } from './part-sdrc-item-req/part-sdrc-item-req.component';
import { PartSdrcItemOptComponent } from './part-sdrc-item-opt/part-sdrc-item-opt.component';

import { ItemObjectForCreateItemComponent } from './item-object-for-create-item/item-object-for-create-item.component';
import { DeleteFilesComponent } from './delete-files/delete-files.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { AccountAuthComponentComponent } from './account-auth-component/account-auth-component.component';




@NgModule({
  imports: [
    RouterModule.forChild(Adminroutes),
    CommonModule,
    SharedModule,
    ListboxModule,
    MultiSelectModule,
  ],
  declarations: [
    TransferUserComponent,
    ListUserComponent,
    ModifyUserComponent,
    AdminLoginComponent,
    AdminComponent,
    AdminMenuComponent,
    CreateUserComponent,
    CreateTeamComponent,
    ModifyTeamComponent,
    CreateAclComponent,
    InitialAfrComponent,
    AfrMenuComponent,
    ListTeamComponent,
    ListmembershipTeamComponent,
    UserNotifyComponent,
    OstMetricsComponent,
    ApplicationInfoComponent,
    AfrReportsComponent,
    AfrInitalProcessComponent,
    AfrReportComponent,
    AfrHelpComponent,
    AfrFordlevelComponent,
    AfrRetentioncodeComponent,
    RetentionComponent,
    OstMetricsPortalComponent,
    ApplicationInfoComponent,
    ModifyProgramComponent,
    AppReauthorizationComponent,
    ListItemComponent,
    ItemObjectComponent,
    TestLabObjectComponent,
    DrawingObjectComponent,
    EngineObjectComponent,
    EngineProgramObjectComponent,
    FileObjectComponent,
    FolderObjectComponent,
    JlrCaeFolderObjectComponent,
    JlrGroupRegisterObjectComponent,
    OptegraItemObjectComponent,
    PartObjectComponent,
    PtnvhqosTestObjectComponent,
    PtnvhqosReportObjectComponent,
    QueryObjectComponent,
    RefObjectComponent,
    SdrcItemObjectComponent,
    TransProgramObjectComponent,
    UrlObjectComponent,
    VehicleProgramObjectComponent,
    AfrHomeComponent,
    CreateItemComponent,
    StdActivityForReqComponent,
    StdActivityForOptComponent,


    AttachedattrForReqComponent,
    AttachedattrForOptComponent,
    AttachedfileForReqComponent,
    AttachedfileForOptComponent,
    BuildingForOptComponent,
    CompanyForOptComponent,

    StddomainForOptComponent,
    DrawingForOptComponent,
    EngineForReqComponent,
    EngineForOptComponent,
    EngineconfigForReqComponent,
    EngineconfigForOptComponent,
    EpdbookForReqComponent,
    EpdbookForOptComponent,
    EpimdrawingForOptComponent,
    EpimfileForOptComponent,
    FiledataForOptComponent,
    FolderForOptComponent,
    JlrcaefolderForOptComponent,
    JlrgroupregisterForReqComponent,
    JlrgroupregisterForOptComponent,
    DavlocktokenForReqComponent,
    DavlocktokenForOptComponent,
    NvhmodaltestbookForOptComponent,
    OptegraitemForOptComponent,
    PartForReqComponent,
    PartForOptComponent,
    FilessdrcitmspartsForReqComponent,
    FilessdrcitmspartsForOptComponent,
    PartbaseForOptComponent,


    ExternalRefOptComponent,
    StdTypeOptComponent,
    TransPgmForReqComponent,
    TransPgmForOptComponent,
    TransConfigForReqComponent,

    TeamForOptComponent,
    StdSubjectForOptComponent,
    StdValveTrainForOptComponent,
    SdrcItemForOptComponent,
    RosterForReqComponent,
    RosterForOptComponent,

    PtnvhqosTestForOptComponent,
    PtnvhqosReportForOptComponent,
    PowerTrainForOptComponent,
    PersonForOptComponent,
    PersonForReqComponent,
    VehicleProgReqComponent,
    VehicleProgOptComponent,
    VehicleConfigOptComponent,
    VehicleConfigReqComponent,
    UrlOptComponent,
    TypeOptComponent,

    TestLabOptComponent,
    TestLabReqComponent,
    TeamOptComponent,
    SubjectOptComponent,
    StdTransArchOptComponent,
    StdEngieFuelOptComponent,
    StdEngieArchOptComponent,
    StdDriveTrainArchOptComponent,
    StdBodyStyleOptComponent,

    RetScheduleOptComponent,
    RefOptComponent,
    QueryOptComponent,
    PartPrefixOptComponent,

    StdpartnameForReqComponent,
    PartSdrcItemReqComponent,
    PartSdrcItemOptComponent,
    ItemObjectForCreateItemComponent,
    DeleteFilesComponent,
    ErrorMessageComponent,


    AccountAuthComponentComponent,
  ],
  exports: [RouterModule,
    AdminLoginComponent,
    AdminComponent,
    AdminMenuComponent,
    CreateUserComponent,
    CreateTeamComponent,
    ModifyTeamComponent,
    TransferUserComponent,
    ListUserComponent,
    ModifyUserComponent,
    AfrMenuComponent,
    CreateAclComponent,
    InitialAfrComponent,
    ListTeamComponent,
    ListmembershipTeamComponent,
    UserNotifyComponent,
    OstMetricsComponent,
    ApplicationInfoComponent,
    AfrReportsComponent,
    AfrInitalProcessComponent,
    AfrReportComponent,
    AfrHelpComponent,
    AfrFordlevelComponent,
    AfrRetentioncodeComponent,
    RetentionComponent,
    OstMetricsPortalComponent,
    ApplicationInfoComponent,
    ModifyProgramComponent,
    AppReauthorizationComponent,
    ListItemComponent,
    AfrHomeComponent,
    ErrorMessageComponent,

    AccountAuthComponentComponent,
  ]
})
export class AdminModule { }
