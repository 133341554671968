import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppreauthService } from '../../core/service/appreauth.service'
import { ReauthList } from '../../core/model/appreauth';
import { ApplicationsInfo, ReauthInitiateBO, appList } from 'app/core/model/admin-reauthorization';
import { AccountReauthResponseApi } from 'app/core/model/editPropertiesModels';
import { environment } from "../../../environments/environment";

@Component({
  selector: 'app-reauthorization',
  templateUrl: './app-reauthorization.component.html',
  styleUrls: ['./app-reauthorization.component.css']
})
export class AppReauthorizationComponent implements OnInit {

  reauth: ReauthList[];
  appreauthorization: FormGroup;
  application: appList[];
  applicationInfo = new ApplicationsInfo();
  accountReauthInitiateBO = new ReauthInitiateBO();
  showManangersScreen: boolean = false;
  uniqueApproverCdsidList: string[];
  cdsidList: string[];
  checkSelectAll: boolean = false;
  isError: string = '';
  environment = environment.redirectUrl;
  checkboxArr = [];
  gotMailApproverCdsids: string[] = [];
  noMailApproverCdsids: string[] = [];

  //private service :createAclService
  constructor(private formBuilder: FormBuilder, private appreauth: AppreauthService) {
    this.appreauth.getappreauth().subscribe((data: any) => {
      this.reauth = data;
      console.log('getappreauth', data);
    }, (error) => {
      console.log('/////////////', error);
    });
  }

  ngOnInit() {
    this.appreauthorization = this.formBuilder.group({
      year: [''],
      reminderNo: ['', Validators.required],
      completionDate: [''],
      appInfo: [''],
    });
  }

  reauthorization() {
    if (this.checkRequiredField()) {
      console.log("reauth", this.appreauthorization.value);
      //console.log('applicationInfo:',this.application);
      this.accountReauthInitiateBO = this.appreauthorization.value;
      this.accountReauthInitiateBO.appInfo = this.checkboxArr;
      console.log('this.accountReauthInitiateBO', this.accountReauthInitiateBO);
      if (this.accountReauthInitiateBO) {
        this.appreauth.performAccount(this.accountReauthInitiateBO).subscribe((data: AccountReauthResponseApi) => {
          console.log('/////////////////', data);
          console.log('/////////////////', data.isError);
          console.log('/////////////////', data.uniqueApproverCdsidList);

          if (data.isError === '' && data.uniqueApproverCdsidList) {
            console.log('///////IF//////////');
            this.showManangersScreen = true;
            this.uniqueApproverCdsidList = data.uniqueApproverCdsidList;
            this.isError = '';
          } else if (data.isError === 'approver') {
            console.log('///////ELSE//////////');
            this.showManangersScreen = true;
            this.isError = 'approver';
          } else if (data.isError === 'teamIds') {
            console.log('///////ELSE//////////');
            this.showManangersScreen = true;
            this.isError = 'teamIds';
          }
        }, (error) => {
          console.log(error);
        });
      }
    }
  }


  checkRequiredField(): boolean {
    if (!this.appreauthorization.get('year').value) {
      alert("You need to enter the year of account reauthorization");
      return false;
    }

	  if (this.appreauthorization.get('year').value !== '') {
		  if(!(/^[0-9]+$/.test(this.appreauthorization.get('year').value))){
			  alert("You need to enter the valid year");
			  return false;
		  }

	  }


	  if (!this.appreauthorization.get('reminderNo').value) {
      alert("You need to enter the reminder number of  account reauthorization email");
      return false;
    }

	 if (this.appreauthorization.get('reminderNo').value !== '') {
		 if(!(/^[0-9]+$/.test(this.appreauthorization.get('reminderNo').value))){
			 alert("You need to enter the valid reminder number between 1 and 3");
			 return false;
		 } else {
			 if (!(this.appreauthorization.get('reminderNo').value >= 1 && this.appreauthorization.get('reminderNo').value <= 3)) {
				 alert("You need to enter the valid reminder number between 1 and 3");
				 return false;
			 }
		 }

	 }

    if (!this.appreauthorization.get('completionDate').value) {
      alert("You need to enter the date by which account reauthorization needs to be completed");
      return false;
    }

    console.log('/////this.appreauthorization.get(appInfo).value/////////', this.appreauthorization.get('appInfo').value);

    if (this.checkboxArr.length === 0) {
      alert("Please select at least one application.");
      return false;
    }

    return true;
  }

  onSelectAllChangeEvent() {
    console.log('//////////////', this.checkSelectAll);
    if (this.checkSelectAll) {
      this.cdsidList = this.uniqueApproverCdsidList;
    } else {
      this.cdsidList = [];
    }
  }

  onSendMail() {
    //console.log('////////ON SEND MAIL//////', this.cdsidList);
	  this.accountReauthInitiateBO.cdsIds = this.cdsidList;
    this.appreauth.sendMail(this.accountReauthInitiateBO).subscribe((data: ReauthInitiateBO) => {
      console.log('/////////////////', data);
      this.isError = 'AcountAuthNotificationFeebBack';
      this.gotMailApproverCdsids = data.gotMailApproverCdsids;
      this.noMailApproverCdsids = data.noMailApproverCdsids;
    }, (error) => {
      console.log(error);
    });
  }

  selectCheckbox(event: any, teamId: any) {
    if (event) {
      this.checkboxArr.push(teamId);
    } else {
      const index: number = this.checkboxArr.indexOf(teamId);
      if (index !== -1) {
        this.checkboxArr.splice(index, 1);
      }
    }
  }
}

