export class ViewProperties {
	itemId: any;
	label: any;
	domain: any;
	depth: any;
	itemType: any;
	basePart: any;
	version: any;
	type: any;
	nodeTypeLabel: any;
	parentId: any;
	cdsId: any;
	cdsIdName: any;
	flag: any;
	visiable: any;
	parentLabel: any;
	subsquentLabel: any;
	rootLabel:any;
	folderType: any;
	size: any;
	simpleFolderParent:any;
	simpleFolderLabel:any

}

export class ViewDataTableHeaders {
    treedata:any;
    name : string ;
    lockOwner : string ;
    version : number;
    size : number;
    dateModified : string;
    type : string;
    owner : string ;
    description : string;
	message: string;
	id:string;
}

export class DataTableHeaderResposne extends ViewDataTableHeaders {
    data : ViewDataTableHeaders[];
}