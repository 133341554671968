import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'adminDateFormatPipe' })
export class AdminDateFormatPipe implements PipeTransform {
        transform(value: string) {
                var datePipe = new DatePipe("en-US");
                value = datePipe.transform(value, 'yyyy-MM-dd hh:mm:ss.S');
                return value;
        }
}