import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'trans-pgm-for-opt',
  templateUrl: './trans-pgm-for-opt.component.html',
  styleUrls: ['./trans-pgm-for-opt.component.css']
})
export class TransPgmForOptComponent implements OnInit {

  transProgramOptObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
  }

  _formClear() {
    this.transProgramOptObjectForm = this.formBuilder.group({
      oldName: '',
      continuous: '',
      changeLevel: '',
      rmsProgramName: '',
      compProgram: '',
      workTaskNum: '',
      leadVehicle: '',
      listDomains: '',
      endOfLife: '',
      comments: '',
      lifeCode: '',
      modelYearEnd: '',
      kickOff: '',
      jobOne: '',
      pmtDomain: ''

    });
    this.formReady.emit(this.transProgramOptObjectForm);
  }

}
