import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'engineconfig-for-req',
  templateUrl: './engineconfig-for-req.component.html',
  styleUrls: ['./engineconfig-for-req.component.css']
})
export class EngineconfigForReqComponent implements OnInit {

  engineConfigReqObjectForm:FormGroup;  
  @Output() formReady = new EventEmitter<FormGroup>()

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this._formClear();
  }

  _formClear() {
    this.engineConfigReqObjectForm = this.formBuilder.group({
      curApproval: [''],
      engProgram: [''],
     
      
    });
    this.formReady.emit(this.engineConfigReqObjectForm);
  }

}
