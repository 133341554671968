import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlConsumerService } from '../../../app/oauth/url-consumer.service';
import { SharedService } from '../../core/service/shared.service';
import { AfrInitalProcessService } from '../../core/service/afr-inital-process.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'afr-home',
  templateUrl: './afr-home.component.html',
  styleUrls: ['./afr-home.component.css']
})
export class AfrHomeComponent implements OnInit {

  cdsid: string;
  year: string;
  Calcyear: number;
  fileCount: number = 0;

  subsciptiondeleteAllfiles: Subscription;
  constructor(private _Activatedroute: ActivatedRoute, private userService: UrlConsumerService, private shared: SharedService, private service: AfrInitalProcessService) { }

  ngOnInit() {

    this._Activatedroute.paramMap.subscribe(params => {
      console.log("afrHome parama", params);
      console.log("afrHome userService", this.userService);
      this.userService.afrcdsid = params.get('cdsid');
      this.userService.afryear = params.get('year');
      this.cdsid = this.userService.afrcdsid;
      this.year = this.userService.afryear;

      this.shared.updateAFRLink({ cdsid: this.cdsid, year: this.year })
      this.Calcyear = (Number(this.year) - 1);
      console.log("calcyear", this.Calcyear);
    });
    this._Activatedroute
      .data
      .subscribe(data =>
        {
          if(data.afrlink)
          {
            sessionStorage.setItem('afrlink','true');
            this.shared.updateadminmenu('true');
          }
        console.log("router data",data)
      }
        );
    this.getFileCount(this.cdsid, this.year);
  }
  deleteAll() {
    //console.log('CDSID:', this.cdsid);
    console.log('year:', this.year);

    this.subsciptiondeleteAllfiles = this.service.deleteAllFiles(this.cdsid, this.year).subscribe((data: any) => {
      console.log("Success Delete All files", data);
      alert(data.message);
      this. ngOnInit();

    }, (error) => {
      console.log("error Delete All files", error);
    })
  }

  getFileCount(cdsid, year) {
    console.log('CDSID:', cdsid);
    console.log('year:', year);
    this.subsciptiondeleteAllfiles = this.service.getAFRFileCount(cdsid, year).subscribe((data: any) => {
      console.log('GET Count Response:', data);
      this.fileCount = data;
    }, (error) => {
      console.log("error in file count", error);
    })

  }

}
