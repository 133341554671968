import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ItemInitiationForm2 } from 'app/core/model/itemAdminModels';

@Component({
  selector: 'attachedfile-for-req',
  templateUrl: './attachedfile-for-req.component.html',
  styleUrls: ['./attachedfile-for-req.component.css']
})
export class AttachedfileForReqComponent implements OnInit {

  attchFileReqObjectForm: FormGroup;
  @Output() formReady = new EventEmitter<FormGroup>()
  isDefaultOptions: ItemInitiationForm2[];

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.isDefaultOptions = [
      { label: 'y', value: 'y' },
      { label: 'n', value: 'n' }
    ];
    this._formClear();
  }

  _formClear() {
    this.attchFileReqObjectForm = this.formBuilder.group({
      isDefault: { label: 'n', value: 'n' },
      version: 'v1',
    });
    this.formReady.emit(this.attchFileReqObjectForm);
  }

}
