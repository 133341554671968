import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {createAclModel} from '../../core/model/createAclModel';

@Injectable()
export class createAclService {

	private teamURI = environment.apdmServiceUrl;
	cdsId: any;
	token: any;
	private headers: HttpHeaders;

	constructor(private http: HttpClient) {
		this.token = JSON.parse(sessionStorage.getItem('strAccessToken'));
		if (this.token) {
			this.cdsId = this.token.CommonName;
		}

		if (this.cdsId !== undefined) {
			this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
		} else {
			this.cdsId = 'Invalid';
			this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');

		}

	}

	setinsertACL(model: createAclModel) {
		return this.http.post(this.teamURI + 'adminPortal/aclAction/insertACL', model);
	}

	generateExpired(year: string) {
		return this.http.get(this.teamURI + 'generateExpired/' + year);
	}

	generateLeftCompany() {
		return this.http.get(this.teamURI + 'generateLeftCompany/');
	}

	afterCompletedList(year: string) {
		return this.http.get(this.teamURI + 'aftCompletedList/' + year);
	}

}