import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CreateUserService } from '../../core/service/create-user.service';
import { PersonData, ApplicationMembership, Subject } from 'app/core/model/user-admin';



@Component({
  selector: 'create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {
  SearchProfileForm: FormGroup;
  profileForm: FormGroup;
  selectitem: ApplicationMembership[];
  organizationlist: Subject[];
  cdsidvalue: string;
  applicationMember: ApplicationMembership[];
  has_access: any[];
  errorDisplay: boolean = false;
  previewError: string;
  organList: string;
  titleName: string;
  showUserProfile: boolean = false;
  createPerson = new PersonData();
  checkButton: boolean = false;
  selectedList: string;
  dtSelectedRows: any[];
  clearUserdata:boolean = false;


  constructor(private formBuilder: FormBuilder, private createuser: CreateUserService) { }
  ngOnInit() {
    this.SearchProfileForm = this.formBuilder.group({
      cdsid: ['', Validators.required]
    });
    this.profileForm = this.formBuilder.group({
      id: [''],
      application: [''],
      authorizer: [''],
      building: [''],
      businessCase: [''],
      company: [''],
      country: [''],
      createdOn: [''],
      department: [''],
      division: [''],
      email: [''],
      empType: [''],
      firstName: [''],
      lastName: [''],
      hasAccess: [''],
      hasCdsRecord: [''],
      location: [''],
      mailDrop: [''],
      modifiedOn: [''],
      office: [''],
      organization: [''],
      password: [''],
      phone: [''],
      reauthorizedOn: [''],
      reason: [''],
      reportsTo: [''],
      role: [''],
      steward: [''],
      storageClass: [''],
      unit: [''],
      cdsId: [''],
      lastAuthorizedOn: [''],
      buildingName: [''],
      departmentCode: [''],
      fordMailDrop: [''],
      fordBusinessUnitCode: [''],
      managerRole: [''],
      errorMessage: [''],
      stewardId: [''],
      reportsToId: [''],
      authorizerId: [''],
      companyId: [''],
      buildingId: ['']
    });
    this.has_access = [
      { label: 'y', value: 'y' },
      { label: 'n', value: 'n' },
      { label: 'w', value: 'w' },
    ];


  }



  reviewProfile() {
    console.log("cdsid", this.SearchProfileForm.value);
    console.log(this.SearchProfileForm.value)
    if (this.SearchProfileForm.valid) {
      this.cdsidvalue = this.SearchProfileForm.get('cdsid').value;
      //console.log('this.cdsidvalue', this.cdsidvalue);
      this.createuser.getOrganizationList().subscribe((data: Subject[]) => {
        this.organizationlist = data;
        console.log("organization", this.organizationlist);
      })
      this.createuser.getUserProfile(this.cdsidvalue).subscribe((response: PersonData) => {
        console.log('review response', response);
        if (response) {
          if (response.personData && response.personData.errorMessage) {
            this.errorDisplay = true;
            this.previewError = '';
            this.previewError = response.personData.errorMessage;
          } else {
            if (response.personData) {
              this.titleName = response.personData.cdsId;
              this.profileForm.setValue(response.personData);
              this.profileForm.get('hasCdsRecord').setValue('y');
              this.profileForm.get('password').setValue('Use WSL Password');
            }
            if (response.applicationMembership) {
              this.applicationMember = [];
              this.applicationMember = response.applicationMembership;
              console.log('this.applicationMember:', this.applicationMember);
              //this.selectitem =response.applicationMembership;
              this.dtSelectedRows = [this.applicationMember[0]];
              console.log(' this.dtSelectedRows:', this.dtSelectedRows);
              // this.selectedCategories = this.applicationMember[0].appName;
              // console.log(' this.selectedCategories:', this.selectedCategories);
            }
            this.showUserProfile = true;
          }
          console.log('  this.organizationlist:', this.organizationlist)
        }
      });

    } else {
      this.errorDisplay = true;
      this.previewError = '';
      this.previewError = 'Please input a new or existing CDSID to start the search!';
    }
  }

  createUser() {
    //this.checkButton = true;
    console.log('selectitem', this.selectitem);
    console.log('checkbox value', this.selectitem);
    console.log('this.profileForm.value', this.profileForm.value);
    if (this.profileForm.value) {
      this.createPerson = new PersonData();
      this.createPerson.personData = this.profileForm.value;
      console.log('selected List Hass:', this.selectedList);
      if (this.selectedList) {
        console.log('this.selectedList == IF');
        this.createPerson.personData.hasAccess = this.selectedList;
      } else {
        console.log('this.selectedList == ELSE');
        if (this.has_access) {
          for (var i = 0; i < this.has_access.length; i++) {
            console.log(this.has_access[i]);
            console.log(this.has_access[0].label);
            this.createPerson.personData.hasAccess = this.has_access[0].label;
            break;
          }
        }
      }
      this.createPerson.applicationMembership = [];
      if (this.selectitem) {
        this.createPerson.applicationMembership = this.selectitem;
        console.log(' this.createPerson.applicationMembership', this.createPerson.applicationMembership);
        this.createPerson.applicationMembership.forEach(element => {
          console.log('element.businessCase', element.businessCase);
          if (element.businessCase) {
            console.log('if');
            this.checkButton = true;
          } else {
            console.log('else');
            this.checkButton = false;
            this.errorDisplay = true;
            this.previewError = '';
            this.previewError = 'A business case is needed for Analytical Powertrain Data ManagerUsage';
          }
        });
      }
      console.log('PERSON DATA', this.createPerson);
      console.log('this.checkButton', this.checkButton);
      if (this.checkButton) {
        if (this.createPerson) {
          this.createuser.createUser(this.createPerson).subscribe((response: string) => {
            console.log('response', response);
            if (response == 'true') {
              console.log('New user account was successfully created');
              this.errorDisplay = true;
              this.clearUserdata = true;
              this.previewError = '';
              this.previewError = 'New user account was successfully created';
              // alert("Data Saved Successfully !");   whie clicking ok button

            } else {
              console.log('Error Occured while creating a new user account');
              this.errorDisplay = true;
              this.clearUserdata = false;
              this.previewError = '';
              this.previewError = 'Error Occured while creating a new user account';
              // alert("Error Saving Data !");
            }
          });
        }
      }
    }
  }

  close() {
    this.errorDisplay = false;
    console.log('clearUserdata:', this.clearUserdata);
    if (this.clearUserdata) {
      this.showUserProfile = false;
      this.SearchProfileForm.reset();
    }

  }
  onRowSelectApp(data: any) {
    console.log('data.....:', data);
    if (data) {
      // this.selectedList = 'y';
      this.profileForm.get('hasAccess').setValue('y');
    }

  }
  onRowUnSelected(event: any) {
    console.log('event row unSelect ', event);
    this.profileForm.get('hasAccess').setValue('n');
  }

}
