import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TableModule } from 'primeng/table';

import { ApplicationInfoModel, TeamInfo, result, response } from '../../core/model/ApplicationInfoModel';
import { CreateUserService } from '../../core/service/create-user.service';



@Component({
  selector: 'application-info',
  templateUrl: './application-info.component.html',
  styleUrls: ['./application-info.component.css']
})
export class ApplicationInfoComponent implements OnInit {
  applicationInfoForm: FormGroup;
  submitted: boolean = false;
  ApplicationInfoModelarray: ApplicationInfoModel[];
  TeamInfoList: TeamInfo[];
  selectedTeam: any;
  cols: any[];
  addFlag: boolean = false;
  saveFlag: boolean = true;
  resetFlag: boolean = true;
  selectedItem: ApplicationInfoModel;
  result = new result();
  flagDisplay: boolean = false;
  response: any;
  formValue: any;

  constructor(private formBuilder: FormBuilder, private service: CreateUserService) { }

  ngOnInit() {
    this.applicationInfoForm = this.formBuilder.group({
      application: [''],
      teamInfoname: [''],
      itemNo: ['']
    });

    this.cols = [
      { field: 'id', header: 'TeamID' },
      { field: 'appName', header: 'Application' },
      { field: 'teamName', header: 'TeamName' },
      { field: 'itemNo', header: 'Item No' }
    ];
    this.service.getApplicationInfoList().subscribe((data: ApplicationInfoModel[]) => {
      this.ApplicationInfoModelarray = data;
    });

    this.service.getIntialTeamList().subscribe((data: TeamInfo[]) => {
      this.TeamInfoList = data;
      console.log(this.TeamInfoList);
    });
    console.log(this.TeamInfoList);
    this.applicationInfoForm.disable()
    // this.applicationInfoForm.controls['application'].disable();
    // this.applicationInfoForm.controls['teamInfoname'].disable();
    // this.applicationInfoForm.controls['itemNo'].disable();
  }

  onRowSelect(event) {
    console.log(this.selectedItem);
    this.TeamInfoList.forEach((element: TeamInfo) => {
      if (element.label == this.selectedItem.teamName) {
        this.selectedTeam = element;
      }
    });
    this.applicationInfoForm.patchValue({
      application: this.selectedItem.appName,
      itemNo: this.selectedItem.itemNo,
      teamInfoname: this.selectedTeam
    });
    this.applicationInfoForm.enable();
    this.addFlag = true;
    // this.onClickenable();
  }
  Save() {
    console.log("*****")
    console.log('this.selectedItem:', this.selectedItem);
    console.log('this.selectedTeam:', this.selectedTeam);
    console.log('applicationInfoForm:', this.applicationInfoForm.value);
    if (this.applicationInfoForm.valid) {
      this.result = new result();
      if (this.selectedItem) {
        if (this.selectedItem.appId) {
          console.log('this.selectedItem.appId:', this.selectedItem.appId);
          this.result = ({
            id: this.selectedItem.id,
            appName: this.applicationInfoForm.get('application').value,
            teamInfo: this.applicationInfoForm.get('teamInfoname').value,
            itemNo: this.applicationInfoForm.get('itemNo').value,
            appId: this.selectedItem.appId,
            hidProcessFlag: 'M'
          });
        }
      } else {
        this.result = ({
          id: '',
          appName: this.applicationInfoForm.get('application').value,
          teamInfo: this.applicationInfoForm.get('teamInfoname').value,
          itemNo: this.applicationInfoForm.get('itemNo').value,
          appId: '',
          hidProcessFlag: 'C'
        });
      }

      console.log('RESULT:', this.result);

      if (this.result) {
        this.service.modifyApplicationInfo(this.result).subscribe((data: response) => {
          this.response = data.result;
          if (this.response) {
            this.flagDisplay = true;
            this.addFlag = false;
            this.applicationInfoForm.disable();
            this.ngOnInit();
          }
        });
      }
    } else {
      this.flagDisplay = true;
      this.response = "Above fields are Mandatory";

    }
  }


  change() {
    this.selectedTeam = this.applicationInfoForm.get('teamInfoname').value;
    console.log(this.selectedTeam);
    this.applicationInfoForm.patchValue({
      teamInfoname: this.selectedTeam
    });
  }

  onClickenable() {
    this.applicationInfoForm.controls['application'].enable();
    this.applicationInfoForm.controls['teamInfoname'].enable();
    this.applicationInfoForm.controls['itemNo'].enable();
    this.addFlag = true;
    this.saveFlag = false;
    this.resetFlag = false;
    // this.selectedItem = ({
    //   id: '',
    //   appName: '',
    //   teamName: '',
    //   itemNo: '',
    //   appId: '',
    // });
  }

  reset() {
    this.applicationInfoForm.setValue({
      application: '',
      itemNo: '',
      teamInfoname: this.TeamInfoList
    });
    this.applicationInfoForm.controls['application'].disable();
    this.applicationInfoForm.controls['teamInfoname'].disable();
    this.applicationInfoForm.controls['itemNo'].disable();
    this.addFlag = false;
    this.saveFlag = true;
    this.resetFlag = true;
  }
  close() {
    this.flagDisplay = false;
  }
}
