import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UrlConsumerService} from './url-consumer.service';

//this is a dummy component, set up to do nothing deliberately.
//see the app-routing.module for more details.
@Component({
	template: ''
})
export class OAuthCallbackComponent {
}
