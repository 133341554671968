import { Injectable } from '@angular/core';
import {HttpClient ,HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import { Observable } from 'rxjs/internal/Observable';
import { result} from '../../core/model/ApplicationInfoModel';
import { ReviewAction, PersonData } from '../model/user-admin';




@Injectable({
  providedIn: 'root'
})
export class CreateUserService {

  private teamURI = environment.apdmServiceUrl;
  private headers: HttpHeaders;
  cdsId: any;
  token: any;

  constructor(private http: HttpClient) {
    this.token = JSON.parse(sessionStorage.getItem('strAccessToken'));
    if (this.token) {
      this.cdsId = this.token.CommonName;
    }
    //console.log(' cdsid--->', this.cdsId);
    if (this.cdsId !== undefined) {
      this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
    } else {
      this.cdsId = 'Invalid';
      this.headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', '*');
    }
  }



  getUserProfile(cdsid: string): Observable<any> {
    return this.http.get(this.teamURI + 'getcreateUser/' + cdsid , {
      headers: this.headers
    });
  }


  getOrganizationList() {
    return this.http.get(this.teamURI + 'getorganization');
  }


  createUser(personData: PersonData) : Observable<string> {
    console.log('Service PersonData:',personData);
    return this.http.post(this.teamURI + 'insertuser', personData, {
      responseType: 'text',
    });
  }

  getApplicationInfoList (){
    return this.http.get(this.teamURI + 'applicationinfolist');
  }


  getIntialTeamList (){
    return this.http.get(this.teamURI + 'appinfoteamlist');
  }

   modifyApplicationInfo(result : result){
     return this.http.post(this.teamURI + 'modifyapplicationinfo' , result);
   }


  getUserList(username: string ){
    console.log('cdsid:',username);
    return this.http.post(this.teamURI + 'togetuserslist' , username);
  }
  getReviewAction(review:ReviewAction): Observable<string>{
        console.log('REVIEWAcTion:',review);
    return this.http.post(this.teamURI + 'reviewaction' , review, {
      responseType: 'text',
    });
  }
  executeTransfer(review:ReviewAction): Observable<string>{
    console.log('executeTransfer:',review);
    return this.http.post(this.teamURI + 'executetransferassets' , review, {
      responseType: 'text',
    });

  }



}
