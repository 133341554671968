import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {  Subject } from 'app/core/model/teamAdministrationModel';
import { CreateUserService } from 'app/core/service/create-user.service';
import { ReviewAction } from 'app/core/model/user-admin';

@Component({
  selector: 'transfer-user',
  templateUrl: './transfer-user.component.html',
  styleUrls: ['./transfer-user.component.css']
})
export class TransferUserComponent implements OnInit {
  fromCdsIdForm: FormGroup;
  toCdsIdForm: FormGroup;
  reviewActionForm: FormGroup;
  userlist: Subject[];
  userTolist: Subject[];
  cdsId: string;
  review: ReviewAction;
  selectedList: string;
  selectedToList: string;
  getTeamName: string;
  titleTeamName: string;
  displayScreen: boolean = false;
  displayData: string;
  errorDisplay:boolean = false;
  transferError:string;
  getTeamNameTo:string;
  teamSelected:string[];

  constructor(private formBuilder: FormBuilder, private createuser: CreateUserService) { }

  ngOnInit() {
    this.fromCdsIdForm = this.formBuilder.group({
      teamName: ['', Validators.required],
    });

    this.toCdsIdForm = this.formBuilder.group({
      teamName: ['', Validators.required],
    });
    this.reviewActionForm = this.formBuilder.group({
      fromCdsId: [''],
      toCdsId: [''],
      selectedCheckBox: [''],
    });
    this.teamSelected = ['file', 'acl', 'team', 'dav', 'session', 'personal'];
    //this.reviewActionForm.get('selectedCheckBox').setValue(['file', 'acl', 'team', 'dav', 'session', 'personal']);
  }
  //fromsearch
  fromSearch() {
    if (this.fromCdsIdForm.valid) {
      if (this.fromCdsIdForm.value) {
        console.log('From search', this.fromCdsIdForm.value);
        this.cdsId = '';
        this.cdsId = this.fromCdsIdForm.get('teamName').value;
        //console.log('The cdsid:', this.cdsId)
        this.createuser.getUserList(this.cdsId).subscribe((response: Subject[]) => {
          console.log(response);
          if (response) {
            this.userlist = [];
            this.userlist = response;
            console.log(this.userlist);
          }
        });
      }
    }else{
      this.errorDisplay = true;
      this.transferError = '';
      this.transferError = 'Please input part of CDSID to start the search!';
    }
  }
  //tosearch
  toSearch() {
    if (this.toCdsIdForm.valid) {
      if (this.toCdsIdForm.value) {
        console.log('From search', this.toCdsIdForm.value);
        this.cdsId = '';
        this.cdsId = this.toCdsIdForm.get('teamName').value;
        //console.log('The cdsid:', this.cdsId)
        this.createuser.getUserList(this.cdsId).subscribe((response: Subject[]) => {
          console.log(response);
          if (response) {
            this.userTolist = [];
            this.userTolist = response;
            console.log(this.userTolist);
          }
        });
      }
    }else{
      this.errorDisplay = true;
      this.transferError = '';
      this.transferError = 'Please input part of CDSID to start the search!';
    }
  }
  reviewAction() {
    console.log('get team', this.selectedList);
    if (this.selectedList) {
      console.log('this.selectedList == IF')
      this.getTeamName = this.selectedList;
      this.titleTeamName = this.selectedList;
    } else {
      console.log('this.selectedList == ELSE');
      if (this.userlist) {
        for (var i = 0; i < this.userlist.length; i++) {
          console.log(this.userlist[i]);
          console.log(this.userlist[0].label);
          this.getTeamName = this.userlist[0].label;
          this.titleTeamName = this.userlist[0].label;
          break;
        }
      }
    }

    if (this.selectedToList) {
      console.log('this.selectedToList == IF')
      this.getTeamNameTo = this.selectedToList;
    } else {
      console.log('this.selectedToList == ELSE');
      if (this.userTolist) {
        for (var i = 0; i < this.userTolist.length; i++) {
          console.log(this.userTolist[i]);
          console.log(this.userTolist[0].label);
          this.getTeamNameTo = this.userTolist[0].label;
          break;
        }
      }
    }


    if (this.reviewActionForm.valid) {
      this.review = this.reviewActionForm.value;
      this.review.fromCdsId = this.getTeamName;
       this.review.toCdsId = this.getTeamNameTo;
       this.review.selectedCheckBox = [];
       this.review.selectedCheckBox = this.teamSelected;
       console.log(' this.review.selectedCheckBox', this.review.selectedCheckBox);
      if (this.review.fromCdsId || this.review.toCdsId) {
        this.createuser.getReviewAction(this.review).subscribe((response: string) => {
          console.log('REVIEW RESPONSE', response);
          if (response) {
            this.displayData = response;
            this.displayScreen = true;
          }
        });
      }
    }
  }
  executeTransfer() {
    if (this.reviewActionForm.value) {
      this.review = this.reviewActionForm.value;
      this.review.fromCdsId = this.getTeamName;
      this.review.toCdsId = this.getTeamNameTo;
      if (this.review.fromCdsId && this.review.toCdsId) {
        this.createuser.executeTransfer(this.review).subscribe((data:string) => {
          console.log('execute transfer');
          if(data){

            this.transferError = '';
            this.transferError = data;
            this.errorDisplay = true;
          }

        })
      }
    }
  }
  close(){
    this.errorDisplay = false;
  }
}


