import { Component, OnInit } from '@angular/core';
import { ItemAdministrationService } from 'app/core/service/item-administration.service';
import { ItemTypeList, ItemInitiationForm2, CreateItemPropertiesApi, VehicleProgramApi, VehicleConfigApi, SdrcReportApi, TransProgramApi, AttachFileApi } from 'app/core/model/itemAdminModels';
import { FormGroup, FormBuilder } from '@angular/forms';
import { EditPropertiesService } from 'app/core/service/edit-properties.service';
import { SearchService } from 'app/core/service/search.service';
import { Result } from 'app/core/model/searchModel';
import { SharedService } from 'app/core/service/shared.service';

@Component({
  selector: 'create-item',
  templateUrl: './create-item.component.html',
  styleUrls: ['./create-item.component.css']
})
export class CreateItemComponent implements OnInit {

  selectedItemType = new ItemTypeList();
  ownerCds: string = '';
  label: string = '';
  itemTypeOptions: ItemTypeList[];
  createItemObjectForm: FormGroup;
  showItemComponent: boolean = false;

  securityLevelOptions: ItemInitiationForm2[];
  retentionScheduleOptions: ItemInitiationForm2[];
  approvalOptions: ItemInitiationForm2[];
  defaultServerOptions: ItemInitiationForm2[];
  domainOptions: ItemInitiationForm2[];

  createItemPropertiesApi = new CreateItemPropertiesApi();
  vehicleProgramApi = new VehicleProgramApi();
  vehicleConfigApi = new VehicleConfigApi();
  sdrcReportApi = new SdrcReportApi();
  transProgramApi = new TransProgramApi();

  ownerSuggestions: Result;

  itemId: string = '';

  constructor(private formBuilder: FormBuilder, public _ItemAdministrationService: ItemAdministrationService,
    public _EditpropertiesService: EditPropertiesService, private search: SearchService, public sharedService: SharedService) { 
      
    }

  ngOnInit() {

    this.selectedItemType = {
      id: 0,
      label: 'root'
    }

    this.showItemComponent = false;

    this._ItemAdministrationService.getItemType().subscribe(data => {
      if (data.result) {
        this.itemTypeOptions = data.itemTypeList;
      }
    });

    this._EditpropertiesService.getSecurityList().subscribe(data => {
      this.securityLevelOptions = data;
    });

    this._EditpropertiesService.getRetScheduleList().subscribe(data => {
      this.retentionScheduleOptions = data;
      // console.log('retentionScheduleOptions ---->',this.retentionScheduleOptions);
    });

    this._EditpropertiesService.getApprovalList().subscribe(data => {
      this.approvalOptions = data;
     // this.approvalOptions[0] = { label: "None", value: "None" };
    });

    this._EditpropertiesService.getServerList().subscribe(data => {
      this.defaultServerOptions = data;
    });

    this._EditpropertiesService.getDomainListD().subscribe(data => {
      this.domainOptions = data;
      this.domainOptions[0] = { label: "None", value: "None" };
    });


    this.createItemObjectForm = this.formBuilder.group({

    });
  }

  Ownersearch(event) {
    this.search.getOwnerResult(event.query).subscribe((data: Result) => {
      this.ownerSuggestions = data;
    });
  }

  defineItem() {
    // console.log("////<======this.selectedItemType.id======>///", this.selectedItemType.id);
    // console.log("////<======this.selectedItemType.id======>///", this.selectedItemType.label);
    // console.log("////<======cdsid======>///", this.ownerCds);
    if (this.ownerCds == undefined
      || this.ownerCds == "") {
      alert("Owner does not Exist in TEAM Table");
    } else {
      this._ItemAdministrationService.checkOwner(this.ownerCds).subscribe(data => {
        // console.log('data ============>', data);
        if (data.length) {
          alert(data);
        } else {
          this.showItemComponent = true;
        }
      });
    }
  }

  createItem() {

    if (this.validateCreateItem()) {
      // console.log('<============Entering=========>');
      if (this.createItemObjectForm.value) {
        this.createItemPropertiesApi.type = this.selectedItemType.id;
        if (this.createItemObjectForm.value.itemObjectForm) {
          // console.log('itemObjectForm', this.createItemObjectForm.value.itemObjectForm);
          this._updateItemObject();
        }

        if (this.createItemObjectForm.value.testLabReqObjectForm) {
          this.createItemPropertiesApi.testRqNum = this.createItemObjectForm.value.testLabReqObjectForm.testRqNo
        }

        if (this.createItemObjectForm.value.testLabOptObjectForm) {
          this._updateTestLabOpt();
        }

        if (this.createItemObjectForm.value.vehicleProgramReqObjectForm) {
          this._updateVehicleProgramReq();
        }

        if (this.createItemObjectForm.value.vehicleProgramOptObjectForm) {
          this._updateVehicleProgramOpt();
        }
        this.createItemPropertiesApi.vehicleProgramApi = this.vehicleProgramApi;

        if (this.createItemObjectForm.value.vehicleConfigReqObjectForm) {
          this.vehicleConfigApi.vehicle_config_program = this.createItemObjectForm.value.vehicleConfigReqObjectForm.vehicleProgram
          this.vehicleConfigApi.vehicle_config_cur_approval = this.createItemObjectForm.value.vehicleConfigReqObjectForm.curApproval
        }

        if (this.createItemObjectForm.value.vehicleConfigOptObjectForm) {
          this._updateVehicleConfigOpt();
        }
        this.createItemPropertiesApi.vehicleConfigApi = this.vehicleConfigApi;

        if (this.createItemObjectForm.value.externalRefOptObjectForm) {
          this.createItemPropertiesApi.externalUrl = this.createItemObjectForm.value.externalRefOptObjectForm.toUrl;
        }

        if (this.createItemObjectForm.value.stdTypeOptObjectForm) {
          // this.createItemObjectForm.value.stdTypeOptObjectForm.name
          // this.createItemObjectForm.value.stdTypeOptObjectForm.comments
          // this.createItemObjectForm.value.stdTypeOptObjectForm.tableName
        }

        if (this.createItemObjectForm.value.transConfigReqObjectForm) {
          this.createItemPropertiesApi.transconfigProgram = this.createItemObjectForm.value.transConfigReqObjectForm.curApproval
          this.createItemPropertiesApi.transconfigCurApproval = this.createItemObjectForm.value.transConfigReqObjectForm.transProgram
        }

        if (this.createItemObjectForm.value.teamOptObjectForm) {
          // this.createItemObjectForm.value.teamOptObjectForm.name
          // this.createItemObjectForm.value.teamOptObjectForm.comments
        }

        if (this.createItemObjectForm.value.stdSubjectOptObjectForm) {
          this.createItemObjectForm.value.stdSubjectOptObjectForm.comments
        }

        if (this.createItemObjectForm.value.stdValueOptObjectForm) {
          this.createItemPropertiesApi.stdGuiOrder = this.createItemObjectForm.value.stdValueOptObjectForm.guiOrder
        }

        if (this.createItemObjectForm.value.stdTransArchOptObjectForm) {
          this.createItemPropertiesApi.stdGuiOrder = this.createItemObjectForm.value.stdTransArchOptObjectForm.guiOrder
        }

        if (this.createItemObjectForm.value.stdEngineFuelOptObjectForm) {
          this.createItemPropertiesApi.stdGuiOrder = this.createItemObjectForm.value.stdEngineFuelOptObjectForm.guiOrder
        }

        if (this.createItemObjectForm.value.stdEngineArchOptObjectForm) {
          this.createItemPropertiesApi.stdGuiOrder = this.createItemObjectForm.value.stdEngineArchOptObjectForm.guiOrder
        }

        if (this.createItemObjectForm.value.stdDriveTrainArchOptObjectForm) {
          this.createItemPropertiesApi.stdGuiOrder = this.createItemObjectForm.value.stdDriveTrainArchOptObjectForm.guiOrder
        }

        if (this.createItemObjectForm.value.stdBodyStyleOptObjectForm) {
          this.createItemPropertiesApi.stdGuiOrder = this.createItemObjectForm.value.stdBodyStyleOptObjectForm.guiOrder
        }

        if (this.createItemObjectForm.value.sdrcItemOptObjectForm) {
          this._updateSdrcItemOpt();
        }

        if (this.createItemObjectForm.value.rosterReqObjectForm) {
          this.createItemPropertiesApi.added_manually = this.createItemObjectForm.value.rosterReqObjectForm.addedManually
          this.createItemPropertiesApi.joined_on = this.createItemObjectForm.value.rosterReqObjectForm.joinedOn
        }

        if (this.createItemObjectForm.value.rosterOptObjectForm) {
          this.createItemPropertiesApi.rosterTeam = this.createItemObjectForm.value.rosterReqObjectForm.team
          this.createItemPropertiesApi.rosterMember = this.createItemObjectForm.value.rosterReqObjectForm.member
        }

        if (this.createItemObjectForm.value.retScheduleOptObjectForm) {
          this._updateRetSchedule();
        }

        if (this.createItemObjectForm.value.refOptObjectForm) {
          this.createItemPropertiesApi.refToId = this.createItemObjectForm.value.refOptObjectForm.toID
          this.createItemPropertiesApi.refToType = this.createItemObjectForm.value.refOptObjectForm.toType
        }

        if (this.createItemObjectForm.value.powerTrainOptForm) {
          this.createItemPropertiesApi.transProgram = this.createItemObjectForm.value.powerTrainOptForm.transProgram
          this.createItemPropertiesApi.engineProgram = this.createItemObjectForm.value.powerTrainOptForm.engineProgram
        }

        if (this.createItemObjectForm.value.personReqForm) {
          this._personReq();
        }

        if (this.createItemObjectForm.value.personOptForm) {
          this._personOpt();
        }

        //Std Activity Create Method
        this._createStdActivity();

        // Attached Attr Create Method
        this._createAttachedAttr();

        // Attached File / File Create Method
        this._createAttachedFile();        

        if (this.createItemObjectForm.value.buildingOptObjectForm) {
          this._buildingOpt();
        }

        if (this.createItemObjectForm.value.stdSubjectOptObjectForm) {
          this._stdSubjectOpt();
        }

        if (this.createItemObjectForm.value.companyOptObjectForm) {
          this._companyOpt();
        }

        if (this.createItemObjectForm.value.stdDomainOptObjectForm) {
          this._stdDomainOpt();
        }

        if (this.createItemObjectForm.value.drawingOptReqObjectForm) {
          this._drawingOpt();
        }

        if (this.createItemObjectForm.value.engineForReqObjectForm) {
          this._engineReq();
        }

        if (this.createItemObjectForm.value.engineOptObjectForm) {
          this._engineOpt();
        }

        if (this.createItemObjectForm.value.engineOptObjectForm) {
          this._engineOpt();
        }

        if (this.createItemObjectForm.value.engineConfigReqObjectForm) {
          this._engineConfigReq();
        }

        if (this.createItemObjectForm.value.engineConfOptObjectForm) {
          this._engineConfigOpt();
        }

        if (this.createItemObjectForm.value.partPrefixOptForm) {
          this.createItemPropertiesApi.partPrefix = this.createItemObjectForm.value.partPrefixOptForm.prefix
        }

        if (this.createItemObjectForm.value.fileSdrcItmpartsOptObjectForm) {
          this._fileSdrcItmPartsOpt();
        }

        if (this.createItemObjectForm.value.fileSdrcItmPartsReqObjectForm) {
          this._fileSdrcItmPartsReq();
        }

        if (this.createItemObjectForm.value.partOptForm) {
          this._partOpt();
        }

        if (this.createItemObjectForm.value.partReqForm) {
          this.createItemPropertiesApi.partNum = this.createItemObjectForm.value.partOptForm.num
          // this.createItemPropertiesApi. = this.createItemObjectForm.value.partOptForm.num
        }

        if (this.createItemObjectForm.value.epimDrawingOptObjectForm) {
          this._epimDrawing();
        }

        if (this.createItemObjectForm.value.epimFileOptObjectForm) {
          this._epimFile();
        }

        if (this.createItemObjectForm.value.jlrCaeFolderOpt) {
          this._jlrcaeFolderOpt();
        }

        if (this.createItemObjectForm.value.jlrGroupRegisterReq) {
          this._jlrGroupRegisterReq();
        }

        if (this.createItemObjectForm.value.jlrGroupRegisterOpt) {
          this._jlrGroupRegisterOpt();
        }

        if (this.createItemObjectForm.value.davLockTokenReq) {
          this._davlockTokenReq();
        }
        if (this.createItemObjectForm.value.davLockTokenOpt) {
          this._davlockTokenOpt();
        }

        if (this.createItemObjectForm.value.nvhModelTestBookOpt) {
          this._nvhModelTestBookOpt();
        }

        if (this.createItemObjectForm.value.optegraOptForm) {
          this._optegraOpt();
        }

        if (this.createItemObjectForm.value.ptnvhqosTestOptForm) {
          this._updateptnvhqosTestOpt();
        }

        if (this.createItemObjectForm.value.ptnvhqosReportOptForm) {
          this._updateptnvhqosReportOpt();
        }
        if (this.createItemObjectForm.value.queryOptObjectForm) {
          this._updateQueryOpt();
        }

        if (this.createItemObjectForm.value.transProgramReqObjectForm) {
          this._updateTransProgReq();
        }

        if (this.createItemObjectForm.value.transProgramReqObjectForm) {
          this._updateTransProgOpt();
        }
        this.createItemPropertiesApi.transProgramApi = this.transProgramApi;

        this._ItemAdministrationService.postCreateAdItem(this.createItemPropertiesApi).subscribe(data => {
          this.itemId = '';
          // console.log('data ============>', data);
          alert(data); 
          if(data.includes('-')){
            var str = data;
            var splitted = str.split("-", 1);
            this.itemId = splitted[0];
            // console.log('------------------>',this.itemId);
            this.sharedService.createItemId.next(this.itemId);
          }          
        });

      }
    }
  }

  private _createStdActivity() {
    if (this.createItemObjectForm.value.stdActivityReqObjectForm) {
      this._stdActivityReq();
    }
    if (this.createItemObjectForm.value.stdActOptObjectForm) {
      this._stdActivityOpt();
    }
  }

  private _createAttachedAttr() {    
    if (this.createItemObjectForm.value.attachedAttrReqObjectForm) {
      this._attachedAttrReq();
    }
    if (this.createItemObjectForm.value.attachedAttrOptObjectForm) {
      this._attachedAttrOpt();
    }
  }

  private _createAttachedFile() {
    this.createItemPropertiesApi.attachFileApi = new AttachFileApi();
    if (this.createItemObjectForm.value.attchFileReqObjectForm) {
      this._attachedFileReq();
    }
    if (this.createItemObjectForm.value.attchFileOptObjectForm) {
      this._attachedFileOpt();
    }
  }

  _updateTransProgOpt() {
    this.transProgramApi.transprog_old_name = this.createItemObjectForm.value.transProgramOptObjectForm.oldName
    this.transProgramApi.transprog_end_of_life = this.createItemObjectForm.value.transProgramOptObjectForm.endOfLife;
    this.transProgramApi.transprog_comp_prog = this.createItemObjectForm.value.transProgramOptObjectForm.compProgram;
    this.transProgramApi.transprog_change_level = this.createItemObjectForm.value.transProgramOptObjectForm.changeLevel;
    this.transProgramApi.transprog_comments = this.createItemObjectForm.value.transProgramOptObjectForm.comments;
    this.transProgramApi.transprog_model_year_end = this.createItemObjectForm.value.transProgramOptObjectForm.modelYearEnd;
    this.transProgramApi.transprog_rms_prog_name = this.createItemObjectForm.value.transProgramOptObjectForm.rmsProgramName;
    this.transProgramApi.transprog_life_code = this.createItemObjectForm.value.transProgramOptObjectForm.lifeCode;
    this.transProgramApi.transprog_work_task_num = this.createItemObjectForm.value.transProgramOptObjectForm.workTaskNum;
    this.transProgramApi.transprog_continuous = this.createItemObjectForm.value.transProgramOptObjectForm.continuous;
    this.transProgramApi.transprog_pmt_domain = this.createItemObjectForm.value.transProgramOptObjectForm.pmtDomain;
    this.transProgramApi.transprog_list_domain = this.createItemObjectForm.value.transProgramOptObjectForm.listDomains;
    this.transProgramApi.transprog_lead_vehicle = this.createItemObjectForm.value.transProgramOptObjectForm.leadVehicle;
    this.transProgramApi.job_one = this.createItemObjectForm.value.transProgramOptObjectForm.jobOne;
    this.transProgramApi.end_of_life = this.createItemObjectForm.value.transProgramOptObjectForm.endOfLife;
    this.transProgramApi.kick_off = this.createItemObjectForm.value.transProgramOptObjectForm.kickOff;
  }

  _updateTransProgReq() {
    this.transProgramApi.transprog_alt_label = this.createItemObjectForm.value.transProgramReqObjectForm.altLabel;
    this.transProgramApi.transprog_cur_approval = this.createItemObjectForm.value.transProgramReqObjectForm.curApproval;
    this.transProgramApi.transprog_design_team = this.createItemObjectForm.value.transProgramReqObjectForm.brand;
    this.transProgramApi.transprog_config = this.createItemObjectForm.value.transProgramReqObjectForm.arch;
    this.transProgramApi.transprog_num_speeds = this.createItemObjectForm.value.transProgramReqObjectForm.numSpeeds;
    this.transProgramApi.transprog_drive_train = this.createItemObjectForm.value.transProgramReqObjectForm.driveTrain;
    this.transProgramApi.transprog_torque_capacity = this.createItemObjectForm.value.transProgramReqObjectForm.torqueCapacity;
    this.transProgramApi.transprog_model_year = this.createItemObjectForm.value.transProgramReqObjectForm.modelYear;
  }

  _fileSdrcItmPartsReq() {
    // this.createItemObjectForm.value.fileSdrcItmPartsReqObjectForm.fordNum
    // this.createItemObjectForm.value.fileSdrcItmPartsReqObjectForm.fordId
    // this.createItemObjectForm.value.fileSdrcItmPartsReqObjectForm.IdeasId
  }

  _personReq() {
    // this.createItemObjectForm.value.personReqForm.building
    // this.createItemObjectForm.value.personReqForm.hasCdsRecord
    // this.createItemObjectForm.value.personReqForm.company
    // this.createItemObjectForm.value.personReqForm.reportsTo
    // this.createItemObjectForm.value.personReqForm.storageClass
  }

  _fileSdrcItmPartsOpt() {
    // this.createItemObjectForm.value.fileSdrcItmpartsOptObjectForm.ideasNum
    // this.createItemObjectForm.value.fileSdrcItmpartsOptObjectForm.fordName
    // this.createItemObjectForm.value.fileSdrcItmpartsOptObjectForm.displayOrder
    // this.createItemObjectForm.value.fileSdrcItmpartsOptObjectForm.ideasRevision
    // this.createItemObjectForm.value.fileSdrcItmpartsOptObjectForm.tdmModifiedOn
    // this.createItemObjectForm.value.fileSdrcItmpartsOptObjectForm.ideasName
    // this.createItemObjectForm.value.fileSdrcItmpartsOptObjectForm.tdmCreator
    // this.createItemObjectForm.value.fileSdrcItmpartsOptObjectForm.ideasVersion
  }

  _personOpt() {
    // this.createItemObjectForm.value.personReqForm.country
    // this.createItemObjectForm.value.personReqForm.password
    // this.createItemObjectForm.value.personReqForm.application
    // this.createItemObjectForm.value.personReqForm.lastName
    // this.createItemObjectForm.value.personReqForm.unit
    // this.createItemObjectForm.value.personReqForm.division
    // this.createItemObjectForm.value.personReqForm.empType
    // this.createItemObjectForm.value.personReqForm.hasAccess
    // this.createItemObjectForm.value.personReqForm.reason
    // this.createItemObjectForm.value.personReqForm.name
    // this.createItemObjectForm.value.personReqForm.location
    // this.createItemObjectForm.value.personReqForm.organization
    // this.createItemObjectForm.value.personReqForm.email
    // this.createItemObjectForm.value.personReqForm.authorizer
    // this.createItemObjectForm.value.personReqForm.mailDrop
    // this.createItemObjectForm.value.personReqForm.firstName
    // this.createItemObjectForm.value.personReqForm.office
    // this.createItemObjectForm.value.personReqForm.department
    // this.createItemObjectForm.value.personReqForm.reauthorizedOn
    // this.createItemObjectForm.value.personReqForm.role
    // this.createItemObjectForm.value.personReqForm.businessCase
    // this.createItemObjectForm.value.personReqForm.phone
  }

  _partOpt() {
    this.createItemPropertiesApi.partBase = this.createItemObjectForm.value.partOptForm.base
    this.createItemPropertiesApi.partName = this.createItemObjectForm.value.partOptForm.name
    this.createItemPropertiesApi.partPrefix = this.createItemObjectForm.value.partOptForm.prefix
    this.createItemPropertiesApi.partdesignTeam = this.createItemObjectForm.value.partOptForm.designTeam
    this.createItemPropertiesApi.partChangeLevel = this.createItemObjectForm.value.partOptForm.changeLevel
    this.createItemPropertiesApi.partComments = this.createItemObjectForm.value.partOptForm.comments
    this.createItemPropertiesApi.partComparatorNum = this.createItemObjectForm.value.partOptForm.compNum
  }

  validateCreateItem(): boolean {
    let flag: boolean = true;
    var ItemType = this.selectedItemType.id;
    //alert("Item no: "+ItemType)				
    if (this.createItemObjectForm.value) {
      if (this.createItemObjectForm.value.itemObjectForm) {
        console.log('this.createItemObjectForm.value.itemObjectForm===>', this.createItemObjectForm.value.itemObjectForm);
        if (this.createItemObjectForm.value.itemObjectForm.label == "") {
          alert("Label is required"); flag = false;
        } else if (this.createItemObjectForm.value.itemObjectForm.steward == undefined
          || this.createItemObjectForm.value.itemObjectForm.steward == "") {
          alert("Steward is required"); flag = false;
        } else if (this.createItemObjectForm.value.itemObjectForm.parent == "") {
          alert("Parent is required"); flag = false;
        } else if (!this.validateInteger(this.createItemObjectForm.value.itemObjectForm.parent)) {
          alert("Please Enter Valid Parent Id"); flag = false;
        } else if (this.createItemObjectForm.value.itemObjectForm.domain == undefined 
          || this.createItemObjectForm.value.itemObjectForm.domain == ''
          || this.createItemObjectForm.value.itemObjectForm.domain.value == "None") {
          alert("Domain is required"); flag = false;
        } else if (this.createItemObjectForm.value.itemObjectForm.milestone != ""
          && !this.validateInteger(this.createItemObjectForm.value.itemObjectForm.milestone)) {
          alert("Please Enter Valid Milestone"); flag = false;
        } else if (this.createItemObjectForm.value.itemObjectForm.program != ""
          && !this.validateInteger(this.createItemObjectForm.value.itemObjectForm.program)) {
          alert("Please Enter Valid Program"); flag = false;
        } else if (this.createItemObjectForm.value.itemObjectForm.holdCount != ""
          && !this.validateInteger(this.createItemObjectForm.value.itemObjectForm.holdCount)) {
          alert("Please Enter Valid Hold_Count"); flag = false;
        } else if (this.createItemObjectForm.value.itemObjectForm.leader != ""
          && !this.validateInteger(this.createItemObjectForm.value.itemObjectForm.leader)) {
          alert("Please Enter Valid Leader"); flag = false;
        } else if (this.createItemObjectForm.value.itemObjectForm.expirationDate != ""
          && !this.ValidateDate(this.createItemObjectForm.value.itemObjectForm.expirationDate)) {
          flag = false;
        } else if (ItemType == 98) {
          if (this.createItemObjectForm.value.stdActivityReqObjectForm) {
            if (this.createItemObjectForm.value.stdActivityReqObjectForm.forDomain == ""
              || this.createItemObjectForm.value.stdActivityReqObjectForm.forType == "") {
              alert("Please Enter Required Fields for STD_ACTIVITY"); flag = false;
            } else if (this.createItemObjectForm.value.stdActivityReqObjectForm.forType != ""
              && !this.validateInteger(this.createItemObjectForm.value.stdActivityReqObjectForm.forType)) {
              alert("Please Enter Valid FOR_TYPE for STD_ACTIVITY"); flag = false;
            }
          }
          if (this.createItemObjectForm.value.stdActOptObjectForm) {
            if (this.createItemObjectForm.value.stdActOptObjectForm != ""
              && !this.validateInteger(this.createItemObjectForm.value.stdActOptObjectForm.mixin_type)) {
              alert("Please Enter Valid MIXIN_TYPE for STD_ACTIVITY"); flag = false;
            } else if (this.createItemObjectForm.value.stdActOptObjectForm.swizzleType != ""
              && !this.validateInteger(this.createItemObjectForm.value.stdActOptObjectForm.swizzle_type)) {
              alert("Please Enter Valid SWIZZLE_TYPE for STD_ACTIVITY"); flag = false;
            }
          }
        } else if (ItemType == 5) {
          if (this.createItemObjectForm.value.stdPartNameReqObjectForm) {
            if (this.createItemObjectForm.value.stdPartNameReqObjectForm.base == "" ||
              this.createItemObjectForm.value.stdPartNameReqObjectForm.designTeam == "" ||
              this.createItemObjectForm.value.stdPartNameReqObjectForm.name == "") {
              alert("Please Enter Required Fields for STD_PART_NAME"); flag = false;
            }
          }
        } else if (ItemType == 24) {
          if (document.forms[0].roster_team == "" || document.forms[0].roster_member == "") {
            alert("Please Enter Required Fields for ROSTER"); flag = false;
          }
        } else if (ItemType == 32 || ItemType == 63) {
          if (this.createItemObjectForm.value.attchFileReqObjectForm) {
            if (this.createItemObjectForm.value.attchFileReqObjectForm.version == "") {
              alert("Please Enter Required Fields for FILE Item"); flag = false;
            }
          }
          if (this.createItemObjectForm.value.attchFileOptObjectForm) {
            if (this.createItemObjectForm.value.attchFileOptObjectForm.accsOn != ""
              && !this.ValidateDate(this.createItemObjectForm.value.attchFileOptObjectForm.accsOn)) {
              alert("Please Enter Valid Date for ACCESSED_ON");
              flag = false;
            } else if (this.createItemObjectForm.value.attchFileOptObjectForm.delfrmDskOn != ""
              && !this.ValidateDate(this.createItemObjectForm.value.attchFileOptObjectForm.delfrmDskOn)) {
              alert("Please Enter Valid Date for DELETED_FROM_DISK _ON");
              flag = false;
            }
          }
        } else if (ItemType == 40) {
          if (this.createItemObjectForm.value.partReqForm) {
            if (this.createItemObjectForm.value.partReqForm.num == "") {
              alert("Please Enter Required Fields for Part Item"); flag = false;
            }
          }
        } else if (ItemType == 42) {
          if (this.createItemObjectForm.value.vehicleProgramReqObjectForm) {
            if (this.createItemObjectForm.value.vehicleProgramReqObjectForm.altLabel == ""
              || this.createItemObjectForm.value.vehicleProgramReqObjectForm.brand == ""
              || this.createItemObjectForm.value.vehicleProgramReqObjectForm.curApproval == ""
              || this.createItemObjectForm.value.vehicleProgramReqObjectForm.namePlate == ""
              || this.createItemObjectForm.value.vehicleProgramReqObjectForm.programCode == ""
              || this.createItemObjectForm.value.vehicleProgramReqObjectForm.modelYear == "") {
              alert("Please Enter Required Fields for VEHICLE_PROGRAM"); flag = false;
            }
          }
        } else if (ItemType == 44) {
          if (this.createItemObjectForm.value.transProgramReqObjectForm) {
            if (this.createItemObjectForm.value.transProgramReqObjectForm.altLabel == ""
              || this.createItemObjectForm.value.transProgramReqObjectForm.curApproval == ""
              || this.createItemObjectForm.value.transProgramReqObjectForm.numSpeeds == ""
              || this.createItemObjectForm.value.transProgramReqObjectForm.driveTrain == ""
              || this.createItemObjectForm.value.transProgramReqObjectForm.torqueCapacity == ""
              || this.createItemObjectForm.value.transProgramReqObjectForm.modelYear == ""
              || this.createItemObjectForm.value.transProgramReqObjectForm.brand == ""
              || this.createItemObjectForm.value.transProgramReqObjectForm.arch == "") {
              alert("Please Enter Required Fields for TRANS_PROGRAM"); flag = false;
            }
          }
        } else if (ItemType == 46) {
          if (this.createItemObjectForm.value.vehicleConfigReqObjectForm
            && this.createItemObjectForm.value.vehicleConfigReqObjectForm.vehicleProgram == "") {
            alert("Please Enter Required Fields for VEHICLE_CONFIG"); flag = false;
          }
        } else if (ItemType == 47) {
          if (this.createItemObjectForm.value.engineConfigReqObjectForm
            && this.createItemObjectForm.value.engineConfigReqObjectForm.engProgram == "") {
            alert("Please Enter Required Fields for ENGINE_CONFIG"); flag = false;
          }
        } else if (ItemType == 48) {
          if (this.createItemObjectForm.value.transConfigReqObjectForm
            && this.createItemObjectForm.value.transConfigReqObjectForm.transProgram == "") {
            alert("Please Enter Required Fields for TRANS_CONFIG"); flag = false;
          }
        } else if (ItemType == 49) {
          if (this.createItemObjectForm.value.engineForReqObjectForm
            && this.createItemObjectForm.value.engineForReqObjectForm.serialNum == "") {
            alert("Please Enter Required Fields for ENGINE"); flag = false;
          }
        } else if (ItemType == 62) {
          if (this.createItemObjectForm.value.attachedAttrReqObjectForm
            && this.createItemObjectForm.value.attachedAttrReqObjectForm.name == "") {
            alert("Please Enter Required Fields for ATTACHED_ATTR"); flag = false;
          }
        } else if (ItemType == 110) {
          if (this.createItemObjectForm.value.testLabReqObjectForm
            && this.createItemObjectForm.value.testLabReqObjectForm.testRqNo == "") {
            alert("Please Enter Required Fields for TESTLAB"); flag = false;
          }
        }
      }
    }
    // alert("Creating a new item...");
    return flag;
  }

  validateInteger(strValue): boolean {
    var objRegExp = /(^-?\d\d*$)/;
    return objRegExp.test(strValue);
  }

  ValidateDate(date): boolean {
    //var dt=document.frmSample.txtDate
    //alert("Date-->"+date.value);
    let flag: boolean = true;
    // if (this.isDate(date) == false) {
    //   flag = false;
    // }
    return flag;
  }

  // isDate(dtStr): boolean {
  //   let flag: boolean = true
  //   var dtCh = "-";
  //   var daysInMonth = this.DaysArray(12)
  //   var pos1 = dtStr.indexOf(dtCh)
  //   var pos2 = dtStr.indexOf(dtCh, pos1 + 1)
  //   var strMonth = dtStr.substring(0, pos1)
  //   var strDay = dtStr.substring(pos1 + 1, pos2)
  //   var strYear = dtStr.substring(pos2 + 1)
  //   var strYr = strYear
  //   if (strDay.charAt(0) == "0" && strDay.length > 1) strDay = strDay.substring(1)
  //   if (strMonth.charAt(0) == "0" && strMonth.length > 1) strMonth = strMonth.substring(1)
  //   for (var i = 1; i <= 3; i++) {
  //     if (strYr.charAt(0) == "0" && strYr.length > 1) strYr = strYr.substring(1)
  //   }
  //   var month = parseInt(strMonth)
  //   var day = parseInt(strDay)
  //   var year = parseInt(strYr)
  //   if (pos1 == -1 || pos2 == -1) {
  //     alert("The date format should be : mm/dd/yyyy")
  //     flag = false;
  //   }
  //   if (strMonth.length < 1 || month < 1 || month > 12) {
  //     alert("Please enter a valid month")
  //     flag = false;
  //   }
  //   if (strDay.length < 1 || day < 1 || day > 31 || (month == 2 && day > this.daysInFebruary(year)) || day > daysInMonth[month]) {
  //     alert("Please enter a valid day")
  //     flag = false;
  //   }
  //   if (dtStr.indexOf(dtCh, pos2 + 1) != -1 || this.isInteger(this.stripCharsInBag(dtStr, dtCh)) == false) {
  //     alert("Please enter a valid date")
  //     flag = false;
  //   }
  //   return flag;
  // }

  DaysArray(n) {
    for (var i = 1; i <= n; i++) {
      this[i] = 31
      if (i == 4 || i == 6 || i == 9 || i == 11) { this[i] = 30 }
      if (i == 2) { this[i] = 29 }
    }
    return this
  }

  daysInFebruary(year) {
    // February has 29 days in any year evenly divisible by four,
    // EXCEPT for centurial years which are not also divisible by 400.
    return (((year % 4 == 0) && ((!(year % 100 == 0)) || (year % 400 == 0))) ? 29 : 28);
  }

  isInteger(s): boolean {
    var i;
    let flag: boolean = true;
    for (i = 0; i < s.length; i++) {
      // Check that current character is number.
      var c = s.charAt(i);
      if (((c < "0") || (c > "9"))) flag = false;
    }
    // All characters are numbers.
    return flag;
  }

  stripCharsInBag(s, bag): string {
    var i;
    var returnString = "";
    // Search through string's characters one by one.
    // If character is not in bag, append to returnString.
    for (i = 0; i < s.length; i++) {
      var c = s.charAt(i);
      if (bag.indexOf(c) == -1) returnString += c;
    }
    return returnString;
  }

  _engineConfigOpt() {
    this.createItemPropertiesApi.engineConfigApi.true_num_values = this.createItemObjectForm.value.engineConfOptObjectForm.trueNumVal
    this.createItemPropertiesApi.engineConfigApi.max_torque = this.createItemObjectForm.value.engineConfOptObjectForm.maxTorq
    this.createItemPropertiesApi.engineConfigApi.engine_config_comments = this.createItemObjectForm.value.engineConfOptObjectForm.comments
    this.createItemPropertiesApi.engineConfigApi.max_rpm = this.createItemObjectForm.value.engineConfOptObjectForm.maxRpm
    this.createItemPropertiesApi.engineConfigApi.torque_curve = this.createItemObjectForm.value.engineConfOptObjectForm.torqCurve
    this.createItemPropertiesApi.engineConfigApi.true_value_train = this.createItemObjectForm.value.engineConfOptObjectForm.trueValTrain
    this.createItemPropertiesApi.engineConfigApi.true_displacement = this.createItemObjectForm.value.engineConfOptObjectForm.trueValTrain
    this.createItemPropertiesApi.engineConfigApi.true_bank_angle = this.createItemObjectForm.value.engineConfOptObjectForm.truebankAngle
    this.createItemPropertiesApi.engineConfigApi.true_config = this.createItemObjectForm.value.engineConfOptObjectForm.trueConfig
    this.createItemPropertiesApi.engineConfigApi.true_fuel = this.createItemObjectForm.value.engineConfOptObjectForm.trueFuel
    this.createItemPropertiesApi.engineConfigApi.max_horse_power = this.createItemObjectForm.value.engineConfOptObjectForm.maxHorsPow
  }

  _engineConfigReq() {
    this.createItemPropertiesApi.engineConfigApi.engine_config_curappr = this.createItemObjectForm.value.engineConfigReqObjectForm.curApproval
    this.createItemPropertiesApi.engineConfigApi.engine_config_program = this.createItemObjectForm.value.engineConfigReqObjectForm.engProgram
  }

  _engineOpt() {
    this.createItemPropertiesApi.engine_worktask_num = this.createItemObjectForm.value.engineForReqObjectForm.wrktskNum
    this.createItemPropertiesApi.engine_part = this.createItemObjectForm.value.engineForReqObjectForm.part
  }

  _engineReq() {
    this.createItemPropertiesApi.engine_serial_num = this.createItemObjectForm.value.engineForReqObjectForm.serialNum
  }

  _drawingOpt() {
    this.createItemPropertiesApi.drawing_brass_tag = this.createItemObjectForm.value.drawingOptReqObjectForm.brasstag
    this.createItemPropertiesApi.drawing_purchase_order = this.createItemObjectForm.value.drawingOptReqObjectForm.purOrder
    this.createItemPropertiesApi.drawing_name = this.createItemObjectForm.value.drawingOptReqObjectForm.name
    this.createItemPropertiesApi.drawing_num = this.createItemObjectForm.value.drawingOptReqObjectForm.num
    this.createItemPropertiesApi.drawing_vendor = this.createItemObjectForm.value.drawingOptReqObjectForm.vendor
    this.createItemPropertiesApi.drawing_comments = this.createItemObjectForm.value.drawingOptReqObjectForm.comments
  }

  _stdDomainOpt() {
    this.createItemPropertiesApi.stdDomainComments = this.createItemObjectForm.value.stdDomainOptObjectForm.comments
    this.createItemPropertiesApi.stdDomainCode = this.createItemObjectForm.value.stdDomainOptObjectForm.code
  }

  _companyOpt() {
    this.createItemPropertiesApi.company_name = this.createItemObjectForm.value.companyOptObjectForm.name
    this.createItemPropertiesApi.company_fullname = this.createItemObjectForm.value.companyOptObjectForm.fullName
    this.createItemPropertiesApi.company_comments = this.createItemObjectForm.value.companyOptObjectForm.comments
  }

  _stdSubjectOpt() {
    this.createItemPropertiesApi.stdSubjectComments = this.createItemObjectForm.value.stdSubjectOptObjectForm.comments
  }

  _buildingOpt() {
    this.createItemPropertiesApi.building_coutry = this.createItemObjectForm.value.buildingOptObjectForm.country
    this.createItemPropertiesApi.building_street = this.createItemObjectForm.value.buildingOptObjectForm.street
    this.createItemPropertiesApi.building_comments = this.createItemObjectForm.value.buildingOptObjectForm.comments
    this.createItemPropertiesApi.building_fullname = this.createItemObjectForm.value.buildingOptObjectForm.fullName
    this.createItemPropertiesApi.building_city = this.createItemObjectForm.value.buildingOptObjectForm.city
    this.createItemPropertiesApi.building_name = this.createItemObjectForm.value.buildingOptObjectForm.name
    this.createItemPropertiesApi.building_postalcode = this.createItemObjectForm.value.buildingOptObjectForm.postCode
    this.createItemPropertiesApi.building_state = this.createItemObjectForm.value.buildingOptObjectForm.state


  }
  _attachedFileOpt() {

    this.createItemPropertiesApi.attachFileApi.file_actual_server = this.createItemObjectForm.value.attchFileOptObjectForm.actServer
    this.createItemPropertiesApi.attachFileApi.file_fmccrypt_key = this.createItemObjectForm.value.attchFileOptObjectForm.fmCrptKey
    this.createItemPropertiesApi.attachFileApi.file_orig_stored_size = this.createItemObjectForm.value.attchFileOptObjectForm.orgStrdSize
    this.createItemPropertiesApi.attachFileApi.File_view_count = this.createItemObjectForm.value.attchFileOptObjectForm.viewCount
    this.createItemPropertiesApi.attachFileApi.file_stored_size = this.createItemObjectForm.value.attchFileOptObjectForm.strSize
    this.createItemPropertiesApi.attachFileApi.file_external_mime_type = this.createItemObjectForm.value.attchFileOptObjectForm.extMimType
    this.createItemPropertiesApi.attachFileApi.file_mime_type = this.createItemObjectForm.value.attchFileOptObjectForm.mimType
    this.createItemPropertiesApi.attachFileApi.file_actual_size = this.createItemObjectForm.value.attchFileOptObjectForm.actSize
    this.createItemPropertiesApi.attachFileApi.file_mime_param = this.createItemObjectForm.value.attchFileOptObjectForm.mimParam
    this.createItemPropertiesApi.attachFileApi.file_is_encrypted = this.createItemObjectForm.value.attchFileOptObjectForm.isEncrpt.value
    this.createItemPropertiesApi.attachFileApi.file_st_mode = this.createItemObjectForm.value.attchFileOptObjectForm.stMode
    this.createItemPropertiesApi.attachFileApi.accessed_on = this.createItemObjectForm.value.attchFileOptObjectForm.accsOn
    this.createItemPropertiesApi.attachFileApi.file_compression = this.createItemObjectForm.value.attchFileOptObjectForm.accsOn
    this.createItemPropertiesApi.attachFileApi.file_checksum = this.createItemObjectForm.value.attchFileOptObjectForm.chkSum
    this.createItemPropertiesApi.attachFileApi.file_backup_path = this.createItemObjectForm.value.attchFileOptObjectForm.bckUpPath
    this.createItemPropertiesApi.attachFileApi.file_comments = this.createItemObjectForm.value.attchFileOptObjectForm.comments
    this.createItemPropertiesApi.attachFileApi.deleted_on = this.createItemObjectForm.value.attchFileOptObjectForm.delfrmDskOn
    this.createItemPropertiesApi.attachFileApi.file_path = this.createItemObjectForm.value.attchFileOptObjectForm.path
    this.createItemPropertiesApi.attachFileApi.file_ret_checkpoint = this.createItemObjectForm.value.attchFileOptObjectForm.rtChkPt
    this.createItemPropertiesApi.attachFileApi.file_mime_subtype = this.createItemObjectForm.value.attchFileOptObjectForm.mimSubType
  }

  _attachedAttrOpt() {
    this.createItemPropertiesApi.attached_attr_num = this.createItemObjectForm.value.attachedAttrOptObjectForm.numValue
    this.createItemPropertiesApi.attached_attr_str = this.createItemObjectForm.value.attachedAttrOptObjectForm.strValue
    this.createItemPropertiesApi.date_val = this.createItemObjectForm.value.attachedAttrOptObjectForm.dateValue
  }

  _attachedAttrReq() {
    this.createItemPropertiesApi.attached_attr_name = this.createItemObjectForm.value.attachedAttrReqObjectForm.name
  }

  _stdActivityOpt() {
    this.createItemPropertiesApi.mixin_type = this.createItemObjectForm.value.stdActOptObjectForm.mixinType
    this.createItemPropertiesApi.comments = this.createItemObjectForm.value.stdActOptObjectForm.comments
    this.createItemPropertiesApi.swizzle_type = this.createItemObjectForm.value.stdActOptObjectForm.swizzleType
  }

  _stdActivityReq() {
    this.createItemPropertiesApi.for_domain = this.createItemObjectForm.value.stdActivityReqObjectForm.forDomain
    this.createItemPropertiesApi.for_type = this.createItemObjectForm.value.stdActivityReqObjectForm.forType
  }

  _updateRetSchedule() {
    this.createItemPropertiesApi.ret_hist_status = this.createItemObjectForm.value.retScheduleOptObjectForm.historicalStatus
    this.createItemPropertiesApi.ret_category = this.createItemObjectForm.value.retScheduleOptObjectForm.category
    this.createItemPropertiesApi.ret_period = this.createItemObjectForm.value.retScheduleOptObjectForm.period
    this.createItemPropertiesApi.ret_title = this.createItemObjectForm.value.retScheduleOptObjectForm.title
    this.createItemPropertiesApi.ret_tax_status = this.createItemObjectForm.value.retScheduleOptObjectForm.taxStatus
  }

  _updateSdrcItemOpt() {
    this.sdrcReportApi.sdrc_metaphase_creator = this.createItemObjectForm.value.sdrcItemOptObjectForm.metaphaseCreator
    this.sdrcReportApi.sdrc_is_metaphase_frozen = this.createItemObjectForm.value.sdrcItemOptObjectForm.isMetaphaseFrozen
    this.sdrcReportApi.sdrc_tdm_id = this.createItemObjectForm.value.sdrcItemOptObjectForm.tdmID
    this.sdrcReportApi.sdrc_metaphase_id = this.createItemObjectForm.value.sdrcItemOptObjectForm.metaphaseId
    this.sdrcReportApi.sdrc_num = this.createItemObjectForm.value.sdrcItemOptObjectForm.num
    this.sdrcReportApi.sdrc_ideas_type = this.createItemObjectForm.value.sdrcItemOptObjectForm.ideasType
    this.sdrcReportApi.Tdm_created_on = this.createItemObjectForm.value.sdrcItemOptObjectForm.tdmCreatedOn
    // this.sdrcReportApi. = this.createItemObjectForm.value.sdrcItemOptObjectForm.curApproval
    this.sdrcReportApi.sdrc_last_checkin_user = this.createItemObjectForm.value.sdrcItemOptObjectForm.lastCheckinUser
    this.sdrcReportApi.sdrc_last_checkout_user = this.createItemObjectForm.value.sdrcItemOptObjectForm.tdmModifiedOn
    this.sdrcReportApi.sdrc_metaphase_class = this.createItemObjectForm.value.sdrcItemOptObjectForm.metaphaseClass
    this.sdrcReportApi.sdrc_name = this.createItemObjectForm.value.sdrcItemOptObjectForm.name
    this.sdrcReportApi.sdrc_tdm_project = this.createItemObjectForm.value.sdrcItemOptObjectForm.tdmProject
    this.sdrcReportApi.Metaphase_modified_on = this.createItemObjectForm.value.sdrcItemOptObjectForm.metaphaseModifiedOn
    this.sdrcReportApi.sdrc_revision = this.createItemObjectForm.value.sdrcItemOptObjectForm.revision
    this.sdrcReportApi.sdrc_is_metaphase_superceded = this.createItemObjectForm.value.sdrcItemOptObjectForm.isMetaphaseSuperceded
    this.sdrcReportApi.sdrc_tdm_library = this.createItemObjectForm.value.sdrcItemOptObjectForm.tdmLibrary
    this.sdrcReportApi.sdrc_last_checkout_user = this.createItemObjectForm.value.sdrcItemOptObjectForm.lastCheckOutUser
    this.sdrcReportApi.Metaphase_checkin_on = this.createItemObjectForm.value.sdrcItemOptObjectForm.metaphaseCheckinOn
    this.sdrcReportApi.sdrc_tdm_status = this.createItemObjectForm.value.sdrcItemOptObjectForm.tdmStatus
    this.sdrcReportApi.sdrc_tdm_creator = this.createItemObjectForm.value.sdrcItemOptObjectForm.tdmCreator
    this.sdrcReportApi.sdrc_is_metaphase_checkedout = this.createItemObjectForm.value.sdrcItemOptObjectForm.isMetaphaseCheckedOut
    this.sdrcReportApi.sdrc_version = this.createItemObjectForm.value.sdrcItemOptObjectForm.version
    this.sdrcReportApi.Metaphase_created_on = this.createItemObjectForm.value.sdrcItemOptObjectForm.metaphaseCreatedOn

    this.createItemPropertiesApi.sdrcReportApi = this.sdrcReportApi
  }

  _updateVehicleConfigOpt() {
    this.vehicleConfigApi.vehicle_config_engine = this.createItemObjectForm.value.vehicleConfigOptObjectForm.engine
    this.vehicleConfigApi.vehicle_config_trans = this.createItemObjectForm.value.vehicleConfigOptObjectForm.trans
    this.vehicleConfigApi.vehicle_config_zero_to_sixty = this.createItemObjectForm.value.vehicleConfigOptObjectForm.zeroToSixty
    this.vehicleConfigApi.vehicle_config_fuel_econ = this.createItemObjectForm.value.vehicleConfigOptObjectForm.fuelEconomy
    this.vehicleConfigApi.vehicle_config_body_style = this.createItemObjectForm.value.vehicleConfigOptObjectForm.bodyStyle
    this.vehicleConfigApi.vehicle_config_weight = this.createItemObjectForm.value.vehicleConfigOptObjectForm.weight
  }
  _updateVehicleProgramOpt() {
    this.vehicleProgramApi.vehicle_program_change_level = this.createItemObjectForm.value.vehicleProgramOptObjectForm.changeLevel
    this.vehicleProgramApi.vehicle_program_comparator_prog = this.createItemObjectForm.value.vehicleProgramOptObjectForm.compProg
    this.vehicleProgramApi.vehicle_program_list_domain = this.createItemObjectForm.value.vehicleProgramOptObjectForm.listDomain
    this.vehicleProgramApi.vehicle_program_comments = this.createItemObjectForm.value.vehicleProgramOptObjectForm.comments
    this.vehicleProgramApi.vehicle_program_end_of_life = this.createItemObjectForm.value.vehicleProgramOptObjectForm.endOfLife
    this.vehicleProgramApi.vehicle_program_model_year_end = this.createItemObjectForm.value.vehicleProgramOptObjectForm.modelYearEnd
    this.vehicleProgramApi.vehicle_program_job_one = this.createItemObjectForm.value.vehicleProgramOptObjectForm.jobOne
    this.vehicleProgramApi.vehicle_program_pmt_domain = this.createItemObjectForm.value.vehicleProgramOptObjectForm.pmtDomain
    this.vehicleProgramApi.vehicle_program_kick_off = this.createItemObjectForm.value.vehicleProgramOptObjectForm.kickOff
  }

  _updateVehicleProgramReq() {
    this.vehicleProgramApi.Vehicle_program_alt_label = this.createItemObjectForm.value.vehicleProgramReqObjectForm.altLabel
    this.vehicleProgramApi.vehicle_program_cur_approval = this.createItemObjectForm.value.vehicleProgramReqObjectForm.curApproval
    this.vehicleProgramApi.vehicle_program_brand =this.createItemObjectForm.value.vehicleProgramReqObjectForm.brand;
    this.vehicleProgramApi.vehicle_program_name_plate = this.createItemObjectForm.value.vehicleProgramReqObjectForm.namePlate
    this.vehicleProgramApi.vehicle_program_code = this.createItemObjectForm.value.vehicleProgramReqObjectForm.programCode
    this.vehicleProgramApi.vehicle_program_model_year = this.createItemObjectForm.value.vehicleProgramReqObjectForm.modelYear
  }


  _updateTestLabOpt() {
    this.createItemPropertiesApi.test_cert = this.createItemObjectForm.value.testLabOptObjectForm.certTest
    this.createItemPropertiesApi.test_type = this.createItemObjectForm.value.testLabOptObjectForm.testType
    this.createItemPropertiesApi.test_work_task_num = this.createItemObjectForm.value.testLabOptObjectForm.workTaskNum
    this.createItemPropertiesApi.test_unit_under_test = this.createItemObjectForm.value.testLabOptObjectForm.unitUnderTest
    this.createItemPropertiesApi.test_date = this.createItemObjectForm.value.testLabOptObjectForm.testDate
    this.createItemPropertiesApi.test_title = this.createItemObjectForm.value.testLabOptObjectForm.testTitle
  }

  _updateItemObject() {
    this.createItemPropertiesApi.id = this.createItemObjectForm.value.itemObjectForm.id
    this.createItemPropertiesApi.owner = this.createItemObjectForm.value.itemObjectForm.owner
    this.createItemPropertiesApi.steward = this.createItemObjectForm.value.itemObjectForm.steward.id
    this.createItemPropertiesApi.label = this.createItemObjectForm.value.itemObjectForm.label
    this.createItemPropertiesApi.default_server = this.createItemObjectForm.value.itemObjectForm.defaultServer.label
    this.createItemPropertiesApi.expiration_date = this.createItemObjectForm.value.itemObjectForm.expirationDate
    this.createItemPropertiesApi.ret_schedule = this.createItemObjectForm.value.itemObjectForm.retSchedule.value
    this.createItemPropertiesApi.parent = this.createItemObjectForm.value.itemObjectForm.parent
    this.createItemPropertiesApi.milestone = this.createItemObjectForm.value.itemObjectForm.milestone
    this.createItemPropertiesApi.notify_on_read_denied = this.createItemObjectForm.value.itemObjectForm.notifyOnReadDenied.value
    this.createItemPropertiesApi.program = this.createItemObjectForm.value.itemObjectForm.program
    this.createItemPropertiesApi.approvalRequired = this.createItemObjectForm.value.itemObjectForm.approvalRequired.value
    this.createItemPropertiesApi.hold_count = this.createItemObjectForm.value.itemObjectForm.holdCount
    this.createItemPropertiesApi.lock_on_checkout = this.createItemObjectForm.value.itemObjectForm.lockOnCheckOut.value
    this.createItemPropertiesApi.itemType = this.createItemObjectForm.value.itemObjectForm.type
    this.createItemPropertiesApi.security_level = this.createItemObjectForm.value.itemObjectForm.securityLevel.value
    this.createItemPropertiesApi.keep_all_versions = this.createItemObjectForm.value.itemObjectForm.keppAllVersion.value
    this.createItemPropertiesApi.leader = this.createItemObjectForm.value.itemObjectForm.leader
    this.createItemPropertiesApi.domain = this.createItemObjectForm.value.itemObjectForm.domain.value
    console.log('itemModifyForm //////', this.createItemPropertiesApi);
  }

  _optegraOpt() {
    this.createItemPropertiesApi.optegraPartType = this.createItemObjectForm.value.optegraOptForm.partType;
    this.createItemPropertiesApi.optegraRevision = this.createItemObjectForm.value.optegraOptForm.revision;
    this.createItemPropertiesApi.optegraMigratedOn = this.createItemObjectForm.value.optegraOptForm.migratedOn;
    this.createItemPropertiesApi.optegraComments = this.createItemObjectForm.value.optegraOptForm.comments;
    this.createItemPropertiesApi.optegraStatus = this.createItemObjectForm.value.optegraOptForm.optegraStatus;
  }

  _nvhModelTestBookOpt() {
    this.createItemPropertiesApi.display_order = this.createItemObjectForm.value.nvhModelTestBookOpt.displayOrder
  }

  _davlockTokenReq() {
    this.createItemPropertiesApi.lock_expires_on = this.createItemObjectForm.value.davLockTokenReq.expiresOn
  }

  _davlockTokenOpt() {
    this.createItemPropertiesApi.lock_started_on = this.createItemObjectForm.value.davLockTokenReq.startedOn
    this.createItemPropertiesApi.lock_depth = this.createItemObjectForm.value.davLockTokenReq.depth
  }

  _jlrGroupRegisterOpt() {
    this.createItemPropertiesApi.jlrGrpCheckFilter = this.createItemObjectForm.value.jlrGroupRegisterOpt.checkInFilter
  }

  _jlrGroupRegisterReq() {
    this.createItemPropertiesApi.jlrGrpAutofreeze = this.createItemObjectForm.value.jlrGroupRegisterReq.autoFreeze.value
  }

  _jlrcaeFolderOpt() {
    this.createItemPropertiesApi.jlrCaeKeyword = this.createItemObjectForm.value.jlrCaeFolderOpt.keywords
    this.createItemPropertiesApi.jlrCaeProjectId = this.createItemObjectForm.value.jlrCaeFolderOpt.projectId
    this.createItemPropertiesApi.jlrCaeComments = this.createItemObjectForm.value.jlrCaeFolderOpt.comments
    this.createItemPropertiesApi.jlrCaeMigrated = this.createItemObjectForm.value.jlrCaeFolderOpt.migratedOn
  }

  _folderOpt() {
    this.createItemPropertiesApi.folder_purchase_order = this.createItemObjectForm.value.folderOptForm.purchaseOrder
    this.createItemPropertiesApi.folder_vendor = this.createItemObjectForm.value.folderOptForm.vendor
    this.createItemPropertiesApi.folder_project_id = this.createItemObjectForm.value.folderOptForm.projectId
    this.createItemPropertiesApi.folder_comments = this.createItemObjectForm.value.folderOptForm.comments
  }

  _attachedFileReq() {
    this.createItemPropertiesApi.attachFileApi.file_is_default = this.createItemObjectForm.value.attchFileReqObjectForm.isDefault.value
    this.createItemPropertiesApi.attachFileApi.file_version = this.createItemObjectForm.value.attchFileReqObjectForm.version
  }

  _epimFile() {
    // this.createItemPropertiesApi.attachFileApi.dis= this.createItemObjectForm.value.epimFileOptObjectForm.dispOrder
    // dispOrder
  }

  _epimDrawing() {
    this.createItemPropertiesApi.drawing_brass_tag = this.createItemObjectForm.value.epimDrawingOptObjectForm.brasTag
    //this.createItemPropertiesApi.drawing_p this.createItemObjectForm.value.epimDrawingOptObjectForm.progName
    //this.createItemPropertiesApi.drawing_= this.createItemObjectForm.value.epimDrawingOptObjectForm.dispOrder
    // this.createItemPropertiesApi.display_order= this.createItemObjectForm.value.epimDrawingOptObjectForm.dispOrder
    this.createItemPropertiesApi.drawing_vendor = this.createItemObjectForm.value.epimDrawingOptObjectForm.vendName
    this.createItemPropertiesApi.drawing_purchase_order = this.createItemObjectForm.value.epimDrawingOptObjectForm.purchOrder
    this.createItemPropertiesApi.drawing_num = this.createItemObjectForm.value.epimDrawingOptObjectForm.drawNum
  }

  _updateptnvhqosReportOpt() {
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_eng_displacement = this.createItemObjectForm.value.ptnvhqosReportOptForm.engineDisplacement
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_vehicle_configdesc = this.createItemObjectForm.value.ptnvhqosReportOptForm.ptnvhqosVehicleConfigDesc
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_driver_seat_type = this.createItemObjectForm.value.ptnvhqosReportOptForm.driverSeatType
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_other_vehicle_specs = this.createItemObjectForm.value.ptnvhqosReportOptForm.otherVehicleSpecs
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_eng_cyl_config = this.createItemObjectForm.value.ptnvhqosReportOptForm.engineCyclinderConfig
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_vehbuildlevel = this.createItemObjectForm.value.ptnvhqosReportOptForm.vehBuildLevel
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_condition = this.createItemObjectForm.value.ptnvhqosReportOptForm.ptnvhqosCondition
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_gear_sel_location = this.createItemObjectForm.value.ptnvhqosReportOptForm.gearSelectorLocation
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_body_style = this.createItemObjectForm.value.ptnvhqosReportOptForm.bodyStyle
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_veh_opt_or_pack = this.createItemObjectForm.value.ptnvhqosReportOptForm.vehOptionOrPackages
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_vehicle_prog = this.createItemObjectForm.value.ptnvhqosReportOptForm.vehicleProgram
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_wheel_type = this.createItemObjectForm.value.ptnvhqosReportOptForm.wheelType
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_trim_level = this.createItemObjectForm.value.ptnvhqosReportOptForm.trimLevel
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_orig_date = this.createItemObjectForm.value.ptnvhqosReportOptForm.OrigDate
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_fuel_type = this.createItemObjectForm.value.ptnvhqosReportOptForm.fuelType
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_market = this.createItemObjectForm.value.ptnvhqosReportOptForm.market
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_vehicle_type = this.createItemObjectForm.value.ptnvhqosReportOptForm.vehicleType
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_tire_size = this.createItemObjectForm.value.ptnvhqosReportOptForm.tireSize
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_tire_manufacture = this.createItemObjectForm.value.ptnvhqosReportOptForm.tireManufature
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_num_cylinder = this.createItemObjectForm.value.ptnvhqosReportOptForm.noOfCylinders
    //this.createItemPropertiesApi.ptnvhqosReportApi.p=this.createItemObjectForm.value.ptnvhqosReportOptForm.endDate
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_assblyplantcode = this.createItemObjectForm.value.ptnvhqosReportOptForm.assblyPlantCode
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_test_metric_desc = this.createItemObjectForm.value.ptnvhqosReportOptForm.testMetricDesc
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_valvetrainconfig = this.createItemObjectForm.value.ptnvhqosReportOptForm.valveTrainConfig
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_vehicle_config = this.createItemObjectForm.value.ptnvhqosReportOptForm.ptnvhqosVehicleConfig
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_orig_time = this.createItemObjectForm.value.ptnvhqosReportOptForm.OrigTime
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_vehicle_model = this.createItemObjectForm.value.ptnvhqosReportOptForm.vehicleModel
    //this.createItemPropertiesApi.ptnvhqosReportApi.pt=this.createItemObjectForm.value.ptnvhqosReportOptForm.startDate
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_valves_per_cylinder = this.createItemObjectForm.value.ptnvhqosReportOptForm.valvesPerCylinder
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_roof_options = this.createItemObjectForm.value.ptnvhqosReportOptForm.roofOptions
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_tire_type = this.createItemObjectForm.value.ptnvhqosReportOptForm.tireType
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_vin = this.createItemObjectForm.value.ptnvhqosReportOptForm.ptnvhqosVin
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_driveline_type = this.createItemObjectForm.value.ptnvhqosReportOptForm.drivelineType
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_seat_material = this.createItemObjectForm.value.ptnvhqosReportOptForm.seatMaterial
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_number_of_doors = this.createItemObjectForm.value.ptnvhqosReportOptForm.noOfDoors
    this.createItemPropertiesApi.ptnvhqosReportApi.ptnvhqos_report_transmission_type = this.createItemObjectForm.value.ptnvhqosReportOptForm.transmissionType
  }

  _updateptnvhqosTestOpt() {
    // this.ptnvhqosTestApi = this.createItemObjectForm.value.ptnvhqosTestOptForm;
    // this.createItemPropertiesApi.ptnvhqosTestApi = this.ptnvhqosTestApi;
    // console.log('this.createItemPropertiesApi.ptnvhqosTestApi',this.createItemPropertiesApi.ptnvhqosTestApi);
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_speedstddev = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosSpeedDev
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_order3 = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosOrder3
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_fanspeedhighlimit = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosFansSpeedHighlimit
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_vehicle_config_disc = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosVehicleConfigDesc
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_driver_seat_type = this.createItemObjectForm.value.ptnvhqosTestOptForm.driverSeatType
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_amb_humidity = this.createItemObjectForm.value.ptnvhqosTestOptForm.ambHumidityUnits
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_thresh_steering = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosThreshSteering
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_polynomial_range = this.createItemObjectForm.value.ptnvhqosTestOptForm.polynomialRange
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_fanspeedmax = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosMaxFanSpeed
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_engine_cyl_config = this.createItemObjectForm.value.ptnvhqosTestOptForm.engineCylinderConfirmation
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_highsweepspeed = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosHighSweepsSpeed
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_minfanspeed_operc = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosMinFanSpeedOperc
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_ordercut_width = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosOrderCutWidth
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_trans_temp_units = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosTransTempUnits
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_amb_airpress_units = this.createItemObjectForm.value.ptnvhqosTestOptForm.ambAirPressureUnits
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_idlerpmquality = this.createItemObjectForm.value.ptnvhqosTestOptForm.idlerPmQuality
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_vib_orderopt = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosVibOrderOpt
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_gear_sele_loc = this.createItemObjectForm.value.ptnvhqosTestOptForm.gearSelectorLocation
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_amb_humidity = this.createItemObjectForm.value.ptnvhqosTestOptForm.ambHumidity
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_veh_opt_or_pack = this.createItemObjectForm.value.ptnvhqosTestOptForm.vehOptionsOrPackages
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_body_style = this.createItemObjectForm.value.ptnvhqosTestOptForm.bodyStyle
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_vehicle_program = this.createItemObjectForm.value.ptnvhqosTestOptForm.vehicleProgram
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_fanspeedavg = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosFanSpeedDavg
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_idle_ver = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosIdleVer
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_default_snd_fftsize = this.createItemObjectForm.value.ptnvhqosTestOptForm.defaultSndFftsize
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_pot_proc = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosPotProc
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_wot_ver = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosWotVer
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_tire_manuf = this.createItemObjectForm.value.ptnvhqosTestOptForm.tireManufacture
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_num_cyl = this.createItemObjectForm.value.ptnvhqosTestOptForm.numberOfCylinder
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_fftopt_method = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosFftOptMethod
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_snd_orderopt = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosSndOrderOpt
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_assblyplantcode = this.createItemObjectForm.value.ptnvhqosTestOptForm.assblyPlantCode
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_threshcheck = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosThresCheck
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_highanalspeed = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosHighanalSpeed
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_vehicle_model = this.createItemObjectForm.value.ptnvhqosTestOptForm.vehicleModel
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_roof_opt = this.createItemObjectForm.value.ptnvhqosTestOptForm.roofOptions
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_sweepavgfactor = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosSweepavFactor
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_vin = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosVin
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_tire_type = this.createItemObjectForm.value.ptnvhqosTestOptForm.tireType
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_freqresolution = this.createItemObjectForm.value.ptnvhqosTestOptForm.freqresolution
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_driveline_type = this.createItemObjectForm.value.ptnvhqosTestOptForm.drivelineType
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_amb_windspeed = this.createItemObjectForm.value.ptnvhqosTestOptForm.ambWindSpeed
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_no_of_doors = this.createItemObjectForm.value.ptnvhqosTestOptForm.noOfDoors
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_fanspeedlowlimit = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosFansSpeedLowLimit
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_engine_displacement = this.createItemObjectForm.value.ptnvhqosTestOptForm.engineDisplacement
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_lowanalspeed = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosLowanalSpeed
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_fanspeedmin = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosFanSpeedLimit
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_basefilename = this.createItemObjectForm.value.ptnvhqosTestOptForm.baseFileName
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_condition = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosCondition
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_amb_airpressure = this.createItemObjectForm.value.ptnvhqosTestOptForm.ambAirPressure
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_trans_temp = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosTransTemp
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_order2 = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosOrder2
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_neru_proc = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosNeruProc
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_other_vehicle_spec = this.createItemObjectForm.value.ptnvhqosTestOptForm.otherVehicleSpecs
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_neru_ver = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosNeruVer
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_amb_temp = this.createItemObjectForm.value.ptnvhqosTestOptForm.ambTemp
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_vehbuildlevel = this.createItemObjectForm.value.ptnvhqosTestOptForm.vehBuildLevel
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_default_vib_fftsize = this.createItemObjectForm.value.ptnvhqosTestOptForm.defaultVibFftSize
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_amb_temp_units = this.createItemObjectForm.value.ptnvhqosTestOptForm.ambTempUnits
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_wheel_type = this.createItemObjectForm.value.ptnvhqosTestOptForm.wheelType
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_lowsweepspeed = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosLowSweepSpeed
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_amb_windspeed_units = this.createItemObjectForm.value.ptnvhqosTestOptForm.ambWindSpeedUnits
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_trim_level = this.createItemObjectForm.value.ptnvhqosTestOptForm.trimLevel
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_description = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosTestDesc
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_speedstddev = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosSpeedStdDev
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_sweepcheck = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosSweepCheck
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_threshmethod = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosThreshMethod
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_order4 = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosOrder4
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_wot_proc = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosWotProc
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_ordercut_method = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosOrderCutMethod
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_fuel_type = this.createItemObjectForm.value.ptnvhqosTestOptForm.fuelType
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_orig_date = this.createItemObjectForm.value.ptnvhqosTestOptForm.origDate
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_thresh_seat = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosThreshSeat
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_pot_ver = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosPotVer
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_operator = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosOperator
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_order1 = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosOrder1
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_num_repairs = this.createItemObjectForm.value.ptnvhqosTestOptForm.numRepairs
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_veh_build_date = this.createItemObjectForm.value.ptnvhqosTestOptForm.vehBuildDate
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_vehicle_type = this.createItemObjectForm.value.ptnvhqosTestOptForm.vehicleType
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_tire_size = this.createItemObjectForm.value.ptnvhqosTestOptForm.tireSize
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_percent_repairs = this.createItemObjectForm.value.ptnvhqosTestOptForm.percentRepairs
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_opcondition = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosOpCondition
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_numberoffailed = this.createItemObjectForm.value.ptnvhqosTestOptForm.numberOfFailed
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_fanrangetype = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosFanRangeType
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_valvetrainconfig = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosValveTrainConfig
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_vehicle_config = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosVehicleConfig
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_orig_time = this.createItemObjectForm.value.ptnvhqosTestOptForm.origTime
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_targetspeed = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosTargetSpeed
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_per_cyl = this.createItemObjectForm.value.ptnvhqosTestOptForm.valuesPerCylinder
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_testcondition = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosTestCondition
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_idle_proc = this.createItemObjectForm.value.ptnvhqosTestOptForm.ptnvhqosIdleProc
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_assblyplantloc = this.createItemObjectForm.value.ptnvhqosTestOptForm.assblyPlantLoc
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_seat_material = this.createItemObjectForm.value.ptnvhqosTestOptForm.seatMaterial
    this.createItemPropertiesApi.ptnvhqosTestApi.ptnvhqos_test_transmission_type = this.createItemObjectForm.value.ptnvhqosTestOptForm.transmissionType
  }
  _updateQueryOpt() {

    this.createItemPropertiesApi.childQuery = this.createItemObjectForm.value.queryOptObjectForm.childQuery;

  }



  formInitialized(name: string, form: FormGroup) {
    this.createItemObjectForm.setControl(name, form);
  }

}
