import { UrlConsumerService } from '../oauth/url-consumer.service';
import { AuthGuardService } from '../oauth/auth-guard.service';
import { OAuthCallbackComponent } from '../oauth/oauth-callback.component';
import { UnderConstructionComponent } from '../error-page/under-construction/under-construction.component';
import { LoginComponent } from '../oauth/login.component';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AdminComponent } from '../admin/admin.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { CreateUserComponent } from '../admin/create-user/create-user.component';
import { CreateTeamComponent } from './create-team/create-team.component';
import { ModifyTeamComponent } from './modify-team/modify-team.component';
import { ModifyUserComponent } from './modify-user/modify-user.component';
import { ListUserComponent } from './list-user/list-user.component';
import { TransferUserComponent } from './transfer-user/transfer-user.component';
import { CreateAclComponent } from './create-acl/create-acl.component';

import { ListTeamComponent } from './list-team/list-team.component';
import { ListmembershipTeamComponent } from './listmembership-team/listmembership-team.component';
import { UserNotifyComponent } from './user-notify/user-notify.component';
import { OstMetricsComponent } from './ost-metrics/ost-metrics.component';

import { InitialAfrComponent } from './initial-afr/initial-afr.component';
import { AfrInitalProcessComponent } from './afr-inital-process/afr-inital-process.component';
import { AfrReportComponent } from './afr-report/afr-report.component';
import { AfrHelpComponent } from './afr-help/afr-help.component';
import { AfrFordlevelComponent } from './afr-fordlevel/afr-fordlevel.component';
import { AfrRetentioncodeComponent } from './afr-retentioncode/afr-retentioncode.component';
import { RetentionComponent } from './retention/retention.component';

import { ApplicationInfoComponent } from './application-info/application-info.component';
import { ModifyProgramComponent } from './modify-program/modify-program.component';
import { AfrReportsComponent } from './afr-reports/afr-reports.component';
import { AppReauthorizationComponent } from './app-reauthorization/app-reauthorization.component';
import { ListItemComponent } from './list-item/list-item.component';
import { AfrHomeComponent } from './afr-home/afr-home.component';
import { CreateItemComponent } from './create-item/create-item.component';
import { DeleteFilesComponent } from './delete-files/delete-files.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { AccountAuthComponentComponent } from './account-auth-component/account-auth-component.component';

export const Adminroutes: Routes = [
	{
		path: '',
		redirectTo: '/admin-portal/login',
		pathMatch: 'full'
	},
	{
		path: 'admin-portal',
		component: AdminComponent,
		children: [
			{
				path: 'create-user',
				component: CreateUserComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'modifyUser',
				component: ModifyUserComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'team-create',
				component: CreateTeamComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'team-modify',
				component: ModifyTeamComponent,
				canActivate: [AuthGuardService]
			}, {
				path: 'listUser',
				component: ListUserComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'transferUserFile',
				component: TransferUserComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'list-team',
				component: ListTeamComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'notifyUser',
				component: UserNotifyComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'login',
				component: AdminLoginComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'listmember-team',
				component: ListmembershipTeamComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'ost-metrics',
				component: OstMetricsComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'modifyProgram/:type',
				component: ModifyProgramComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'createItem',
				component: CreateItemComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'listItem/:type',
				component: ListItemComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'modifyItem/:type',
				component: ListItemComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'accountAuth/:authYearStr/:authCompletionStr/:authReminderNoStr/:approverCdsidsStr/:teamIdsStr',
				component: AccountAuthComponentComponent,
			},
			{
				path: 'AnnualFileReview',
				component: InitialAfrComponent,
				canActivate: [AuthGuardService],
				children: [
					{
						path: 'runAnnualFileReview',
						component: AfrInitalProcessComponent,
						canActivate: [AuthGuardService]
					},
					{
						path: 'reportMain',
						component: AfrReportComponent,
						canActivate: [AuthGuardService]
					},
					{
						path: 'retentioncodelevel/:cdsid/:year',
						component: AfrRetentioncodeComponent,
						canActivate: [AuthGuardService]
					},
					{
						path: 'retentionfolderlevel/:cdsid/:year',
						component: AfrFordlevelComponent,
						canActivate: [AuthGuardService]
					},
					{
						path: 'retention',
						component: RetentionComponent,
						canActivate: [AuthGuardService]
					},
					{
						path: 'afrHelp',
						component: AfrHelpComponent,
						canActivate: [AuthGuardService]
					}, {
						path: 'afrhome/:cdsid/:year',
						component: AfrHomeComponent,
						data : {afrlink : true},
						canActivate: [AuthGuardService]
					},
					{
						path: 'menu/afrhome/:cdsid/:year',
						component: AfrHomeComponent,
						canActivate: [AuthGuardService]
					},
					{
						path: 'showDeletedLog/:cdsid/:year',
						component: DeleteFilesComponent,
						data : {afrlink : true},
						canActivate: [AuthGuardService]
					},
					{
						path: 'menu/showDeletedLog/:cdsid/:year',
						component: DeleteFilesComponent,
						canActivate: [AuthGuardService]
					},
				]
			}, {
				path: 'createAcl',
				component: CreateAclComponent,
				canActivate: [AuthGuardService]
			}, {
				path: 'applicationInfo',
				component: ApplicationInfoComponent,
				canActivate: [AuthGuardService]
			}, {
				path: 'afrReports',
				component: AfrReportsComponent,
				canActivate: [AuthGuardService]
			},
			{
				path: 'appreauthorization',
				component: AppReauthorizationComponent,
				canActivate: [AuthGuardService]
				//https://localhost:4200/apdmweb/#/admin-portal/accountAuth?authYearStr=2020&authCompletionStr=2020&authReminderNoStr=1&approverCdsidsStr=chorbal&teamIdsStr=22
			},
			{
				path: 'error',
				component: ErrorMessageComponent,
				canActivate: [AuthGuardService]
			},
		],
		canActivate: [AuthGuardService]
	}
];